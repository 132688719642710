import { useTranslation } from 'react-i18next'
import { Form, Input } from 'antd'
import { SearchUser } from 'types/listUser'
import Utils from 'utils'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'

type Props = {
  handleSearch: (value: SearchUser) => void
  onFormFocus: () => void
  onFormBlur: () => void
}
export default function FormSearchBranch({
  handleSearch,
  onFormFocus,
  onFormBlur
}: Props) {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const onSearch = (values: any) => {
    handleSearch(values)
    form.setFieldsValue(Utils.trimObjectValues(values))
  }

  return (
    <Form
      layout="horizontal"
      className="flex items-center w-full"
      onFinish={onSearch}
      colon={false}
      initialValues={{ role: '' }}
      form={form}
      onFocus={onFormFocus}
      onBlur={onFormBlur}
    >
      <Form.Item
        className="flex-1 label-custom-select text-sm font-medium"
        label={t('user.keyword')}
        name="keyword"
        rules={[{ validator: Validators.keywordValidator(t) }]}
      >
        <Input className="h-10" />
      </Form.Item>

      <Form.Item className="flex-3">
        <Button
          type="primary"
          className="ml-24"
          sizing="w-40 h-10"
          htmlType="submit"
        >
          {t('user.buttonSearch')}
        </Button>
      </Form.Item>
    </Form>
  )
}
