import React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import useAuth from 'hooks/useAuth'
import { Role } from 'models/User'

import Button from 'components/atoms/Button'

type Props = {
  userId?: number
  organizationId?: number
  disabled?: boolean
  handleOpenDeleteModal: () => void
}

export default function DeleteUserButton({
  userId,
  organizationId,
  disabled,
  handleOpenDeleteModal
}: Props) {
  const { t } = useTranslation()
  const { profile } = useAuth()

  const handleDelete = () => {
    !disabled && handleOpenDeleteModal()
  }

  if (profile?.id === userId) {
    // eslint-disable-next-line
    return <div />
  }

  if (
    profile?.role === Role.BA &&
    profile.manageOrganizations?.find((item) => item.id === organizationId)
  ) {
    return (
      <Button
        className={clsx(
          { 'bg-[#B80808]': !disabled },
          {
            'hover:!opacity-100 border border-solid border-[#d9d9d9] hover:!border-[#d9d9d9] bg-[#0000000a] text-[#00000040] hover:!text-[#00000040]':
              disabled
          }
        )}
        sizing="w-44 h-14"
        onClick={handleDelete}
        disabled={disabled}
      >
        {t('user.delete')}
      </Button>
    )
  }

  if (profile?.role === Role.UA || profile?.role === Role.SA) {
    return (
      <Button
        className={clsx(
          { 'bg-[#B80808]': !disabled },
          {
            'hover:!opacity-100 border border-solid border-[#d9d9d9] hover:!border-[#d9d9d9] bg-[#0000000a] text-[#00000040] hover:!text-[#00000040]':
              disabled
          }
        )}
        sizing="w-44 h-14"
        onClick={handleDelete}
        disabled={disabled}
      >
        {t('user.delete')}
      </Button>
    )
  }

  // eslint-disable-next-line
  return <div />
}

DeleteUserButton.defaultProps = {
  userId: undefined,
  organizationId: undefined,
  disabled: undefined
}
