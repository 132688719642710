import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function AnnotationSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.6705 7.61356L22.9452 6.88852C22.5054 6.44844 21.7921 6.44879 21.3523 6.88852L20.6738 7.56739L22.992 9.88559L23.6705 9.20711C24.1103 8.76696 24.1103 8.05368 23.6705 7.61356Z"
        fill="white"
      />
      <path
        d="M14.3164 13.9247V16.2429H16.6346L22.3601 10.5174L20.042 8.19922L14.3164 13.9247Z"
        fill="white"
      />
      <path d="M15.278 7.07812H8.73047V8.16938H15.278V7.07812Z" fill="white" />
      <path d="M15.278 11.0781H8.73047V12.1694H15.278V11.0781Z" fill="white" />
      <path
        d="M12.7317 15.0781H8.73047V16.1694H12.7317V15.0781Z"
        fill="white"
      />
      <path
        d="M18.5543 19.2788C18.5543 19.4461 18.4179 19.5822 18.2509 19.5825H5.75836C5.59141 19.5822 5.4557 19.4461 5.455 19.2788V3.75875C5.4557 3.59141 5.59141 3.45539 5.75836 3.455H18.2509C18.4179 3.45535 18.5543 3.59141 18.5543 3.75875V8.14254L20.0093 6.68754V3.75875C20.0086 2.78719 19.2228 2.00074 18.2509 2H5.75836C4.78719 2.00074 4.00074 2.78719 4 3.75875V19.2788C4.00074 20.2503 4.78719 21.0368 5.75836 21.0375H18.2509C19.2228 21.0368 20.0086 20.2503 20.0093 19.2788V14.4101L18.5543 15.8651V19.2788Z"
        fill="white"
      />
    </svg>
  )
}

function SelectedAnnotationSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.6705 7.61356L22.9452 6.88852C22.5054 6.44844 21.7921 6.44879 21.3523 6.88852L20.6738 7.56739L22.992 9.88559L23.6705 9.20711C24.1103 8.76696 24.1103 8.05368 23.6705 7.61356Z"
        fill="#609DF9"
      />
      <path
        d="M14.3164 13.9247V16.2429H16.6346L22.3601 10.5174L20.042 8.19922L14.3164 13.9247Z"
        fill="#609DF9"
      />
      <path
        d="M15.278 7.07812H8.73047V8.16938H15.278V7.07812Z"
        fill="#609DF9"
      />
      <path
        d="M15.278 11.0781H8.73047V12.1694H15.278V11.0781Z"
        fill="#609DF9"
      />
      <path
        d="M12.7317 15.0781H8.73047V16.1694H12.7317V15.0781Z"
        fill="#609DF9"
      />
      <path
        d="M18.5543 19.2788C18.5543 19.4461 18.4179 19.5822 18.2509 19.5825H5.75836C5.59141 19.5822 5.4557 19.4461 5.455 19.2788V3.75875C5.4557 3.59141 5.59141 3.45539 5.75836 3.455H18.2509C18.4179 3.45535 18.5543 3.59141 18.5543 3.75875V8.14254L20.0093 6.68754V3.75875C20.0086 2.78719 19.2228 2.00074 18.2509 2H5.75836C4.78719 2.00074 4.00074 2.78719 4 3.75875V19.2788C4.00074 20.2503 4.78719 21.0368 5.75836 21.0375H18.2509C19.2228 21.0368 20.0086 20.2503 20.0093 19.2788V14.4101L18.5543 15.8651V19.2788Z"
        fill="#609DF9"
      />
    </svg>
  )
}

export default function AnnotationIcon(
  props: Partial<CustomIconComponentProps> & { selected?: boolean }
) {
  const { selected, ...rest } = props

  if (selected) return <Icon component={SelectedAnnotationSvg} {...rest} />

  return <Icon component={AnnotationSvg} {...rest} />
}

AnnotationIcon.defaultProps = {
  selected: undefined
}
