import { useTranslation } from 'react-i18next'

export default function ResizeNotification() {
  const { t } = useTranslation()

  const shadow = 'shadow-[0_0_10px_0_#00000026]'
  const layout =
    'bg-white flex flex-col gap-[50px] w-[680px] h-[600px] px-[120px] py-[70px] rounded-[20px] text-center'

  return (
    <div className="h-full min-h-[800px] flex">
      <div className={`m-auto ${layout} ${shadow}`}>
        <img
          src="/assets/resize-guide.png"
          alt="resize screen guide"
          className="w-full h-full"
        />
        <div className="mx-auto w-[332px] text-4xl font-bold">
          {t('translation.resizeGuideMessage')}
        </div>
      </div>
    </div>
  )
}
