import clsx from 'clsx'
import { Floor } from 'models/Building'
import { useRecoilState } from 'recoil'
import currentViewerState from 'store/viewerStore'

import ShowBarIcon from 'components/icons/ShowBarIcon'

import ViewerFloorDetail from './ViewerFloorDetail'

type Props = {
  floors: Floor[] | undefined
}

export default function ViewerLeftBarDetailBuilding({ floors }: Props) {
  const [currentViewer, setCurrentViewer] = useRecoilState(currentViewerState)

  return (
    <>
      <div
        className={clsx(
          'absolute top-0 left-0 bottom-0 bg-[#162841] overflow-auto z-[500] grid grid-rows-[auto_auto_1fr]',
          {
            'min-w-[240px]': currentViewer.isOpenLeftMenu
          },
          {
            'w-0': !currentViewer.isOpenLeftMenu
          }
        )}
      >
        <ViewerFloorDetail floors={floors || []} />
      </div>

      <div
        className={clsx(
          'absolute top-3 cursor-pointer z-[500]',
          {
            'left-64': currentViewer.isOpenLeftMenu
          },
          {
            'left-4': !currentViewer.isOpenLeftMenu
          }
        )}
        onClick={() =>
          setCurrentViewer((prev) => ({
            ...prev,
            isOpenLeftMenu: !prev.isOpenLeftMenu
          }))
        }
      >
        <ShowBarIcon
          className={!currentViewer.isOpenLeftMenu ? 'rotate-180' : ''}
        />
      </div>
    </>
  )
}
