import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function PointMarkerSvg() {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99975 0C4.54177 0 0.117188 4.42528 0.117188 9.88256C0.117188 15.3398 7.88452 24 9.99975 24C12.1142 24 19.8823 15.3398 19.8823 9.88256C19.8823 4.42528 15.4578 0 9.99975 0ZM9.99975 14.4185C7.49414 14.4185 5.46384 12.3875 5.46384 9.88256C5.46384 7.37695 7.49414 5.34666 9.99975 5.34666C12.5047 5.34666 14.5357 7.37695 14.5357 9.88256C14.5357 12.3875 12.5047 14.4185 9.99975 14.4185Z"
        fill="white"
      />
    </svg>
  )
}

function SelectedPointMarkerSvg() {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99975 0C4.54177 0 0.117188 4.42528 0.117188 9.88256C0.117188 15.3398 7.88452 24 9.99975 24C12.1142 24 19.8823 15.3398 19.8823 9.88256C19.8823 4.42528 15.4578 0 9.99975 0ZM9.99975 14.4185C7.49414 14.4185 5.46384 12.3875 5.46384 9.88256C5.46384 7.37695 7.49414 5.34666 9.99975 5.34666C12.5047 5.34666 14.5357 7.37695 14.5357 9.88256C14.5357 12.3875 12.5047 14.4185 9.99975 14.4185Z"
        fill="#609DF9"
      />
    </svg>
  )
}

export default function PointMarkerIcon(
  props: Partial<CustomIconComponentProps> & { selected?: boolean }
) {
  const { selected, ...rest } = props

  if (selected) return <Icon component={SelectedPointMarkerSvg} {...rest} />

  return <Icon component={PointMarkerSvg} {...rest} />
}

PointMarkerIcon.defaultProps = {
  selected: undefined
}
