import React from 'react'

export default function PrintIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 1.5C6.70435 1.5 5.94129 1.81607 5.37868 2.37868C4.81607 2.94129 4.5 3.70435 4.5 4.5V6H19.5V4.5C19.5 3.70435 19.1839 2.94129 18.6213 2.37868C18.0587 1.81607 17.2956 1.5 16.5 1.5H7.5Z"
        fill="#F2F2F3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 13.5H7.5C7.10218 13.5 6.72064 13.658 6.43934 13.9393C6.15804 14.2206 6 14.6022 6 15V19.5C6 19.8978 6.15804 20.2794 6.43934 20.5607C6.72064 20.842 7.10218 21 7.5 21H16.5C16.8978 21 17.2794 20.842 17.5607 20.5607C17.842 20.2794 18 19.8978 18 19.5V15C18 14.6022 17.842 14.2206 17.5607 13.9393C17.2794 13.658 16.8978 13.5 16.5 13.5Z"
        fill="#F2F2F3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10.5C0 9.70435 0.316071 8.94129 0.87868 8.37868C1.44129 7.81607 2.20435 7.5 3 7.5H21C21.7956 7.5 22.5587 7.81607 23.1213 8.37868C23.6839 8.94129 24 9.70435 24 10.5V15C24 15.7956 23.6839 16.5587 23.1213 17.1213C22.5587 17.6839 21.7956 18 21 18H19.5V15C19.5 14.2044 19.1839 13.4413 18.6213 12.8787C18.0587 12.3161 17.2956 12 16.5 12H7.5C6.70435 12 5.94129 12.3161 5.37868 12.8787C4.81607 13.4413 4.5 14.2044 4.5 15V18H3C2.20435 18 1.44129 17.6839 0.87868 17.1213C0.316071 16.5587 0 15.7956 0 15V10.5ZM3.75 12C3.94891 12 4.13968 11.921 4.28033 11.7803C4.42098 11.6397 4.5 11.4489 4.5 11.25C4.5 11.0511 4.42098 10.8603 4.28033 10.7197C4.13968 10.579 3.94891 10.5 3.75 10.5C3.55109 10.5 3.36032 10.579 3.21967 10.7197C3.07902 10.8603 3 11.0511 3 11.25C3 11.4489 3.07902 11.6397 3.21967 11.7803C3.36032 11.921 3.55109 12 3.75 12Z"
        fill="#F2F2F3"
      />
    </svg>
  )
}
