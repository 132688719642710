import { useState } from 'react'
import { Page } from 'react-pdf'
import useIntersectionObserver from 'hooks/useIntersectionObserver'

type Props = {
  onViewPage: () => void
  pageNumber: number
  scale: number
}

export default function PdfPage({ onViewPage, pageNumber, scale }: Props) {
  const [isLoaded, setIsLoaded] = useState(false)

  const { ref }: any = useIntersectionObserver({
    onIntersecting: () => {
      isLoaded && onViewPage()
    },
    threshold: Number((0.6 / scale).toFixed(1))
  })

  return (
    <Page
      width={1500}
      scale={1}
      canvasRef={ref}
      loading={null}
      pageNumber={pageNumber}
      renderAnnotationLayer={false}
      renderTextLayer={false}
      onLoadSuccess={() => setIsLoaded(true)}
      className="[&>canvas]:!w-full [&>canvas]:!h-auto"
    />
  )
}
