type Props = {
  size?: 'large' | 'small'
}

export default function AirConditionIcon({ size }: Props) {
  if (size === 'small') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28}>
        <path
          d="M2.332 3.11h23.336c.43 0 .777.347.777.773v10.504a.776.776 0 0 1-.777.773h-1.953a.151.151 0 0 1-.145-.105l-1.21-3.61a.866.866 0 0 0-.82-.59H6.46a.866.866 0 0 0-.82.59l-1.21 3.61a.151.151 0 0 1-.145.105H2.332a.776.776 0 0 1-.777-.773V3.883c0-.426.347-.774.777-.774Zm9.266 5.199c.43 0 .777-.348.777-.774v-.762A.776.776 0 0 0 11.598 6H6.324a.776.776 0 0 0-.777.773v.762c0 .426.348.774.777.774Zm8.027-.774v-.762A.776.776 0 0 0 18.848 6h-.766a.776.776 0 0 0-.777.773v.762c0 .426.347.774.777.774h.766c.43 0 .777-.348.777-.774Zm3.492 0v-.762A.776.776 0 0 0 22.34 6h-.766a.776.776 0 0 0-.777.773v.762c0 .426.348.774.777.774h.766c.43 0 .777-.348.777-.774ZM6.29 14.957l.762-2.273a.156.156 0 0 1 .148-.106h13.602a.16.16 0 0 1 .148.106l.762 2.273c.035.098-.043.203-.148.203H6.438a.153.153 0 0 1-.149-.203Zm0 0"
          style={{
            stroke: 'none',
            fillRule: 'evenodd',
            fill: '#00cbbb',
            fillOpacity: 1
          }}
        />
        <path
          d="M9.137 23.57c-.067-1.152-.567-1.957-.969-2.601-.355-.57-.54-.883-.516-1.278.02-.367.235-1.015 1.078-2.101.149-.195.012-.48-.23-.48H6.602a.49.49 0 0 0-.426.25c-.45.788-.688 1.507-.727 2.19-.066 1.188.457 2.028.88 2.704.32.52.573.926.605 1.457.035.594-.211 1.363-.735 2.293a.294.294 0 0 0 .254.441h1.711a.494.494 0 0 0 .453-.3c.399-.934.567-1.774.52-2.575ZM15.945 23.57c-.066-1.152-.566-1.957-.968-2.601-.352-.57-.536-.883-.512-1.278.02-.367.234-1.015 1.074-2.101a.297.297 0 0 0-.23-.48h-1.895a.485.485 0 0 0-.426.25c-.453.788-.687 1.507-.726 2.19-.067 1.188.457 2.028.879 2.704.32.52.574.926.605 1.457.035.594-.215 1.363-.738 2.293a.297.297 0 0 0 .254.441h1.71a.494.494 0 0 0 .454-.3c.398-.934.566-1.774.52-2.575ZM22.758 23.57c-.067-1.152-.567-1.957-.969-2.601-.355-.57-.539-.883-.516-1.278.02-.367.235-1.015 1.079-2.101.148-.195.011-.48-.23-.48h-1.9a.49.49 0 0 0-.425.25c-.45.788-.688 1.507-.727 2.19-.066 1.188.457 2.028.88 2.704.32.52.573.926.605 1.457.035.594-.211 1.363-.735 2.293a.294.294 0 0 0 .254.441h1.711a.494.494 0 0 0 .453-.3c.399-.934.567-1.774.52-2.575Zm0 0"
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: '#00cbbb',
            fillOpacity: 1
          }}
        />
      </svg>
    )
  }
  return (
    <svg
      width="32"
      height="30"
      viewBox="0 0 32 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 0H31C31.552 0 32 0.445267 32 0.9939V14.497C32 15.0457 31.552 15.4909 31 15.4909H28.493C28.407 15.4909 28.33 15.4363 28.303 15.3548L26.748 10.7152C26.596 10.263 26.171 9.95888 25.691 9.95888H6.309C5.829 9.95888 5.404 10.263 5.252 10.7152L3.697 15.3548C3.67 15.4363 3.593 15.4909 3.507 15.4909H1C0.448 15.4909 0 15.0457 0 14.497V0.9939C0 0.445267 0.448 0 1 0ZM12.912 6.68199C13.464 6.68199 13.912 6.23672 13.912 5.68809V4.71009C13.912 4.16146 13.464 3.71619 12.912 3.71619H6.132C5.58 3.71619 5.132 4.16146 5.132 4.71009V5.68809C5.132 6.23672 5.58 6.68199 6.132 6.68199H12.912ZM23.232 5.68809V4.71009C23.232 4.16146 22.784 3.71619 22.232 3.71619H21.248C20.696 3.71619 20.248 4.16146 20.248 4.71009V5.68809C20.248 6.23672 20.696 6.68199 21.248 6.68199H22.232C22.784 6.68199 23.232 6.23672 23.232 5.68809ZM27.723 5.68809V4.71009C27.723 4.16146 27.275 3.71619 26.723 3.71619H25.739C25.187 3.71619 24.739 4.16146 24.739 4.71009V5.68809C24.739 6.23672 25.187 6.68199 25.739 6.68199H26.723C27.275 6.68199 27.723 6.23672 27.723 5.68809ZM6.08496 15.2301L7.06496 12.308C7.09196 12.2265 7.16896 12.1719 7.25496 12.1719H24.744C24.83 12.1719 24.907 12.2265 24.934 12.308L25.914 15.2301C25.957 15.3583 25.861 15.4915 25.724 15.4915H6.27496C6.13796 15.4915 6.04196 15.3593 6.08496 15.2301Z"
        fill="#00CBBB"
      />
      <path
        d="M9.74665 26.3029C9.66041 24.8216 9.01668 23.7876 8.5 22.9575C8.04569 22.2285 7.81007 21.8239 7.83933 21.3151C7.86551 20.8467 8.13963 20.0127 9.22304 18.6162C9.41632 18.3657 9.24152 18 8.92736 18H6.48979C6.26417 18 6.05396 18.1198 5.94154 18.3174C5.36095 19.3327 5.05756 20.257 5.00751 21.1377C4.92127 22.6626 5.59503 23.7448 6.13635 24.6146C6.54985 25.2783 6.87556 25.8026 6.9156 26.4857C6.95949 27.2474 6.64148 28.237 5.96849 29.4328C5.82604 29.6857 6.00776 30 6.29574 30H8.49538C8.74717 30 8.97741 29.8506 9.07751 29.6164C9.59034 28.416 9.80671 27.3338 9.74665 26.3029Z"
        fill="#00CBBB"
      />
      <path
        d="M18.503 26.3029C18.4168 24.8216 17.773 23.7876 17.2563 22.9575C16.802 22.2285 16.5664 21.8239 16.5957 21.3151C16.6219 20.8467 16.896 20.0127 17.9794 18.6162C18.1727 18.3657 17.9979 18 17.6837 18H15.2461C15.0205 18 14.8103 18.1198 14.6979 18.3174C14.1173 19.3327 13.8139 20.257 13.7639 21.1377C13.6776 22.6626 14.3514 23.7448 14.8927 24.6146C15.3062 25.2783 15.6319 25.8026 15.672 26.4857C15.7158 27.2474 15.3978 28.237 14.7248 29.4328C14.5824 29.6857 14.7641 30 15.0521 30H17.2517C17.5035 30 17.7338 29.8506 17.8339 29.6164C18.3467 28.416 18.5631 27.3338 18.503 26.3029Z"
        fill="#00CBBB"
      />
      <path
        d="M27.2593 26.3029C27.1731 24.8216 26.5294 23.7876 26.0127 22.9575C25.5584 22.2285 25.3228 21.8239 25.352 21.3151C25.3782 20.8467 25.6523 20.0127 26.7357 18.6162C26.929 18.3657 26.7542 18 26.4401 18H24.0025C23.7769 18 23.5667 18.1198 23.4542 18.3174C22.8736 19.3327 22.5703 20.257 22.5202 21.1377C22.434 22.6626 23.1077 23.7448 23.649 24.6146C24.0625 25.2783 24.3883 25.8026 24.4283 26.4857C24.4722 27.2474 24.1542 28.237 23.4812 29.4328C23.3387 29.6857 23.5205 30 23.8084 30H26.0081C26.2599 30 26.4901 29.8506 26.5902 29.6164C27.103 28.416 27.3194 27.3338 27.2593 26.3029Z"
        fill="#00CBBB"
      />
    </svg>
  )
}

AirConditionIcon.defaultProps = {
  size: 'large'
}
