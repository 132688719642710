import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from 'antd'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'

type Props = {
  isOpenConfirm: string
  setIsOpenConfirm: Dispatch<SetStateAction<string>>
}

export default function ConfirmMoveToOtherPoint({
  isOpenConfirm,
  setIsOpenConfirm
}: Props) {
  const { t } = useTranslation()

  const handleClose = () => {
    setIsOpenConfirm('')
  }

  const title = () => {
    if (isOpenConfirm === 'point') {
      return 'building.confirmChangePointTitle'
    }
    if (isOpenConfirm === 'name') {
      return 'building.confirmSettingPointTitle'
    }
    return 'building.confirmDeletePointTitle'
  }

  const description = () => {
    if (isOpenConfirm === 'point') {
      return 'building.confirmChangePoint'
    }
    return 'building.confirmSetting'
  }

  return (
    <Modal
      width={580}
      onCancel={handleClose}
      rootClassName="shared-permission-modal"
      isEnterable
      onEnter={handleClose}
    >
      <Title title={t(title())} bottomMargin="mb-8" />

      <div className="flex flex-col gap-10 text-center">
        <Typography className="text-[14px] leading-[21px]">
          {t(description())}
        </Typography>

        <Button
          sizing="w-[140px] h-[50px] mx-auto"
          onClick={handleClose}
          type="primary"
        >
          {t('building.OK')}
        </Button>
      </div>
    </Modal>
  )
}
