import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams
} from 'react-router-dom'
import useAuth from 'hooks/useAuth'

export default function ProtectedRoute() {
  const { profile } = useAuth()
  const location = useLocation()

  const [searchParams] = useSearchParams()

  if (!profile)
    return (
      <Navigate
        to={searchParams.get('signin') === 'shimizu' ? 'smz_signin' : 'signin'}
        state={{ from: location }}
        replace
      />
    )

  return <Outlet />
}
