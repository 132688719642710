import { ConfigProvider } from 'antd'
import jaJP from 'antd/locale/ja_JP'
import useAuth from 'hooks/useAuth'
import Routes from 'routes'

import ResizeContainer from 'components/atoms/ResizeContainer'
import Spinner from 'components/atoms/Spinner'

import 'dayjs/locale/ja'

export default function App() {
  const { firstLoading } = useAuth({ revalidateOnMount: true })

  if (firstLoading) return <Spinner />

  return (
    <ConfigProvider
      locale={jaJP}
      theme={{
        token: {
          colorPrimary: '#3163B7',
          colorError: '#B80808',
          fontSize: 16
        },
        components: {
          Dropdown: {
            colorBgElevated: '#3163B7',
            colorText: '#fff',
            controlHeight: 36
          },
          Table: {
            colorIcon: '#ffff'
          },
          Pagination: {
            colorBgContainer: '#3163B7',
            colorPrimary: '#3163B7',
            lineWidth: 1,
            colorBorder: '#3163B7',
            colorPrimaryHover: '#d6d7d9'
          }
        }
      }}
    >
      <ResizeContainer>
        <Routes />
      </ResizeContainer>
    </ConfigProvider>
  )
}
