import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function UserGroupSvg() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5668 8.87511C11.5668 10.5547 10.203 11.9162 8.52074 11.9162C6.83845 11.9162 5.47468 10.5547 5.47468 8.87511C5.47468 7.19554 6.83845 5.83398 8.52074 5.83398C10.203 5.83398 11.5668 7.19554 11.5668 8.87511ZM20.7397 6.95109C22.1933 8.40233 22.1933 10.7552 20.7397 12.2065C19.2861 13.6577 16.9294 13.6577 15.4758 12.2065C14.0222 10.7552 14.0222 8.40233 15.4758 6.95109C16.9294 5.49985 19.2861 5.49985 20.7397 6.95109ZM25.6501 19.3012C25.195 16.5797 23.2976 14.3455 20.7693 13.4082C20.0132 13.9337 19.0961 14.2427 18.107 14.2427C17.1179 14.2427 16.2008 13.9333 15.4447 13.4082C12.9164 14.3455 11.019 16.5797 10.5639 19.3012C10.4488 19.9882 10.9236 20.6354 11.6142 20.7359C15.9199 21.3626 20.2937 21.3626 24.5994 20.7359C25.2899 20.6354 25.7644 19.9882 25.6497 19.3012H25.6501ZM10.8907 12.0837C11.7305 12.4272 12.4786 12.9475 13.089 13.5983V13.5991C11.4842 14.753 10.3088 16.4371 9.79611 18.3684C7.59615 18.4443 5.39097 18.3238 3.20711 18.006C2.64213 17.9236 2.2534 17.3941 2.34757 16.8316C2.70852 14.6738 4.17691 12.8904 6.1495 12.0837C6.81307 12.5734 7.63318 12.8639 8.52008 12.8639C9.40698 12.8639 10.2271 12.5734 10.8907 12.0837Z"
        fill="#021120"
      />
    </svg>
  )
}

export default function UserGroupIcon(
  props: Partial<CustomIconComponentProps>
) {
  return <Icon component={UserGroupSvg} {...props} />
}
