import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Button, Typography } from 'antd'
import { Building } from 'models/Building'
import AddFloorModal from 'pages/building/buildingDetail/components/ModalAddFloor'
import { useRecoilState } from 'recoil'
import { revalidateBuildingDetailState } from 'store/buildingStore'
import useSWR from 'swr'

export default function DetailBuilding() {
  const { id } = useParams()
  const { t } = useTranslation()
  const [addFloorModal, setAddFloorModal] = useState<boolean>(false)
  const handleCancelAddFloor = () => setAddFloorModal(false)
  const [revalidateBuildingDetail, setRevalidateBuildingDetail] =
    useRecoilState(revalidateBuildingDetailState)

  const {
    data: building,
    mutate,
    isValidating
  } = useSWR<Building>(
    {
      url: `/v1/buildings/${id}`
    },
    {
      revalidateOnMount: false
    }
  )

  useEffect(() => {
    if (revalidateBuildingDetail) {
      setRevalidateBuildingDetail(false)
      mutate()
    }
  }, [revalidateBuildingDetail, mutate, setRevalidateBuildingDetail])

  return (
    <div className="m-auto">
      {building?.floors && !isValidating ? (
        <div className="py-[100px] px-20 rounded-[20px] bg-white shadow-[0_0_10px_0_#00000026]">
          <div className="text-[#000000] text-xl font-bold">
            {t('translation.selectFloorNotification')}
          </div>
        </div>
      ) : null}

      {!building?.floors && !isValidating ? (
        <div
          className="bg-white w-[460px] items-center flex flex-col rounded-[20px] h-[330px] justify-center gap-[40px] mx-auto"
          style={{ boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)' }}
        >
          <Typography className="text-[20px] font-[700]">
            {t('common.floor.unregisterNotice')}
          </Typography>
          <Button
            className="w-[300px] h-[60px] rounded-[4px] bg-[#3163B7]"
            onClick={() => setAddFloorModal(true)}
          >
            <Typography className="text-[16px] font-[700] text-white">
              {t('common.floor.register')}
            </Typography>
          </Button>
        </div>
      ) : null}

      {addFloorModal && <AddFloorModal handleCancel={handleCancelAddFloor} />}
    </div>
  )
}
