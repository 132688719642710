import { useTranslation } from 'react-i18next'
import { Typography } from 'antd'
import { FloorPointElement } from 'types/building'

type Props = {
  floor: FloorPointElement
}

export default function FloorPoint({ floor }: Props) {
  const { t } = useTranslation()
  const { id, name, totalImage360, totalPoint } = floor
  return (
    <div
      key={id}
      className="flex items-center justify-between px-[10px] text-[#fff] text-[12px] truncate"
    >
      <Typography.Text
        className="text-[#fff] text-[12px] truncate w-[120px]"
        ellipsis={{
          tooltip: name
        }}
      >
        {name}
      </Typography.Text>
      <div className="flex text-[#fff] text-[12px] items-center gap-[2px]">
        <Typography className="text-[#fff] text-[12px]">
          {totalImage360} {t('translation.sheet')}
        </Typography>
        /
        <Typography className="text-[#fff] text-[12px]">
          {totalPoint}&nbsp;{t('translation.part')}
        </Typography>
      </div>
    </div>
  )
}
