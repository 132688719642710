import { Dispatch, SetStateAction } from 'react'
import { Typography } from 'antd'
import { useRecoilValue } from 'recoil'
import { toggleResizeNotificationState } from 'store/displayToggleStore'
import useSWR from 'swr'
import { BuildingFile } from 'types/building'

import Spinner from 'components/atoms/Spinner'
import LeftArrowIcon from 'components/icons/LeftArrowIcon'

import ViewerImage from './ViewerImage'
import ViewerPdf from './ViewerPdf'
import ViewerVideo from './ViewerVideo'

type Props = {
  fileIndex: number
  file: any
  maxLength: number
  setPreviewFileIndex: Dispatch<SetStateAction<number | undefined>>
}

export default function ViewerFilePreview({
  fileIndex,
  file,
  maxLength,
  setPreviewFileIndex
}: Props) {
  const videoTypes = 'mp4,mov'
  const imageTypes = 'jpg,png,jpeg'
  const pdfType = 'pdf'
  const fileExtension = file.extension.toLowerCase()
  const isOpenResizeNotification = useRecoilValue(toggleResizeNotificationState)

  // get file s3path on every render
  const { data: fileDetail, isValidating } = useSWR<BuildingFile>({
    url: `/v1/buildings/files/${file.id}`
  })

  const handleClosePreview = () => {
    setPreviewFileIndex(undefined)
  }

  const moveToNextFile = () => {
    fileIndex < maxLength - 1 && setPreviewFileIndex((prev: any) => prev + 1)
  }

  const moveToPreviousFile = () => {
    fileIndex > 0 && setPreviewFileIndex((prev: any) => prev - 1)
  }

  if (isOpenResizeNotification) {
    return null
  }

  return (
    <div
      className={`absolute w-full top-0 bottom-0 left-0 z-[600] bg-[#000000CC] grid grid-rows-[auto_1fr] overflow-auto ${
        isOpenResizeNotification ? '' : ''
      }`}
    >
      <div className="sticky top-0 w-full h-[50px] grid grid-cols-[auto_1fr] items-center gap-[10px] px-5 mb-[-50px] z-20">
        <div className="cursor-pointer" onClick={handleClosePreview}>
          <LeftArrowIcon color="#ffffff" size={24} />
        </div>
        <div
          className="truncate"
          style={{
            textShadow: '2px 2px #000000'
          }}
        >
          <Typography.Text
            className="font-bold text-white"
            ellipsis={{ tooltip: file.name }}
          >
            {file.name}
          </Typography.Text>
        </div>
      </div>

      {fileDetail && !isValidating && (
        <>
          {pdfType.includes(fileExtension) && (
            <ViewerPdf file={fileDetail} handleCloseFile={handleClosePreview} />
          )}
          {videoTypes.includes(fileExtension) && (
            <div className="mt-[50px] pt-5 pb-10 flex items-center overflow-auto">
              <ViewerVideo file={fileDetail} />
            </div>
          )}
          {imageTypes.includes(fileExtension) && (
            <div className="m-full h-full bg-transparent z-10">
              <ViewerImage file={fileDetail} />
            </div>
          )}
        </>
      )}

      {!fileDetail && !isValidating && (
        <div className="text-white m-auto">ファイルが見つかりません</div>
      )}

      {isValidating && <Spinner />}

      {fileIndex !== 0 && (
        <div
          className="absolute top-1/2 -translate-y-1/2 left-7 w-[60px] h-[60px] bg-[#00000099] rounded-full grid place-items-center cursor-pointer z-20"
          onClick={moveToPreviousFile}
        >
          <LeftArrowIcon size={32} />
        </div>
      )}

      {fileIndex !== maxLength - 1 && (
        <div
          className="absolute top-1/2 -translate-y-1/2 right-7 w-[60px] h-[60px] bg-[#00000099] rounded-full grid place-items-center rotate-180 cursor-pointer z-20"
          onClick={moveToNextFile}
        >
          <LeftArrowIcon size={32} />
        </div>
      )}
    </div>
  )
}
