import { Dispatch, SetStateAction, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Input, Typography } from 'antd'
import useMutation from 'hooks/useMutation'
import { useRecoilState } from 'recoil'
import { floorDetailState, selectedAreaState } from 'store/buildingStore'
import useSWR from 'swr'
import { AreaDetail } from 'types/building'
import Utils from 'utils'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'

type Props = {
  areaDetail: AreaDetail | undefined
  setIsOpen: Dispatch<SetStateAction<string>>
}

export default function EditAreaNameModal({ areaDetail, setIsOpen }: Props) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { floorId } = useParams()
  const stopPropagation = useRef<boolean>(false)
  const navigate = useNavigate()

  const [selectedArea, setSelectedArea] = useRecoilState(selectedAreaState)
  const [, setFloorDetail] = useRecoilState(floorDetailState)

  const { mutate } = useSWR(
    {
      url: `v1/floors/${floorId}`
    },
    {
      revalidateOnMount: false
    }
  )

  const getFloorDetail = () => mutate().then((data) => setFloorDetail(data))

  const { trigger: updateAreaName, isMutating } = useMutation(
    `v1/areas/${selectedArea}`
  )

  const handleClose = () => {
    setIsOpen('')
  }

  const handleSubmit = (values: any) => {
    !isMutating &&
      updateAreaName(
        {
          method: 'patch',
          data: Utils.trimObjectValues(values),
          successMessage: t('building.saved')
        },
        {
          onSuccess: () => {
            getFloorDetail()
            handleClose()
          },
          onError: (error) => {
            getFloorDetail()
            setSelectedArea(undefined)
            handleClose()
            Utils.handleErrorNavigate(error, navigate)
          }
        }
      )
  }

  const handleEnterSubmit = () => {
    if (stopPropagation.current) return
    form
      .validateFields()
      .then(() => handleSubmit(form.getFieldsValue()))
      .catch(() => {})
  }

  return (
    <Modal
      onCancel={handleClose}
      width={526}
      isEnterable
      onEnter={handleEnterSubmit}
    >
      <Title title={t('building.editAreaName')} bottomMargin="mb-8" />

      <Form
        form={form}
        initialValues={areaDetail}
        onFocus={() => {
          stopPropagation.current = true
        }}
        onBlur={() => {
          stopPropagation.current = false
        }}
        onFinish={handleSubmit}
      >
        <div className="flex items-center gap-[50px]">
          <Typography className="text-[14px] leading-[21px] font-medium">
            {t('common.floor.areaName')}
          </Typography>
          <Form.Item
            name="name"
            className="mb-0 flex-grow thin-placeholder thin-error-message"
            rules={[
              {
                validator: Validators.keywordsAndLengthCustomValidator(
                  t,
                  200,
                  true,
                  true
                )
              }
            ]}
          >
            <Input
              className="h-[38px] text-sm"
              placeholder={t('common.floor.placeHolder')}
            />
          </Form.Item>
        </div>

        <div className="flex gap-10 justify-center mt-10">
          <Button sizing="w-[140px] h-[50px]" onClick={handleClose}>
            {t('building.buttonCancel')}
          </Button>

          <Button type="primary" sizing="w-[140px] h-[50px]" htmlType="submit">
            {t('building.buttonSave')}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}
