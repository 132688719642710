import { useTranslation } from 'react-i18next'
import { Select, Typography } from 'antd'
import { SharedUser } from 'models/Building'
import { CurrentUser } from 'models/User'
import { UserAction } from 'types/building'
import Utils from 'utils'

import DropdownIconCustom from 'components/icons/DropdownIconCustom'

type Props = {
  user: SharedUser
  profile: CurrentUser | null | undefined
  handleSelect: (option: any) => void
}

export default function UserPermissionSelector({
  user,
  profile,
  handleSelect
}: Props) {
  const { t } = useTranslation()

  if (user.role !== 'USER' && !user.isChangeAction) {
    return (
      <div className="flex items-center justify-start h-8">
        <div className="my-auto pr-[11px] text-[#021120] text-[14px] leading-7">
          {(user.role === 'SA' && t('user.roles.systemAdmin')) ||
            (user.role === 'BA' && t('user.roles.branchAdmin')) ||
            (user.role === 'UA' && t('user.roles.userAdmin'))}
        </div>
      </div>
    )
  }

  if (
    (profile &&
      Utils.validRoleChecking(
        profile.role,
        user.organizationId,
        profile.organization,
        profile.manageOrganizations
      )) ||
    user.isChangeAction
  ) {
    return (
      <Select
        defaultValue={user.action}
        className="text-start w-[100px] text-[#021120] text-[14px] leading-7 custom-label-select-shared-building"
        popupMatchSelectWidth={false}
        size="small"
        bordered={false}
        suffixIcon={
          <DropdownIconCustom colorIcon="#3B3C46" width={19.5} height={10.5} />
        }
        options={[
          {
            value: 'VIEW',
            label: '閲覧者',
            userId: user.id
          },
          {
            value: 'EDIT',
            label: '編集者',
            userId: user.id
          },
          {
            value: 'REMOVE',
            label: '共有解除',
            userId: user.id
          }
        ]}
        onChange={(_, option: any) => {
          handleSelect(option)
        }}
      />
    )
  }

  return (
    <Typography className="text-[#021120] text-[14px] leading-7">
      {user.action === UserAction.VIEW ? '閲覧者' : '編集者'}
    </Typography>
  )
}
