import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Typography } from 'antd'
import { Building } from 'models/Building'
import { useRecoilValue } from 'recoil'
import { toggleResizeNotificationState } from 'store/displayToggleStore'
import useSWR from 'swr'
import { Folders } from 'types/building'
import Utils from 'utils'

import ResizeNotification from 'components/atoms/ResizeNotification'
import ViewerHeader from 'components/organisms/Header/ViewerHeader'

import ListChildrenFolder from './ListChildrenFolder'
import ListFile from './ListFile'
import ListFolder from './ListFolder'

export default function BuildingFileList() {
  const { id } = useParams()
  const navigate = useNavigate()
  const [selectedFolder, setSelectedFolder] = useState<Folders | undefined>()
  const [selectedChildrenFolder, setSelectedChildrenFolder] = useState<
    Folders | undefined
  >()
  const { t } = useTranslation()
  const isOpenResizeNotification = useRecoilValue(toggleResizeNotificationState)

  const { data: building } = useSWR<Building>(
    {
      url: `/v1/buildings/${id}`
    },
    {
      onError: (err) => {
        Utils.handleErrorNavigate(err, navigate)
      }
    }
  )

  return (
    <div className="h-screen grid grid-rows-[auto_1fr] overflow-x-hidden bg-[#F9FBFF]">
      <ViewerHeader building={building} />

      {isOpenResizeNotification ? (
        <div className="z-[999]">
          <ResizeNotification />
        </div>
      ) : null}

      <div
        className={`grid grid-rows-[auto_auto_1fr] gap-5 p-5 overflow-auto ${
          isOpenResizeNotification ? 'hidden' : ''
        }`}
      >
        <Typography className="text-xl font-bold text-[#021120]">
          {t('building.titleFileList')}
        </Typography>

        <ListFolder
          selectedFolder={selectedFolder}
          setSelectedFolder={setSelectedFolder}
        />

        <ListChildrenFolder
          selectedFolder={selectedFolder}
          setSelectedFolder={setSelectedFolder}
          selectedChildrenFolder={selectedChildrenFolder}
          setSelectedChildrenFolder={setSelectedChildrenFolder}
        />

        <ListFile
          selectedFolder={selectedFolder}
          setSelectedFolder={setSelectedFolder}
          selectedChildrenFolder={selectedChildrenFolder}
          setSelectedChildrenFolder={setSelectedChildrenFolder}
        />
      </div>
    </div>
  )
}
