import React from 'react'

export default function DownloadIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1773 6.20558C21.2602 5.26588 20.0462 4.61272 18.6934 4.3767C18.281 3.26675 17.5842 2.29686 16.6867 1.56261C15.5549 0.63636 14.0973 0.0774225 12.5212 0.0781257C10.929 0.0774225 9.45689 0.647282 8.32003 1.59097C7.46616 2.29831 6.79336 3.21767 6.38241 4.27016C4.62731 4.27813 3.02794 4.99527 1.878 6.14666C0.720094 7.30316 -0.000749415 8.91453 5.84683e-07 10.6813C-0.000749415 12.4476 0.720094 14.059 1.87795 15.2155C3.03445 16.3738 4.6455 17.0946 6.41222 17.0938C6.96684 17.0938 7.72688 17.0938 8.59641 17.0938V14.5656C7.72688 14.5656 6.9668 14.5656 6.41222 14.5656C5.33578 14.5649 4.37278 14.1328 3.66577 13.4277C2.96095 12.7207 2.52895 11.7585 2.5282 10.6813C2.52895 9.60444 2.96095 8.64139 3.66577 7.93447C4.37278 7.23003 5.33574 6.79798 6.41222 6.79728C6.62897 6.79728 6.85078 6.81978 7.08281 6.85981L8.29744 7.0722L8.53964 5.86339C8.72513 4.93639 9.22842 4.12175 9.93249 3.53806C10.638 2.95442 11.5327 2.60708 12.5211 2.60638C13.4994 2.60708 14.3846 2.94749 15.0864 3.52025C15.7876 4.09334 16.2923 4.89453 16.4895 5.80663L16.699 6.77764L17.6918 6.8038C18.7428 6.83183 19.6818 7.27184 20.3684 7.97263C21.0528 8.67561 21.471 9.62263 21.4718 10.6813C21.471 11.7584 21.039 12.7207 20.3342 13.4277C19.6273 14.1328 18.6643 14.5649 17.5878 14.5656C17.0379 14.5656 16.2772 14.5656 15.4043 14.5656V17.0938C16.2772 17.0938 17.0379 17.0938 17.5878 17.0938C19.3545 17.0946 20.9656 16.3737 22.122 15.2155C23.28 14.059 24.0008 12.4483 24 10.6813C24.0008 8.94331 23.3032 7.35589 22.1773 6.20558Z"
        fill="#F2F2F3"
      />
      <path
        d="M13.9151 10.4961H10.0863V18.7412H7.625L12.0007 23.9257L16.3764 18.7412H13.9151V10.4961Z"
        fill="#F2F2F3"
      />
    </svg>
  )
}
