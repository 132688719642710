import { Dispatch, SetStateAction } from 'react'
import { Typography } from 'antd'
import Const from 'constants/constant'
import { Folders } from 'types/building'

import RightArrowIcon from 'components/icons/RightArrowIcon'

import FolderIcon from './FolderIcon'

type Props = {
  selectedFolder: Folders | undefined
  setSelectedFolder: Dispatch<SetStateAction<Folders | undefined>>
}

export default function ListFolder({
  selectedFolder,
  setSelectedFolder
}: Props) {
  if (selectedFolder) return null

  return (
    <div className="bg-white">
      {Const.BUILDING_FOLDER.map((item, index) => (
        <div
          key={item.id}
          className={`h-[60px] px-5 flex items-center gap-[16px] border-0 border-b ${
            !index ? 'border-t' : ''
          } border-solid border-[#EEEFF2] cursor-pointer`}
          onClick={() => setSelectedFolder(item)}
        >
          <div className="h-[36px] w-[36px] flex justify-center">
            <FolderIcon type={item.id} size="large" />
          </div>
          <Typography className="font-medium text-[#021120]">
            {item.label}
          </Typography>
          <div className="ml-auto">
            <RightArrowIcon color="#3B3C46" size={24} />
          </div>
        </div>
      ))}
    </div>
  )
}
