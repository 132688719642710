import { PropsWithChildren } from 'react'
import { useRecoilValue } from 'recoil'
import { toggleResizeNotificationState } from 'store/displayToggleStore'

import ResizeNotification from 'components/atoms/ResizeNotification'
import FooterTransparent from 'components/organisms/Footer/FooterTransparent'
import HeaderTransparent from 'components/organisms/Header/HeaderTransparent'

export default function DefaultLayout({ children }: PropsWithChildren) {
  const isOpenResizeNotification = useRecoilValue(toggleResizeNotificationState)

  return (
    <div className="h-screen grid grid-rows-[auto_1fr_auto]">
      <HeaderTransparent />

      {isOpenResizeNotification ? (
        <div className="z-[999]">
          <ResizeNotification />
        </div>
      ) : null}

      <div
        className={`grow h-full ${isOpenResizeNotification ? 'hidden' : ''}`}
      >
        {children}
      </div>
      <FooterTransparent />
    </div>
  )
}
