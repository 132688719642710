import { MutableRefObject, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormInstance, Input, Select, Typography } from 'antd'
import useAuth from 'hooks/useAuth'
import useMutation from 'hooks/useMutation'
import { Branch } from 'models/Branch'
import { ListOrganizations } from 'models/Organization'
import { Role } from 'models/User'
import ModalOrganization from 'pages/user/listUser/components/ModalOrganization'
import useSWR from 'swr'
import { OrderByType } from 'types'
import { ParamQueryOrganization } from 'types/listOrganization'
import { SelectBranch, SelectOrganization } from 'types/listUser'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'
import FormItem from 'components/atoms/FormItem'
import Spinner from 'components/atoms/Spinner'
import DropdownIconCustom from 'components/icons/DropdownIconCustom'

import ModalBranch from './ModalBranch'

type Props = {
  form: FormInstance<any>
  handleOk: (value: any) => void
  handleCancel: () => void
  onFormFocus: () => void
  onFormBlur: () => void
  organizationId: MutableRefObject<SelectOrganization | undefined>
}
export default function FormAddBuilding({
  form,
  handleOk,
  handleCancel,
  onFormFocus,
  onFormBlur,
  organizationId
}: Props) {
  const { t } = useTranslation()
  const [openModalOrg, setOpenModalOrg] = useState(false)
  const [openModalBranch, setOpenModalBranch] = useState(false)
  const branchId = useRef<SelectBranch | undefined>(undefined)
  const { profile } = useAuth()

  const [listServices, setListServices] = useState<Branch[]>([])
  const [queries, setQueries] = useState<ParamQueryOrganization | null>({
    sort: 'id',
    sortOrder: OrderByType.descend
  })

  const { isValidating } = useSWR<ListOrganizations>({
    url: `/v1/organizations`,
    params: { ...queries, include_my_org: profile?.role === Role.BA }
  })

  const { trigger } = useMutation('/v1/branches', {
    onSuccess: (services) => {
      setListServices(services.edges)
      form.setFieldValue('branchId', services.edges[0].id)
    }
  })

  const openSelectOrganization = () => {
    setOpenModalOrg(true)
  }

  const closeModalOrganization = () => {
    setOpenModalOrg(false)
  }

  const handleSelectOrganization = (value: SelectOrganization) => {
    setOpenModalOrg(false)
    organizationId.current = value
    form.setFields([
      {
        name: 'organizationId',
        errors: undefined
      }
    ])
  }

  const openSelectBranch = () => {
    setOpenModalBranch(true)
  }

  const closeModalBranch = () => {
    setOpenModalBranch(false)
  }

  const handleSelectBranch = async (value: SelectBranch) => {
    setOpenModalBranch(false)
    branchId.current = value
    trigger({
      method: 'get',
      data: {
        type: 'service',
        keyword: value.name
      }
    })
  }

  return (
    <>
      {openModalBranch && (
        <ModalBranch
          handleCancel={closeModalBranch}
          handleOk={handleSelectBranch}
          buildingBranchName={branchId.current?.name}
        />
      )}

      {openModalOrg && (
        <ModalOrganization
          queries={queries}
          setQueries={setQueries}
          handleCancel={closeModalOrganization}
          handleOk={handleSelectOrganization}
          roleUser="none"
          setOrganization={null}
          organizationId={organizationId}
        />
      )}

      <Spinner spinning={isValidating}>
        <Form
          form={form}
          layout="horizontal"
          labelCol={{ span: 6 }}
          onFinish={handleOk}
          colon={false}
          className="flex h-full flex-col w-full text-[14px] mt-3 relative"
          onFocus={onFormFocus}
          onBlur={onFormBlur}
        >
          <FormItem
            required
            label={t('building.buildingNameForm')}
            name="name"
            className="input-invite-user add-building mb-8"
            rules={[
              {
                validator: Validators.nameBuildingValidator(t)
              }
            ]}
          >
            <Input
              placeholder={t(`organization.placeholderName`)}
              className="h-[38px] w-[300px] float-right py-[9px] px-4 rounded-md text-[14px] text-[#021120] leading-[21px]"
            />
          </FormItem>

          <FormItem
            label={t('building.code')}
            name="code"
            className="input-invite-user add-building mb-8"
            rules={[
              {
                validator: Validators.codeValidator(t)
              }
            ]}
          >
            <Input
              placeholder={t(`organization.placeholderName`)}
              className="h-[38px] w-[300px] float-right py-[9px] px-4 rounded-md text-[14px] text-[#021120] leading-[21px]"
            />
          </FormItem>

          <FormItem
            label={t('building.constructionName')}
            name="aliasName"
            className="input-invite-user add-building mb-8"
            rules={[
              {
                validator: Validators.aliasNameValidator(t)
              }
            ]}
          >
            <Input
              placeholder={t(`organization.placeholderName`)}
              className="h-[38px] w-[300px] float-right py-[9px] px-4 rounded-md text-[14px] text-[#021120] leading-[21px]"
            />
          </FormItem>

          <FormItem
            label={t('building.branchName')}
            className="input-invite-user add-building mb-8"
          >
            <div className="ml-auto flex w-[300px] justify-between items-center">
              <div className="text-[14px] text-[#021120] leading-[21px]">
                {branchId.current?.name}
              </div>
              <Button
                type="primary"
                className="ml-14"
                sizing="w-[100px] h-10"
                onClick={openSelectBranch}
              >
                {t('user.buttonSelectOrganization')}
              </Button>
            </div>
          </FormItem>

          {branchId.current?.id && (
            <FormItem
              label={t('building.serviceName')}
              name="branchId"
              className="ml-[73px] -mt-[14px] mb-8 input-invite-user"
            >
              <Select
                className="!w-[300px] float-right rounded-md text-[14px] text-[#021120] leading-[21px]"
                placeholder={t('building.placeholder')}
                suffixIcon={<DropdownIconCustom colorIcon="#021120" />}
              >
                {listServices.map((service) => {
                  return (
                    <Select.Option value={service.id} key={service.id}>
                      <span className="text-[14px] text-[#021120] leading-[21px] pl-[6px]">
                        {service.serviceStoreName}
                      </span>
                    </Select.Option>
                  )
                })}
              </Select>
            </FormItem>
          )}

          <FormItem
            label={t('user.organization')}
            name="organizationId"
            className="input-invite-user add-building mb-8"
          >
            <div className="w-[300px] ml-auto grid grid-cols-[1fr_auto] gap-3 items-center">
              <Input
                bordered={false}
                value={organizationId?.current?.id || 0}
                hidden
                className="h-[38px] w-[300px] float-right py-[9px] px-4 rounded-md"
              />
              <Typography.Text
                ellipsis={{
                  tooltip: organizationId.current?.name
                }}
                className="text-[14px] leading-[21px] text-[#021120] font-medium truncate"
              >
                {organizationId.current?.name}
              </Typography.Text>
              <Button
                type="primary"
                className="rounded-[4px]"
                sizing="w-[100px] h-10"
                onClick={openSelectOrganization}
              >
                {t('user.buttonSelectOrganization')}
              </Button>
            </div>
          </FormItem>

          <div className="flex gap-10 justify-center w-full items-end h-full mb-20">
            <Button
              sizing="w-[140px] h-[50px]"
              className="bg-[#A6AAAF]"
              onClick={handleCancel}
            >
              {t('building.buttonCancel')}
            </Button>
            <Button
              type="primary"
              sizing="w-[140px] h-[50px]"
              htmlType="submit"
            >
              {t('building.buttonSave')}
            </Button>
          </div>
        </Form>
      </Spinner>
    </>
  )
}
