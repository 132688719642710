import ReactPlayer from 'react-player'
import { BuildingFile } from 'types/building'

type Props = {
  file: BuildingFile
}

export default function ViewerVideo({ file }: Props) {
  return (
    <div className="relative m-auto w-[80vw] aspect-video bg-[#021120]">
      <ReactPlayer
        url={file.s3Path}
        playing={false}
        controls
        width="100%"
        height="100%"
        style={{ objectFit: 'contain' }}
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload'
            }
          }
        }}
      />
    </div>
  )
}
