import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Typography } from 'antd'
import { Building } from 'models/Building'
import BuildingInformationModal from 'pages/building/buildingDetailViewMode/components/BuildingInformationModal'
import { useRecoilState, useRecoilValue } from 'recoil'
import { floorDetailState } from 'store/buildingStore'
import { toggleResizeNotificationState } from 'store/displayToggleStore'
import { currentPovState } from 'store/krpanoStore'
import currentViewerState from 'store/viewerStore'

import FileIcon from 'components/icons/FileIcon'
import InformationIcon from 'components/icons/InformationIcon'
import LeftArrowIcon from 'components/icons/LeftArrowIcon'

import UserDropdown from '../UserDropdown'

type Props = {
  building?: Building
}

export default function ViewerHeader({ building }: Props) {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [isOpenInfoPopup, setIsOpenInfoPopup] = useState(false)

  const [, setFloorDetail] = useRecoilState(floorDetailState)
  const [, setCurrentViewer] = useRecoilState(currentViewerState)
  const [, setCurrentPov] = useRecoilState(currentPovState)
  const isOpenResizeNotification = useRecoilValue(toggleResizeNotificationState)

  const handleToggleInformationModal = () => {
    setIsOpenInfoPopup((prev) => !prev)
  }
  const backToPreviousBuilding = pathname.split('/').slice(0, -1).join('/')

  const handleBack = () => {
    if (pathname.includes('files')) {
      navigate(backToPreviousBuilding)
      return
    }
    setFloorDetail(null)
    setCurrentViewer({
      selectedFloor: undefined,
      selectedPoint: undefined,
      selectedArea: undefined,
      isOpenLeftMenu: true,
      isOpenMiniMap: false
    })
    navigate('/buildings')
  }

  const handleSaveCurrentView = () => {
    const krpano = document.getElementById('embedpano-viewer') as any
    if (krpano) {
      const h = krpano.get('view.hlookat')
      const v = krpano.get('view.vlookat')
      const z = krpano.get('view.fov')
      setCurrentPov({ h, v, z })
    }
  }

  const handleNavigate = () => {
    if (pathname.includes('files')) return
    handleSaveCurrentView()
    navigate('files')
  }

  return (
    <>
      <div
        className={`h-[60px] grid ${
          isOpenResizeNotification ? '' : 'grid-cols-3'
        } items-center pl-5 bg-gradient-to-r from-[#3163B7] to-[#123674]`}
      >
        <div
          className="flex items-center gap-8 select-none cursor-pointer"
          onClick={handleBack}
        >
          <LeftArrowIcon size={20} />
          <img
            src="/logo/transparent_header.png"
            alt="logo"
            className="max-w-[207px]"
          />
        </div>

        <div className={isOpenResizeNotification ? 'hidden' : ''}>
          <Typography.Paragraph
            ellipsis={{
              rows: 1,
              tooltip: building?.organization?.name
            }}
            className="text-white text-center text-[14px] font-[500] truncate !mb-0"
          >
            {building?.organization?.name}
          </Typography.Paragraph>
          <Typography.Paragraph
            ellipsis={{
              rows: 1,
              tooltip: building?.name
            }}
            className="text-white text-center truncate font-[500] !mb-0"
          >
            {building?.name}
          </Typography.Paragraph>
        </div>
        <div
          className={`relative h-full flex justify-end gap-6 ${
            isOpenResizeNotification ? 'hidden' : ''
          }`}
          style={{
            backgroundImage: "url('/assets/background_header.png')"
          }}
        >
          <div
            className="h-[35px] my-auto flex flex-col items-center justify-between cursor-pointer"
            onClick={handleToggleInformationModal}
          >
            <InformationIcon />
            <Typography className=" text-center text-[11px] leading-[11px] font-[700] text-white">
              {t('common.header.information')}
            </Typography>
          </div>
          <div
            className="h-[35px] flex flex-col items-center justify-between my-auto cursor-pointer"
            onClick={handleNavigate}
          >
            <FileIcon isActive={pathname.includes('files')} />
            <Typography
              className={`text-center text-[11px] leading-[11px] font-[700] ${
                pathname.includes('files') ? 'text-[#609DF9]' : 'text-white'
              }`}
            >
              {t('common.header.file')}
            </Typography>
          </div>
          <UserDropdown t={t} />
        </div>
      </div>
      {isOpenInfoPopup && (
        <BuildingInformationModal
          building={building}
          handleToggle={handleToggleInformationModal}
        />
      )}
    </>
  )
}

ViewerHeader.defaultProps = {
  building: undefined
}
