import { initReactI18next } from 'react-i18next'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import en from './en'
import ja from './ja'
import { convertLanguageJsonToObject } from './translations'

export const translationsJson = { en, ja }

// Create the 'translations' object to provide full intellisense support for the static json files.
convertLanguageJsonToObject(en)
convertLanguageJsonToObject(ja)

export const i18n = i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: translationsJson,
    fallbackLng: 'ja',
    debug: false,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })
