import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Typography } from 'antd'
import useMutation from 'hooks/useMutation'
import { useRecoilState } from 'recoil'
import { buildingFilesState } from 'store/buildingStore'
import { BuildingFile } from 'types/building'
import Utils from 'utils'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'

type Props = {
  item: BuildingFile
  handleSetRevalidateByCategory: () => void
  handleToggleDeleteFile: (file: BuildingFile | undefined) => void
}

export default function ModalDeleteFile({
  item,
  handleSetRevalidateByCategory,
  handleToggleDeleteFile
}: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [, setBuildingFiles] = useRecoilState(buildingFilesState)

  const { trigger, isMutating } = useMutation(`v1/buildings/files/${item.id}`)

  const handleCancel = () => {
    handleToggleDeleteFile(undefined)
  }

  const handleDeletedFile = () => {
    !isMutating &&
      trigger(
        {
          method: 'delete',
          data: null,
          successMessage: 'organization.successDeletedNotification'
        },
        {
          onSuccess: () => {
            setBuildingFiles((prev) =>
              prev.filter((file) => file.id !== item.id)
            )
            handleCancel()
          },
          onError: (error) => {
            handleSetRevalidateByCategory()
            handleCancel()
            Utils.handleErrorNavigate(error, navigate)
          }
        }
      )
  }

  return (
    <Modal
      width="480px"
      onCancel={handleCancel}
      isEnterable
      onEnter={handleDeletedFile}
    >
      <div className="text-center flex flex-col gap-[30px] items-center">
        <Title title="ファイル削除" />
        <Typography className="text-[14px] font-[400]">
          {t('common.deleteNotice')}
        </Typography>
        <div className="flex px-3 bg-[#F9FBFF] w-[360px] h-[60px] py-[18px] rounded-[10px]">
          <Typography.Text
            className="m-auto text-[16px] font-[400] truncate"
            ellipsis={{
              tooltip: item.name
            }}
          >
            {item.name}
          </Typography.Text>
        </div>
        <div className="flex gap-10 justify-center w-full items-end">
          <Button onClick={handleCancel} sizing="w-[140px] h-[50px]">
            {t('common.cancel')}
          </Button>
          <Button
            onClick={handleDeletedFile}
            sizing="w-[140px] h-[50px]"
            className="bg-[#B80808]"
          >
            {t('common.delete')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
