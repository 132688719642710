import React from 'react'

export default function ZoomOutIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_661_61519)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.75 18C10.8334 18 11.9062 17.7866 12.9071 17.372C13.9081 16.9574 14.8175 16.3497 15.5836 15.5836C16.3497 14.8175 16.9574 13.9081 17.372 12.9071C17.7866 11.9062 18 10.8334 18 9.75C18 8.66659 17.7866 7.5938 17.372 6.59286C16.9574 5.59193 16.3497 4.68245 15.5836 3.91637C14.8175 3.15029 13.9081 2.5426 12.9071 2.12799C11.9062 1.71339 10.8334 1.5 9.75 1.5C7.56196 1.5 5.46354 2.36919 3.91637 3.91637C2.36919 5.46354 1.5 7.56196 1.5 9.75C1.5 11.938 2.36919 14.0365 3.91637 15.5836C5.46354 17.1308 7.56196 18 9.75 18V18ZM19.5 9.75C19.5 12.3359 18.4728 14.8158 16.6443 16.6443C14.8158 18.4728 12.3359 19.5 9.75 19.5C7.16414 19.5 4.68419 18.4728 2.85571 16.6443C1.02723 14.8158 0 12.3359 0 9.75C0 7.16414 1.02723 4.68419 2.85571 2.85571C4.68419 1.02723 7.16414 0 9.75 0C12.3359 0 14.8158 1.02723 16.6443 2.85571C18.4728 4.68419 19.5 7.16414 19.5 9.75V9.75Z"
          fill="#F2F2F3"
        />
        <path
          d="M15.5156 17.6141C15.5606 17.6741 15.6086 17.7311 15.6626 17.7866L21.4376 23.5616C21.7189 23.8431 22.1004 24.0013 22.4983 24.0014C22.8963 24.0016 23.2779 23.8436 23.5594 23.5624C23.8408 23.2811 23.999 22.8996 23.9992 22.5017C23.9993 22.1037 23.8414 21.7221 23.5601 21.4406L17.7851 15.6656C17.7315 15.6113 17.6738 15.5612 17.6126 15.5156C17.0242 16.3179 16.3171 17.0261 15.5156 17.6156V17.6141Z"
          fill="#F2F2F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 9.75C4.5 9.55109 4.57902 9.36032 4.71967 9.21967C4.86032 9.07902 5.05109 9 5.25 9H14.25C14.4489 9 14.6397 9.07902 14.7803 9.21967C14.921 9.36032 15 9.55109 15 9.75C15 9.94891 14.921 10.1397 14.7803 10.2803C14.6397 10.421 14.4489 10.5 14.25 10.5H5.25C5.05109 10.5 4.86032 10.421 4.71967 10.2803C4.57902 10.1397 4.5 9.94891 4.5 9.75Z"
          fill="#F2F2F3"
        />
      </g>
      <defs>
        <clipPath id="clip0_661_61519">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
