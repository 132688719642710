import { PropsWithChildren } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import clsx from 'clsx'

export type Props = {
  size?: 'small' | 'large' | 'default'
  spinning?: boolean
  hideChildren?: boolean
  className?: string
}

export default function Spinner({
  size,
  children,
  spinning,
  hideChildren,
  className
}: PropsWithChildren<Props>) {
  return children ? (
    <Spin
      spinning={spinning}
      size={size}
      indicator={<LoadingOutlined />}
      className={className}
    >
      {hideChildren && spinning ? (
        <div className="invisible">{children}</div>
      ) : (
        children
      )}
    </Spin>
  ) : (
    <div
      className={clsx(
        'absolute top-0 bottom-0 right-0 left-0 flex items-center justify-center z-[10000] bg-[#0001]',
        className
      )}
    >
      <Spin spinning={spinning} size={size} indicator={<LoadingOutlined />} />
    </div>
  )
}

Spinner.defaultProps = {
  size: 'large',
  children: null,
  spinning: true,
  hideChildren: false,
  className: undefined
}
