import { Typography } from 'antd'
import dayjs from 'dayjs'
import { BuildingFile } from 'types/building'

import DeleteIcon from 'components/icons/DeleteIcon'

type Props = {
  item: BuildingFile
  handleToggleDeleteFile: (file: BuildingFile | undefined) => void
}

export default function BuildingFileDetail({
  item,
  handleToggleDeleteFile
}: Props) {
  return (
    <div>
      <div className="border-0 border-b border-solid border-[#1C314D]">
        <div className="flex justify-between pl-[12px] pr-[10px] py-[6px] min-h-[50px] bg-[#162841]">
          <div className="flex flex-col gap-[2px] justify-center" key={item.id}>
            <Typography.Text className="text-white font-[400] text-[13px] leading-[19.5px]">
              {item?.name}
            </Typography.Text>
            <Typography className="text-white font-[400] text-[11px] leading-[16.5px]">
              {dayjs(item?.createdAt).format('YYYY/MM/DD HH:mm')}
            </Typography>
          </div>
          <div
            className="my-auto cursor-pointer"
            onClick={() => handleToggleDeleteFile(item)}
          >
            <DeleteIcon />
          </div>
        </div>
      </div>
    </div>
  )
}
