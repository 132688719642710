import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function MenuOrganizationSvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7600_66636)">
        <path
          d="M14.4557 7.51706L13.9053 6.77802C14.0194 6.50234 14.0499 6.4278 14.1648 6.15212L15.0762 6.01922C15.3195 5.9842 15.5 5.77586 15.5 5.52982V4.47109C15.5 4.22504 15.3195 4.0167 15.0771 3.98168L14.1657 3.84968C14.0517 3.5731 14.0202 3.49946 13.9053 3.22288L14.4566 2.48474C14.6039 2.28808 14.5832 2.01329 14.4099 1.83908L13.662 1.09016C13.4887 0.916849 13.2139 0.896195 13.0164 1.04347L12.2783 1.59483C12.0018 1.47989 11.9281 1.44936 11.6516 1.33441L11.5187 0.422956C11.4837 0.179601 11.2753 -0.000894652 11.0293 3.33592e-06H9.97068C9.72466 3.33592e-06 9.51724 0.180499 9.48132 0.422956L9.34843 1.33441C9.07188 1.44936 8.99825 1.47989 8.72169 1.59393L7.98271 1.04347C7.78607 0.896195 7.51131 0.915951 7.33802 1.08926L6.59006 1.83729C6.41587 2.0115 6.39611 2.28628 6.54337 2.48294L7.09468 3.22109C6.97975 3.49767 6.94922 3.5713 6.83519 3.84878L5.92381 3.98079C5.67958 4.01671 5.5 4.22504 5.5 4.47019V5.52892C5.5 5.77497 5.68048 5.9833 5.92381 6.01832L6.83609 6.15122C6.95102 6.42691 6.98155 6.50144 7.09558 6.77802L6.54427 7.51616C6.39701 7.71282 6.41766 7.98851 6.59096 8.16182L7.33891 8.90984C7.51221 9.08315 7.78787 9.10381 7.98451 8.95654L8.72259 8.40517C8.99915 8.51922 9.07278 8.55065 9.34933 8.66469L9.48132 9.57615C9.51634 9.8195 9.72555 10 9.97068 10H11.0284C11.2744 10 11.4828 9.8195 11.5187 9.57615L11.6516 8.662C11.9272 8.54795 12.0009 8.51742 12.2765 8.40338L13.0173 8.95564C13.2139 9.10291 13.4896 9.08315 13.6629 8.90894L14.4108 8.16002C14.5841 7.98671 14.6039 7.71193 14.4566 7.51527L14.4557 7.51706ZM11.9326 6.43409C11.1407 7.22611 9.85755 7.22611 9.06559 6.43409C8.27364 5.64206 8.27364 4.35794 9.06559 3.56681C9.85755 2.77479 11.1416 2.77479 11.9335 3.56681C12.7255 4.35884 12.7246 5.64296 11.9335 6.43499L11.9326 6.43409Z"
          fill="white"
        />
        <path
          d="M20 16.1619V17.4593C20 17.6983 19.8105 17.8935 19.5728 17.8935H14.8106C14.7128 16.8472 14.1819 15.984 13.2906 15.4028C13.4922 14.6866 13.9863 14.2094 14.585 13.9033C15.128 14.3745 15.8305 14.6504 16.5938 14.6504C17.3572 14.6504 18.0649 14.3752 18.6026 13.9033C19.3961 14.3074 20.0008 15.0175 20.0008 16.1611L20 16.1619Z"
          fill="white"
        />
        <path
          d="M16.5938 13.7792C17.8272 13.7792 18.8267 12.7773 18.8267 11.541C18.8267 10.3046 17.8272 9.30273 16.5938 9.30273C15.3603 9.30273 14.3616 10.3046 14.3616 11.541C14.3616 12.7773 15.3611 13.7792 16.5938 13.7792Z"
          fill="white"
        />
        <path
          d="M7.71615 15.4035C6.82492 15.9787 6.29395 16.8418 6.19618 17.8942H1.4332C1.19479 17.8942 1 17.6982 1 17.46V16.1626C1 15.0182 1.60468 14.3081 2.40415 13.9048C2.94114 14.3759 3.64359 14.6519 4.40696 14.6519C5.17033 14.6519 5.87804 14.3767 6.41579 13.9048C7.01445 14.2109 7.51459 14.688 7.71615 15.4042V15.4035Z"
          fill="white"
        />
        <path
          d="M4.4092 13.7792C5.64262 13.7792 6.64215 12.7773 6.64215 11.541C6.64215 10.3046 5.64262 9.30273 4.4092 9.30273C3.17577 9.30273 2.177 10.3046 2.177 11.541C2.177 12.7773 3.17653 13.7792 4.4092 13.7792Z"
          fill="white"
        />
        <path
          d="M12.5092 16.0093C11.9699 16.4827 11.2667 16.7594 10.5011 16.7594C9.73549 16.7594 9.03304 16.4827 8.49379 16.0093C7.69883 16.4164 7.09265 17.1242 7.09265 18.2694V19.569C7.09265 19.808 7.28519 20.001 7.5236 20.001H13.4794C13.7178 20.001 13.9103 19.808 13.9103 19.569V18.2694C13.9103 17.125 13.3041 16.4164 12.5092 16.0093Z"
          fill="white"
        />
        <path
          d="M10.5018 15.8856C11.7353 15.8856 12.7348 14.8837 12.7348 13.6474C12.7348 12.4111 11.7353 11.4092 10.5018 11.4092C9.26843 11.4092 8.26965 12.4111 8.26965 13.6474C8.26965 14.8837 9.26918 15.8856 10.5018 15.8856Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7600_66636">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default function MenuOrganizationIcon(
  props: Partial<CustomIconComponentProps>
) {
  return <Icon component={MenuOrganizationSvg} {...props} />
}
