import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Typography } from 'antd'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import { useRecoilState } from 'recoil'
import { currentPovState } from 'store/krpanoStore'
import currentViewerState from 'store/viewerStore'
import useSWR from 'swr'
import { ListRelatedPoints, RelatedPoint } from 'types/building'

import LazyThumbnail from 'components/atoms/LazyThumbnail'
import Modal from 'components/atoms/Modal'
import Spinner from 'components/atoms/Spinner'
import Title from 'components/atoms/Title'
import CloseIcon from 'components/icons/CloseIcon'

type Props = {
  selectedFile: number | undefined
  setSelectedFile: Dispatch<SetStateAction<number | undefined>>
}

export default function ModalListRelatedPoints({
  selectedFile,
  setSelectedFile
}: Props) {
  const { id } = useParams()
  const navigate = useNavigate()

  const [relatedPoints, setRelatedPoints] = useState<RelatedPoint[]>([])
  const [pagination, setPagination] = useState({
    page: 1,
    page_size: 10
  })

  const [currentViewer, setCurrentViewer] = useRecoilState(currentViewerState)
  const [, setCurrentPov] = useRecoilState(currentPovState)

  const { data: listRelatedPoints, isValidating } = useSWR<ListRelatedPoints>(
    selectedFile
      ? {
          url: `/v1/buildings/${id}/image-related-file/${selectedFile}`,
          params: { ...pagination }
        }
      : null,
    {
      onSuccess: (data) => {
        setRelatedPoints((prev) => [...prev, ...data.edges])
      }
    }
  )

  const { ref: intersectingRef } = useIntersectionObserver({
    onIntersecting: () => {
      if (
        !isValidating &&
        listRelatedPoints &&
        relatedPoints.length < listRelatedPoints?.pagination.total
      ) {
        setPagination((prev) => ({ ...prev, page: prev.page + 1 }))
      }
    }
  })

  const handleMoveToPoint = useCallback(
    (selectedFloor: number, selectedArea: number, selectedPoint: string) => {
      currentViewer.selectedPoint !== selectedPoint && setCurrentPov(null)
      setCurrentViewer((prev) => ({
        ...prev,
        selectedFloor,
        selectedArea: `${selectedArea}`,
        selectedPoint
      }))
      navigate(`/viewer/buildings/${id}`)
    },
    [currentViewer.selectedPoint, id, navigate, setCurrentPov, setCurrentViewer]
  )

  const handleCloseModal = () => {
    setSelectedFile(undefined)
    setPagination({ page: 1, page_size: 10 })
    setRelatedPoints([])
  }

  if (!selectedFile) return null

  return (
    <Modal open width={500}>
      <div
        className="absolute top-5 right-5 cursor-pointer"
        onClick={handleCloseModal}
      >
        <CloseIcon />
      </div>
      <Title title="フロア画像一覧" />
      <div className="min-h-[470px] max-h-[470px] space-y-[10px] overflow-auto">
        {relatedPoints.map((item) => (
          <div
            key={item.point.id}
            className="flex gap-3 items-center cursor-pointer"
            onClick={() =>
              handleMoveToPoint(item.floorId, item.point.areaId, item.point.id)
            }
          >
            <div className="w-[220px] h-[110px] object-contain relative">
              <div className="absolute top-1 left-1 z-10 rounded-lg flex bg-[#021120] p-[2px] min-w-[16px]">
                <div className="m-auto text-[13px] leading-[13px] font-bold text-white">
                  {item.point.order}
                </div>
              </div>
              <LazyThumbnail
                url={item.point.image360?.thumbnail || ''}
                name={item.point.image360?.thumbnail || ''}
                width={220}
                height={110}
              />
            </div>
            <Typography className="font-medium">
              フロア：{item.name}階
            </Typography>
          </div>
        ))}
        <Spinner spinning={isValidating}>
          <div
            id="intersecting boundary"
            className="h-10"
            ref={intersectingRef}
          />
        </Spinner>
      </div>
    </Modal>
  )
}
