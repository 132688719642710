import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function DeleteIconSVG() {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.59617 5.78847C4.72368 5.78847 4.84596 5.83912 4.93613 5.92928C5.02629 6.01944 5.07694 6.14173 5.07694 6.26924V12.0385C5.07694 12.166 5.02629 12.2883 4.93613 12.3784C4.84596 12.4686 4.72368 12.5192 4.59617 12.5192C4.46866 12.5192 4.34638 12.4686 4.25622 12.3784C4.16605 12.2883 4.1154 12.166 4.1154 12.0385V6.26924C4.1154 6.14173 4.16605 6.01944 4.25622 5.92928C4.34638 5.83912 4.46866 5.78847 4.59617 5.78847ZM7.00002 5.78847C7.12753 5.78847 7.24981 5.83912 7.33997 5.92928C7.43013 6.01944 7.48079 6.14173 7.48079 6.26924V12.0385C7.48079 12.166 7.43013 12.2883 7.33997 12.3784C7.24981 12.4686 7.12753 12.5192 7.00002 12.5192C6.87251 12.5192 6.75022 12.4686 6.66006 12.3784C6.5699 12.2883 6.51925 12.166 6.51925 12.0385V6.26924C6.51925 6.14173 6.5699 6.01944 6.66006 5.92928C6.75022 5.83912 6.87251 5.78847 7.00002 5.78847ZM9.88463 6.26924C9.88463 6.14173 9.83398 6.01944 9.74382 5.92928C9.65366 5.83912 9.53137 5.78847 9.40386 5.78847C9.27636 5.78847 9.15407 5.83912 9.06391 5.92928C8.97375 6.01944 8.92309 6.14173 8.92309 6.26924V12.0385C8.92309 12.166 8.97375 12.2883 9.06391 12.3784C9.15407 12.4686 9.27636 12.5192 9.40386 12.5192C9.53137 12.5192 9.65366 12.4686 9.74382 12.3784C9.83398 12.2883 9.88463 12.166 9.88463 12.0385V6.26924Z"
        fill="#AC6060"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.25 3.38462C13.25 3.63963 13.1487 3.8842 12.9684 4.06453C12.788 4.24485 12.5435 4.34615 12.2885 4.34615H11.8077V13C11.8077 13.51 11.6051 13.9992 11.2444 14.3598C10.8838 14.7205 10.3946 14.9231 9.88462 14.9231H4.11538C3.60535 14.9231 3.11621 14.7205 2.75556 14.3598C2.39492 13.9992 2.19231 13.51 2.19231 13V4.34615H1.71154C1.45652 4.34615 1.21195 4.24485 1.03163 4.06453C0.851305 3.8842 0.75 3.63963 0.75 3.38462V2.42308C0.75 2.16806 0.851305 1.92349 1.03163 1.74317C1.21195 1.56284 1.45652 1.46154 1.71154 1.46154H5.07692C5.07692 1.20652 5.17823 0.961952 5.35855 0.781628C5.53888 0.601305 5.78345 0.5 6.03846 0.5H7.96154C8.21655 0.5 8.46113 0.601305 8.64145 0.781628C8.82177 0.961952 8.92308 1.20652 8.92308 1.46154H12.2885C12.5435 1.46154 12.788 1.56284 12.9684 1.74317C13.1487 1.92349 13.25 2.16806 13.25 2.42308V3.38462ZM3.26731 4.34615L3.15385 4.40288V13C3.15385 13.255 3.25515 13.4996 3.43547 13.6799C3.6158 13.8602 3.86037 13.9615 4.11538 13.9615H9.88462C10.1396 13.9615 10.3842 13.8602 10.5645 13.6799C10.7448 13.4996 10.8462 13.255 10.8462 13V4.40288L10.7327 4.34615H3.26731ZM1.71154 3.38462V2.42308H12.2885V3.38462H1.71154Z"
        fill="#AC6060"
      />
    </svg>
  )
}

function ShadowDeleteIconSvg() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_7046_45482)">
        <path
          d="M5.59617 5.78847C5.72368 5.78847 5.84596 5.83912 5.93613 5.92928C6.02629 6.01944 6.07694 6.14173 6.07694 6.26924V12.0385C6.07694 12.166 6.02629 12.2883 5.93613 12.3784C5.84596 12.4686 5.72368 12.5192 5.59617 12.5192C5.46866 12.5192 5.34638 12.4686 5.25622 12.3784C5.16605 12.2883 5.1154 12.166 5.1154 12.0385V6.26924C5.1154 6.14173 5.16605 6.01944 5.25622 5.92928C5.34638 5.83912 5.46866 5.78847 5.59617 5.78847ZM8.00002 5.78847C8.12753 5.78847 8.24981 5.83912 8.33997 5.92928C8.43013 6.01944 8.48079 6.14173 8.48079 6.26924V12.0385C8.48079 12.166 8.43013 12.2883 8.33997 12.3784C8.24981 12.4686 8.12753 12.5192 8.00002 12.5192C7.87251 12.5192 7.75022 12.4686 7.66006 12.3784C7.5699 12.2883 7.51925 12.166 7.51925 12.0385V6.26924C7.51925 6.14173 7.5699 6.01944 7.66006 5.92928C7.75022 5.83912 7.87251 5.78847 8.00002 5.78847ZM10.8846 6.26924C10.8846 6.14173 10.834 6.01944 10.7438 5.92928C10.6537 5.83912 10.5314 5.78847 10.4039 5.78847C10.2764 5.78847 10.1541 5.83912 10.0639 5.92928C9.97375 6.01944 9.92309 6.14173 9.92309 6.26924V12.0385C9.92309 12.166 9.97375 12.2883 10.0639 12.3784C10.1541 12.4686 10.2764 12.5192 10.4039 12.5192C10.5314 12.5192 10.6537 12.4686 10.7438 12.3784C10.834 12.2883 10.8846 12.166 10.8846 12.0385V6.26924Z"
          fill="#AC6060"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.25 3.38462C14.25 3.63963 14.1487 3.8842 13.9684 4.06453C13.788 4.24485 13.5435 4.34615 13.2885 4.34615H12.8077V13C12.8077 13.51 12.6051 13.9992 12.2444 14.3598C11.8838 14.7205 11.3946 14.9231 10.8846 14.9231H5.11538C4.60535 14.9231 4.11621 14.7205 3.75556 14.3598C3.39492 13.9992 3.19231 13.51 3.19231 13V4.34615H2.71154C2.45652 4.34615 2.21195 4.24485 2.03163 4.06453C1.8513 3.8842 1.75 3.63963 1.75 3.38462V2.42308C1.75 2.16806 1.8513 1.92349 2.03163 1.74317C2.21195 1.56284 2.45652 1.46154 2.71154 1.46154H6.07692C6.07692 1.20652 6.17823 0.961952 6.35855 0.781628C6.53888 0.601305 6.78345 0.5 7.03846 0.5H8.96154C9.21655 0.5 9.46113 0.601305 9.64145 0.781628C9.82177 0.961952 9.92308 1.20652 9.92308 1.46154H13.2885C13.5435 1.46154 13.788 1.56284 13.9684 1.74317C14.1487 1.92349 14.25 2.16806 14.25 2.42308V3.38462ZM4.26731 4.34615L4.15385 4.40288V13C4.15385 13.255 4.25515 13.4996 4.43547 13.6799C4.6158 13.8602 4.86037 13.9615 5.11538 13.9615H10.8846C11.1396 13.9615 11.3842 13.8602 11.5645 13.6799C11.7448 13.4996 11.8462 13.255 11.8462 13V4.40288L11.7327 4.34615H4.26731ZM2.71154 3.38462V2.42308H13.2885V3.38462H2.71154Z"
          fill="#AC6060"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_7046_45482"
          x="0.75"
          y="0.5"
          width="14.5"
          height="16.4238"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7046_45482"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7046_45482"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default function DeleteIcon(
  props: Partial<CustomIconComponentProps> & { shadow?: boolean }
) {
  const { shadow, ...rest } = props
  if (shadow) {
    return <Icon component={ShadowDeleteIconSvg} {...rest} />
  }

  return <Icon component={DeleteIconSVG} {...rest} />
}

DeleteIcon.defaultProps = {
  shadow: undefined
}
