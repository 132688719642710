import { ChangeEvent, Dispatch, PropsWithChildren, SetStateAction } from 'react'
import { FormInstance } from 'antd'
import ErrorCodes from 'constants/error'
import { TFunction } from 'i18next'
import { Role } from 'models/User'

import { BuildingStatus } from './building'
import { ParamQueryUser } from './listUser'

export type RouteObject = {
  path: string
  layout?: ({ children }: PropsWithChildren) => JSX.Element
  guardOnLogged?: boolean
  children: NestedRoute[]
}

type NestedRoute = {
  name: string
  path: string
  element: JSX.Element
  index?: boolean
  layout?: ({ children }: PropsWithChildren) => JSX.Element
  role?: string[]
}

export type TokenBundle = {
  idToken: string
  refreshToken: string
  expiresIn: number
}

export type ColumnTable = {
  name: string
  label?: string
  width?: string
  hasSorter?: boolean
  onClick?: (id: number | string, record: any) => void
  buttonLabel?: string
  excludeVersion?: number[]
  required?: boolean
  customLabel?: React.ReactNode
  onCell?: (record: any, rowIndex: any) => any
}

export type PaginationConfig = {
  page: number
  pageSize: number
  isHidePagination?: boolean
  queries: ParamQueryUser | null
  setQueries:
    | Dispatch<SetStateAction<ParamQueryUser>>
    | ((pagination: ParamQueryUser) => void)
}

export enum OrderBy {
  ASC = 'asc',
  DESC = 'desc'
}

export enum OrderByType {
  ascend = 'asc',
  descend = 'desc'
}

export type Sort = {
  sort?: string
  sortOrder?: OrderByType
}

export type ScrollType = {
  x?: string | number | true | undefined
  y?: string | number | undefined
}

export type ExtraTableProps<T> = {
  size?: 'large' | 'middle' | 'small'
  className?: string
  id?: string
  isLoading?: boolean
  total?: number
  data?: T[]
  columns: ColumnTable[]
  isSubmitting?: boolean
  isShowOrder?: boolean
  pagination?: PaginationConfig
  components?: any
  rowKey?: string
  router?: string
  onChange?: (sorter: string, field: string) => void
  rowClassName?: (record: DataSource<T>, index: number) => string
  scroll?: ScrollType
  isDetail?: boolean
  isSelect?: boolean
  actionSelect?: any
  bordered?: boolean
  role?: Role
  refetch?: () => void
}

export type DataSource<K> = K & {
  order: number
  status?: BuildingStatus
}

export type Pagination = {
  current: number
  defaultPageSize: number
  defaultOrderBy: OrderBy.DESC
}
export type I18nT = TFunction<'translation', undefined>

export type ErrorCode = keyof typeof ErrorCodes.API_ERROR_CODES

export type ValidationErrorCodes =
  keyof typeof ErrorCodes.VALIDATION_ERROR_CODES

export type Method = 'post' | 'put' | 'delete' | 'patch' | 'get'

export type MutateArg = {
  arg: {
    method?: Method
    query?: any[]
    form?: FormInstance
    errorMessage?: string
    successMessage?: string
    data: any
    headers?: any
    params?: any
  }
}

export type UploadProps = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  accept: string
  name: string
  wrapperClassName?: string
  value?: File | null
}

export const ErrorNetwork = 'ERR_NETWORK'
