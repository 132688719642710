import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from 'antd'

import Title from 'components/atoms/Title'

export default function EmailExpired() {
  const { t } = useTranslation()
  return (
    <div className="flex h-full bg-[#F9FBFF]">
      <div className="m-auto flex flex-col justify-between items-center w-[470px] h-[19rem] p-[3.75rem] bg-white rounded-[1.25rem] shadow-[0px_0px_10px_0px_#00000026]">
        <Title title={t('user.invitationExpiredTitle')} />
        <div className="h-28 flex flex-col justify-between text-justify">
          <Typography className="text-[#021120] font-normal leading-[26px]">
            {t('user.invitationExpiredDescription')}
          </Typography>
          <Typography className="text-[#021120] font-normal leading-[26px]">
            {t('user.invitationExpiredGuideline')}
          </Typography>
        </div>
      </div>
    </div>
  )
}
