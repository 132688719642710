import React from 'react'

type Props = {
  isSelfLocked?: boolean
}

export default function LockIcon({ isSelfLocked }: Props) {
  if (isSelfLocked) {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_13218_75595)">
          <g clipPath="url(#clip1_13218_75595)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 16.6337C0 18.0687 1.39587 18.0687 1.39587 18.0687H6.81927C7.16824 18.0687 7.44998 17.7791 7.44998 17.4203V15.4653C7.44998 15.2941 7.51721 15.1296 7.63567 15.0078L10.4946 12.0719C10.8724 11.6835 10.6419 11.022 10.1137 10.9693C9.67504 10.9232 9.10517 10.8903 8.38482 10.8903C1.39587 10.887 0 15.1954 0 16.6337ZM11.3462 8.18815C10.5587 8.99782 9.49256 9.44873 8.38162 9.44873C7.27069 9.44873 6.20458 8.99453 5.417 8.18815C4.62942 7.38178 4.19081 6.28247 4.19081 5.14038C4.19081 3.99829 4.63262 2.90228 5.417 2.09261C6.20458 1.28294 7.27069 0.832031 8.38162 0.832031C9.49256 0.832031 10.5587 1.28623 11.3462 2.09261C12.1338 2.90228 12.5724 3.99829 12.5724 5.14038C12.5724 6.28247 12.1306 7.37849 11.3462 8.18815ZM15.3674 18.072C15.3674 18.072 16.7632 18.072 16.7632 16.637C16.7632 16.3836 16.7184 16.0413 16.6096 15.6496C16.5487 15.4357 16.2958 15.36 16.1389 15.5114L14.0227 17.5652C13.8338 17.7462 13.9619 18.072 14.218 18.072H15.3642H15.3674ZM19.6318 8.0565L18.8186 7.2205C18.3256 6.71364 17.5284 6.71364 17.0354 7.2205L16.2766 8.00055L18.8731 10.6698L19.6318 9.88977C20.1249 9.38291 20.1249 8.56336 19.6318 8.0565ZM8.82343 17.756V15.8109C8.82343 15.6595 8.88106 15.5114 8.98671 15.4027L15.4538 8.75426L18.1399 11.5157L11.6728 18.1642C11.5672 18.2728 11.4263 18.332 11.2758 18.332H9.3837C9.07316 18.332 8.82343 18.0753 8.82343 17.756Z"
              fill="white"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_13218_75595">
            <rect width="20" height="20" fill="white" />
          </clipPath>
          <clipPath id="clip1_13218_75595">
            <rect
              width="20"
              height="17.5"
              fill="white"
              transform="translate(0 0.832031)"
            />
          </clipPath>
        </defs>
      </svg>
    )
  }

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13218_75612)">
        <path
          d="M0 16.4309C0 17.8475 1.39386 17.8475 1.39386 17.8475L5.69693 17.8475C6.00384 17.8475 6.25 17.5973 6.25 17.2854V12.2524C6.25639 11.83 6.37468 11.4206 6.59527 11.0632L6.78069 10.7578C2.91667 10.7578 0 15.011 0 16.4309Z"
          fill="#021120"
        />
        <path
          d="M11.3307 2.07646C10.5442 1.27717 9.47966 0.832031 8.37033 0.832031C7.26099 0.832031 6.19642 1.28042 5.40997 2.07646C4.62353 2.87576 4.18555 3.95773 4.18555 5.08519C4.18555 6.21265 4.62672 7.29462 5.40997 8.09391C6.19322 8.89321 7.26099 9.33834 8.37033 9.33834C9.47966 9.33834 10.5442 8.88996 11.3307 8.09391C12.1171 7.29462 12.5551 6.21265 12.5551 5.08519C12.5551 3.95773 12.1139 2.87576 11.3307 2.07646Z"
          fill="#021120"
        />
        <path
          d="M18.4522 11.2814H17.0257C16.9706 10.5186 16.636 9.80671 16.0699 9.26188C15.4485 8.66256 14.625 8.33203 13.7463 8.33203C12.8676 8.33203 12.0404 8.66256 11.4228 9.26188C10.8566 9.80671 10.5221 10.5186 10.4669 11.2814H9.04044C8.19118 11.2814 7.5 11.9497 7.5 12.7706V18.5095C7.5 19.3304 8.19118 19.9987 9.04044 19.9987H18.4596C19.3088 19.9987 20 19.3304 20 18.5095V12.7706C20 11.9497 19.3088 11.2814 18.4596 11.2814H18.4522ZM14.3051 16.1304L14.614 18.0482H12.8713L13.1765 16.1304C12.6912 15.9234 12.3529 15.4548 12.3529 14.91C12.3529 14.1726 12.9743 13.5733 13.739 13.5733C14.5037 13.5733 15.125 14.1726 15.125 14.91C15.125 15.4548 14.7868 15.9197 14.3015 16.1304H14.3051ZM12.1654 11.2814C12.2169 10.9291 12.3713 10.6022 12.614 10.3443C12.9154 10.0246 13.3162 9.8503 13.7426 9.8503C14.1691 9.8503 14.5699 10.0246 14.8713 10.3443C15.114 10.6022 15.2684 10.9291 15.3199 11.2814H12.1654Z"
          fill="#021120"
        />
      </g>
      <defs>
        <clipPath id="clip0_13218_75612">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

LockIcon.defaultProps = {
  isSelfLocked: false
}
