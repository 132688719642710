import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Empty, Form, Input, Typography } from 'antd'
import useSWR from 'swr'
import { Folders, ListBuildingFiles } from 'types/building'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'
import Spinner from 'components/atoms/Spinner'
import ViewFile360Icon from 'components/icons/360ViewFileIcon'

import ViewerFilePreview from '../buildingDetailViewMode/components/ViewerFilePreview'
import PreviewFile from '../panoramas/components/PreviewFile'

import FolderIcon from './FolderIcon'
import ModalListRelatedPoints from './ModalListRelatedPoints'

type Props = {
  selectedFolder: Folders | undefined
  setSelectedFolder: Dispatch<SetStateAction<Folders | undefined>>
  selectedChildrenFolder: Folders | undefined
  setSelectedChildrenFolder: Dispatch<SetStateAction<Folders | undefined>>
}

export default function ListFile({
  selectedFolder,
  setSelectedFolder,
  selectedChildrenFolder,
  setSelectedChildrenFolder
}: Props) {
  const { id } = useParams()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const [searchFileName, setSearchFileName] = useState('')
  const [selectedFileIndex, setSelectedFileIndex] = useState<number>()
  const [selectedFile, setSelectedFile] = useState<number>()

  const isShowingFileLevel =
    selectedFolder && (selectedFolder.isFileHolder || selectedChildrenFolder)

  const { data: buildingFiles, isValidating } = useSWR<ListBuildingFiles>(
    isShowingFileLevel
      ? {
          url: '/v1/buildings/files',
          params: {
            building_id: id,
            category: selectedFolder?.isFileHolder
              ? selectedFolder.id
              : selectedChildrenFolder?.id,
            keyword: searchFileName
          }
        }
      : null
  )

  const sortedFiles = useMemo(
    () =>
      [...(buildingFiles?.files || [])].sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
    [buildingFiles?.files]
  )

  const handleSearch = (values: any) => {
    setSearchFileName(values.name)
  }

  const handleBack = () => {
    if (selectedChildrenFolder) {
      setSelectedChildrenFolder(undefined)
    } else {
      setSelectedFolder(undefined)
    }
    searchFileName && setSearchFileName('')
  }

  if (!isShowingFileLevel) return null

  return (
    <>
      <Form form={form} preserve={false} onFinish={handleSearch}>
        <div className="flex items-center justify-between rounded-[10px] bg-white pl-10 pr-5 py-5">
          <div className="flex items-center gap-[10px]">
            <Typography className="my-auto text-[14px] leading-[21px] text-[#021120] font-bold">
              キーワード
            </Typography>
            <Form.Item
              rules={[
                {
                  validator: Validators.searchFileValidator(t, false, true)
                }
              ]}
              name="name"
              className="mb-0 thin-error-message"
            >
              <Input className="w-[180px] h-[38px] rounded-md" />
            </Form.Item>
          </div>
          <Button type="primary" htmlType="submit">
            検索
          </Button>
        </div>
      </Form>
      <div className="grid grid-rows-[auto_1fr] overflow-auto">
        <div className="px-5 bg-[#3163B7]">
          <div
            className="flex gap-4 py-1 items-center mr-auto cursor-pointer"
            onClick={handleBack}
          >
            <FolderIcon type={selectedFolder.id} size="small" />
            <Typography className="text-white font-medium">
              {selectedFolder?.label}
              {selectedChildrenFolder ? (
                <>&nbsp;&gt;&nbsp;{selectedChildrenFolder.label}</>
              ) : (
                ''
              )}
            </Typography>
          </div>
        </div>
        <div className="overflow-auto bg-white">
          {isValidating && <Spinner />}
          {Boolean(!isValidating && sortedFiles.length) &&
            sortedFiles.map((file, index) => (
              <div
                key={file.id}
                className="flex items-center gap-5 justify-between min-h-[80px] p-[10px] border-0 border-b border-solid border-[#EEEFF2]"
              >
                <div
                  className="flex items-center gap-5 cursor-pointer"
                  onClick={() => setSelectedFileIndex(index)}
                >
                  <div className="flex-shrink-0">
                    <PreviewFile file={file} size="large" />
                  </div>
                  <Typography className="font-medium text-[#021120]">
                    {file.name}
                  </Typography>
                </div>
                {file.isRelated && (
                  <Button
                    className="border-[1px] border-solid border-[#3163B7] text-[#3163B7] gap-[3px] py-[7px] px-[3px] bg-white flex justify-center items-center rounded-[4px] hover:!cursor-pointer hover:!text-[#3163B7] hover:!bg-white hover:!opacity-100 mr-[28px]"
                    onClick={() => setSelectedFile(file.id)}
                    sizing="w-[160px] h-[40px]"
                  >
                    <ViewFile360Icon />
                    {t('building.360ViewFile')}
                  </Button>
                )}
              </div>
            ))}
          {Boolean(!isValidating && !sortedFiles.length) && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('building.fileNotFound')}
            />
          )}
        </div>
      </div>

      {buildingFiles && selectedFileIndex !== undefined ? (
        <ViewerFilePreview
          fileIndex={selectedFileIndex}
          file={sortedFiles[selectedFileIndex]}
          maxLength={buildingFiles.files.length}
          setPreviewFileIndex={setSelectedFileIndex}
        />
      ) : null}

      <ModalListRelatedPoints
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
      />
    </>
  )
}
