import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, FormInstance, Typography, Upload, UploadProps } from 'antd'
import useMutation from 'hooks/useMutation'
import { useRecoilState } from 'recoil'
import { floorDetailState, isUploadingMapState } from 'store/buildingStore'
import useSWR from 'swr'
import Utils from 'utils'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Spinner from 'components/atoms/Spinner'
import Title from 'components/atoms/Title'

type Props = {
  form: FormInstance<any>
}

const { Dragger } = Upload

export default function UploadMapModal({ form }: Props) {
  const { t } = useTranslation()
  const { floorId } = useParams()
  const stopPropagation = useRef<boolean>(false)
  const navigate = useNavigate()
  const [isUploadingMap, setIsUploadingMap] =
    useRecoilState(isUploadingMapState)
  const [, setFloorDetail] = useRecoilState(floorDetailState)

  const handleOpenDialog = () => {
    const mapUpload = document.getElementById('map-upload-modal')
    stopPropagation.current = true
    mapUpload && mapUpload.click()
  }

  const { trigger, isMutating } = useMutation(`v1/floors/${floorId}`)

  const { mutate } = useSWR(
    {
      url: `v1/floors/${floorId}`
    },
    {
      revalidateOnMount: false
    }
  )

  const handleClose = () => {
    setIsUploadingMap({
      isOpen: false,
      mapFile: null
    })
  }

  const handleSubmit = () => {
    const formData = new FormData()
    isUploadingMap.mapFile && formData.append('mapFile', isUploadingMap.mapFile)

    if (formData.get('mapFile')) {
      !isMutating &&
        trigger(
          {
            form,
            method: 'patch',
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
            successMessage: t('building.saved')
          },
          {
            onSuccess: () =>
              mutate()
                .then((data) => {
                  setFloorDetail(data)
                  handleClose()
                })
                .catch(() => {
                  handleClose()
                }),
            onError: (error) => {
              Utils.handleErrorNavigate(error, navigate)
              handleClose()
            }
          }
        )
    } else {
      form.setFields([
        {
          name: 'mapUploadWrapper',
          errors: [t('common.validationError.fileRequired')]
        }
      ])
    }
  }

  const types = ['.jpg', '.jpeg', '.png']

  const readFile = (file: File) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = (e) => {
        resolve(e)
      }
      img.onerror = () => {
        reject()
      }
      img.src = window.URL.createObjectURL(file)
    })
  }

  const isTypeInclude = (name: string, types: string[]) => {
    return Boolean(types.find((type) => name.toLowerCase().includes(type)))
  }

  const uploadProps: UploadProps = {
    name: 'map-upload-modal',
    multiple: false,
    showUploadList: false,
    beforeUpload: async (file) => {
      if (!isTypeInclude(file.name, types)) {
        form.setFields([
          {
            name: 'mapUploadWrapper',
            errors: [t('common.validationError.fileNotAllowed')]
          }
        ])
        return false
      }
      if (file.size && file.size / (1024 * 1024) > 50) {
        form.setFields([
          {
            name: 'mapUploadWrapper',
            errors: [t('common.validationError.fileTooLarge')]
          }
        ])
        return false
      }
      await readFile(file)
        .then(() => {
          setIsUploadingMap((prev) => ({
            ...prev,
            mapFile: file
          }))
          form.resetFields(['mapUploadWrapper'])
        })
        .catch(() => {
          form.setFields([
            {
              name: 'mapUploadWrapper',
              errors: [t('translation.brokenFileMessage')]
            }
          ])
        })
      return false
    },
    fileList: []
  }

  const handleEnterSubmit = () => {
    if (stopPropagation.current) {
      return
    }
    handleSubmit()
  }

  return (
    <Modal
      onCancel={handleClose}
      width={580}
      isEnterable
      onEnter={handleEnterSubmit}
    >
      <Spinner spinning={isMutating}>
        <Title title={t('building.mapUploadTitle')} bottomMargin="mb-8" />

        <Form form={form}>
          <Form.Item
            name="mapUploadWrapper"
            className="mb-0 thin-error-message"
          >
            <Dragger
              id="map-upload-modal"
              openFileDialogOnClick={false}
              {...uploadProps}
              onChange={() => {
                stopPropagation.current = false
              }}
            >
              <div className="grid gap-3 justify-items-center mx-[91px] my-2">
                <Typography className="text-[14px] leading-[21px]">
                  {isUploadingMap.mapFile
                    ? isUploadingMap.mapFile.name
                    : t('building.mapUploadGuiden')}
                </Typography>
                <Button
                  type="primary"
                  sizing="w-40 h-10"
                  onClick={handleOpenDialog}
                >
                  {t('building.selectFile')}
                </Button>
              </div>
            </Dragger>
          </Form.Item>
        </Form>

        <div className="flex gap-10 justify-center mt-10">
          <Button sizing="w-[140px] h-[50px]" onClick={handleClose}>
            {t('building.buttonCancel')}
          </Button>

          <Button
            type="primary"
            sizing="w-[140px] h-[50px]"
            onClick={handleSubmit}
          >
            {t('building.buttonSave')}
          </Button>
        </div>
      </Spinner>
    </Modal>
  )
}
