import { useEffect, useMemo, useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Tooltip, Typography } from 'antd'
import usePoint from 'hooks/usePoint'
import ConfirmMoveToOtherPoint from 'pages/building/panoramas/components/ConfirmSetting'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  buildingModeState,
  floorDetailState,
  listPointState,
  selectedAreaState,
  selectedPoint
} from 'store/buildingStore'
import { currentPovState } from 'store/krpanoStore'
import useSWR from 'swr'
import { OpenModalDelete, Point } from 'types/building'
import Utils from 'utils'

import Spinner from 'components/atoms/Spinner'
import LeftArrowIcon from 'components/icons/LeftArrowIcon'

import AreaDropDownMenu from '../MenuDropDown'
import MenuMiniMap from '../MenuMiniMap'
import ModalDeletePoint from '../ModalDeletePoint'

import PointGroupImages from './PointGroupImages'

type Props = {
  handleBack: () => void
}

export default function AreaMenu({ handleBack }: Props) {
  const { t } = useTranslation()
  const { floorId } = useParams()

  const [sortedPoint, setSortedPoint] = useState<Point[] | undefined>()
  const [openMenu, setOpenMenu] = useState<OpenModalDelete>({
    pointId: '',
    pointOrder: 0,
    isOpen: ''
  })
  const [isOpenConfirm, setIsOpenConfirm] = useState('')

  const buildingMode = useRecoilValue(buildingModeState)
  const points = useRecoilValue(listPointState)
  const [, setCurrentSelectedPoint] = useRecoilState(selectedPoint)
  const [selectedArea, setSelectedArea] = useRecoilState(selectedAreaState)
  const [currentPov, setCurrentPov] = useRecoilState(currentPovState)
  const floorDetail = useRecoilValue(floorDetailState)

  const { mutate, isValidating } = useSWR(
    {
      url: `v1/floors/${floorId}`
    },
    {
      revalidateOnMount: false
    }
  )

  const { isSorting, handleSortPoint } = usePoint()

  const orderedPoints = useMemo(
    () => [...points].sort((a, b) => a.order - b.order),
    [points]
  )

  const areaDetail: any = floorDetail?.areas.find(
    (item) => item.id === selectedArea
  )

  const onBack = () => {
    setSelectedArea(undefined)
    setCurrentSelectedPoint(undefined)
    currentPov && setCurrentPov(null)
    handleBack()
  }

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return

    if (isSorting) return

    if (sortedPoint) {
      const sorted = [...sortedPoint]
      const order = [...sorted.map((item) => item.order)]
      const [reorderedItem] = sorted.splice(result.source.index, 1)
      sorted.splice(result.destination.index, 0, reorderedItem)
      setSortedPoint(sorted)
      handleSortPoint(
        sorted.map((item, index) => ({
          id: item.id,
          order: order[index]
        }))
      )
    }
  }

  const handleOpenDropdown = (key: string, cb: any) => {
    if (!isOpenConfirm && buildingMode.selectedMode === 'direction') {
      setIsOpenConfirm(key)
      return
    }
    cb()
  }

  useEffect(() => {
    setSortedPoint(
      orderedPoints?.filter((item) => item.areaId === selectedArea)
    )
  }, [orderedPoints, selectedArea])

  return (
    <>
      {isValidating && <Spinner />}
      <div className="h-full grid grid-rows-[auto_auto_auto_1fr]">
        <div className="flex justify-between align-middle h-10 ml-[10px] cursor-pointer">
          <div
            className="flex items-center gap-3 cursor-pointer"
            onClick={onBack}
          >
            <LeftArrowIcon />
            <div className="grid my-[3.5px] gap-1">
              <Tooltip
                placement="right"
                title={
                  floorDetail?.name && floorDetail.name.length > 14
                    ? floorDetail.name
                    : undefined
                }
              >
                <div className="text-white text-[11px] h-[13px] leading-4">
                  {Utils.truncateString(floorDetail?.name || '', 14)}
                </div>
              </Tooltip>
              <div className="text-white h-5 font-bold">
                {t('common.sidebar.areaMenu')}
              </div>
            </div>
          </div>
          <AreaDropDownMenu
            handleToggle={handleOpenDropdown}
            areaDetail={areaDetail}
          />
        </div>

        <MenuMiniMap
          imagePath={floorDetail?.mapFile?.s3Path}
          isValidating={isValidating}
          mutate={mutate}
        />

        <div className="flex justify-between align-middle w-60 min-h-[40px] p-[10px] gap-3 cursor-pointer border-0 border-b border-solid border-[#1c314d]">
          <Typography.Text className="text-inherit text-white font-[700] text-[14px] leading-[14px] my-auto">
            {areaDetail?.name}
          </Typography.Text>
        </div>

        <div className="overflow-y-auto">
          {sortedPoint && (
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="points">
                {(provided: any) => (
                  <div
                    className="cursor-pointer"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {sortedPoint.map((point, index) => (
                      <PointGroupImages
                        key={`key ${point.id}`}
                        point={point}
                        pointIndex={index}
                        isOpenConfirm={isOpenConfirm}
                        setIsOpenConfirm={setIsOpenConfirm}
                        setOpenMenu={setOpenMenu}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      </div>
      {openMenu?.isOpen === 'handleDeletePoint' && (
        <ModalDeletePoint open={openMenu} setOpen={setOpenMenu} />
      )}
      {isOpenConfirm && (
        <ConfirmMoveToOtherPoint
          isOpenConfirm={isOpenConfirm}
          setIsOpenConfirm={setIsOpenConfirm}
        />
      )}
    </>
  )
}
