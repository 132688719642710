import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Avatar, Select } from 'antd'
import useDebounce from 'hooks/useDebounce'
import useMutation from 'hooks/useMutation'
import useSWR from 'swr'
import { InvitedBranchAdmin } from 'types/listOrganization'

import Button from 'components/atoms/Button'

type Props = {
  isRefetchList: boolean
  setIsRefetchList: Dispatch<SetStateAction<boolean>>
}

export default function InviteBrandAdmin({
  isRefetchList,
  setIsRefetchList
}: Props) {
  const { id } = useParams()
  const { t } = useTranslation()

  const [userCanBeInvited, setUserCanBeInvited] =
    useState<InvitedBranchAdmin[]>()
  const [userToBeInvited, setUserToBeInvited] = useState<number[]>()
  const [searchString, setSearchString] = useState('')

  const debouncedSearchString = useDebounce(searchString, 500)

  useSWR<InvitedBranchAdmin[]>(
    {
      url: `v1/organizations/${id}/managers/invite`,
      params: {
        keyword: debouncedSearchString,
        isRefetchList
      }
    },
    {
      onSuccess: (data) => {
        setUserCanBeInvited(data)
      }
    }
  )

  const { trigger: triggerInvite, isMutating: isInviting } = useMutation(
    `v1/organizations/${id}/managers/invite`
  )

  const handleInvite = async () => {
    if (!isInviting && userToBeInvited?.length) {
      await triggerInvite(
        {
          data: {
            userIds: userToBeInvited
          }
        },
        {
          onSuccess: () => {
            setIsRefetchList((prev) => !prev)
          }
        }
      )
      setUserToBeInvited([])
    }
  }

  return (
    <div className="flex gap-[30px] overflow-auto max-h-[100px] rounded-md">
      <Select
        mode="multiple"
        value={userToBeInvited}
        placeholder="メールアドレス、名前をカンマで区切って入力"
        defaultActiveFirstOption={false}
        suffixIcon={null}
        filterOption={false}
        onSearch={(newValue: string) => {
          setSearchString(newValue)
        }}
        onChange={(newValue: number[]) => {
          setUserToBeInvited(newValue)
          setSearchString('')
        }}
        className="grow over-flow-select-box min-h-[40px]"
        notFoundContent={null}
        optionLabelProp="label"
      >
        {userCanBeInvited?.map((user: any) => (
          <Select.Option key={user.id} value={user.id} label={user.email}>
            <div className="flex gap-3">
              <Avatar
                className="min-w-[2.5rem] text-black font-light"
                size="large"
              >
                {user.lastName.split('')[0]}
                {user.firstName.split('')[0]}
              </Avatar>
              <div className="grid">
                <div className="text-sm h-5 text-[#021120]">
                  {user.lastName}&nbsp;{user.firstName}
                </div>
                <div className="text-xs h-4 text-[#555]">{user.email}</div>
              </div>
            </div>
          </Select.Option>
        ))}
      </Select>
      <Button
        loading={isInviting}
        type="primary"
        sizing="w-[100px] h-[40px]"
        className="flex-shrink-0"
        onClick={handleInvite}
      >
        {t('translation.add')}
      </Button>
    </div>
  )
}
