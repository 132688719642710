import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Col, DatePicker, Form, Input, Row, Select, Typography } from 'antd'
import axios from 'axios'
import Const from 'constants/constant'
import dayjs from 'dayjs'
import useMutation from 'hooks/useMutation'
import { Branch } from 'models/Branch'
import { Building } from 'models/Building'
import { Organization } from 'models/User'
import ModalBranch from 'pages/building/listBuilding/components/ModalBranch'
import useSWR from 'swr'
import { SelectBranch } from 'types/listUser'
import Utils from 'utils'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'
import FormItem from 'components/atoms/FormItem'
import Modal from 'components/atoms/Modal'
import Spinner from 'components/atoms/Spinner'
import CalendarIcon from 'components/icons/Calendar'
import DropdownIconCustom from 'components/icons/DropdownIconCustom'

import ImageUpload from '../ImageUpload'

import SelectOrganization from './SelectOrganization'

type Props = {
  handleCancel: () => void
}

export default function ModalEditBuildingDetail({ handleCancel }: Props) {
  const { t } = useTranslation()
  const { id } = useParams()
  const [form] = Form.useForm()

  const stopPropagation = useRef<boolean>(false)

  const [openModalBranch, setOpenModalBranch] = useState(false)
  const [listServices, setListServices] = useState<Branch[]>([])
  const constructionPeriodStart = Form.useWatch('constructionPeriodStart', form)
  const constructionPeriodEnd = Form.useWatch('constructionPeriodEnd', form)
  const [zipcodeField, setZipcodeField] = useState<string>()
  const [defaultBranch, setDefaultBranch] = useState<SelectBranch | undefined>()
  const [selectedOrganization, setSelectedOrganization] = useState<
    Organization | undefined
  >()

  const navigate = useNavigate()

  const {
    data: building,
    mutate: refetchBuilding,
    isValidating
  } = useSWR<Building>(
    {
      url: `/v1/buildings/${id}`
    },
    {
      revalidateOnMount: false
    }
  )

  useSWR(
    defaultBranch
      ? {
          url: '/v1/branches',
          params: {
            type: 'service',
            keyword: defaultBranch.name
          }
        }
      : null,
    {
      onSuccess: (services) => {
        setListServices(services.edges)
        const existingService = services.edges.find(
          (item: any) => item.id === defaultBranch?.id
        )
        form.setFieldValue(
          'branchId',
          existingService ? existingService.id : services.edges[0].id
        )
      }
    }
  )

  const { trigger: editBuilding, isMutating } = useMutation(
    `/v1/buildings/${id}`,
    {
      onError: (error) => {
        if (error?.response?.data?.error?.key === 'code_is_unique') {
          Utils.handleError(error, t, form)
          return
        }
        Utils.handleError(error, t, form)
        navigate('/')
      }
    }
  )

  const openSelectBranch = () => {
    setOpenModalBranch(true)
  }

  const closeModalBranch = () => {
    setOpenModalBranch(false)
  }

  const handleChangedZipcode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setZipcodeField(e.target.value)
  }

  const handleGetCodeAddress = async () => {
    try {
      const res = await axios.get(
        `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${
          zipcodeField?.trim() || building?.zipcode
        }`
      )
      const address = res.data.results[0]
      form.setFieldsValue({
        prefCode: Number(address.prefcode),
        cityName: address.address2,
        streetName: address.address3
      })
    } catch (error) {
      // TODO
    }
  }

  const handleEditInformation = (values: any) => {
    const convertedValues = Utils.buildingFormDataConverted(
      Utils.trimObjectValues(values)
    )

    const formData = new FormData()

    Object.keys(convertedValues).forEach((key) => {
      if (key === 'image') {
        if (convertedValues[key] instanceof File) {
          formData.append(key, convertedValues[key])
          return
        }
        return
      }
      if (
        (key === 'constructionPeriodEnd' ||
          key === 'constructionPeriodStart') &&
        !convertedValues[key]
      ) {
        return
      }
      if (
        key === 'buildingArea' ||
        key === 'totalFloorArea' ||
        key === 'siteArea'
      ) {
        formData.append(key, convertedValues[key])
        return
      }
      if (key === 'organizationId' && !convertedValues[key]) {
        formData.append('organizationId', `${building?.organization.id}`)
        return
      }
      if (key === 'prefCode' && !convertedValues[key]) {
        return
      }
      formData.append(key, convertedValues[key] ? convertedValues[key] : '')
    })

    !isMutating &&
      editBuilding(
        {
          method: 'patch',
          form,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
          successMessage: t('building.saved')
        },
        {
          onSuccess: () => {
            refetchBuilding()
            handleCancel()
          }
        }
      )
  }

  const handleSelectBranch = async (value: SelectBranch) => {
    setOpenModalBranch(false)
    setDefaultBranch(value)
  }

  const disabledStartDate = (currentDate: any) => {
    return (
      constructionPeriodEnd &&
      dayjs(currentDate).format('YYYY-MM-DD') >=
        dayjs(constructionPeriodEnd).format('YYYY-MM-DD')
    )
  }

  const disabledEndDate = (currentDate: any) => {
    return (
      constructionPeriodStart &&
      dayjs(currentDate).format('YYYY-MM-DD') <=
        dayjs(constructionPeriodStart).format('YYYY-MM-DD')
    )
  }

  const handleEnterSubmit = () => {
    if (stopPropagation.current || openModalBranch) {
      return
    }
    form
      .validateFields()
      .then(() => handleEditInformation(form.getFieldsValue()))
      .catch(() => {})
  }

  useEffect(() => {
    if (building) {
      form.setFieldsValue({
        ...building,
        organizationId: building?.organization?.id,
        s3Path: building?.thumbnail || building?.s3Path,
        constructionPeriodStart:
          building?.constructionPeriodStart &&
          dayjs(building.constructionPeriodStart),
        constructionPeriodEnd:
          building?.constructionPeriodEnd &&
          dayjs(building.constructionPeriodEnd)
      })

      setDefaultBranch(building.branch)
      setSelectedOrganization(building.organization)
    }
  }, [building, form])

  return (
    <>
      <Modal
        width={690}
        className="edit-building-modal"
        zIndex={999}
        isEnterable
        onEnter={handleEnterSubmit}
      >
        <Spinner spinning={isMutating || isValidating}>
          <Form
            form={form}
            colon={false}
            onFinish={handleEditInformation}
            labelAlign="left"
            onFocus={() => {
              stopPropagation.current = true
            }}
            onBlur={() => {
              stopPropagation.current = false
            }}
          >
            <Typography className="text-xl leading-[30px] font-bold text-center text-[#3163B7]">
              {t('building.editInformation')}
            </Typography>
            <div className="border-0 border-b border-solid border-[#3163B7] w-10 m-auto mt-[10px] mb-[14px]" />

            <div className="text-center m-auto w-[660px] px-[93.5px] h-[385px] overflow-y-auto">
              <Typography className="leading-[23.17px] font-[700] text-[#3163B7] text-left mb-[14px]">
                {t('building.propertyInformation')}
              </Typography>

              {/* field: name */}
              <Row justify="space-between">
                <Col className="h-[38px] flex items-center">
                  <Typography className="text-[14px] leading-[21px] font-bold text-[#021120]">
                    {t('building.buildingNameForm')}
                  </Typography>
                </Col>
                <Col>
                  <Form.Item
                    name="name"
                    className="thin-error-message thin-light-placeholder"
                    rules={[
                      {
                        validator: Validators.nameBuildingValidator(t)
                      }
                    ]}
                  >
                    <Input
                      className="w-[340px] h-[38px] text-sm text-[#021120]"
                      placeholder={t('common.floor.placeHolder')}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* field: code */}
              <Row justify="space-between">
                <Col className="h-[38px] flex items-center">
                  <Typography className="text-[14px] leading-[21px] font-bold text-[#021120]">
                    {t('building.code')}
                  </Typography>
                </Col>
                <Col>
                  <Form.Item
                    name="code"
                    className="thin-error-message thin-light-placeholder"
                    rules={[
                      {
                        validator: Validators.codeValidator(t)
                      }
                    ]}
                  >
                    <Input
                      className="w-[340px] h-[38px] text-sm text-[#021120]"
                      placeholder={t('common.floor.placeHolder')}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* field: aliasName */}
              <Row justify="space-between">
                <Col className="h-[38px] flex items-center">
                  <Typography className="text-[14px] leading-[21px] font-bold text-[#021120]">
                    {t('building.constructionName')}
                  </Typography>
                </Col>
                <Col>
                  <Form.Item
                    name="aliasName"
                    className="thin-error-message thin-light-placeholder"
                    rules={[
                      {
                        validator: Validators.aliasNameValidator(t)
                      }
                    ]}
                  >
                    <Input
                      className="w-[340px] h-[38px] text-sm text-[#021120]"
                      placeholder={t('common.floor.placeHolder')}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* field: zipcode */}
              <Row justify="space-between">
                <Col className="h-[38px] flex items-center">
                  <Typography className="text-[14px] leading-[21px] font-bold text-[#021120]">
                    {t('building.postCode')}
                  </Typography>
                </Col>
                <Col className="flex gap-[19px]">
                  <Form.Item
                    name="zipcode"
                    className="thin-error-message thin-light-placeholder"
                    rules={[
                      { validator: Validators.zipcodeValidator(t) },
                      {
                        transform: (value: string) => value.trim()
                      }
                    ]}
                  >
                    <Input
                      className="w-[220px] h-[38px] text-sm text-[#021120]"
                      placeholder={t('common.floor.placeHolder')}
                      onChange={handleChangedZipcode}
                    />
                  </Form.Item>
                  <div className="bg-[#F9FBFF]">
                    <Button
                      sizing="w-[100px] h-[40px]"
                      type="primary"
                      onClick={handleGetCodeAddress}
                    >
                      {t('organization.buttonZipCode')}
                    </Button>
                  </div>
                </Col>
              </Row>

              {/* field: prefCode */}
              <Row justify="space-between">
                <Col className="h-[38px] flex items-center">
                  <Typography className="text-[14px] leading-[21px] font-bold text-[#021120]">
                    {t('building.province')}
                  </Typography>
                </Col>
                <Col>
                  <Form.Item
                    name="prefCode"
                    className="thin-light-placeholder thin-error-message"
                  >
                    <Select
                      className="!w-[340px] input-edit-information "
                      placeholder={t('translation.selectProvinceCode')}
                      suffixIcon={<DropdownIconCustom colorIcon="#021120" />}
                    >
                      {Const.PREFECTURES.map((children: any) => {
                        return (
                          <Select.Option
                            key={children.prefCode}
                            value={children.prefCode}
                          >
                            <span className="text-sm text-[#021120]">
                              {children.prefName}
                            </span>
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              {/* field: cityName */}
              <Row justify="space-between">
                <Col className="h-[38px] flex items-center">
                  <Typography className="text-[14px] leading-[21px] font-bold text-[#021120]">
                    {t('building.city')}
                  </Typography>
                </Col>
                <Col>
                  <Form.Item
                    name="cityName"
                    className="thin-error-message thin-light-placeholder"
                    rules={[
                      {
                        validator: Validators.keywordsAndLengthCustomValidator(
                          t,
                          20,
                          false,
                          true
                        )
                      }
                    ]}
                  >
                    <Input
                      className="w-[340px] h-[38px] text-sm text-[#021120]"
                      placeholder={t('common.floor.placeHolder')}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* field: streetName */}
              <Row justify="space-between">
                <Col className="h-[38px] flex items-center">
                  <Typography className="text-[14px] leading-[21px] font-bold text-[#021120]">
                    {t('building.street')}
                  </Typography>
                </Col>
                <Col>
                  <Form.Item
                    name="streetName"
                    className="thin-error-message thin-light-placeholder"
                    rules={[
                      {
                        validator: Validators.keywordsAndLengthCustomValidator(
                          t,
                          30,
                          false,
                          true
                        )
                      }
                    ]}
                  >
                    <Input
                      className="w-[340px] h-[38px] text-sm text-[#021120]"
                      placeholder={t('common.floor.placeHolder')}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* field: buildingName */}
              <Row justify="space-between">
                <Col className="h-[38px] flex items-center">
                  <Typography className="text-[14px] leading-[21px] font-bold text-[#021120]">
                    {t('building.apartment')}
                  </Typography>
                </Col>
                <Col>
                  <Form.Item
                    name="buildingName"
                    className="thin-error-message thin-light-placeholder"
                    rules={[
                      {
                        validator: Validators.keywordsAndLengthCustomValidator(
                          t,
                          50,
                          false,
                          true
                        )
                      }
                    ]}
                  >
                    <Input
                      className="w-[340px] h-[38px] text-sm text-[#021120]"
                      placeholder={t('common.floor.placeHolder')}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* field: branch select */}
              <Row justify="space-between">
                <Col className="h-[38px] flex items-center">
                  <Typography className="text-[14px] leading-[21px] font-bold text-[#021120]">
                    {t('building.branchName')}
                  </Typography>
                </Col>
                <Col>
                  <Form.Item className="input-invite-user add-building mb-6">
                    <div className="ml-auto flex w-[340px] justify-between items-center">
                      <div className="text-[14px] leading-[21px] font-medium text-[#021120]">
                        {defaultBranch?.name}
                      </div>
                      <Button
                        type="primary"
                        sizing="w-[100px] h-10"
                        className="ml-14"
                        onClick={openSelectBranch}
                      >
                        {t('user.buttonSelectOrganization')}
                      </Button>
                    </div>
                  </Form.Item>
                </Col>
              </Row>

              {/* field: branchId */}
              {defaultBranch && (
                <Row justify="space-between">
                  <Col />
                  <Col className="w-[340px]">
                    <Row justify="space-between">
                      <Col className="h-[38px] flex items-center">
                        <Typography className="text-[14px] leading-[21px] font-bold text-[#021120]">
                          {t('building.serviceName')}
                        </Typography>
                      </Col>
                      <Col>
                        <FormItem
                          wrapperCol={{ span: 24 }}
                          name="branchId"
                          className="flex justify-end mb-8"
                          line={false}
                        >
                          <Select
                            className="!w-[278px] float-right rounded-md text-[14px] leading-[21px] font-medium input-edit-information"
                            placeholder={t('building.placeholder')}
                            suffixIcon={
                              <DropdownIconCustom colorIcon="#000000" />
                            }
                          >
                            {listServices.map((service) => {
                              return (
                                <Select.Option
                                  value={service.id}
                                  key={service.id}
                                >
                                  <span className="text-sm text-[#021120]">
                                    {service.serviceStoreName}
                                  </span>
                                </Select.Option>
                              )
                            })}
                          </Select>
                        </FormItem>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}

              {/* field: organizationId */}
              <SelectOrganization
                form={form}
                selectedOrganization={selectedOrganization}
                setSelectedOrganization={setSelectedOrganization}
              />

              {/* field: image */}
              <Row justify="space-between">
                <Col className="h-[120px] flex items-center">
                  <Typography className="text-[14px] leading-[21px] font-bold text-[#021120]">
                    {t('building.propertyImage')}
                  </Typography>
                </Col>
                <Col>
                  <ImageUpload form={form} isValidating={isValidating} />
                </Col>
              </Row>

              <div className="w-full mb-[30px] border-0 border-b border-[#CED0D4] border-solid" />

              <Typography className="text-[16px] leading-[23.17px] font-[700] text-[#3163B7] text-left mb-[14px]">
                {t('building.propertyData')}
              </Typography>

              {/* field: siteArea */}
              <Row justify="space-between">
                <Col className="h-[38px] flex items-center">
                  <Typography className="text-[14px] leading-[21px] font-medium text-[#021120]">
                    {t('building.siteAreaSquare')}
                  </Typography>
                </Col>
                <Col className="flex">
                  <Form.Item
                    name="siteArea"
                    className="thin-error-message thin-light-placeholder"
                    rules={[
                      {
                        validator: Validators.inputAreaValidator(t)
                      }
                    ]}
                  >
                    <Input
                      className="w-[310px] h-[38px] text-sm text-[#021120]"
                      placeholder={t('common.floor.placeHolder')}
                    />
                  </Form.Item>
                  <div className="h-[38px] flex">
                    <span className="px-2 mt-auto text-[14px] leading-[21px] font-medium text-[#021120]">
                      &#13217;
                    </span>
                  </div>
                </Col>
              </Row>

              {/* field: buildingArea */}
              <Row justify="space-between">
                <Col className="h-[38px] flex items-center">
                  <Typography className="text-[14px] leading-[21px] font-medium text-[#021120]">
                    {t('building.buildingAreaSquare')}
                  </Typography>
                </Col>
                <Col className="flex">
                  <Form.Item
                    name="buildingArea"
                    className="thin-error-message thin-light-placeholder"
                    rules={[
                      {
                        validator: Validators.inputAreaValidator(t)
                      }
                    ]}
                  >
                    <Input
                      className="w-[310px] h-[38px] text-sm text-[#021120]"
                      placeholder={t('common.floor.placeHolder')}
                    />
                  </Form.Item>
                  <div className="h-[38px] flex">
                    <span className="px-2 mt-auto text-[14px] leading-[21px] font-medium text-[#021120]">
                      &#13217;
                    </span>
                  </div>
                </Col>
              </Row>

              {/* field: totalFloorArea */}
              <Row justify="space-between">
                <Col className="h-[38px] flex items-center">
                  <Typography className="text-[14px] leading-[21px] font-medium text-[#021120]">
                    {t('building.totalFloorAreaSquare')}
                  </Typography>
                </Col>
                <Col className="flex">
                  <Form.Item
                    name="totalFloorArea"
                    className="thin-error-message thin-light-placeholder"
                    rules={[
                      {
                        validator: Validators.inputAreaValidator(t)
                      }
                    ]}
                  >
                    <Input
                      className="w-[310px] h-[38px] text-sm text-[#021120]"
                      placeholder={t('common.floor.placeHolder')}
                    />
                  </Form.Item>
                  <div className="h-[38px] flex">
                    <span className="px-2 mt-auto text-[14px] leading-[21px] font-medium text-[#021120]">
                      &#13217;
                    </span>
                  </div>
                </Col>
              </Row>

              {/* field: structure */}
              <Row justify="space-between">
                <Col className="h-[38px] flex items-center">
                  <Typography className="text-[14px] leading-[21px] font-medium text-[#021120]">
                    {t('building.architectureStructure')}
                  </Typography>
                </Col>
                <Col>
                  <Form.Item
                    name="structure"
                    className="thin-error-message thin-light-placeholder"
                    rules={[
                      {
                        validator: Validators.keywordsAndLengthCustomValidator(
                          t,
                          200,
                          undefined,
                          true
                        )
                      }
                    ]}
                  >
                    <Input
                      className="w-[310px] h-[38px] text-sm mr-[30px] text-[#021120]"
                      placeholder={t('common.floor.placeHolder')}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* field: floorNumber */}
              <Row justify="space-between">
                <Col className="h-[38px] flex items-center">
                  <Typography className="text-[14px] leading-[21px] font-medium text-[#021120]">
                    {t('building.numberOfFloor')}
                  </Typography>
                </Col>
                <Col>
                  <Form.Item
                    name="floorNumber"
                    className="thin-error-message thin-light-placeholder"
                    rules={[
                      {
                        validator: Validators.keywordsAndLengthCustomValidator(
                          t,
                          200,
                          false,
                          true
                        )
                      }
                    ]}
                  >
                    <Input
                      className="w-[310px] h-[38px] text-sm mr-[30px] text-[#021120]"
                      placeholder={t('common.floor.placeHolder')}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <div className="w-full mb-[30px] border-0 border-b border-[#CED0D4] border-solid" />

              <Typography className="text-[16px] leading-[23.16px] font-[700] text-[#3163B7] text-left mb-[14px]">
                {t('building.designConstruction')}
              </Typography>

              {/* field: designerName */}
              <Row justify="space-between">
                <Col className="h-[38px] flex items-center">
                  <Typography className="text-[14px] leading-[21px] font-medium text-[#021120]">
                    {t('building.design')}
                  </Typography>
                </Col>
                <Col>
                  <Form.Item
                    name="designerName"
                    className="thin-error-message thin-light-placeholder"
                    rules={[
                      {
                        validator: Validators.keywordsAndLengthCustomValidator(
                          t,
                          200,
                          false,
                          true
                        )
                      }
                    ]}
                  >
                    <Input
                      className="w-[340px] h-[38px] text-sm text-[#021120]"
                      placeholder={t('common.floor.placeHolder')}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* field: architectName */}
              <Row justify="space-between">
                <Col className="h-[38px] flex items-center">
                  <Typography className="text-[14px] leading-[21px] font-medium text-[#021120]">
                    {t('building.construction')}
                  </Typography>
                </Col>
                <Col>
                  <Form.Item
                    name="architectName"
                    className="thin-error-message thin-light-placeholder"
                    rules={[
                      {
                        validator: Validators.keywordsAndLengthCustomValidator(
                          t,
                          200,
                          false,
                          true
                        )
                      }
                    ]}
                  >
                    <Input
                      className="w-[340px] h-[38px] text-sm text-[#021120]"
                      placeholder={t('common.floor.placeHolder')}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* field: constructionPeriodStart & constructionPeriodEnd */}
              <Row justify="space-between">
                <Col className="h-[38px] flex items-center">
                  <Typography className="text-[14px] leading-[21px] font-medium text-[#021120]">
                    {t('building.constructionPeriod')}
                  </Typography>
                </Col>
                <Col>
                  <Form.Item className="!mb-0">
                    <div className="grid grid-cols-[1fr_auto_1fr] w-[340px]">
                      <Form.Item
                        name="constructionPeriodStart"
                        className="mb-0"
                      >
                        <DatePicker
                          placeholder=""
                          className="h-[38px] text-sm"
                          suffixIcon={<CalendarIcon />}
                          format="YYYY年MM月DD日"
                          disabledDate={disabledStartDate}
                        />
                      </Form.Item>
                      <span className="flex items-center h-[38px] p-[10px] text-[#021120]">
                        〜
                      </span>
                      <Form.Item name="constructionPeriodEnd" className="mb-0">
                        <DatePicker
                          placeholder=""
                          className="h-[38px] !text-sm"
                          suffixIcon={<CalendarIcon />}
                          format="YYYY年MM月DD日"
                          disabledDate={disabledEndDate}
                        />
                      </Form.Item>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <div className="flex gap-10 justify-center w-full items-end h-full py-5 border-0 border-t border-solid border-[#EEEFF2]">
              <Button
                sizing="w-[140px] h-[50px]"
                className="bg-[#A6AAAF]"
                onClick={() => {
                  handleCancel()
                  form.resetFields()
                }}
              >
                {t('common.cancel')}
              </Button>
              <Button
                sizing="w-[140px] h-[50px]"
                type="primary"
                htmlType="submit"
              >
                {t('common.confirm')}
              </Button>
            </div>
          </Form>
        </Spinner>
      </Modal>

      {openModalBranch && (
        <ModalBranch
          handleCancel={closeModalBranch}
          handleOk={handleSelectBranch}
          buildingBranchName={defaultBranch?.name}
        />
      )}
    </>
  )
}
