import React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from 'antd/es/typography/Typography'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'

type Props = {
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleDelete: () => void
  organizationName: string
}

export default function ModalDeleteOrganization({
  isModalOpen,
  setIsModalOpen,
  handleDelete,
  organizationName
}: Props) {
  const { t } = useTranslation()

  const handleOk = () => {
    handleDelete()
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <Modal
      key={organizationName}
      open={isModalOpen}
      onCancel={handleCancel}
      className="rounded-modal-lg"
      rootClassName=""
      isEnterable
      onEnter={handleOk}
      footer={[
        <div className="flex justify-center items-center gap-6 pb-5">
          <Button
            className="bg-[#A6AAAF]"
            sizing="w-[140px] h-[50px]"
            onClick={handleCancel}
          >
            {t('organization.buttonCancel')}
          </Button>

          <Button
            className="bg-[#B80808]"
            sizing="w-[140px] h-[50px]"
            onClick={handleOk}
          >
            {t('organization.buttonDelete')}
          </Button>
        </div>
      ]}
    >
      <div className="min-h-[240px] w-[480px] px-[48px] pt-[40px] pb-[28px] rounded-[20px] flex flex-col items-center justify-center">
        <Title title={t('organization.titleModalDelete')} height={30} />

        <Typography className="text-[14px] font-[400] mt-[16px] mb-[40px]">
          {t('organization.questionModelDelete')}
        </Typography>

        <Typography className="text-[16px] font-[400] bg-[#F9FBFF] w-[360px] py-4 rounded-[10px] leading-6	text-center">
          {organizationName}
        </Typography>
      </div>
    </Modal>
  )
}
