import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Form } from 'antd'
import { CurrentOrganization } from 'models/Organization'
import useSWR from 'swr'

import Spinner from 'components/atoms/Spinner'

import FormRegister from '../listOrganization/components/FormRegister'

export default function DetailOrganization() {
  const params = useParams()
  const { t } = useTranslation()
  const pathDetailAPI = params.id ? `/v1/organizations/${params.id}` : ''
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const urlDetailOrg =
    params && params.id
      ? {
          url: pathDetailAPI
        }
      : null

  const { data: organization, isValidating } = useSWR<CurrentOrganization>(
    urlDetailOrg,
    {
      onError: (err) => {
        if (err.response.data.error.key === 'record_not_found') {
          navigate('/404')
        }
      },
      onSuccess: (organization) => {
        const dataDetail = {
          id: organization.id,
          name: organization.name,
          nameRuby: organization.nameRuby,
          url: organization?.url || '',
          phone: organization?.phone || '',
          zipcode: organization?.zipcode,
          emailDomain: organization.emailDomain,
          prefCode: organization.prefCode,
          cityName: organization.cityName,
          buildingName: organization.buildingName,
          streetName: organization?.streetName || ''
        }

        form.setFieldsValue(dataDetail)
      }
    }
  )

  return (
    <Spinner spinning={isValidating}>
      <div>
        <div className="flex items-center font-bold text-xl mb-5 text-[20px] leading-[30px]">
          {t('organization.titleUpdated')}
        </div>
        <FormRegister form={form} organization={organization} />
      </div>
    </Spinner>
  )
}
