import { useEffect, useRef } from 'react'

type Props = {
  onIntersecting: () => void
  threshold?: number
}

export default function useIntersectionObserver({
  onIntersecting,
  threshold
}: Props) {
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const node = ref?.current
    const hasIOSupport = !!window.IntersectionObserver

    if (!hasIOSupport || !node) return undefined

    const observer = new IntersectionObserver(
      ([entry]: IntersectionObserverEntry[]): void => {
        entry?.isIntersecting && onIntersecting()
      },
      { threshold }
    )

    observer.observe(node)

    return () => {
      observer.disconnect()
    }
  }, [onIntersecting, threshold])

  return { ref }
}

useIntersectionObserver.defaultProps = {
  threshold: 0
}
