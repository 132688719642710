import { Dispatch, SetStateAction, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { List, Typography } from 'antd'
import { Annotation, BuildingFile } from 'types/building'

import Button from 'components/atoms/Button'
import RightArrowIcon from 'components/icons/RightArrowIcon'

import PreviewFile from './PreviewFile'

type Props = {
  currentSelected: Annotation | undefined
  selectedChildrenFolder: any
  setSelectedChildrenFolder: Dispatch<SetStateAction<any>>
  childrenFolder: any
  setIsAddingFile: Dispatch<SetStateAction<boolean>>
  setExistsFile: Dispatch<SetStateAction<BuildingFile[]>>
}

export default function FolderList({
  currentSelected,
  selectedChildrenFolder,
  setSelectedChildrenFolder,
  childrenFolder,
  setIsAddingFile,
  setExistsFile
}: Props) {
  const { t } = useTranslation()

  // handle select file in that folder by category id
  const annotationsFile: BuildingFile[] = useMemo(() => {
    const filterList = currentSelected?.files?.filter(
      (file) => file.category === selectedChildrenFolder?.id
    )
    return filterList?.sort((a, b) => a.name.localeCompare(b.name)) || []
  }, [currentSelected?.files, selectedChildrenFolder])

  const handleSelectChildrenFolder = (value: any) => {
    setSelectedChildrenFolder(value)
  }

  useEffect(() => {
    setExistsFile(annotationsFile)
  }, [annotationsFile, setExistsFile])

  if (selectedChildrenFolder) {
    return (
      <>
        <List
          style={{
            height: 248,
            overflow: 'auto'
          }}
          dataSource={annotationsFile}
          renderItem={(item) => (
            <List.Item className="flex gap-5 !justify-start !pl-[10px] min-h-[60px] cursor-pointer">
              <div className="w-fit">
                <PreviewFile file={item} />
              </div>
              <Typography className="text-[#021120] leading-[23.17px] font-medium">
                {item.name}
              </Typography>
            </List.Item>
          )}
        />
        <Button
          type="primary"
          sizing="w-[300px] h-[60px] m-auto mt-[40px]"
          onClick={() => setIsAddingFile(true)}
        >
          {t('building.selectFile')}
        </Button>
      </>
    )
  }

  return (
    <List
      style={{
        height: 300,
        overflow: 'auto'
      }}
      dataSource={childrenFolder}
      renderItem={(item: any) => (
        <List.Item
          className="flex !pl-[10px] h-[60px] !pr-5 cursor-pointer"
          onClick={() =>
            handleSelectChildrenFolder({
              id: item.id,
              label: item.label
            })
          }
        >
          <Typography className="text-[#021120] leading-[23.17px] font-medium">
            {item.label}
          </Typography>
          <RightArrowIcon color="#3B3C46" size={24} />
        </List.Item>
      )}
    />
  )
}
