import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function DirectionSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.3377 6.32327C5.25464 6.12885 5.2191 5.93459 5.23465 5.7601C5.2502 5.5856 5.3163 5.43706 5.42627 5.32946C5.53624 5.22187 5.6862 5.15903 5.86098 5.14729C6.03577 5.13555 6.22921 5.17533 6.42177 5.2626L19.5383 11.0816C20.4128 11.4698 20.8996 12.5747 20.4084 13.0553L14.2047 14.8044L12.8227 20.4772C12.331 20.9583 11.2367 20.4462 10.8685 19.5642L5.3377 6.32327Z"
        fill="white"
      />
    </svg>
  )
}

export default function DirectionIcon(
  props: Partial<CustomIconComponentProps>
) {
  return <Icon component={DirectionSvg} {...props} />
}
