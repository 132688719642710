import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function HistoryClockSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_19494_150645)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.75617 12C1.75617 14.0291 2.35783 16.0127 3.48508 17.6998C4.61233 19.387 6.21455 20.7021 8.08914 21.4787C9.96374 22.2554 12.0265 22.4587 14.0167 22.0631C16.0069 21.6674 17.835 20.6906 19.27 19.256C20.705 17.8214 21.6824 15.9935 22.0787 14.0035C22.4749 12.0134 22.2721 9.95057 21.496 8.07575C20.7199 6.20092 19.4054 4.59832 17.7185 3.47058C16.0316 2.34284 14.0483 1.74059 12.0192 1.74C11.7884 1.74 11.5671 1.64834 11.404 1.48518C11.2408 1.32203 11.1492 1.10074 11.1492 0.87C11.1492 0.639262 11.2408 0.417974 11.404 0.254817C11.5671 0.0916604 11.7884 0 12.0192 0C14.7054 0.000790858 17.3137 0.902876 19.4265 2.56183C21.5393 4.22079 23.0343 6.54067 23.6723 9.15007C24.3103 11.7595 24.0543 14.5075 22.9453 16.9541C21.8363 19.4008 19.9384 21.4046 17.5555 22.6447C15.1727 23.8848 12.4426 24.2896 9.80246 23.7941C7.16228 23.2987 4.76469 21.9318 2.99353 19.9121C1.22236 17.8925 0.180067 15.337 0.0335066 12.6547C-0.113054 9.97246 0.644599 7.31859 2.18517 5.118L1.19067 4.2525C1.07188 4.14922 0.983336 4.01565 0.934474 3.86601C0.885613 3.71638 0.878263 3.5563 0.913208 3.40281C0.948153 3.24933 1.02408 3.10821 1.13291 2.99448C1.24173 2.88075 1.37937 2.79867 1.53117 2.757L5.31117 1.722C5.44924 1.68433 5.59447 1.6813 5.734 1.71319C5.87352 1.74507 6.00302 1.81088 6.11102 1.90479C6.21903 1.9987 6.30219 2.1178 6.35315 2.25154C6.40411 2.38529 6.42129 2.52953 6.40317 2.6715L5.90517 6.5595C5.88518 6.71575 5.82315 6.86365 5.72568 6.9874C5.62822 7.11116 5.49899 7.20613 5.35177 7.26219C5.20455 7.31824 5.04488 7.33327 4.88979 7.30567C4.7347 7.27808 4.59001 7.20889 4.47117 7.1055L3.50667 6.2655C2.36233 7.95878 1.75205 9.9563 1.75467 12H1.75617Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.75 5C11.9489 5 12.1397 5.07902 12.2803 5.21967C12.421 5.36032 12.5 5.55109 12.5 5.75V13.565L17.372 16.349C17.5397 16.4502 17.6612 16.6129 17.7104 16.8024C17.7597 16.992 17.7329 17.1933 17.6358 17.3633C17.5386 17.5333 17.3788 17.6586 17.1905 17.7124C17.0022 17.7661 16.8003 17.7441 16.628 17.651L11.378 14.651C11.2632 14.5854 11.1678 14.4907 11.1014 14.3764C11.035 14.262 11 14.1322 11 14V5.75C11 5.55109 11.079 5.36032 11.2197 5.21967C11.3603 5.07902 11.5511 5 11.75 5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_19494_150645">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default function HistoryClockIcon(
  props: Partial<CustomIconComponentProps>
) {
  return <Icon component={HistoryClockSvg} {...props} />
}
