import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import Api from 'services/api'
import { SWRConfig } from 'swr'

import './locales/i18n'

import App from './App'
import reportWebVitals from './reportWebVitals'

import './styles/antd.css'
import './styles/global.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <RecoilRoot>
    <BrowserRouter>
      <SWRConfig
        value={{
          fetcher: ({ url, params }) => Api.get(url, params),
          shouldRetryOnError: false,
          revalidateOnFocus: false,
          keepPreviousData: true
        }}
      >
        <App />
      </SWRConfig>
    </BrowserRouter>
  </RecoilRoot>
)

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
