import DetailBuilding from 'pages/building/buildingDetail/BuildingDetail'
import ViewerDetailBuilding from 'pages/building/buildingDetailViewMode/ViewerBuildingDetail'
import BuildingFileList from 'pages/building/buildingFileList'
import DetailFloor from 'pages/building/floor/FloorDetail'
import Building from 'pages/building/listBuilding/ListBuilding'
import ContactUs from 'pages/contact-us/ContactUs'
import Homepage from 'pages/homepage/HomePage'
import OrganizationList from 'pages/organization/listOrganization/ListOrganization'
import RegisterOrganization from 'pages/organization/organizationDetail/components/RegisterOrganization'
import DetailOrganization from 'pages/organization/organizationDetail/DetailOrganization'
import UserList from 'pages/user/listUser/ListUser'
import EmailExpired from 'pages/user/register/EmailExpired'
import UserRegister from 'pages/user/register/UserRegister'
import DetailUser from 'pages/user/userDetail/UserDetail'
import { RouteObject } from 'types'

import DefaultLayout from 'components/templates/DefaultLayout'
import DetailBuildingLayout from 'components/templates/DetailBuildingLayout'
import EmptyLayout from 'components/templates/EmptyLayout'
import MainLayout from 'components/templates/MainLayout'

const userRoutes: RouteObject[] = [
  {
    path: '',
    layout: MainLayout,
    children: [
      {
        name: 'homepage',
        path: '',
        index: true,
        element: <Homepage />,
        role: ['SA', 'BA', 'UA']
      },
      {
        name: 'userList',
        path: 'users',
        element: <UserList />,
        role: ['SA', 'BA', 'UA']
      },
      {
        name: 'buildings',
        path: 'buildings',
        element: <Building />,
        role: ['SA', 'BA', 'UA', 'USER']
      },
      {
        name: 'buildings',
        path: 'buildings/:id',
        element: <DetailBuilding />,
        layout: DetailBuildingLayout,
        role: ['SA', 'BA', 'UA', 'USER']
      },
      {
        name: 'floors',
        path: 'buildings/:id/floors/:floorId',
        element: <DetailFloor />,
        layout: DetailBuildingLayout,
        role: ['SA', 'BA', 'UA', 'USER']
      },
      {
        name: 'organizations',
        path: 'organizations',
        element: <OrganizationList />,
        role: ['SA', 'BA']
      },
      {
        name: 'organizations',
        path: 'organizations/register',
        element: <RegisterOrganization />,
        role: ['SA']
      },
      {
        name: 'organizations',
        path: 'organizations/:id',
        element: <DetailOrganization />,
        role: ['SA', 'BA', 'UA']
      },
      {
        name: 'users',
        path: 'users/:id',
        element: <DetailUser />,
        role: ['SA', 'BA', 'UA', 'USER']
      },
      {
        name: 'contactUs',
        path: 'contact-us',
        layout: DefaultLayout,
        element: <ContactUs />,
        role: ['SA', 'BA', 'UA', 'USER']
      }
    ]
  },
  {
    path: '/viewer',
    layout: MainLayout,
    children: [
      {
        name: 'buildings',
        path: 'buildings/:id',
        layout: EmptyLayout,
        element: <ViewerDetailBuilding />,
        role: ['SA', 'BA', 'UA', 'USER']
      },
      {
        name: 'buildings',
        path: 'buildings/:id/files',
        layout: EmptyLayout,
        element: <BuildingFileList />,
        role: ['SA', 'BA', 'UA', 'USER']
      }
    ]
  },
  {
    path: '',
    layout: MainLayout,
    guardOnLogged: true,
    children: [
      {
        name: 'users',
        path: 'users/register/:id/:token',
        element: <UserRegister />
      }
    ]
  },
  {
    path: '/invitation-expired',
    guardOnLogged: true,
    children: [
      {
        name: 'users',
        path: '',
        element: <EmailExpired />
      }
    ]
  }
]

export default userRoutes
