import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function MenuBuildingSvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0086 3.84744V18.9998H18.5093C18.7804 18.9998 19 18.7798 19 18.5082V5.94755C19 5.74929 18.8814 5.57071 18.6991 5.49399L13.6891 3.39319C13.3658 3.2574 13.0093 3.49572 13.0093 3.84676L13.0086 3.84744ZM17.204 16.5805H14.804C14.5729 16.5805 14.3858 16.3931 14.3858 16.1616C14.3858 15.93 14.5729 15.7426 14.804 15.7426H17.204C17.4351 15.7426 17.6221 15.93 17.6221 16.1616C17.6221 16.3931 17.4351 16.5805 17.204 16.5805ZM17.204 14.4105H14.804C14.5729 14.4105 14.3858 14.2231 14.3858 13.9915C14.3858 13.76 14.5729 13.5726 14.804 13.5726H17.204C17.4351 13.5726 17.6221 13.76 17.6221 13.9915C17.6221 14.2231 17.4351 14.4105 17.204 14.4105ZM17.204 12.2404H14.804C14.5729 12.2404 14.3858 12.053 14.3858 11.8215C14.3858 11.59 14.5729 11.4025 14.804 11.4025H17.204C17.4351 11.4025 17.6221 11.59 17.6221 11.8215C17.6221 12.053 17.4351 12.2404 17.204 12.2404ZM17.204 10.0711H14.804C14.5729 10.0711 14.3858 9.88365 14.3858 9.65212C14.3858 9.42058 14.5729 9.23318 14.804 9.23318H17.204C17.4351 9.23318 17.6221 9.42058 17.6221 9.65212C17.6221 9.88365 17.4351 10.0711 17.204 10.0711ZM17.204 7.901H14.804C14.5729 7.901 14.3858 7.7136 14.3858 7.48207C14.3858 7.25053 14.5729 7.06313 14.804 7.06313H17.204C17.4351 7.06313 17.6221 7.25053 17.6221 7.48207C17.6221 7.7136 17.4351 7.901 17.204 7.901Z"
        fill="white"
      />
      <path
        d="M1 3.77868V18.5087C1 18.7803 1.21959 19.0003 1.4907 19.0003H5.03878V15.7968C5.03878 15.6155 5.18518 15.4688 5.36614 15.4688H7.57294C7.7539 15.4688 7.90029 15.6155 7.90029 15.7968V19.0009H11.9391V1.49184C11.9391 1.17611 11.6463 0.942539 11.3393 1.01315L1.3809 3.29931C1.15792 3.35023 1 3.54918 1 3.778V3.77868ZM5.54778 13.7523C5.54778 13.9214 5.41087 14.0579 5.24279 14.0579H3.61277C3.44401 14.0579 3.3071 13.9207 3.3071 13.7517V12.2375C3.3071 12.0685 3.44401 11.9313 3.61277 11.9313H5.24279C5.41155 11.9313 5.54846 12.0685 5.54846 12.2375V13.7523H5.54778ZM5.54778 10.3703C5.54778 10.5394 5.41087 10.6758 5.24279 10.6758H3.61277C3.44401 10.6758 3.3071 10.5387 3.3071 10.3696V8.85548C3.3071 8.68641 3.44401 8.54925 3.61277 8.54925H5.24279C5.41155 8.54925 5.54846 8.68641 5.54846 8.85548V10.3703H5.54778ZM5.54778 6.98758C5.54778 7.15665 5.41087 7.2938 5.24211 7.2938H3.61209C3.44333 7.2938 3.30642 7.15665 3.30642 6.98758V5.47343C3.30642 5.30437 3.44333 5.16721 3.61209 5.16721H5.24211C5.41087 5.16721 5.54778 5.30437 5.54778 5.47343V6.98758ZM9.63198 13.7523C9.63198 13.9214 9.49507 14.0579 9.32698 14.0579H7.69697C7.5282 14.0579 7.3913 13.9207 7.3913 13.7517V12.2375C7.3913 12.0685 7.5282 11.9313 7.69697 11.9313H9.32698C9.49575 11.9313 9.63265 12.0685 9.63265 12.2375V13.7523H9.63198ZM9.63198 10.3696C9.63198 10.5387 9.49507 10.6758 9.32631 10.6758H7.69629C7.52753 10.6758 7.39062 10.5387 7.39062 10.3696V8.85548C7.39062 8.68641 7.52753 8.54925 7.69629 8.54925H9.32631C9.49507 8.54925 9.63198 8.68641 9.63198 8.85548V10.3696ZM9.63198 6.9869C9.63198 7.15597 9.49507 7.29312 9.32631 7.29312H7.69629C7.52753 7.29312 7.39062 7.15597 7.39062 6.9869V5.47275C7.39062 5.30369 7.52753 5.16653 7.69629 5.16653H9.32631C9.49507 5.16653 9.63198 5.30369 9.63198 5.47275V6.9869Z"
        fill="white"
      />
    </svg>
  )
}

export default function MenuBuildingIcon(
  props: Partial<CustomIconComponentProps>
) {
  return <Icon component={MenuBuildingSvg} {...props} />
}
