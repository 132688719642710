import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import useMutation from 'hooks/useMutation'

import Button from 'components/atoms/Button'
import Spinner from 'components/atoms/Spinner'
import Title from 'components/atoms/Title'

export default function AppDownload() {
  const { t } = useTranslation()
  const isDownloadingRef = useRef<boolean>(false)

  const { trigger } = useMutation('/v1/files/app')

  const shadow = 'shadow-[0_0_10px_0_#00000026]'
  const layout =
    'bg-white flex flex-col justify-between w-[476px] h-[332px] p-[60px] rounded-[20px] text-center'

  const handleDownloadApk = () => {
    if (!isDownloadingRef.current) {
      isDownloadingRef.current = true
      trigger(
        {
          method: 'get',
          data: null
        },
        {
          onSuccess: (data) => {
            const url = data
            const link = document.createElement('a')
            link.href = url
            link.download = 'digitori360-app.apk'
            link.click()
            isDownloadingRef.current = false
          },
          onError: () => {
            isDownloadingRef.current = false
          }
        }
      )
    }
  }

  return (
    <>
      {isDownloadingRef.current && <Spinner />}
      <div className="flex h-full bg-[#F9FBFF]">
        <div className={`m-auto ${layout} ${shadow}`}>
          <Title title={t('translation.androidAppDownloadTitle')} />
          <div className="mb-[30px] text-left leading-[26px] text-[#021120] whitespace-pre-line">
            {t('translation.androidAppDownloadGuide')}
          </div>
          <Button
            type="primary"
            block
            sizing="h-[60px]"
            onClick={handleDownloadApk}
          >
            {t('translation.androidAppDownload')}
          </Button>
        </div>
      </div>
    </>
  )
}
