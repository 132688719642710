import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button } from 'antd'

import Title from 'components/atoms/Title'

export default function TimeExpired() {
  const { t } = useTranslation()

  return (
    <>
      <Title title="resetPassword.timeExpiredTitle" />

      <p className=" text-lg my-5">{t('resetPassword.timeExpiredSubTitle1')}</p>
      <p className=" text-lg my-5">{t('resetPassword.timeExpiredSubTitle2')}</p>

      <Link
        to="/forgot_password"
        className="text-primary font-semibold flex justify-center underline"
      >
        <Button type="primary" block size="large" className="mb-6">
          {t('resetPassword.forgotPasswordButton')}
        </Button>
      </Link>
    </>
  )
}
