import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { selectedPoint } from 'store/buildingStore'
import { KrpanoInitProps } from 'types/krpano'
import Krpano from 'utils/krpano'

export default function usePreviewKrpano() {
  const currentSelectedPoint = useRecoilValue(selectedPoint)
  const krpanoOnreadyCallback = async (
    krpano: any,
    krpanoInitProps: KrpanoInitProps
  ) => {
    // get initial variables when embed pano
    const { h, v, z } = krpanoInitProps
    const varString = `layer[set_first_view_button].visible=true&view.hlookat=${h}&view.vlookat=${v}&view.fov=${z}`
    await krpano.call(
      `loadpano('/krpano/xml-assets/pano_preview.xml','${varString}',IGNOREKEEP,BLEND(1));`
    )
    krpano?.call(`set(initzoom,${krpanoInitProps.z});`)
  }

  const embedpano = (krpanoInitProps: KrpanoInitProps) => {
    const embedpano = document.getElementById('embedpano-preview') as any
    const target = document.getElementById('preview-krpano-container')
    const { imageUrl, previewUrl, h, v, z } = krpanoInitProps
    if (target) {
      if (!embedpano) {
        ;(window as any)?.embedpano({
          id: 'embedpano-preview',
          xml: null,
          target: 'preview-krpano-container',
          html5: 'auto+webgl',
          initvars: {
            url: imageUrl,
            previewurl: previewUrl
          },
          vars: {
            'contextmenu.versioninfo': false,
            'contextmenu.fullscreen': false
          },
          mwheel: false,
          bgcolor: '#FFFFFF',
          onready: (krpano: any) => {
            krpanoOnreadyCallback(krpano, krpanoInitProps)
          }
        })
      } else {
        embedpano.call(
          `load_pano_image(${imageUrl},${h},${v},${z},${previewUrl});`
        )
        embedpano?.call(`set(initzoom,${krpanoInitProps.z});`)
      }
    }
  }

  useEffect(() => {
    return () => {
      Krpano.UnmountPanoPreview()
    }
  }, [])

  return {
    embedpano,
    currentSelectedPoint
  }
}
