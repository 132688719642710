import React from 'react'
import { UploadStatus } from 'types/building'

import CheckedIcon from 'components/icons/CheckedIcon'
import LoadingIcon from 'components/icons/LoadingIcon'
import UploadIcon from 'components/icons/UploadIcon'

type Props = {
  status: UploadStatus
}

export default function UploadStatusIcon({ status }: Props) {
  if (status === 'loading') {
    return <LoadingIcon className="animate-spin" />
  }
  if (status === 'success') {
    return <CheckedIcon />
  }
  if (status === 'default') {
    return <div />
  }
  return <UploadIcon />
}
