import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormInstance } from 'antd'
import { FileMagicNumber } from 'types/building'
import Utils from 'utils'

import Upload from 'components/atoms/Upload'

type Props = {
  form: FormInstance
  isValidating: boolean
}

export default function ImageUpload({ form, isValidating }: Props) {
  const { t } = useTranslation()

  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const initialThumbnail = form.getFieldValue('s3Path')
  const thumbnail = selectedFile
    ? URL.createObjectURL(selectedFile)
    : initialThumbnail

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target

    const resetForm = () => {
      setSelectedFile(null)
      form.resetFields(['image'])
    }
    if (files?.length) {
      Utils.validateFile(files[0], t)
        .then(() => {
          Utils.getFileMagicNumber(files[0], FileMagicNumber.IMAGE)
            .then(() => {
              setSelectedFile(files[0])
              form.setFieldValue('image', files[0])
            })
            .catch(() => {
              form.setFields([
                {
                  name: 'imageWrapper',
                  errors: [t('translation.brokenFileMessage')]
                },
                { name: 'image', value: '' }
              ])
              setSelectedFile(null)
            })
        })
        .catch((error) => {
          form.setFields([
            {
              name: 'imageWrapper',
              errors: [error.message]
            },
            { name: 'image', value: '' }
          ])
        })
      return
    }

    resetForm()
  }

  return (
    <Form.Item
      name="imageWrapper"
      dependencies={['image']}
      hasFeedback
      className="thumbnail-upload bold-label !mb-12"
    >
      <div className="w-[340px] ml-auto flex justify-between items-center">
        <div className="w-[200px] aspect-video bg-[#CED0D4]">
          {!thumbnail && !isValidating ? (
            <img
              src="/assets/no-image.svg"
              alt="noImage"
              className="w-full h-full object-contain bg-[#CED0D4]"
            />
          ) : (
            <img
              className="w-full h-full object-contain bg-[#021120]"
              src={thumbnail}
              alt="thumbnail"
              loading="lazy"
            />
          )}
        </div>

        <Upload
          name="image"
          onChange={handleFileChange}
          accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG"
          value={selectedFile}
        />
      </div>
    </Form.Item>
  )
}
