import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function PreviewMapSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7183_40582)">
        <g clipPath="url(#clip1_7183_40582)">
          <path
            d="M23.991 17.0428L23.583 16.1455L19.3386 6.79688H13.8579H13.4199H6.80254L7.45312 7.64063L6.80254 8.27564H12.5491H14.7288H15.6985L10.8059 14.4112L4.11013 11.5891L5.61454 8.27564H6.80254L7.45312 7.64063L6.80254 6.79688H4.66092L0.00927488 17.0428C-0.00539699 17.0757 -0.00244387 17.1131 0.0165874 17.1431C0.0363686 17.1732 0.0693218 17.1915 0.105228 17.1915H23.895C23.9309 17.1915 23.9639 17.1731 23.9837 17.1431C24.0027 17.1131 24.0056 17.0757 23.991 17.0428ZM16.6448 8.27569H18.3858L20.2996 12.4908L15.1836 10.1075L16.6448 8.27569ZM2.23879 15.712L3.8047 12.263L11.9873 15.712H2.23879ZM13.8923 15.712L11.5142 14.7093L14.709 10.7029L20.769 13.5249L21.7615 15.7119L13.8923 15.712Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_7183_40582">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_7183_40582">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default function PreviewMapIcon(
  props: Partial<CustomIconComponentProps>
) {
  return <Icon component={PreviewMapSvg} {...props} />
}
