import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Input, Radio, Typography } from 'antd'
import TableColumn from 'constants/tableColumn'
import useMutation from 'hooks/useMutation'
import { CurrentUser } from 'models/User'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  selectedPoint,
  selectedPointDetail,
  setAnnotationModeState
} from 'store/buildingStore'
import { displayToggleState } from 'store/displayToggleStore'
import useSWR from 'swr'
import { SetAnnotation } from 'types/building'
import Krpano from 'utils/krpano'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'
import ExtraTable from 'components/organisms/ExtraTable'

export default function ModalListSetAnnotation() {
  const { id, floorId } = useParams()
  const { t } = useTranslation()

  const [searchKeyword, setSearchKeyword] = useState('')
  const [searchParams, setSearchParams] = useState({
    keyword: ''
  })

  const currentSelectedPoint = useRecoilValue(selectedPoint)
  const displayToggle = useRecoilValue(displayToggleState)
  const [{ selectedSetAnnotation }, setSelectedMode] = useRecoilState(
    setAnnotationModeState
  )
  const [, setPointDetail] = useRecoilState(selectedPointDetail)

  const { data: profile } = useSWR<CurrentUser>('v1/users/me', {
    revalidateOnMount: false
  })

  const { trigger: triggerDuplicate, isMutating: isDuplicating } = useMutation(
    `v1/buildings/${id}/set-annotations`
  )

  const { trigger: triggerAddAnnotation, isMutating: isAddingAnnotation } =
    useMutation(`v1/annotations`)

  const { data, isValidating, mutate } = useSWR<SetAnnotation[]>({
    url: `v1/buildings/${id}/set-annotations`,
    params: searchParams
  })

  const handleDuplicate = useCallback(
    (setAnnotationId?: number) => {
      if (!isDuplicating) {
        triggerDuplicate(
          {
            data: null,
            query: [setAnnotationId, 'duplicate']
          },
          {
            onSuccess: () => {
              mutate()
            }
          }
        )
      }
    },
    [isDuplicating, mutate, triggerDuplicate]
  )

  const listSetAnnotation = useMemo(
    () =>
      data?.map((item) => ({
        id: item?.id || '',
        name: item.name,
        checkbox: <Radio value={item.id} />,
        actions: (
          <div className="flex justify-end gap-[32px]">
            <Button
              type="primary"
              sizing="w-[100px] h-[31px]"
              onClick={(e) => {
                e.stopPropagation()
                handleDuplicate(item.id)
              }}
            >
              {t('translation.copy')}
            </Button>
            <Button
              type="primary"
              sizing="w-[100px] h-[31px]"
              onClick={(e) => {
                e.stopPropagation()
                setSelectedMode((prev) => ({
                  ...prev,
                  mode: 'addSetAnnotation',
                  selectedSetAnnotation: item.id
                }))
              }}
            >
              {t('translation.edit')}
            </Button>
          </div>
        )
      })) || [],
    [data, handleDuplicate, setSelectedMode, t]
  )

  const handleSearch = () => {
    if (!isValidating) {
      setSearchParams((prev) => ({
        ...prev,
        keyword: searchKeyword
      }))
    }
  }

  const handleChangeCheckbox = (value: any) => {
    setSelectedMode((prev) => ({
      ...prev,
      selectedSetAnnotation: value.id
    }))
  }

  const handleCloseModal = () => {
    setSelectedMode((prev) => ({
      ...prev,
      mode: undefined,
      selectedSetAnnotation: undefined
    }))
  }

  const handleSubmit = () => {
    if (!isAddingAnnotation && selectedSetAnnotation) {
      const krpano = document.getElementById('embedpano-full') as any
      const hlookat = Number(krpano.get('view.hlookat'))
      const vlookat = Number(krpano.get('view.vlookat'))
      triggerAddAnnotation(
        {
          method: 'put',
          data: {
            annotation: {
              set_annotation_id: selectedSetAnnotation,
              head_h: hlookat,
              head_v: vlookat,
              mid_h: hlookat + 5,
              mid_v: vlookat + 5,
              h: hlookat,
              v: vlookat + 10
            },
            building_id: Number(id),
            floor_id: Number(floorId),
            point_id: currentSelectedPoint
          }
        },
        {
          onSuccess: (data) => {
            setPointDetail(
              (prev) =>
                prev && {
                  ...prev,
                  annotations: [...(prev?.annotations || []), data.data]
                }
            )
            Krpano.AddAnnotation(krpano, [data.data])
            handleCloseModal()
          }
        }
      )
    }
  }

  return (
    <Modal width="840px" rootClassName="shared-permission-modal">
      <Title height={28.96} title={t('building.listSetAnnotation')} />

      <div className="mx-1 flex items-center gap-5 mb-[30px]">
        <Typography className="text-sm font-medium flex-shrink-0">
          {t('user.keyword')}
        </Typography>
        <Input
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          className="h-[38px]"
        />
        <Button type="primary" className="flex-shrink-0" onClick={handleSearch}>
          {t('user.buttonSearch')}
        </Button>
      </div>

      <Radio.Group
        className="w-full table-list-org-invite"
        value={selectedSetAnnotation}
      >
        <ExtraTable
          isDetail={false}
          scroll={{ y: 350 }}
          bordered={false}
          isLoading={isValidating}
          columns={TableColumn.COLUMN_SET_ANNOTATION}
          data={listSetAnnotation}
          actionSelect={handleChangeCheckbox}
        />
      </Radio.Group>

      <div className="flex justify-center gap-10 mt-[30px]">
        <Button sizing="w-[140px] h-[50px]" onClick={handleCloseModal}>
          {t('common.cancel')}
        </Button>
        <Button
          type="primary"
          sizing="w-[140px] h-[50px]"
          disabled={
            !selectedSetAnnotation ||
            !displayToggle.isLocked ||
            displayToggle.lockedBy !== profile?.id
          }
          loading={isAddingAnnotation}
          onClick={handleSubmit}
        >
          {t('building.reflectAnnotation')}
        </Button>
      </div>
    </Modal>
  )
}
