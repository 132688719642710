import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Select, Typography } from 'antd'
import TableColumn from 'constants/tableColumn'
import { OrderByType } from 'types'
import { InvitedBranchAdmin } from 'types/listOrganization'

import DropdownIconCustom from 'components/icons/DropdownIconCustom'
import ExtraTable from 'components/organisms/ExtraTable'

type Props = {
  isLoading: boolean
  sharedUser: InvitedBranchAdmin[]
  handleUnInvite: (value: number) => void
}

export default function ListBranchAdmin({
  isLoading,
  sharedUser,
  handleUnInvite
}: Props) {
  const { t } = useTranslation()

  const [queries, setQueries] = useState({
    sort: 'name',
    sortOrder: 'asc'
  })

  const sortedList = useMemo(
    () =>
      sharedUser.sort((a: any, b: any) => {
        const firstItem =
          queries.sort === 'name'
            ? `${a.lastName} ${a.firstName}`
            : a[queries.sort]
        const secondItem =
          queries.sort === 'name'
            ? `${b.lastName} ${b.firstName}`
            : b[queries.sort]
        return queries.sortOrder === 'asc'
          ? firstItem.localeCompare(secondItem)
          : secondItem.localeCompare(firstItem)
      }),
    [queries, sharedUser]
  )

  const sharedUserList = useMemo(
    () =>
      sortedList.map((user: any) => ({
        id: user.id,
        name: (
          <Typography className="text-sm text-[#021120] font-medium">
            {user.lastName}&nbsp;{user.firstName}
          </Typography>
        ),
        organizationName: (
          <Typography className="text-[14px] leading-7 text-[#021120]">
            {user?.organizationName}
          </Typography>
        ),
        email: (
          <Typography className="text-[14px] leading-7 text-[#021120]">
            {user.email}
          </Typography>
        ),
        action: (
          <Select
            defaultValue={-user.id}
            bordered={false}
            options={[
              { value: -user.id, label: t('user.BA') },
              { value: user.id, label: t('translation.delete') }
            ]}
            onChange={handleUnInvite}
            suffixIcon={
              <DropdownIconCustom colorIcon="#000" width={13} height={7} />
            }
            className="w-full"
          />
        )
      })),
    [handleUnInvite, sortedList, t]
  )

  const tableColumns = useMemo(
    () =>
      TableColumn.COLUMN_USER_SHARED.map((column) =>
        column.name === 'organizationName' || column.name === 'action'
          ? { ...column, hasSorter: false }
          : column
      ),
    []
  )

  const onTableChange = useCallback((sorter: string, field: string) => {
    setQueries((prev) => ({
      ...prev,
      sort: sorter ? field : 'name',
      sortOrder: sorter
        ? OrderByType[sorter as keyof typeof OrderByType]
        : OrderByType.ascend
    }))
  }, [])

  return (
    <ExtraTable
      isLoading={isLoading}
      columns={tableColumns}
      data={sharedUserList}
      scroll={{ y: 300 }}
      className="mt-[14px]"
      size="small"
      bordered={false}
      isDetail={false}
      onChange={onTableChange}
    />
  )
}
