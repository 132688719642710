import { useTranslation } from 'react-i18next'
import { Form, Input, Select, Typography } from 'antd'
import { SearchUser } from 'types/listUser'
import Utils from 'utils'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'
import DropdownIconCustom from 'components/icons/DropdownIconCustom'

type Props = {
  handleSearch: (value: SearchUser) => void
  listRole: string[]
}
export default function FormSearch({ handleSearch, listRole }: Props) {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const onSearch = (values: any) => {
    handleSearch(
      Utils.trimObjectValues({
        ...values,
        domain: values.domain
      })
    )
    form.setFieldsValue(Utils.trimObjectValues(values))
  }

  return (
    <Form
      layout="horizontal"
      className="font-semibold w-full"
      onFinish={onSearch}
      colon={false}
      form={form}
    >
      <div className="flex gap-[30px] w-full">
        <Form.Item
          label={
            <Typography className="text-sm">{t('user.keyword')}</Typography>
          }
          name="keyword"
          className="flex mb-0 item-center label-custom-select thin-placeholder custom-error-message"
          rules={[{ validator: Validators.keywordValidator(t) }]}
        >
          <Input className="min-w-[180px] h-[38px] text-sm" />
        </Form.Item>

        <Form.Item
          label={
            <Typography className="text-sm">{t('user.domain')}</Typography>
          }
          name="domain"
          className="flex items-center mb-0 label-custom-select thin-placeholder custom-error-message"
          rules={[{ validator: Validators.domainValidator(t) }]}
        >
          <Input className="min-w-[180px] h-[38px] text-sm" />
        </Form.Item>

        <Form.Item
          className="flex items-center mb-0 label-custom-select thin-placeholder"
          label={<Typography className="text-sm">{t('user.role')}</Typography>}
          name="role"
        >
          <Select
            className="min-w-[180px] h-[38px]"
            placeholder={t('user.all')}
            suffixIcon={<DropdownIconCustom colorIcon="#021120" />}
          >
            <Select.Option value="">
              <span className="text-sm">{t('user.all')}</span>
            </Select.Option>
            {listRole.map((role) => {
              return (
                <Select.Option key={role} value={role}>
                  <span className="text-sm">{t(`user.${role}`)}</span>
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>

        <div className="ml-20">
          <Button type="primary" sizing="w-[160px] h-[40px]" htmlType="submit">
            {t('user.buttonSearch')}
          </Button>
        </div>
      </div>
    </Form>
  )
}
