import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormItemProps } from 'antd'

type Props = FormItemProps & {
  label?: string
  className: string
  required?: boolean
  line?: boolean
}

export default function FormItem(props: PropsWithChildren<Props>) {
  const { t } = useTranslation()
  const { label, required, line, children, className, ...remainProps } = props
  return (
    <>
      <Form.Item
        className={className}
        {...remainProps}
        label={
          <div className="flex items-center gap-3">
            {label && <div>{label}</div>}
            {required && (
              <div className="flex">
                <div className="text-[#B80808] text-xs">※</div>
                <div className="text-[#B80808] text-xs">
                  {t('common.form.required')}
                </div>
              </div>
            )}
          </div>
        }
      >
        {children}
      </Form.Item>
      {line && <hr className="border-[0.5px] -mt-4 border-slate-50" />}
    </>
  )
}

FormItem.defaultProps = {
  label: null,
  required: false,
  children: null,
  line: true
}
