import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { UploadFile } from 'antd'
import axios from 'axios'
import dayjs from 'dayjs'
import { useRecoilState } from 'recoil'
import {
  buildingFilesState,
  revalidateFileListState,
  uploadingFiles
} from 'store/buildingStore'
import { SelectedFiles, UploadingFiles, UploadStatus } from 'types/building'
import Utils from 'utils'

import useMutation from './useMutation'

type Props = {
  buildingId: number
  category: number
}

export default function useUpload({ buildingId, category }: Props) {
  const { t } = useTranslation()
  const [selectedFiles, setSelectedFiles] = useRecoilState(uploadingFiles)
  const [, setBuildingFiles] = useRecoilState(buildingFilesState)
  const [, setRevalidateFileList] = useRecoilState(revalidateFileListState)
  const navigate = useNavigate()
  const { trigger: getPresignedUrl } = useMutation(
    'v1/buildings/presigned-urls'
  )

  const { trigger: saveFile } = useMutation('v1/buildings/files')

  const { trigger: deleteFile, isMutating } = useMutation('v1/buildings/files')

  const convertedFile = (uploadedFile: UploadFile<any>, path: any) => {
    return {
      extension: uploadedFile.name.split('.').pop(),
      name: uploadedFile.name,
      mimeType: uploadedFile.type,
      size: uploadedFile.size,
      s3Path: path,
      takenAt: dayjs(uploadedFile.lastModified).format('YYYY-MM-DDTHH:mm:ssZ')
    }
  }

  const changeStatusState = (
    status: UploadStatus,
    uploadFile: SelectedFiles,
    index?: number,
    dataId?: number
  ) => {
    if (index || index === 0) {
      setSelectedFiles((prev) =>
        prev.map((item) =>
          item.id === uploadFile.id
            ? {
                ...item,
                files: item.files.map((subitem) =>
                  subitem.file.uid === uploadFile.files[index].file.uid
                    ? {
                        ...subitem,
                        uploadStatus: status,
                        id: dataId || 0
                      }
                    : subitem
                )
              }
            : item
        )
      )
    } else {
      setSelectedFiles((prev) =>
        prev.map((item) =>
          item.id === uploadFile.id
            ? {
                ...item,
                files: item.files.map((subitem) => ({
                  ...subitem,
                  uploadStatus: status
                }))
              }
            : item
        )
      )
    }
  }

  const uploadFile = async () => {
    const newUploadFiles = selectedFiles[selectedFiles.length - 1]
    newUploadFiles &&
      newUploadFiles.files.length &&
      (await getPresignedUrl(
        {
          data: {
            buildingId,
            count: newUploadFiles.files.length,
            queueId: newUploadFiles.id,
            category
          }
        },
        {
          onSuccess: async (data) => {
            const paths = data.data.s3PreSignLinks

            /* eslint-disable no-await-in-loop */
            for (let i = 0; i < paths.length; i += 1) {
              await axios
                .put(paths[i].presignedUrl, newUploadFiles.files[i].file, {
                  signal: newUploadFiles.files[i].abortSignal?.signal
                })
                .then(async () => {
                  const files = convertedFile(
                    newUploadFiles.files[i].file,
                    paths[i].key
                  )

                  await saveFile({
                    data: {
                      buildingId,
                      category,
                      ...files
                    }
                  })
                    .then((data) => {
                      changeStatusState(
                        UploadStatus.SUCCESS,
                        newUploadFiles,
                        i,
                        data.data.id
                      )
                    })
                    .catch(() => {
                      changeStatusState(UploadStatus.FAIL, newUploadFiles, i)
                    })
                })
                .catch(() => {
                  changeStatusState(UploadStatus.FAIL, newUploadFiles, i)
                })
              if (i === paths.length - 1) {
                setRevalidateFileList(category)
              }
            }
          },
          onError: (error) => {
            changeStatusState(UploadStatus.FAIL, newUploadFiles)
            Utils.handleErrorNavigate(error, navigate)
          }
        }
      ))
  }

  const reUpload = (uploadId: number, file: any) => {
    const newUploadFiles = selectedFiles.find((item) => item.id === uploadId)

    if (newUploadFiles) {
      const index = newUploadFiles.files.findIndex(
        (item) => item.file.uid === file.uid
      )

      changeStatusState(UploadStatus.LOADING, newUploadFiles, index)

      getPresignedUrl(
        {
          data: {
            buildingId,
            count: 1,
            queueId: 1,
            category
          }
        },
        {
          onSuccess: async (data) => {
            const paths = data.data.s3PreSignLinks
            await axios
              .put(paths[0].presignedUrl, file, {
                signal: newUploadFiles.files.find(
                  (item) => item.file.uid === file.uid
                )?.abortSignal?.signal
              })
              .then(async () => {
                const files = convertedFile(file, paths[0].key)

                await saveFile({
                  data: {
                    buildingId,
                    category,
                    ...files
                  }
                })
                  .then((data) => {
                    changeStatusState(
                      UploadStatus.SUCCESS,
                      newUploadFiles,
                      index,
                      data.data.id
                    )
                    setRevalidateFileList(category)
                  })
                  .catch(() => {
                    changeStatusState(UploadStatus.FAIL, newUploadFiles, index)
                  })
              })
              .catch(() => {
                changeStatusState(UploadStatus.FAIL, newUploadFiles, index)
              })
          },
          onError: (error) => {
            changeStatusState(UploadStatus.FAIL, newUploadFiles, index)
            Utils.handleErrorNavigate(error, navigate)
          }
        }
      )
    }
  }

  const cancelUpload = (selectedId: number, file: UploadingFiles) => () => {
    if (file.uploadStatus === UploadStatus.LOADING) {
      if (file.abortSignal) {
        file.abortSignal.abort()

        setSelectedFiles((prev) =>
          prev
            .map((item) =>
              item.id === selectedId
                ? {
                    ...item,
                    files: item.files.filter(
                      (subitem) => subitem.file.uid !== file.file.uid
                    )
                  }
                : item
            )
            .filter((item) => item.files.length)
        )
      }
    }

    if (file.uploadStatus === UploadStatus.SUCCESS) {
      if (file.id && !isMutating) {
        deleteFile(
          {
            method: 'delete',
            data: null,
            query: [file.id],
            successMessage: t('organization.successDeletedNotification')
          },
          {
            onSuccess: () => {
              setBuildingFiles((prev) =>
                prev.filter((item) => item.id !== file.id)
              )
              setSelectedFiles((prev) =>
                prev
                  .map((item) =>
                    item.id === selectedId
                      ? {
                          ...item,
                          files: item.files.filter(
                            (subitem) => subitem.id !== file.id
                          )
                        }
                      : item
                  )
                  .filter((item) => item.files.length)
              )
            },
            onError: (error) => {
              setRevalidateFileList(category)
              Utils.handleErrorNavigate(error, navigate)
            }
          }
        )
      }
    }

    if (file.uploadStatus === UploadStatus.FAIL) {
      setSelectedFiles((prev) =>
        prev
          .map((item) =>
            item.id === selectedId
              ? {
                  ...item,
                  files: item.files.filter(
                    (subitem) => subitem.file.uid !== file.file.uid
                  )
                }
              : item
          )
          .filter((item) => item.files.length)
      )
    }
  }

  const removeSuccessFile = () => {
    setSelectedFiles((prev) =>
      prev
        .filter((item) =>
          item.files.find((file) => file.uploadStatus !== UploadStatus.SUCCESS)
        )
        .map((item) => ({
          ...item,
          files: item.files.filter(
            (file) => file.uploadStatus !== UploadStatus.SUCCESS
          )
        }))
    )
  }

  return { uploadFile, reUpload, cancelUpload, removeSuccessFile }
}
