import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function CustomChecked() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10.5" cy="10" r="10" fill="#3163B7" />
      <circle
        cx="10.5004"
        cy="10.0004"
        r="6.77778"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  )
}

export default function CustomCheckedIcon(
  props: Partial<CustomIconComponentProps>
) {
  return <Icon component={CustomChecked} {...props} />
}
