import { useMemo, useState } from 'react'
import { Input, Typography } from 'antd'
import PreviewFile from 'pages/building/panoramas/components/PreviewFile'
import { useRecoilState } from 'recoil'
import { currentAnnotation } from 'store/krpanoStore'
import { AnnotationType } from 'types/building'

import Modal from 'components/atoms/Modal'
import Spinner from 'components/atoms/Spinner'
import CloseIcon from 'components/icons/CloseIcon'

import AnnotationHeader from './AnnotationHeader'
import AnnotationMenu from './AnnotationMenu'
import ViewerFilePreview from './ViewerFilePreview'

export default function AnnotationModal() {
  const [selectedMenu, setSelectedMenu] = useState(0)
  const [previewFileIndex, setPreviewFileIndex] = useState<number>()
  const [currentSelected, setCurrentSelected] =
    useRecoilState(currentAnnotation)

  const annotationType = currentSelected?.type || 0

  const sortedFiles = useMemo(
    () =>
      [...(currentSelected?.files || [])].sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
    [currentSelected?.files]
  )

  const fileList = useMemo(
    () => [
      {
        category: 1,
        files: sortedFiles.filter(
          (item) => item.category === annotationType + 1
        )
      },
      {
        category: 2,
        files: sortedFiles.filter(
          (item) => item.category === annotationType + 2
        )
      },
      {
        category: 3,
        files: sortedFiles.filter(
          (item) => item.category === annotationType + 3
        )
      },
      {
        category: 5,
        files: []
      }
    ],
    [annotationType, sortedFiles]
  )

  const displayList = useMemo(
    () =>
      fileList.filter(
        (item) =>
          (item.files.length && item.category !== 5) || item.category === 5
      ),
    [fileList]
  )

  const itemsMenu = useMemo(() => {
    return [
      { value: 1, labels: ['取扱説明書'], length: fileList[0].files.length },
      {
        value: 2,
        labels: ['製作図', '機器完成図'],
        length: fileList[1].files.length
      },
      { value: 3, labels: ['申送り'], length: fileList[2].files.length },
      { value: 5, labels: ['リンク'], length: currentSelected?.link ? 1 : 0 }
    ]
  }, [currentSelected?.link, fileList])

  const displayMenu = useMemo(
    () => itemsMenu.filter((item) => item.length),
    [itemsMenu]
  )

  const handleSelectMenu = (value: number) => () => {
    selectedMenu !== value && setSelectedMenu(value)
  }

  const handleCloseModal = () => {
    setCurrentSelected(undefined)
  }

  const handleLink = (link: string): string => {
    if (link.startsWith('http')) return link
    return `https://${link}`
  }

  return (
    <>
      <Modal
        width="840px"
        open={previewFileIndex === undefined}
        rootClassName="annotation-detail-modal"
      >
        <div
          className="absolute top-5 right-5 cursor-pointer"
          onClick={handleCloseModal}
        >
          <CloseIcon />
        </div>
        <AnnotationHeader type={currentSelected?.type} />
        <div className="mt-6">
          {currentSelected?.type !== AnnotationType.MEMO ? (
            <>
              <AnnotationMenu
                items={displayMenu}
                selectedMenu={selectedMenu}
                handleSelect={handleSelectMenu}
              />
              <div className="mt-[30px] mx-5 h-[320px] overflow-auto">
                {displayList[selectedMenu].category !== 5 ? (
                  <div className="grid gap-5">
                    {displayList[selectedMenu].files?.map((item, index) => (
                      <div
                        className="grid grid-cols-[auto_1fr] gap-[10px] items-center cursor-pointer"
                        key={item.id}
                        onClick={() => setPreviewFileIndex(index)}
                      >
                        <PreviewFile file={item} size="large" />
                        <Typography className="text-[#021120] text-sm">
                          {item.name}
                        </Typography>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="pt-10 text-center">
                    {currentSelected?.link ? (
                      <a
                        href={handleLink(currentSelected?.link)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="text-[#3163B7] text-sm underline">
                          {currentSelected?.name || currentSelected?.link}
                        </span>
                      </a>
                    ) : (
                      <span className="text-[#3163B7] text-sm underline cursor-pointer">
                        {currentSelected?.name}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="mt-[30px] mx-5 h-[392px]">
              <Input.TextArea
                value={currentSelected.memo}
                autoSize={{
                  minRows: 15,
                  maxRows: 15
                }}
                className="text-sm text-[#021120]"
                readOnly
              />
            </div>
          )}
          {currentSelected?.isMutating && <Spinner />}
        </div>
      </Modal>
      {previewFileIndex !== undefined && (
        <ViewerFilePreview
          fileIndex={previewFileIndex}
          file={displayList[selectedMenu].files[previewFileIndex]}
          maxLength={displayList[selectedMenu].files.length}
          setPreviewFileIndex={setPreviewFileIndex}
        />
      )}
    </>
  )
}
