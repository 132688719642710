import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Col, Form, Input, Row, Select, Typography } from 'antd'
import Const from 'constants/constant'
import dayjs from 'dayjs'
import useAuth from 'hooks/useAuth'
import { CurrentUser, Role } from 'models/User'
import { UserStatus } from 'types/userDetail'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'
import DropdownIconCustom from 'components/icons/DropdownIconCustom'

import DeleteUserButton from './ButtonDeleteUser'

type Props = {
  form: any
  user?: CurrentUser
  handleOpenDeleteModal: () => void
  handleOpenOrganizationModal: () => void
  handleSubmit: (values: any) => void
}

export default function UserDetailForm({
  form,
  user,
  handleOpenDeleteModal,
  handleOpenOrganizationModal,
  handleSubmit
}: Props) {
  const { t } = useTranslation()
  const role = Form.useWatch('role', form)
  const { profile } = useAuth()
  const selfViewing = profile?.id === user?.id
  const disabled = profile?.role === Role.UA && user?.role === Role.UA
  const rolesList = useMemo(() => {
    switch (user?.role) {
      case Role.SA:
        return Const.SA_ROLES_LIST.map((item) => ({
          label: t(item.label),
          value: item.value
        }))
      case Role.BA:
        return Const.BA_ROLES_LIST.map((item) => ({
          label: t(item.label),
          value: item.value
        }))
      case Role.UA:
        return Const.UA_ROLES_LIST.map((item) => ({
          label: t(item.label),
          value: item.value
        }))
      default:
        return Const.USER_ROLES_LIST.map((item) => ({
          label: t(item.label),
          value: item.value
        }))
    }
  }, [user?.role, t])

  const styleRow =
    'min-h-[3.75rem] py-[7px] px-5 border-0 border-solid border-b border-[#EEEFF2] flex items-center text-[#021120]'

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      preserve={false}
      initialValues={user}
    >
      <div className="bg-white">
        <Row className="py-3 px-5 bg-[#3163B7] text-white font-bold">
          <Col span={4}>{t('user.item')}</Col>
          <Col span={18}>{t('user.content')}</Col>
        </Row>

        <Row className={styleRow}>
          <Col span={4} className="font-medium">
            {t('user.digitoriID')}
          </Col>
          <Col span={18}>{user?.id}</Col>
        </Row>

        <Row className={styleRow}>
          <Col span={4} className="font-medium">
            <span>{t('user.name')}</span>
            <span className="text-[#B80808] text-xs font-medium pl-2">
              {t('user.isRequired')}
            </span>
          </Col>
          <Col span={18}>
            <Row className="flex gap-8">
              <Form.Item
                name="lastName"
                className="mb-0"
                rules={[
                  {
                    validator: Validators.nameValidator(t)
                  }
                ]}
              >
                <Input
                  className="w-[19rem] h-11"
                  placeholder={t('user.lastName')}
                />
              </Form.Item>
              <Form.Item
                name="firstName"
                className="mb-0"
                rules={[
                  {
                    validator: Validators.nameValidator(t)
                  }
                ]}
              >
                <Input
                  className="w-[19rem] h-11"
                  placeholder={t('user.firstName')}
                />
              </Form.Item>
            </Row>
          </Col>
        </Row>

        <Row className={styleRow}>
          <Col span={4} className="font-medium">
            <span>{t('user.kanaName')}&#xa0;</span>
            <span className="text-[#B80808] text-xs font-medium pl-1">
              {t('user.isRequired')}
            </span>
          </Col>
          <Col span={18}>
            <Row className="flex gap-8">
              <Form.Item
                name="lastNameRuby"
                className="mb-0"
                rules={[
                  {
                    validator: Validators.kanaNameValidator(t)
                  }
                ]}
              >
                <Input
                  className="w-[19rem] h-11"
                  placeholder={t('user.kanaLastName')}
                />
              </Form.Item>
              <Form.Item
                name="firstNameRuby"
                className="mb-0"
                rules={[
                  {
                    validator: Validators.kanaNameValidator(t)
                  }
                ]}
              >
                <Input
                  className="w-[19rem] h-11"
                  placeholder={t('user.kanaFirstName')}
                />
              </Form.Item>
            </Row>
          </Col>
        </Row>

        <Row className={styleRow}>
          <Col span={4} className="font-medium">
            {t('user.email')}
          </Col>
          <Col span={18} className="max-w-[40rem] break-words">
            {user?.status === UserStatus.CONFIRMED ? (
              user.email
            ) : (
              <Form.Item
                name="email"
                className="mb-0"
                rules={[
                  {
                    validator: Validators.emailValidatorDomain(
                      t,
                      user?.organization?.emailDomain
                    )
                  }
                ]}
              >
                <Input
                  className="w-[19rem] h-11"
                  placeholder={t('user.email')}
                />
              </Form.Item>
            )}
          </Col>
        </Row>

        <Row className={styleRow}>
          <Col span={4} className="font-medium">
            {t('user.phoneNumber')}
          </Col>
          <Col span={18}>
            <Form.Item
              name="phone"
              className="mb-0"
              rules={[
                {
                  validator: Validators.phoneNumberValidatorNoRequired(t)
                }
              ]}
            >
              <Input
                className="w-[19rem] h-11"
                placeholder={t('user.phonePlaceholder')}
              />
            </Form.Item>
          </Col>
        </Row>

        {user?.organization?.id && (
          <>
            <Row className={styleRow}>
              <Col span={4} className="font-medium">
                {t('user.companyName')}
              </Col>
              <Col span={18}>{user?.organization?.name}</Col>
            </Row>

            {user.organization.id === 1 && (
              <Row className={styleRow}>
                <Col span={4} className="font-medium">
                  {t('user.employeeID')}
                </Col>
                <Col span={18}>{user?.employeeId}</Col>
              </Row>
            )}
          </>
        )}

        {!user?.organization?.id && (
          <Row className={styleRow}>
            <Col span={4}>
              <span className="font-medium">{t('user.companyName')}&#xa0;</span>
              <span className="text-[#B80808] text-xs font-medium pl-1">
                {t('user.isRequired')}
              </span>
            </Col>
            <Col span={18}>
              <Form.Item
                name="companyName"
                className="mb-0"
                rules={[{ validator: Validators.companyNameValidator(t) }]}
              >
                <Input
                  className="w-[19rem] h-11"
                  placeholder={t('user.companyName')}
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        {user?.organization?.id && (
          <Row className={styleRow}>
            <Col span={4} className="font-medium">
              {t('user.role')}
            </Col>
            <Col span={18}>
              <Row className="flex gap-8">
                <Form.Item name="role" className="mb-0">
                  {rolesList.length === 1 ? (
                    <Typography className="text-[#021120]">
                      {rolesList[0].label}
                    </Typography>
                  ) : (
                    <Select
                      disabled={selfViewing}
                      options={rolesList}
                      placeholder={t('user.role')}
                      style={{
                        width: '19rem',
                        height: '2.75rem'
                      }}
                      size="middle"
                      suffixIcon={<DropdownIconCustom colorIcon="#021120" />}
                    />
                  )}
                </Form.Item>

                {role === Role.BA &&
                  user.organization.id === 1 &&
                  !selfViewing && (
                    <Button
                      type="primary"
                      sizing="h-10"
                      onClick={handleOpenOrganizationModal}
                    >
                      {t('user.organizationManagement')}
                    </Button>
                  )}
              </Row>
            </Col>
          </Row>
        )}

        <Row className={styleRow}>
          <Col span={4} className="font-medium">
            {t('user.createdAt')}
          </Col>
          <Col span={18}>
            {user?.createdAt &&
              user.status !== UserStatus.INVITED &&
              dayjs(user?.createdAt).format('YYYY/MM/DD HH:mm')}
          </Col>
        </Row>

        <Row className={styleRow}>
          <Col span={4} className="font-medium">
            {t('user.latestLoginAt')}
          </Col>
          <Col span={18}>
            {user &&
              user?.latestLoginAt &&
              user?.latestLoginAt !== '0001-01-01T00:00:00Z' &&
              dayjs(user?.latestLoginAt).format('YYYY/MM/DD HH:mm')}
          </Col>
        </Row>
      </div>

      <div className="mt-10 mb-5 w-full flex justify-between">
        {disabled ? (
          <div />
        ) : (
          <DeleteUserButton
            userId={user?.id}
            organizationId={user?.organization?.id}
            handleOpenDeleteModal={handleOpenDeleteModal}
          />
        )}

        <div className="flex gap-20 pb-10">
          <Link to={profile?.role === Role.USER ? '/' : '/users'}>
            <Button sizing="w-44 h-14">{t('user.cancel')}</Button>
          </Link>

          <Button
            sizing="w-44 h-14"
            type="primary"
            htmlType="submit"
            disabled={disabled && profile.id !== user.id}
          >
            {t('user.save')}
          </Button>
        </div>
      </div>
    </Form>
  )
}

UserDetailForm.defaultProps = {
  user: undefined
}
