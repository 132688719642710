import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { notification } from 'antd'
import Api from 'services/api'
import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation'
import { MutateArg } from 'types'
import Utils from 'utils'

export default function useMutation(
  url: string,
  options?: Partial<SWRMutationConfiguration<any, any, string>>
) {
  const { t } = useTranslation()

  const fetcher = useCallback(
    async (url: string, { arg }: MutateArg) => {
      const isFormData = arg.data instanceof FormData
      const data = arg.data ? Utils.trimObjectValues(arg.data) : arg.data
      const queries = arg.query
        ? arg.query?.map((item) => `/${item}`).join('')
        : ''
      const params =
        arg && arg.params
          ? `?${Object.keys(arg.params)
              .map((key) => `${key}=${arg.params[key]}`)
              .join('&')}`
          : ''

      try {
        const method = arg.method || 'post'
        const convertedUrl = `${url}${queries}${params}`

        const response = await Api[method](
          convertedUrl,
          isFormData ? arg.data : data,
          arg.headers
        )

        arg.successMessage &&
          notification.success({
            message: t(arg.successMessage),
            key: t(arg.successMessage)
          })

        return response
      } catch (error: any) {
        Utils.handleError(error, t, arg?.form, arg?.errorMessage)
        throw error
      }
    },
    [t]
  )

  const { trigger, isMutating, data, error } = useSWRMutation(url, fetcher, {
    throwOnError: false,
    ...options
  })

  return {
    trigger,
    isMutating,
    data,
    error
  }
}
