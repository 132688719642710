const ErrorCodes = class ErrorCodes {
  static get API_ERROR_CODES() {
    return {}
  }

  static get VALIDATION_ERROR_CODES() {
    return {
      // forgot-password
      A11002: ['email'], // Email not exist,
      username_exists: ['email'],
      code_is_unique: ['code'],
      email_domain_unique: ['emailDomain'],
      email_not_exists: ['email']
    }
  }
}

export default ErrorCodes
