import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Menu, Typography } from 'antd'
import Const from 'constants/constant'
import dayjs from 'dayjs'
import { Building } from 'models/Building'
import { CurrentUser } from 'models/User'
import DropdownMenu from 'pages/building/buildingDetail/components/MenuDropdown'
import { BuildingLeftMenu } from 'types/building'

import Spinner from 'components/atoms/Spinner'
import LeftArrowIcon from 'components/icons/LeftArrowIcon'
import RotatedArrowIcon from 'components/icons/RotatedArrowIcon'

type Props = {
  handleBack: () => void
  building?: Building
  profile: CurrentUser | null | undefined
  handleSelectOption: (option: BuildingLeftMenu) => void
}

const expandIcon = ({ isOpen }: any) => {
  return <RotatedArrowIcon className={isOpen ? '' : '-rotate-90'} />
}

export default function InformationMenu({
  handleBack,
  building,
  profile,
  handleSelectOption
}: Props) {
  const { t } = useTranslation()
  const [isImageLoading, setIsImageLoading] = useState(false)
  useEffect(() => {
    if (building?.s3Path || building?.thumbnail) {
      setIsImageLoading(true)
    }
  }, [building?.s3Path, building?.thumbnail])

  const itemsInformationMenu = [
    {
      key: 'information',
      label: (
        <div className="flex">
          <Typography.Text className="text-white pl-[12px] text-[13px] font-[400]">
            {t('translation.buildingInformation')}
          </Typography.Text>
        </div>
      ),
      children: [
        {
          key: '1',
          label: (
            <div className="flex flex-col gap-[12px] cursor-default">
              <div className="flex flex-col gap-[4px]">
                <Typography.Text className="text-white text-[12px] font-[400]">
                  {t('building.buildingNameForm')}
                </Typography.Text>
                <Typography.Text
                  ellipsis={{
                    tooltip: building?.name
                  }}
                  className="text-white text-[12px] font-[400]"
                >
                  {building?.name}
                </Typography.Text>
              </div>
              <div className="flex flex-col gap-[4px]">
                <Typography.Text className="text-white text-[12px] font-[400]">
                  {t('building.code')}
                </Typography.Text>
                <Typography.Text
                  ellipsis={{
                    tooltip: building?.code
                  }}
                  className="text-white text-[12px] font-[400]"
                >
                  {building?.code}
                </Typography.Text>
              </div>
              <div className="flex flex-col gap-[4px]">
                <Typography.Text className="text-white text-[12px] font-[400]">
                  {t('building.constructionName')}
                </Typography.Text>
                <Typography.Text
                  ellipsis={{
                    tooltip: building?.aliasName
                  }}
                  className="text-white text-[12px] font-[400]"
                >
                  {building?.aliasName}
                </Typography.Text>
              </div>
              <div className="flex flex-col gap-[4px]">
                <Typography.Text className="text-white text-[12px] font-[400]">
                  {t('building.branchNameInfo')}
                </Typography.Text>
                <Typography.Text
                  ellipsis={{
                    tooltip: building?.branch?.name
                  }}
                  className="text-white text-[12px] font-[400]"
                >
                  {building?.branch?.name}
                </Typography.Text>
              </div>
              <div className="flex flex-col gap-[4px]">
                <Typography.Text className="text-white text-[12px] font-[400]">
                  {t('building.serviceName')}
                </Typography.Text>
                <Typography.Text
                  ellipsis={{
                    tooltip: building?.branch?.serviceStoreName
                  }}
                  className="text-white text-[12px] font-[400]"
                >
                  {building?.branch?.serviceStoreName}
                </Typography.Text>
              </div>
              <div className="flex flex-col gap-[4px]">
                <Typography.Text className="text-white text-[12px] font-[400]">
                  {t('building.address')}
                </Typography.Text>
                <Typography.Text
                  className="text-white text-[12px] font-[400]"
                  ellipsis={{
                    tooltip: (
                      <>
                        {building?.zipcode}&nbsp;
                        {building?.prefCode &&
                          Const.PREFECTURES.find(
                            (item) => item.prefCode === building.prefCode
                          )?.prefName}
                        &nbsp;{building?.cityName}&nbsp;
                        {building?.streetName}&nbsp;{building?.buildingName}
                      </>
                    )
                  }}
                >
                  {building?.zipcode}&nbsp;
                  {building?.prefCode &&
                    Const.PREFECTURES.find(
                      (item) => item.prefCode === building.prefCode
                    )?.prefName}
                  &nbsp;{building?.cityName}&nbsp;
                  {building?.streetName}&nbsp;{building?.buildingName}
                </Typography.Text>
              </div>
              <div className="flex flex-col gap-[4px]">
                <Typography.Text className="text-white text-[12px] font-[400] ">
                  {t('building.createdDate')}
                </Typography.Text>
                <Typography.Text className="text-white text-[12px] font-[400] ">
                  {building?.createdAt &&
                    dayjs(building?.createdAt).format('YYYY/MM/DD')}
                </Typography.Text>
              </div>
              <div className="flex flex-col gap-[4px]">
                <Typography.Text className="text-white text-[12px] font-[400] ">
                  {t('building.updateTime')}
                </Typography.Text>
                <Typography.Text className="text-white text-[12px] font-[400] ">
                  {building?.updatedAt &&
                    dayjs(building?.updatedAt).format('YYYY/MM/DD HH:mm')}
                </Typography.Text>
              </div>
            </div>
          )
        }
      ]
    },
    {
      key: 'property',
      label: (
        <div className="flex">
          <Typography.Text className="text-white pl-[12px] text-[13px] font-[400]">
            {t('building.property')}
          </Typography.Text>
        </div>
      ),
      children: [
        {
          key: '2',
          label: (
            <div className="flex flex-col gap-[10px] cursor-default">
              <div className="flex justify-between flex-wrap">
                <Typography.Text className="text-white text-[12px] font-[400]">
                  {t('building.sideArea')}
                </Typography.Text>
                <Typography.Text className="text-white text-[12px] font-[400] whitespace-pre-line">
                  {Number(building?.siteArea || 0).toLocaleString()} m2
                </Typography.Text>
              </div>
              <div className="flex justify-between flex-wrap">
                <Typography.Text className="text-white text-[12px] font-[400]">
                  {t('building.floorArea')}
                </Typography.Text>
                <Typography.Text className="text-white text-[12px] font-[400] whitespace-pre-line">
                  {Number(building?.totalFloorArea || 0)?.toLocaleString()} m2
                </Typography.Text>
              </div>
              <div className="flex justify-between flex-wrap">
                <Typography.Text className="text-white text-[12px] font-[400]">
                  {t('building.buildingArea')}
                </Typography.Text>
                <Typography.Text className="text-white text-[12px] font-[400] whitespace-pre-line">
                  {Number(building?.buildingArea || 0).toLocaleString()} m2
                </Typography.Text>
              </div>
              <div className="flex flex-col gap-[4px]">
                <Typography.Text className="text-white text-[12px] font-[400]">
                  {t('building.structure')}
                </Typography.Text>
                <Typography.Text className="text-white text-[12px] font-[400]">
                  {building?.structure}
                </Typography.Text>
              </div>
              <div className="flex justify-between flex-wrap">
                <Typography.Text className="text-white text-[12px] font-[400]">
                  {t('building.numberOfFloor')}
                </Typography.Text>
                <Typography.Text className="text-white text-[12px] font-[400] whitespace-pre-line">
                  {building?.floorNumber}
                </Typography.Text>
              </div>
            </div>
          )
        }
      ]
    },
    {
      key: 'designInstruction',
      label: (
        <div className="flex">
          <Typography.Text className="text-white pl-[12px] text-[13px] font-[400]">
            {t('building.designConstruction')}
          </Typography.Text>
        </div>
      ),
      children: [
        {
          key: 'design',
          label: (
            <div className="flex flex-col gap-[10px] cursor-default">
              <div className="flex flex-col gap-[4px]">
                <Typography.Text className="text-white text-[12px] font-[400] ">
                  {t('building.design')}
                </Typography.Text>
                <Typography.Text
                  className="text-white text-[12px] font-[400] "
                  ellipsis={{
                    tooltip: building?.designerName
                  }}
                >
                  {building?.designerName}
                </Typography.Text>
              </div>
              <div className="flex flex-col gap-[4px]">
                <Typography.Text className="text-white text-[12px] font-[400]">
                  {t('building.construction')}
                </Typography.Text>
                <Typography.Text
                  className="text-white text-[12px] font-[400] truncate"
                  ellipsis={{
                    tooltip: building?.architectName
                  }}
                >
                  {building?.architectName}
                </Typography.Text>
              </div>
              <div className="flex flex-col gap-[4px]">
                <Typography.Text className="text-white text-[12px] font-[400] ">
                  {t('building.constructionPeriod')}
                </Typography.Text>
                <Typography.Text className="text-white text-[12px] font-[400] ">
                  {building?.constructionPeriodStart
                    ? dayjs(building?.constructionPeriodStart).format(
                        'YYYY/MM/DD - '
                      )
                    : ''}
                  {building?.constructionPeriodStart
                    ? dayjs(building?.constructionPeriodEnd).format(
                        'YYYY/MM/DD'
                      )
                    : ''}
                </Typography.Text>
              </div>
            </div>
          )
        }
      ]
    }
  ]

  return (
    <div>
      <div className="flex justify-between align-middle h-[40px] ml-[10px] cursor-pointer">
        <div className="flex gap-3 cursor-pointer" onClick={handleBack}>
          <div className="my-auto">
            <LeftArrowIcon />
          </div>
          <Typography.Text className="text-inherit text-white font-[700] text-[16px] my-auto">
            {t('translation.buildingInformation')}
          </Typography.Text>
        </div>
        <div>
          <DropdownMenu
            handleSelectOption={handleSelectOption}
            profile={profile}
          />
        </div>
      </div>
      <Spinner spinning={isImageLoading}>
        <div className="w-[240px] aspect-video">
          {building?.s3Path ? (
            <img
              src={building.thumbnail || building.s3Path}
              alt="building"
              className="w-full h-full object-contain bg-[#021120]"
              loading="lazy"
              onLoad={() => {
                setIsImageLoading(false)
              }}
              onError={({ currentTarget }) => {
                setIsImageLoading(false)
                currentTarget.onerror = null // prevents looping
                currentTarget.src = '/assets/no-image.svg'
              }}
            />
          ) : (
            <img
              src="/assets/no-image.svg"
              alt="noImage"
              className="w-full h-full object-contain bg-[#CED0D4]"
            />
          )}
        </div>
      </Spinner>
      <Menu
        theme="dark"
        style={{ width: 240 }}
        inlineIndent={0}
        mode="inline"
        items={itemsInformationMenu}
        rootClassName="custom-menu-information bordered-menu"
        expandIcon={expandIcon}
      />
    </div>
  )
}

InformationMenu.defaultProps = {
  building: undefined
}
