import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function InitDirectionSvg() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6545_91793)">
        <path
          d="M6.59593 16.1411L0.438205 16.9964C0.318523 17.0128 0.198327 16.9727 0.112546 16.8875C0.0267652 16.8022 -0.0127864 16.6815 0.00365064 16.5618L0.85889 10.4041C0.878923 10.2592 0.979086 10.1385 1.11777 10.0918C1.15784 10.0784 1.19944 10.0718 1.24002 10.0718C1.3407 10.0718 1.43932 10.1113 1.51226 10.1848L6.81526 15.4873C6.9185 15.5905 6.95446 15.7436 6.90823 15.8823C6.862 16.0209 6.74078 16.1211 6.59593 16.1411Z"
          fill="white"
        />
        <path
          d="M16.9962 0.439497L16.1409 6.59722C16.1209 6.74207 16.0207 6.86278 15.8821 6.90952C15.7434 6.95627 15.5903 6.92031 15.4871 6.81655L10.1851 1.51407C10.0818 1.41082 10.0459 1.25775 10.0921 1.11906C10.1389 0.980377 10.2596 0.880215 10.4044 0.860182L16.5621 0.00494242C16.5796 0.0023737 16.5976 0.00134754 16.615 0.00134754C16.7162 0.00134754 16.8149 0.0414119 16.8873 0.114351C16.9726 0.199618 17.0131 0.320327 16.9967 0.44001L16.9962 0.439497Z"
          fill="white"
        />
        <path
          d="M4.23337 15.6719C6.14726 11.9016 10.2745 7.53503 13.8295 5.24874C14.4218 4.87223 15.0207 4.51729 15.673 4.23221L12.7673 1.32646C10.9238 5.27442 6.73745 9.30098 3.13825 11.7162C2.55422 12.0978 1.96352 12.4605 1.32812 12.7656L4.23388 15.6714L4.23337 15.6719Z"
          fill="white"
        />
        <path
          d="M1.51275 6.81687C1.4095 6.92011 1.25643 6.95607 1.11774 6.90984C0.979055 6.8631 0.878892 6.74239 0.858859 6.59754L0.00362017 0.439815C-0.0128169 0.320132 0.0272484 0.199937 0.113029 0.114156C0.185968 0.0412169 0.284077 0.00115061 0.385267 0.00115061C0.402732 0.00115061 0.42071 0.00217867 0.438174 0.00474691L6.5959 0.859986C6.74075 0.880018 6.86146 0.980182 6.9082 1.11887C6.95494 1.25756 6.91899 1.41063 6.81523 1.51387L1.51275 6.81687Z"
          fill="white"
        />
        <path
          d="M16.8881 16.8873C16.8028 16.9726 16.6821 17.0132 16.5625 16.9962L10.4047 16.141C10.2599 16.1209 10.1392 16.0208 10.0924 15.8821C10.0457 15.7434 10.0816 15.5903 10.1854 15.4871L15.4874 10.1846C15.5608 10.1111 15.6594 10.0716 15.7596 10.0716C15.8007 10.0716 15.8418 10.0783 15.8819 10.0916C16.0205 10.1384 16.1207 10.2591 16.1407 10.4039L16.996 16.5617C17.0124 16.6813 16.9724 16.8015 16.8866 16.8873H16.8881Z"
          fill="white"
        />
        <path
          d="M15.6735 12.7666C11.9033 10.8527 7.5367 6.72551 5.25041 3.17049C4.8739 2.57824 4.51896 1.97931 4.23388 1.32697L1.32812 4.23273C5.27609 6.07624 9.30265 10.2626 11.7179 13.8617C12.0995 14.4458 12.4622 15.0365 12.7673 15.6719L15.673 12.7661L15.6735 12.7666Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6545_91793">
          <rect
            width="17"
            height="17"
            fill="white"
            transform="matrix(1 0 0 -1 0 17)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default function InitDirectionIcon(
  props: Partial<CustomIconComponentProps>
) {
  return <Icon component={InitDirectionSvg} {...props} />
}
