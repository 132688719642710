import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, Input, Radio, Row, Typography } from 'antd'
import { FormInstance } from 'antd/lib/form/Form'
import TableColumn from 'constants/tableColumn'
import { ListOrganizations } from 'models/Organization'
import { CurrentUser, Organization, Role } from 'models/User'
import FormSearchOrganization from 'pages/user/listUser/components/FormSearchOrganization'
import Api from 'services/api'
import useSWR from 'swr'
import { OrderByType } from 'types'
import { ParamQueryOrganization } from 'types/listOrganization'
import Utils from 'utils'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'
import ExtraTable from 'components/organisms/ExtraTable'

type Props = {
  form: FormInstance
  selectedOrganization: Organization | undefined
  setSelectedOrganization: Dispatch<SetStateAction<Organization | undefined>>
}

export default function SelectOrganization({
  form,
  selectedOrganization,
  setSelectedOrganization
}: Props) {
  const { t } = useTranslation()

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [queries, setQueries] = useState<ParamQueryOrganization | null>({
    sort: 'id',
    sortOrder: OrderByType.descend
  })

  const { data: profile } = useSWR<CurrentUser | null>('v1/users/me', Api.get, {
    revalidateOnMount: false
  })

  const { data: organizations, isValidating } = useSWR<ListOrganizations>({
    url: `/v1/organizations`,
    params: { ...queries, include_my_org: profile?.role === Role.BA }
  })

  const mapOrganizations = useMemo(
    () =>
      organizations?.edges.map((organization) => ({
        id: organization.id,
        name: organization.name,
        domain: organization.emailDomain,
        checkbox: (
          <div className="flex">
            <Radio value={organization.id} />
          </div>
        )
      })) || [],
    [organizations]
  )

  const handleSearch = (value: any) => {
    setQueries((prev) => ({
      ...prev,
      ...Utils.trimObjectValues(value),
      page: 1
    }))
  }

  const handleChangeCheckbox = (event: any) => {
    const organizationSelect = organizations?.edges.find(
      (item) => item.id === event.id
    )
    if (organizationSelect) {
      setSelectedOrganization(organizationSelect)
    }
  }

  const onTableChange = useCallback(
    (sorter: string, field: string) => {
      if (field === 'checkbox') {
        field = 'id'
      }
      setQueries((prev) => ({
        ...prev,
        sort: sorter ? field : 'id',
        sortOrder: sorter
          ? OrderByType[sorter as keyof typeof OrderByType]
          : OrderByType.descend,
        page: 1
      }))
    },
    [setQueries]
  )

  const handleCancel = () => {
    const organizationId = form.getFieldValue('organizationId')
    const organization = organizations?.edges.find(
      (item) => item.id === organizationId
    )
    if (organization) {
      setSelectedOrganization(organization)
    }
    setIsOpenModal(false)
  }

  const handleSubmit = () => {
    form.setFieldValue('organizationId', selectedOrganization?.id)
    setIsOpenModal(false)
  }

  if (profile?.role !== Role.SA && profile?.role !== Role.BA) return null

  return (
    <>
      <Row justify="space-between">
        <Col className="h-[38px] flex items-center">
          <Typography className="text-[14px] leading-[21px] font-bold text-[#021120]">
            組織名
          </Typography>
        </Col>
        <Col>
          <Form.Item name="organizationId" className="hidden">
            <Input />
          </Form.Item>
          <div className="ml-auto flex w-[340px] justify-between items-center mb-6">
            <Typography.Text
              className="text-[14px] leading-[21px] font-medium text-[#021120] truncate"
              ellipsis={{
                tooltip: selectedOrganization?.name
              }}
            >
              {selectedOrganization?.name}
            </Typography.Text>
            <Button
              type="primary"
              sizing="w-[100px] h-10"
              className="flex-shrink-0"
              onClick={() => setIsOpenModal(true)}
            >
              選択
            </Button>
          </div>
        </Col>
      </Row>
      {isOpenModal && (
        <Modal
          width={1074}
          className="modal-organization"
          rootClassName=""
          isEnterable
          onEnter={handleSubmit}
          zIndex={1000}
        >
          <Title title="user.selectOrganization" />
          <div className="bg-white rounded-lg w-full mb-7 flex items-center">
            <FormSearchOrganization handleSearch={handleSearch} />
          </div>
          <Radio.Group
            className="w-full table-list-org-invite"
            value={selectedOrganization?.id}
          >
            <ExtraTable
              isDetail={false}
              scroll={{ y: 350 }}
              isLoading={isValidating && !organizations}
              columns={TableColumn.COLUMN_USER_SELECT_ORGANIZATION}
              data={mapOrganizations}
              onChange={onTableChange}
              total={organizations?.pagination.total}
              actionSelect={handleChangeCheckbox}
            />
          </Radio.Group>
          <div className="flex justify-center gap-10 mt-[30px]">
            <Button
              sizing="w-[140px] h-[50px]"
              className="px-3 py-[10px]"
              onClick={handleCancel}
            >
              {t('organization.buttonCancel')}
            </Button>
            <Button
              className="px-3 py-[10px]"
              sizing="w-[140px] h-[50px]"
              type="primary"
              onClick={handleSubmit}
            >
              {t('organization.buttonSelect')}
            </Button>
          </div>
        </Modal>
      )}
    </>
  )
}
