import {
  Dispatch,
  Fragment,
  MouseEvent,
  SetStateAction,
  useMemo,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from 'antd'
import dayjs from 'dayjs'
import { useRecoilValue } from 'recoil'
import { selectedPointDetail } from 'store/buildingStore'
import { PointHistory } from 'types/building'

import RotatedArrowIcon from 'components/icons/RotatedArrowIcon'

type Props = {
  versions: PointHistory[]
  setSelectedVersion: Dispatch<SetStateAction<PointHistory | undefined>>
}

export default function PointVersionHistory({
  versions,
  setSelectedVersion
}: Props) {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const pointDetail = useRecoilValue(selectedPointDetail)

  const isVersionIdIncluded = Boolean(
    versions[0].versionId === pointDetail?.versionId ||
      (versions.find((item) => item.versionId === pointDetail?.versionId) &&
        !isOpen)
  )

  const toggleList = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setIsOpen((prev) => !prev)
  }

  const handleSelectVersion = (version: PointHistory) => {
    if (!version || version?.versionId !== pointDetail?.versionId) {
      setSelectedVersion(version)
    }
  }

  const versionGroupedName = useMemo(() => {
    // sorted by name
    const nameSorted = versions
      .map((item) =>
        item.user.isDeleted
          ? t('building.deletedUser')
          : `${item.user.lastName} ${item.user.firstName}`
      )
      .sort((a, b) => a.localeCompare(b))

    // remove duplicated name
    return nameSorted.reduce((arr: string[], item: string) => {
      if (!arr.includes(item)) {
        arr.push(item)
      }
      return arr
    }, [])
  }, [t, versions])

  const editedUsername = (name: string, isDeleted: boolean) => (
    <Typography.Text
      ellipsis={{
        tooltip: !isDeleted ? name : t('building.deletedUser')
      }}
      className="truncate text-[#444746] text-xs pl-4"
    >
      {!isDeleted ? name : t('building.deletedUser')}
    </Typography.Text>
  )

  return (
    <>
      <div
        className="grid grid-cols-[auto_1fr] px-4 py-3 hover:bg-[#dde3ea] cursor-pointer"
        onClick={() => handleSelectVersion(versions[0])}
      >
        {versions.length > 1 ? (
          <div
            className="flex items-center w-4 h-6 cursor-pointer"
            onClick={toggleList}
          >
            <RotatedArrowIcon
              fill="#021120"
              className={isOpen ? 'rotate-360' : '-rotate-90'}
            />
          </div>
        ) : (
          <div className="w-4" />
        )}
        <div>
          <div className="text-[#021120] text-base">
            {dayjs(versions[0].editedAt).format(
              versions[0].isCurrentYear ? 'M月D日、H:mm' : 'YYYY年M月D日、H:mm'
            )}
            {isVersionIdIncluded && (
              <>
                &nbsp;{t('translation.versionRestoredFrom')}
                <br />
                <span className="italic text-xs text-[#444746]">
                  {t('building.currentVersion')}
                </span>
              </>
            )}
          </div>
          <div className="flex flex-col">
            {isOpen
              ? editedUsername(
                  `${versions[0].user.lastName} ${versions[0].user.firstName}`,
                  versions[0].user.isDeleted
                )
              : versionGroupedName.map((item) => (
                  <Fragment key={item}>{editedUsername(item, false)}</Fragment>
                ))}
          </div>
        </div>
      </div>

      {isOpen &&
        versions.map(
          (item, index) =>
            index !== 0 && (
              <div
                key={item.versionId}
                className="py-3 px-10 hover:bg-[#dde3ea] cursor-pointer"
                onClick={() => handleSelectVersion(item)}
              >
                <div className="text-[#021120] text-base">
                  {dayjs(item.editedAt).format(
                    item.isCurrentYear ? 'M月D日、H:mm' : 'YYYY年M月D日、H:mm'
                  )}
                  {pointDetail?.versionId === item.versionId && (
                    <>
                      &nbsp;{t('translation.versionRestoredFrom')}
                      <br />
                      <span className="italic text-xs text-[#444746]">
                        {t('building.currentVersion')}
                      </span>
                    </>
                  )}
                </div>
                {editedUsername(
                  `${item.user.lastName} ${item.user.firstName}`,
                  item.user.isDeleted
                )}
              </div>
            )
        )}
    </>
  )
}
