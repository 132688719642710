type Props = {
  color?: string
  size?: number
  isActive?: boolean
}

export default function LeftArrowIcon({ color, size, isActive }: Props) {
  return (
    <svg
      width={size ? String(size) : '8'}
      height={size ? String(size) : '14'}
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isActive ? 'my-auto rotate-90' : 'my-auto'}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0316 0.540653C11.1015 0.610322 11.1569 0.693085 11.1947 0.784203C11.2325 0.875321 11.252 0.973002 11.252 1.07165C11.252 1.1703 11.2325 1.26799 11.1947 1.3591C11.1569 1.45022 11.1015 1.53298 11.0316 1.60265L2.56111 10.0717L11.0316 18.5407C11.1013 18.6104 11.1567 18.6932 11.1944 18.7843C11.2321 18.8754 11.2516 18.973 11.2516 19.0717C11.2516 19.1703 11.2321 19.2679 11.1944 19.359C11.1567 19.4501 11.1013 19.5329 11.0316 19.6027C10.9619 19.6724 10.8791 19.7277 10.788 19.7654C10.6969 19.8032 10.5992 19.8226 10.5006 19.8226C10.402 19.8226 10.3043 19.8032 10.2132 19.7654C10.1221 19.7277 10.0393 19.6724 9.96961 19.6027L0.969612 10.6027C0.899767 10.533 0.844353 10.4502 0.806544 10.3591C0.768734 10.268 0.749272 10.1703 0.749272 10.0717C0.749272 9.973 0.768735 9.87532 0.806544 9.7842C0.844353 9.69309 0.899767 9.61032 0.969612 9.54065L9.96961 0.540653C10.0393 0.470808 10.122 0.415394 10.2132 0.377584C10.3043 0.339775 10.402 0.320312 10.5006 0.320312C10.5993 0.320312 10.6969 0.339775 10.7881 0.377584C10.8792 0.415394 10.9619 0.470808 11.0316 0.540653Z"
        fill={color || 'white'}
      />
    </svg>
  )
}

LeftArrowIcon.defaultProps = {
  color: undefined,
  size: undefined,
  isActive: false
}
