import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function RotatedArrowSvg(fill?: string) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.64592 4.70939C1.69236 4.66283 1.74754 4.62589 1.80828 4.60068C1.86903 4.57547 1.93415 4.5625 1.99992 4.5625C2.06568 4.5625 2.13081 4.57547 2.19155 4.60068C2.2523 4.62589 2.30747 4.66283 2.35392 4.70939L7.99992 10.3564L13.6459 4.70939C13.6924 4.66291 13.7476 4.62603 13.8083 4.60087C13.8691 4.57571 13.9342 4.56276 13.9999 4.56276C14.0657 4.56276 14.1308 4.57571 14.1915 4.60087C14.2522 4.62603 14.3074 4.66291 14.3539 4.70939C14.4004 4.75588 14.4373 4.81107 14.4624 4.87181C14.4876 4.93255 14.5005 4.99765 14.5005 5.06339C14.5005 5.12914 14.4876 5.19424 14.4624 5.25498C14.4373 5.31572 14.4004 5.37091 14.3539 5.41739L8.35392 11.4174C8.30747 11.464 8.2523 11.5009 8.19155 11.5261C8.13081 11.5513 8.06568 11.5643 7.99992 11.5643C7.93415 11.5643 7.86903 11.5513 7.80828 11.5261C7.74754 11.5009 7.69236 11.464 7.64592 11.4174L1.64592 5.41739C1.59935 5.37095 1.56241 5.31577 1.5372 5.25503C1.512 5.19428 1.49902 5.12916 1.49902 5.06339C1.49902 4.99763 1.512 4.93251 1.5372 4.87176C1.56241 4.81101 1.59935 4.75584 1.64592 4.70939Z"
        fill={fill || 'white'}
      />
    </svg>
  )
}

export default function RotatedArrowIcon(
  props: Partial<CustomIconComponentProps> & { fill?: string }
) {
  const { fill, ...rest } = props
  return <Icon component={() => RotatedArrowSvg(fill)} {...rest} />
}

RotatedArrowIcon.defaultProps = {
  fill: undefined
}
