import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function MenuUserSvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 19C2.5 19 1 19 1 17.5C1 16 2.5 11.5 10 11.5C17.5 11.5 19 16 19 17.5C19 19 17.5 19 17.5 19H2.5ZM10 10C11.1935 10 12.3381 9.52589 13.182 8.68198C14.0259 7.83807 14.5 6.69347 14.5 5.5C14.5 4.30653 14.0259 3.16193 13.182 2.31802C12.3381 1.47411 11.1935 1 10 1C8.80653 1 7.66193 1.47411 6.81802 2.31802C5.97411 3.16193 5.5 4.30653 5.5 5.5C5.5 6.69347 5.97411 7.83807 6.81802 8.68198C7.66193 9.52589 8.80653 10 10 10Z"
        fill="white"
      />
    </svg>
  )
}

export default function MenuUserIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={MenuUserSvg} {...props} />
}
