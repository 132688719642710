import { BuildingFile } from 'types/building'

import LazyThumbnail from 'components/atoms/LazyThumbnail'
import PdfIcon from 'components/icons/PdfIcon'

type Props = {
  file: BuildingFile
  size?: 'large' | 'default'
}

export default function PreviewFile({ file, size }: Props) {
  const { extension, thumbnail, name } = file
  if (extension && extension.toLowerCase() === 'pdf') {
    return <PdfIcon size={size} />
  }

  return (
    <LazyThumbnail
      name={name}
      url={thumbnail}
      className={`bg-[#021120] ${
        size === 'large' ? 'h-[60px] w-[120px]' : 'h-[50px] w-[100px]'
      }`}
      width={size === 'large' ? 120 : 100}
      height={size === 'large' ? 60 : 50}
    />
  )
}

PreviewFile.defaultProps = {
  size: 'default'
}
