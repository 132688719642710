import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

type Props = {
  width?: number
  height?: number
}

function NoImageSvg(width: number, height: number) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={width} height={height} fill="#CED0D5" />
      <g opacity="0.5">
        <path
          d="M89.3573 40.6895H83.857V54.2897H89.3573V40.6895Z"
          fill="#A6AAAF"
        />
        <path
          d="M86.6103 36.9631C90.0402 36.9631 92.8207 34.1771 92.8207 30.7403C92.8207 27.3036 90.0402 24.5176 86.6103 24.5176C83.1803 24.5176 80.3998 27.3036 80.3998 30.7403C80.3998 34.1771 83.1803 36.9631 86.6103 36.9631Z"
          fill="#A6AAAF"
        />
        <path
          d="M88.1087 36.0528C88.7443 32.6756 86.5273 29.4215 83.1567 28.7846C79.7862 28.1477 76.5386 30.3692 75.903 33.7464C75.2673 37.1236 77.4844 40.3776 80.8549 41.0145C84.2254 41.6515 87.473 39.43 88.1087 36.0528Z"
          fill="#A6AAAF"
        />
        <path
          d="M85.4445 45.0132C88.6096 43.6891 90.1043 40.0448 88.7829 36.8734C87.4615 33.702 83.8244 32.2044 80.6592 33.5284C77.494 34.8524 75.9994 38.4967 77.3208 41.6682C78.6422 44.8396 82.2793 46.3372 85.4445 45.0132Z"
          fill="#A6AAAF"
        />
        <path
          d="M95.6089 39.2986C98.0342 36.8685 98.0342 32.9284 95.6089 30.4983C93.1836 28.0682 89.2513 28.0682 86.826 30.4983C84.4007 32.9284 84.4007 36.8684 86.826 39.2986C89.2513 41.7287 93.1836 41.7287 95.6089 39.2986Z"
          fill="#A6AAAF"
        />
        <path
          d="M91.4879 45.2785C94.8269 44.4926 96.8979 41.1433 96.1135 37.7976C95.3291 34.452 91.9864 32.3769 88.6474 33.1629C85.3083 33.9488 83.2374 37.2981 84.0218 40.6438C84.8062 43.9894 88.1489 46.0645 91.4879 45.2785Z"
          fill="#A6AAAF"
        />
        <path
          d="M58.0001 13.3233V54H72.6897C73.4136 54 74.0001 53.4094 74.0001 52.6803V18.9611C74.0001 18.4289 73.6833 17.9495 73.1964 17.7435L59.8173 12.1039C58.9539 11.7393 58.0019 12.3791 58.0019 13.3215L58.0001 13.3233ZM69.2037 47.5055H62.7946C62.1774 47.5055 61.6779 47.0024 61.6779 46.3809C61.6779 45.7593 62.1774 45.2562 62.7946 45.2562H69.2037C69.8209 45.2562 70.3204 45.7593 70.3204 46.3809C70.3204 47.0024 69.8209 47.5055 69.2037 47.5055ZM69.2037 41.68H62.7946C62.1774 41.68 61.6779 41.1769 61.6779 40.5553C61.6779 39.9338 62.1774 39.4307 62.7946 39.4307H69.2037C69.8209 39.4307 70.3204 39.9338 70.3204 40.5553C70.3204 41.1769 69.8209 41.68 69.2037 41.68ZM69.2037 35.8544H62.7946C62.1774 35.8544 61.6779 35.3514 61.6779 34.7298C61.6779 34.1082 62.1774 33.6052 62.7946 33.6052H69.2037C69.8209 33.6052 70.3204 34.1082 70.3204 34.7298C70.3204 35.3514 69.8209 35.8544 69.2037 35.8544ZM69.2037 30.0307H62.7946C62.1774 30.0307 61.6779 29.5276 61.6779 28.9061C61.6779 28.2845 62.1774 27.7814 62.7946 27.7814H69.2037C69.8209 27.7814 70.3204 28.2845 70.3204 28.9061C70.3204 29.5276 69.8209 30.0307 69.2037 30.0307ZM69.2037 24.2052H62.7946C62.1774 24.2052 61.6779 23.7021 61.6779 23.0805C61.6779 22.459 62.1774 21.9559 62.7946 21.9559H69.2037C69.8209 21.9559 70.3204 22.459 70.3204 23.0805C70.3204 23.7021 69.8209 24.2052 69.2037 24.2052Z"
          fill="#A6AAAF"
        />
        <path
          d="M26.0001 13.4083V52.6873C26.0001 53.4116 26.5822 53.9982 27.3009 53.9982H36.7071V45.4558C36.7071 44.9724 37.0952 44.5813 37.5749 44.5813H43.4252C43.905 44.5813 44.2931 44.9724 44.2931 45.4558V54H55.0001V7.31024C55.0001 6.46832 54.2239 5.84547 53.4099 6.03377L27.0099 12.13C26.4187 12.2658 26.0001 12.7963 26.0001 13.4065V13.4083ZM38.0564 40.0041C38.0564 40.455 37.6935 40.8189 37.2479 40.8189H32.9266C32.4792 40.8189 32.1163 40.4531 32.1163 40.0023V35.9647C32.1163 35.5138 32.4792 35.1481 32.9266 35.1481H37.2479C37.6953 35.1481 38.0582 35.5138 38.0582 35.9647V40.0041H38.0564ZM38.0564 30.9856C38.0564 31.4364 37.6935 31.8003 37.2479 31.8003H32.9266C32.4792 31.8003 32.1163 31.4346 32.1163 30.9837V26.9461C32.1163 26.4953 32.4792 26.1295 32.9266 26.1295H37.2479C37.6953 26.1295 38.0582 26.4953 38.0582 26.9461V30.9856H38.0564ZM38.0564 21.9652C38.0564 22.416 37.6935 22.7818 37.2461 22.7818H32.9248C32.4774 22.7818 32.1145 22.416 32.1145 21.9652V17.9276C32.1145 17.4767 32.4774 17.111 32.9248 17.111H37.2461C37.6935 17.111 38.0564 17.4767 38.0564 17.9276V21.9652ZM48.8838 40.0041C48.8838 40.455 48.5209 40.8189 48.0753 40.8189H43.754C43.3066 40.8189 42.9437 40.4531 42.9437 40.0023V35.9647C42.9437 35.5138 43.3066 35.1481 43.754 35.1481H48.0753C48.5227 35.1481 48.8856 35.5138 48.8856 35.9647V40.0041H48.8838ZM48.8838 30.9837C48.8838 31.4346 48.5209 31.8003 48.0735 31.8003H43.7522C43.3048 31.8003 42.9419 31.4346 42.9419 30.9837V26.9461C42.9419 26.4953 43.3048 26.1295 43.7522 26.1295H48.0735C48.5209 26.1295 48.8838 26.4953 48.8838 26.9461V30.9837ZM48.8838 21.9634C48.8838 22.4142 48.5209 22.7799 48.0735 22.7799H43.7522C43.3048 22.7799 42.9419 22.4142 42.9419 21.9634V17.9257C42.9419 17.4749 43.3048 17.1092 43.7522 17.1092H48.0735C48.5209 17.1092 48.8838 17.4749 48.8838 17.9257V21.9634Z"
          fill="#A6AAAF"
        />
      </g>
      <path
        d="M27.5875 37V26.64H29.6875L32.6975 32.17L33.7475 34.41H33.8175C33.7055 33.332 33.5375 31.946 33.5375 30.77V26.64H35.5115V37H33.3975L30.4015 31.456L29.3515 29.244H29.2815C29.3795 30.35 29.5475 31.652 29.5475 32.856V37H27.5875ZM42.1899 37.196C39.4319 37.196 37.5559 35.152 37.5559 31.778C37.5559 28.404 39.4319 26.458 42.1899 26.458C44.9339 26.458 46.8099 28.418 46.8099 31.778C46.8099 35.152 44.9339 37.196 42.1899 37.196ZM42.1899 35.404C43.7299 35.404 44.7099 33.99 44.7099 31.778C44.7099 29.566 43.7299 28.236 42.1899 28.236C40.6359 28.236 39.6699 29.566 39.6699 31.778C39.6699 33.99 40.6359 35.404 42.1899 35.404ZM52.0192 37V26.64H54.0912V37H52.0192ZM56.6403 37V26.64H58.9223L60.6863 31.498C60.9243 32.142 61.1063 32.814 61.3303 33.486H61.3863C61.6243 32.814 61.8063 32.142 62.0163 31.498L63.7383 26.64H66.0343V37H64.1443V32.674C64.1443 31.694 64.3123 30.252 64.4243 29.272H64.3543L63.5003 31.708L61.9043 36.076H60.7143L59.1183 31.708L58.2783 29.272H58.2223C58.3343 30.252 58.4883 31.694 58.4883 32.674V37H56.6403ZM67.2458 37L70.5638 26.64H72.9998L76.3178 37H74.1478L72.6638 31.61C72.3558 30.532 72.0618 29.328 71.7678 28.222H71.7118C71.4318 29.342 71.1378 30.532 70.8298 31.61L69.3458 37H67.2458ZM69.3038 34.34V32.744H74.2318V34.34H69.3038ZM81.7641 37.196C78.9501 37.196 76.7941 35.32 76.7941 31.862C76.7941 28.446 79.0061 26.458 81.7641 26.458C83.2481 26.458 84.2841 27.102 84.9561 27.774L83.8641 29.104C83.3461 28.614 82.7581 28.236 81.8341 28.236C80.0981 28.236 78.9081 29.58 78.9081 31.792C78.9081 34.06 79.9441 35.404 81.9741 35.404C82.4781 35.404 82.9821 35.264 83.2761 35.026V32.982H81.4281V31.288H85.1241V35.95C84.4241 36.65 83.1921 37.196 81.7641 37.196ZM87.3473 37V26.64H93.7033V28.362H89.4193V30.756H93.0453V32.506H89.4193V35.264H93.8573V37H87.3473Z"
        fill="white"
      />
    </svg>
  )
}

export default function NoImageIcon(
  props: Partial<CustomIconComponentProps> & Props
) {
  const { width = 120, height = 60, ...rest } = props
  return <Icon component={() => NoImageSvg(width, height)} {...rest} />
}

NoImageIcon.defaultProps = {
  width: 120,
  height: 60
}
