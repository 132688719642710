import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function CustomCheck() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10.5" cy="10" r="10" fill="#CED0D5" />
    </svg>
  )
}

export default function CustomCheckIcon(
  props: Partial<CustomIconComponentProps>
) {
  return <Icon component={CustomCheck} {...props} />
}
