import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from 'antd'
import TableColumn from 'constants/tableColumn'
import dayjs from 'dayjs'
import useAuth from 'hooks/useAuth'
import { ListBuilding } from 'models/Building'
import { useRecoilState } from 'recoil'
import { queryParamListBuildingState } from 'store/buildingStore'
import useSWR from 'swr'
import { OrderByType } from 'types'
import Utils from 'utils'

import LazyThumbnail from 'components/atoms/LazyThumbnail'
import NoImageIcon from 'components/icons/NoImageIcon'
import ExtraTable from 'components/organisms/ExtraTable'

import HeaderListBuilding from './HeaderListBuilding'

type Props = {
  isFormSearch?: boolean
  idUser?: string
}

export default function TableBuilding({ isFormSearch, idUser }: Props) {
  const { profile } = useAuth()
  const defaultField = 'updatedAt'

  const [queries, setQueries] = useRecoilState(queryParamListBuildingState)

  const params = useMemo(
    () => ({
      ...queries,
      id: idUser === 'me' ? `${profile?.id}` : idUser
    }),
    [idUser, profile?.id, queries]
  )

  const { t } = useTranslation()

  const {
    data: buildings,
    isValidating,
    mutate
  } = useSWR<ListBuilding>({
    url: `/v1/buildings`,
    params
  })

  const listBuilding = buildings?.edges?.map((item) => {
    const fullNameUser = `${item.latestEditedBy?.lastName || ''} ${
      item.latestEditedBy?.firstName || ''
    }`
    return {
      ...item,
      buildingStatus: item.status ? t(`building.${item.status}`) : '',
      branchName: item.branch ? (
        <div>
          <Typography>
            {item.branch.name !== 'その他' ? item.branch.name : ''}
          </Typography>
          <Typography>
            {item.branch.serviceStoreName !== 'その他'
              ? item.branch.serviceStoreName
              : ''}
          </Typography>
        </div>
      ) : (
        ''
      ),
      orgName: (
        <div className="grid grid-cols-1">
          <Typography.Paragraph
            className="!mb-0"
            ellipsis={{
              rows: 2,
              tooltip: item.organizationName
            }}
          >
            {item.organizationName}
          </Typography.Paragraph>
        </div>
      ),
      name: (
        <div className="grid grid-cols-1">
          <Typography.Paragraph
            className="!mb-0"
            ellipsis={{
              rows: 2,
              tooltip: item.name
            }}
          >
            {item.name}
          </Typography.Paragraph>
        </div>
      ),
      updatedAt: (
        <div className="grid grid-cols-1">
          <Typography.Paragraph className="!mb-0">
            {dayjs(item.latestUpdated).format('YYYY/MM/DD HH:mm')}
          </Typography.Paragraph>
          <Typography.Paragraph
            ellipsis={{
              rows: 1,
              tooltip: fullNameUser
            }}
            className="!mb-0 truncate"
          >
            {fullNameUser}
          </Typography.Paragraph>
        </div>
      ),
      image: item.s3Path ? (
        <LazyThumbnail
          name="thumbnail"
          url={item.thumbnail || item.s3Path}
          className="w-[120px] h-[67.5px] !bg-[#021120]"
          width={120}
          height={67.5}
        />
      ) : (
        <NoImageIcon width={120} height={67.5} />
      ),
      createdAt: (
        <div className="grid grid-cols-1">
          <Typography.Paragraph className="!mb-0">
            {dayjs(item.createdAt).format('YYYY/MM/DD')}
          </Typography.Paragraph>
        </div>
      )
    }
  })

  const onTableChange = useCallback(
    (sorter: string, field: string) => {
      setQueries((prev) => ({
        ...prev,
        sort: sorter ? field : defaultField,
        sortOrder: sorter
          ? OrderByType[sorter as keyof typeof OrderByType]
          : OrderByType.descend,
        page: 1
      }))
    },
    [setQueries]
  )

  const handleSearch = (value: any) => {
    setQueries((prev) => ({
      ...prev,
      ...Utils.trimObjectValues(value),
      page: 1
    }))
  }

  return (
    <>
      {isFormSearch && (
        <HeaderListBuilding
          handleSearch={handleSearch}
          getNewBuilding={mutate}
        />
      )}

      <ExtraTable
        isLoading={isValidating && !buildings}
        columns={TableColumn.COLUMN_BUILDING}
        data={listBuilding}
        onChange={onTableChange}
        total={buildings?.pagination.total}
        pagination={{
          page: queries?.page || 1,
          pageSize: queries?.pageSize || 10,
          setQueries,
          queries
        }}
        role={profile?.role}
        refetch={mutate}
      />
    </>
  )
}

TableBuilding.defaultProps = {
  isFormSearch: true,
  idUser: undefined
}
