import Utils from 'utils'

type Props = { imagePath: string | undefined; refetchFloor: any }

export default function useViewBuildingDetail({
  imagePath,
  refetchFloor
}: Props) {
  const initMap = (mapContainerRef: any, setImgBounds: any, img?: any) => {
    const height = img?.naturalHeight
    const width = img?.naturalWidth
    setImgBounds([height, width])
    // init bounds of map
    const initBound = [
      [0, 0],
      [height, width]
    ]
    // calculate and set MinZoom + MaxZoom level
    mapContainerRef.current?.setMinZoom(-999)
    mapContainerRef.current?.fitBounds(initBound, { animate: false })
    mapContainerRef.current?.setMaxBounds(initBound)
    const minZoomLevel = mapContainerRef.current?.getBoundsZoom(initBound)
    mapContainerRef.current?.setView([height / 2, width / 2], minZoomLevel, {
      animate: false
    })
    mapContainerRef.current?.setMinZoom(minZoomLevel)
    mapContainerRef.current?.setMaxZoom(minZoomLevel + 5)
  }

  const mapOnReady = (mapContainerRef: any, setImgBounds: any) => {
    Utils.getMeta(imagePath)
      .then((img) => {
        initMap(mapContainerRef, setImgBounds, img)
      })
      .catch(() => {
        refetchFloor().then((floor: any) => {
          Utils.getMeta(floor?.mapFile.s3Path).then((img) => {
            initMap(mapContainerRef, setImgBounds, img)
          })
        })
      })
  }

  return {
    mapOnReady
  }
}
