import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function CloseSvg() {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1076_66198)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 28.625C18.481 28.625 21.8194 27.2422 24.2808 24.7808C26.7422 22.3194 28.125 18.981 28.125 15.5C28.125 12.019 26.7422 8.68064 24.2808 6.21922C21.8194 3.75781 18.481 2.375 15 2.375C11.519 2.375 8.18064 3.75781 5.71922 6.21922C3.25781 8.68064 1.875 12.019 1.875 15.5C1.875 18.981 3.25781 22.3194 5.71922 24.7808C8.18064 27.2422 11.519 28.625 15 28.625ZM15 30.5C18.9782 30.5 22.7936 28.9196 25.6066 26.1066C28.4196 23.2936 30 19.4782 30 15.5C30 11.5218 28.4196 7.70644 25.6066 4.8934C22.7936 2.08035 18.9782 0.5 15 0.5C11.0218 0.5 7.20644 2.08035 4.3934 4.8934C1.58035 7.70644 0 11.5218 0 15.5C0 19.4782 1.58035 23.2936 4.3934 26.1066C7.20644 28.9196 11.0218 30.5 15 30.5Z"
          fill="#3B3C46"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.71293 9.21293C8.80001 9.12562 8.90347 9.05635 9.01736 9.00909C9.13126 8.96183 9.25336 8.9375 9.37668 8.9375C9.49999 8.9375 9.62209 8.96183 9.73599 9.00909C9.84989 9.05635 9.95334 9.12562 10.0404 9.21293L15.0017 14.1761L19.9629 9.21293C20.0501 9.12576 20.1536 9.05662 20.2675 9.00944C20.3813 8.96227 20.5034 8.93799 20.6267 8.93799C20.7499 8.93799 20.872 8.96227 20.9859 9.00944C21.0998 9.05662 21.2033 9.12576 21.2904 9.21293C21.3776 9.30009 21.4467 9.40357 21.4939 9.51746C21.5411 9.63134 21.5654 9.75341 21.5654 9.87668C21.5654 9.99995 21.5411 10.122 21.4939 10.2359C21.4467 10.3498 21.3776 10.4533 21.2904 10.5404L16.3273 15.5017L21.2904 20.4629C21.3776 20.5501 21.4467 20.6536 21.4939 20.7675C21.5411 20.8813 21.5654 21.0034 21.5654 21.1267C21.5654 21.2499 21.5411 21.372 21.4939 21.4859C21.4467 21.5998 21.3776 21.7033 21.2904 21.7904C21.2033 21.8776 21.0998 21.9467 20.9859 21.9939C20.872 22.0411 20.7499 22.0654 20.6267 22.0654C20.5034 22.0654 20.3813 22.0411 20.2675 21.9939C20.1536 21.9467 20.0501 21.8776 19.9629 21.7904L15.0017 16.8273L10.0404 21.7904C9.95326 21.8776 9.84978 21.9467 9.73589 21.9939C9.62201 22.0411 9.49995 22.0654 9.37668 22.0654C9.25341 22.0654 9.13134 22.0411 9.01746 21.9939C8.90357 21.9467 8.80009 21.8776 8.71293 21.7904C8.62576 21.7033 8.55662 21.5998 8.50944 21.4859C8.46227 21.372 8.43799 21.2499 8.43799 21.1267C8.43799 21.0034 8.46227 20.8813 8.50944 20.7675C8.55662 20.6536 8.62576 20.5501 8.71293 20.4629L13.6761 15.5017L8.71293 10.5404C8.62562 10.4533 8.55635 10.3499 8.50909 10.236C8.46183 10.1221 8.4375 9.99999 8.4375 9.87668C8.4375 9.75336 8.46183 9.63126 8.50909 9.51736C8.55635 9.40347 8.62562 9.30001 8.71293 9.21293Z"
          fill="#3B3C46"
        />
      </g>
      <defs>
        <clipPath id="clip0_1076_66198">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default function CloseIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={CloseSvg} {...props} />
}
