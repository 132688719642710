import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Typography } from 'antd'
import useMutation from 'hooks/useMutation'
import { useRecoilValue } from 'recoil'
import { selectedPoint } from 'store/buildingStore'
import { Annotation } from 'types/building'
import Utils from 'utils'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'

type Props = {
  setIsOpen: Dispatch<SetStateAction<string>>
  annotationSelected: Annotation | undefined
  handleCloseModal: () => void
}

export default function ModalDeleteAnnotation({
  setIsOpen,
  annotationSelected,
  handleCloseModal
}: Props) {
  const { t } = useTranslation()
  const { id, floorId } = useParams()
  const navigate = useNavigate()
  const currentSelectedPoint = useRecoilValue(selectedPoint)

  const { trigger: deleteAnnotation, isMutating } =
    useMutation(`v1/annotations`)

  const handleClose = () => {
    setIsOpen('')
  }

  const handleSubmit = () => {
    const krpano = document.getElementById('embedpano-full') as any
    !isMutating &&
      deleteAnnotation(
        {
          method: 'delete',
          query: [annotationSelected?.id],
          params: {
            building_id: Number(id),
            floor_id: Number(floorId),
            point_id: currentSelectedPoint
          },
          data: null,
          successMessage: t('building.deleted')
        },
        {
          onSuccess: () => {
            krpano.call(`remove_annotation(${annotationSelected?.id});`)
            handleCloseModal()
            handleClose()
          },
          onError: (error) => {
            krpano.call(`remove_annotation(${annotationSelected?.id});`)
            handleClose()
            Utils.handleErrorNavigate(error, navigate)
          }
        }
      )
  }
  return (
    <Modal
      onCancel={handleClose}
      width={440}
      isEnterable
      onEnter={handleSubmit}
    >
      <Title title={t('common.titleDeleteAnnotation')} bottomMargin="mb-8" />

      <div className="flex flex-col gap-10 text-center">
        <Typography className="text-[14px] leading-[21px]">
          {t('building.deleteConfirmation')}
        </Typography>
        <div className="flex gap-10 justify-center">
          <Button sizing="w-[140px] h-[50px]" onClick={handleClose}>
            {t('building.buttonCancel')}
          </Button>

          <Button
            sizing="w-[140px] h-[50px]"
            className="bg-[#B80808]"
            onClick={handleSubmit}
          >
            {t('common.delete')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
