import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Typography } from 'antd'
import useMutation from 'hooks/useMutation'
import { Building } from 'models/Building'
import useSWR from 'swr'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'

type Props = {
  handleCancel: () => void
  type: string
}

export default function DeleteBuildingModal({ handleCancel, type }: Props) {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()

  const { trigger, isMutating } = useMutation(`/v1/buildings/${id}`)

  const { data: building } = useSWR<Building>(
    {
      url: `/v1/buildings/${id}`
    },
    {
      revalidateOnMount: false
    }
  )

  const handleDeleteBuilding = () => {
    if (type !== 'building') handleCancel()
    !isMutating &&
      trigger(
        {
          method: 'delete',
          data: null,
          successMessage: 'organization.successDeletedNotification'
        },
        {
          onSuccess: () => {
            navigate('/buildings')
          },
          onError: () => {
            navigate('/buildings')
          }
        }
      )
  }

  return (
    <Modal
      width="480px"
      onCancel={handleCancel}
      isEnterable
      onEnter={handleDeleteBuilding}
    >
      <div className="text-center flex flex-col gap-[30px] items-center">
        <Typography className="text-[20px] font-[700] text-[#3163B7]">
          {t(`common.delete${type[0].toUpperCase() + type.slice(1)}Title`)}
        </Typography>
        <Typography className="text-[14px] font-[400]">
          {t('common.deleteNotice')}
        </Typography>
        <div className="bg-[#F9FBFF] w-[360px] py-[18px] rounded-[10px]">
          <Typography className="text-[16px] font-[400] ">
            {building?.name}
          </Typography>
        </div>
        <div className="flex gap-10 justify-center w-full items-end">
          <Button onClick={handleCancel} sizing="w-[140px] h-[50px]">
            {t('common.cancel')}
          </Button>
          <Button
            onClick={handleDeleteBuilding}
            sizing="w-[140px] h-[50px]"
            className="bg-[#B80808]"
          >
            {t('common.delete')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
