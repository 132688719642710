import { Dispatch, SetStateAction, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Input, Typography } from 'antd'
import useMutation from 'hooks/useMutation'
import { useRecoilState } from 'recoil'
import {
  floorDetailState,
  revalidateBuildingDetailState
} from 'store/buildingStore'
import Utils from 'utils'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'

type Props = {
  setIsOpen: Dispatch<SetStateAction<string>>
}

export default function EditNameModal({ setIsOpen }: Props) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const stopPropagation = useRef<boolean>(false)
  const { id, floorId } = useParams()
  const navigate = useNavigate()
  const [, setRevalidateBuildingDetail] = useRecoilState(
    revalidateBuildingDetailState
  )
  const [floorDetail, setFloorDetail] = useRecoilState(floorDetailState)

  const { trigger: editFloorName, isMutating } = useMutation(
    `v1/floors/${floorId}`
  )

  const handleClose = () => {
    setIsOpen('')
  }

  const handleSubmit = (values: any) => {
    const formData = new FormData()

    formData.append('name', values.name.trim())

    !isMutating &&
      editFloorName(
        {
          form,
          method: 'patch',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
          successMessage: t('building.saved')
        },
        {
          onSuccess: () => {
            setRevalidateBuildingDetail(true)
            setFloorDetail(
              (prev) => prev && { ...prev, name: values.name.trim() }
            )
          },
          onError: (error) => {
            navigate(`/buildings/${id}`)
            Utils.handleErrorNavigate(error, navigate)
          }
        }
      )
    handleClose()
  }

  const handleEnterSubmit = () => {
    if (stopPropagation.current) return
    form
      .validateFields()
      .then(() => handleSubmit(form.getFieldsValue()))
      .catch(() => {})
  }

  return (
    <Modal
      onCancel={handleClose}
      width={526}
      isEnterable
      onEnter={handleEnterSubmit}
    >
      <Title title={t('building.editNameFloorTitle')} bottomMargin="mb-8" />

      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={floorDetail || undefined}
        onFocus={() => {
          stopPropagation.current = true
        }}
        onBlur={() => {
          stopPropagation.current = false
        }}
      >
        <div className="flex items-center gap-[50px]">
          <Typography className="text-[14px] leading-[21px] font-medium">
            {t('common.floor.name')}
          </Typography>
          <Form.Item
            name="name"
            className="mb-0 flex-grow thin-placeholder thin-error-message"
            rules={[
              {
                validator: Validators.addFloorValidator(t)
              }
            ]}
          >
            <Input
              className="h-[38px] text-sm"
              placeholder={t('common.floor.placeHolder')}
            />
          </Form.Item>
        </div>

        <div className="flex gap-10 justify-center mt-10">
          <Button sizing="w-[140px] h-[50px]" onClick={handleClose}>
            {t('building.buttonCancel')}
          </Button>

          <Button type="primary" sizing="w-[140px] h-[50px]" htmlType="submit">
            {t('building.buttonSave')}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}
