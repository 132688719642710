import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function InformationSvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0709 2.92883C15.2644 1.12055 12.7586 -0.000546675 10 1.99985e-07C7.24145 -0.000546675 4.73559 1.12055 2.92883 2.92883C1.12086 4.73531 -0.000585708 7.24145 2.29495e-07 10C-0.000585708 12.7583 1.12086 15.2641 2.92883 17.0709C4.73559 18.8795 7.24145 20.0003 10 20C12.7586 20.0003 15.2644 18.8795 17.0709 17.0709C18.8795 15.2641 20.0006 12.7583 20 10C20.0005 7.24145 18.8795 4.73531 17.0709 2.92883ZM15.7641 15.7638C14.2855 17.2402 12.2537 18.1509 10 18.1512C7.74633 18.1509 5.71449 17.2403 4.23594 15.7638C2.75973 14.2858 1.84906 12.254 1.84848 10C1.84902 7.74602 2.75973 5.71422 4.23594 4.23625C5.71449 2.75973 7.74633 1.84934 10 1.84879C12.2537 1.84934 14.2855 2.75973 15.7641 4.23625C17.2403 5.71422 18.1509 7.74605 18.1515 10C18.1509 12.254 17.2403 14.2858 15.7641 15.7638Z"
        fill="white"
      />
      <path
        d="M9.83199 7.59172C10.6672 7.59172 11.345 6.91426 11.345 6.07906C11.345 5.24328 10.6672 4.56641 9.83199 4.56641C8.99684 4.56641 8.31934 5.24328 8.31934 6.07906C8.31934 6.9143 8.99684 7.59172 9.83199 7.59172Z"
        fill="white"
      />
      <path
        d="M11.6862 14.1715H11.4807C11.2702 14.1715 11.0994 14.0013 11.0994 13.7908V8.46546C11.0994 8.39554 11.0626 8.33023 11.0029 8.29437C10.9431 8.25792 10.8685 8.2562 10.8067 8.28882L8.2073 9.73359C7.45113 10.1117 7.81402 10.5632 8.09566 10.5516C8.37698 10.5405 9.21593 10.4574 9.21593 10.4574V13.7909C9.21593 14.0013 9.04511 14.1716 8.83464 14.1716H8.43089C8.32597 14.1716 8.24027 14.2573 8.24027 14.3622V15.1936C8.24027 15.2985 8.32597 15.3843 8.43089 15.3843H11.6862C11.7914 15.3843 11.8769 15.2985 11.8769 15.1936V14.3622C11.8769 14.2572 11.7914 14.1715 11.6862 14.1715Z"
        fill="white"
      />
    </svg>
  )
}

export default function InformationIcon(
  props: Partial<CustomIconComponentProps>
) {
  return <Icon component={InformationSvg} {...props} />
}
