import { useEffect } from 'react'
import { Form, FormInstance, Input } from 'antd'
import Validators from 'utils/validators'

type Props = {
  t: any
  form: FormInstance<any>
  memo: string | undefined
  onFormFocus: () => void
  onFormBlur: () => void
}

export default function MemoSetting({
  t,
  form,
  memo,
  onFormFocus,
  onFormBlur
}: Props) {
  useEffect(() => {
    form.setFieldValue('memo', memo)
  }, [form, memo])

  return (
    <div className="pt-[30px] px-[90px] h-[350px]">
      <Form
        form={form}
        preserve={false}
        onFocus={onFormFocus}
        onBlur={onFormBlur}
      >
        <Form.Item
          name="memo"
          className="thin-light-placeholder"
          rules={[
            {
              validator: Validators.memoValidator(t)
            }
          ]}
        >
          <Input.TextArea
            placeholder={t('common.floor.placeHolder')}
            autoSize={{ minRows: 6, maxRows: 11 }}
            className="text-sm text-[#021120]"
          />
        </Form.Item>
      </Form>
    </div>
  )
}
