import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormInstance, Input, Select, Typography } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import Const from 'constants/constant'
import useAuth from 'hooks/useAuth'
import { ListOrganizations } from 'models/Organization'
import { Role } from 'models/User'
import useSWR from 'swr'
import { OrderByType } from 'types'
import { ParamQueryOrganization } from 'types/listOrganization'
import { SelectOrganization } from 'types/listUser'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'
import DropdownIconCustom from 'components/icons/DropdownIconCustom'

import ModalOrganization from './ModalOrganization'

type Props = {
  form: FormInstance
  handleOk: (value: any) => void
  handleCancel: () => void
}
export default function InviteUser({ form, handleOk, handleCancel }: Props) {
  const stopPropagation = useRef<boolean>(false)
  const { profile } = useAuth()
  const { t } = useTranslation()
  const listRole = profile ? Const.ROLES[profile.role] : []
  const role = useWatch('role', form)
  const email = useWatch('email', form)
  const displayButton =
    ((profile?.role === Role.BA || profile?.role === Role.SA) &&
      role === Role.UA) ||
    role === Role.USER
  const disabledButton = !role

  const [queries, setQueries] = useState<ParamQueryOrganization | null>({
    sort: 'id',
    sortOrder: OrderByType.descend
  })
  const [previousSelect, setPreviousSelect] = useState<SelectOrganization>({
    id: 0,
    name: '',
    domain: ''
  })
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [organization, setOrganization] = useState<
    SelectOrganization | undefined
  >({
    id: 0,
    name: '',
    domain: ''
  })
  const disabledSubmit =
    role === 'USER' ? role && email : role && email && !!organization?.id

  const domain = useMemo(
    () =>
      role === Role.SA || role === Role.BA
        ? profile?.organization?.emailDomain
        : organization?.domain,
    [role, organization?.domain, profile]
  )

  const { data: organizations } = useSWR<ListOrganizations>({
    url: `/v1/organizations`,
    params: { ...queries, include_my_org: profile?.role === Role.BA }
  })

  const changeRole = (value: string) => {
    switch (value) {
      case 'UA':
        if (profile?.role === Role.UA) {
          profile.organization &&
            setOrganization({
              id: profile.organization.id,
              name: profile.organization.name,
              domain: profile.organization.emailDomain
            })
          return
        }
        organizations?.edges && organizations?.edges.length > 1
          ? setOrganization({
              id: organizations.edges[0].id,
              name: organizations.edges[0].name,
              domain: organizations.edges[0].emailDomain
            })
          : setOrganization(undefined)
        break
      case 'USER':
        if (profile?.role === Role.UA) {
          profile.organization &&
            setOrganization({
              id: profile.organization.id,
              name: profile.organization.name,
              domain: profile.organization.emailDomain
            })
          return
        }
        setOrganization(Const.OTHER_ORG_DEFAULT)
        break
      default:
        if (profile?.role === Role.SA) {
          profile.organization &&
            setOrganization({
              id: profile.organization.id,
              name: profile.organization.name,
              domain: profile.organization.emailDomain
            })
        }
        break
    }
  }

  const openSelectOrganization = () => {
    setOpenModal(true)
  }

  const closeModalOrganization = () => {
    setOpenModal(false)
    setOrganization(previousSelect)
  }

  const handleSelectOrganization = (value: SelectOrganization) => {
    setOpenModal(false)
    setOrganization(value)
    form.setFieldValue('organizationId', value?.id)
    form.validateFields(['email'])
  }

  const onFinish = (value: any) => {
    const data = {
      ...value,
      organizationId: organization?.id
    }
    if (organization?.id === 0) {
      data.organizationId = null
    }
    handleOk(data)
  }

  const handleEnterSubmit = () => {
    if (stopPropagation.current || openModal || !disabledSubmit) return
    form
      .validateFields()
      .then(() => onFinish(form.getFieldsValue()))
      .catch(() => {})
  }

  return (
    <div>
      {openModal && (
        <ModalOrganization
          previousSelect={previousSelect}
          setPreviousSelect={setPreviousSelect}
          queries={queries}
          setQueries={setQueries}
          handleCancel={closeModalOrganization}
          handleOk={handleSelectOrganization}
          roleUser={role}
          setOrganization={setOrganization}
        />
      )}

      <Modal
        onCancel={handleCancel}
        className="modal-invite-user"
        rootClassName=""
        bodyStyle={{ width: '100%' }}
        isEnterable
        onEnter={handleEnterSubmit}
        zIndex={999}
      >
        <Title title="user.titleInvite" />
        <Form
          form={form}
          layout="horizontal"
          preserve={false}
          labelCol={{ span: 7 }}
          onFinish={onFinish}
          colon={false}
          className="flex flex-col w-full text-[14px] gap-[25px] text-[#021120]"
          onFocus={() => {
            stopPropagation.current = true
          }}
          onBlur={() => {
            stopPropagation.current = false
          }}
        >
          <Form.Item
            label={t('user.role')}
            name="role"
            className="input-invite-user mb-0"
          >
            <Select
              className="!w-[300px]"
              onChange={changeRole}
              placeholder="選択してください"
              suffixIcon={<DropdownIconCustom colorIcon="#021120" />}
            >
              {listRole.map((role) => {
                return (
                  <Select.Option key={role}>
                    <span className="text-[#021120]">{t(`user.${role}`)}</span>
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('user.organization')}
            name="organizationId"
            className="input-invite-user mb-0 "
          >
            <div className="grid grid-cols-[1fr_auto] gap-4 items-center truncate w-[300px]">
              <Typography.Text
                ellipsis={{
                  tooltip: organization?.name
                }}
                className="text-sm font-medium text-[#021120] truncate "
              >
                {organization?.name}
              </Typography.Text>
              {displayButton && profile?.role !== Role.UA && (
                <Button
                  type="primary"
                  sizing="w-[100px] h-10"
                  onClick={openSelectOrganization}
                  disabled={disabledButton}
                >
                  {t('user.buttonSelectOrganization')}
                </Button>
              )}
            </div>
          </Form.Item>
          <Form.Item
            label={t('user.email')}
            name="email"
            dependencies={['role']}
            rules={[
              {
                validator: Validators.emailValidatorDomain(t, domain)
              }
            ]}
            className="input-invite-user mb-0 thin-error-message"
          >
            <Input
              className="h-10 text-sm !w-[300px]"
              placeholder="入力してください"
            />
          </Form.Item>
          <div className="flex gap-10 justify-center mt-3 w-full">
            <Button sizing="w-[140px] h-[50px]" onClick={handleCancel}>
              {t('organization.buttonCancel')}
            </Button>
            <Button
              type="primary"
              sizing="w-[140px] h-[50px]"
              htmlType="submit"
              disabled={!disabledSubmit}
            >
              {t('organization.buttonSave')}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  )
}
