export default function HeaderTransparent() {
  return (
    <div className="flex items-center gap-7 h-14 px-7 pt-5 bg-[#F9FBFF]">
      <img
        src="/logo/filled_header.png"
        alt="logo"
        className="w-[207px] h-[38px]"
      />
    </div>
  )
}
