import { AnnotationType } from 'types/building'

import AirConditionIcon from 'components/icons/AirConditionIcon'
import DocumentIcon from 'components/icons/DocumentIcon'
import HouseIcon from 'components/icons/HouseIcon'
import LightBulbIcon from 'components/icons/LightBulbIcon'

type Props = {
  type: AnnotationType
  size: 'small' | 'large'
}

export default function FolderIcon({ size, type }: Props) {
  switch (type) {
    case AnnotationType.ELECTRICITY:
      return <LightBulbIcon size={size} />
    case AnnotationType.AIR_CONDITION:
      return <AirConditionIcon size={size} />
    case AnnotationType.ARCHITECTURE:
      return <HouseIcon size={size} />
    default:
      return <DocumentIcon size={size} />
  }
}
