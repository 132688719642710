import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AutoComplete, Typography } from 'antd'
import TableColumn from 'constants/tableColumn'
import useDebounce from 'hooks/useDebounce'
import { ListOrganizations } from 'models/Organization'
import useSWR from 'swr'
import { UserDetailManageOrganization } from 'types/userDetail'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import ExtraTable from 'components/organisms/ExtraTable'

export default function ManageOrganizationModal({
  manageModal,
  manageOrganizations,
  setOrganizationModal,
  handleCloseModal
}: UserDetailManageOrganization) {
  const { t } = useTranslation()
  const [autoComplete, setAutoComplete] = useState({
    id: 0,
    search: ''
  })
  const [userOrganization, setUserOrganization] = useState<any[]>([])

  const debounceSearch = useDebounce(autoComplete.search, 500)

  const { data } = useSWR<ListOrganizations>({
    url: '/v1/organizations',
    params: {
      keyword: debounceSearch.trim()
    }
  })

  const options = useMemo(
    () =>
      data?.edges
        ? data.edges
            .filter(
              (item) =>
                !userOrganization.find((org) => org.id === item.id) &&
                item.id !== 1
            )
            .map((item) => ({
              key: item.id,
              label: <span className="text-sm font-medium">{item.name}</span>,
              value: item.id
            }))
        : [],
    [data, userOrganization]
  )

  const handleSearch = (value: string) => {
    setAutoComplete((prev) => ({
      ...prev,
      search: value
    }))
  }

  const handleSelect = (value: any) => {
    const organization = data?.edges.find((item) => item.id === value)
    organization &&
      setAutoComplete({
        search: organization.name,
        id: organization.id
      })
  }

  const sortName = (a: string, b: string): number => {
    if (a < b) {
      return -1 // a.name should come before b.name
    }

    if (a > b) {
      return 1 // b.name should come before a.name
    }

    return 0
  }

  const handleAddOrganization = () => {
    const newList = [
      ...userOrganization,
      data?.edges.find((item) => item.id === autoComplete.id)
    ]
    setOrganizationModal((prev) => ({
      ...prev,
      organizations: [...prev.organizations, autoComplete.id]
    }))
    setUserOrganization(newList)
    setAutoComplete({
      id: 0,
      search: ''
    })
  }

  const onClose = () => {
    handleCloseModal()
    setAutoComplete({
      id: 0,
      search: ''
    })
  }

  const handleChange = (sorter: any) => {
    const newList = userOrganization.slice()
    if (sorter) {
      newList.sort((a, b) =>
        sorter === 'descend'
          ? sortName(a.name, b.name)
          : sortName(b.name, a.name)
      )
      setUserOrganization(newList)
    } else {
      setUserOrganization(manageOrganizations || [])
    }
  }

  useEffect(() => {
    manageOrganizations && setUserOrganization(manageOrganizations)
  }, [manageOrganizations])

  return (
    <Modal open={manageModal.isOpen} rootClassName="" width={840}>
      <div className="py-7 grid gap-10 justify-center">
        <div>
          <Typography className="text-xl font-bold text-center text-[#3163B7]">
            {t('user.organizationManagement')}
          </Typography>
          <div className="block mx-auto mt-4 w-[72px] border-0 border-b border-[#3163B7] border-solid" />
        </div>

        <div className="flex justify-between items-center">
          <div className="flex gap-5 items-center">
            <Typography className="text-sm font-medium">
              {t('user.organization')}
            </Typography>

            <AutoComplete
              options={options}
              onSelect={handleSelect}
              onSearch={handleSearch}
              style={{
                width: 384
              }}
              value={autoComplete.search}
            />
          </div>

          <Button
            type="primary"
            sizing="w-40 h-10"
            onClick={handleAddOrganization}
            disabled={!autoComplete.id}
          >
            {t('user.setting')}
          </Button>
        </div>

        <div className="table-list-org-invite sm-text-table">
          <ExtraTable
            columns={TableColumn.COLUMN_USER_MANAGE_ORGANIZATION}
            isDetail={false}
            onChange={handleChange}
            data={userOrganization}
            scroll={{ y: 300 }}
          />
        </div>

        <div className="w-full flex justify-center">
          <Button sizing="w-36 h-12" onClick={onClose}>
            {t('user.close')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
