import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function AreaNoticeSvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_285_12174)">
        <path
          d="M11.1447 13.4408C10.3565 14.1812 10.0765 14.2406 10.0087 14.2409C9.98677 14.1984 9.91376 13.9765 10.1413 13.121C10.3063 12.5004 10.5636 11.779 10.7906 11.1425C10.9502 10.6947 11.0881 10.308 11.1624 10.0432C11.3532 9.36187 11.3329 8.88176 11.1006 8.57543C10.9927 8.4332 10.7848 8.26367 10.4064 8.26367C9.57563 8.26367 8.39684 9.10383 8.01454 9.79164C7.78466 10.2052 7.85899 10.4891 7.96181 10.6545L8.12309 10.914L8.34165 10.7004C8.9804 10.0762 9.25204 9.94805 9.34302 9.92203C9.42282 10.2393 9.00341 11.4858 8.77341 12.1693C8.64118 12.5623 8.5163 12.9334 8.43552 13.2272C8.14524 14.2833 8.14833 14.9859 8.44493 15.375C8.60333 15.5828 8.84091 15.6926 9.13204 15.6926C10.0612 15.6926 11.3222 14.5997 11.8213 14.1299L12.0078 13.9543L11.3158 13.2801L11.1447 13.4408Z"
          fill="#3B3C46"
        />
        <path
          d="M10.7652 7.05195C11.5228 7.05195 12.1369 6.43783 12.1369 5.68027C12.1369 4.92272 11.5228 4.30859 10.7652 4.30859C10.0077 4.30859 9.39355 4.92272 9.39355 5.68027C9.39355 6.43783 10.0077 7.05195 10.7652 7.05195Z"
          fill="#3B3C46"
        />
        <path
          d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0ZM17.5 10C17.5 14.1355 14.1355 17.5 10 17.5C5.86449 17.5 2.5 14.1355 2.5 10C2.5 5.86449 5.86449 2.5 10 2.5C14.1355 2.5 17.5 5.86449 17.5 10Z"
          fill="#3B3C46"
        />
      </g>
      <defs>
        <clipPath id="clip0_285_12174">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default function AreaNoticeIcon(
  props: Partial<CustomIconComponentProps>
) {
  return <Icon component={AreaNoticeSvg} {...props} />
}
