type Props = {
  size?: 'large' | 'small'
}
export default function LightBulbIcon({ size }: Props) {
  if (size === 'small') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28}>
        <path
          d="M14.938.777h-1.876a.637.637 0 0 0-.644.63V5.44h-.73a.637.637 0 0 0-.645.63v1.593c0 1.305-1.523 2.027-2.453 2.469-.125.062-.242.117-.34.164-2.336 1.187-4.8 3.578-5.137 6.598-.039.37.266.69.649.69h20.48c.38 0 .684-.32.645-.69-.332-3.02-2.801-5.41-5.137-6.598-.098-.047-.21-.102-.336-.16-.934-.446-2.457-1.168-2.457-2.473V6.07a.637.637 0 0 0-.645-.629h-.726V1.406a.638.638 0 0 0-.649-.629Zm-.567 23.375h-.738a.639.639 0 0 0-.649.633v2.586c0 .348.29.629.649.629h.738a.64.64 0 0 0 .649-.629v-2.586a.641.641 0 0 0-.649-.633ZM9.68 22.297l.597.422c.29.207.352.597.145.883l-1.567 2.09a.658.658 0 0 1-.902.14l-.598-.422a.618.618 0 0 1-.144-.879l1.562-2.093a.663.663 0 0 1 .907-.141Zm11.11 2.23-1.563-2.093a.658.658 0 0 0-.903-.137l-.601.422a.617.617 0 0 0-.141.879l1.563 2.093c.21.282.613.344.902.141l.601-.426a.617.617 0 0 0 .141-.879ZM9.171 18.63c.433 2.21 2.43 3.883 4.828 3.883s4.398-1.672 4.828-3.883Zm0 0"
          style={{
            stroke: 'none',
            fillRule: 'evenodd',
            fill: '#f1ae00',
            fillOpacity: 1
          }}
        />
      </svg>
    )
  }

  return (
    <svg
      width="28"
      height="36"
      viewBox="0 0 28 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2057 0.5H12.7959C12.3366 0.5 11.9636 0.862877 11.9636 1.30957V6.49609H11.0284C10.5692 6.49609 10.1961 6.85802 10.1961 7.30566V9.35434C10.1961 11.0292 8.24112 11.9596 7.04193 12.5303C6.88134 12.6068 6.73431 12.6767 6.60734 12.7412H6.60636C3.60612 14.2641 0.432552 17.3386 0.00464546 21.2226C-0.0472517 21.6979 0.342467 22.1103 0.834021 22.1103H15.9586H27.1665C27.658 22.1103 28.0487 21.6979 27.9958 21.2226C27.5679 17.3386 24.3944 14.2641 21.3931 12.7412C21.2664 12.6769 21.1197 12.607 20.9594 12.5307L20.9594 12.5307C19.7602 11.9598 17.8044 11.0288 17.8044 9.35434V7.30566C17.8044 6.85897 17.4323 6.49609 16.9721 6.49609H16.0399V1.30957C16.0399 0.862877 15.6669 0.5 15.2076 0.5H15.2057ZM14.4762 30.5547H13.5254C13.0657 30.5547 12.6931 30.9171 12.6931 31.3643V34.6902C12.6931 35.1373 13.0657 35.4997 13.5254 35.4997H14.4762C14.9359 35.4997 15.3085 35.1373 15.3085 34.6902V31.3643C15.3085 30.9171 14.9359 30.5547 14.4762 30.5547ZM8.4443 28.1681L9.21351 28.7116C9.58539 28.9745 9.66783 29.4807 9.39764 29.8425L7.38781 32.5332C7.11762 32.8949 6.59711 32.9751 6.22523 32.7123L5.45602 32.1687C5.08414 31.9059 5.0017 31.3996 5.27189 31.0379L7.28172 28.3472C7.55191 27.9854 8.07241 27.9053 8.4443 28.1681ZM22.7299 31.0365L20.7201 28.3457C20.4499 27.984 19.9294 27.9038 19.5575 28.1666L18.7883 28.7102C18.4164 28.973 18.334 29.4793 18.6042 29.841L20.614 32.5317C20.8842 32.8935 21.4047 32.9737 21.7766 32.7108L22.5458 32.1673C22.9177 31.9045 23.0001 31.3982 22.7299 31.0365ZM7.7942 23.4492C8.34745 26.2932 10.9149 28.4447 14.0003 28.4447H14.0013C17.0858 28.4447 19.6532 26.2932 20.2074 23.4492H7.7942Z"
        fill="#F1AE00"
      />
    </svg>
  )
}

LightBulbIcon.defaultProps = {
  size: 'large'
}
