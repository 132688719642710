import { atom } from 'recoil'
import { Point } from 'types/building'

type Props = {
  selectedFloor?: number
  selectedPoint?: string
  isOpenLeftMenu: boolean
  isOpenMiniMap: boolean
  selectedArea?: string
  pointDetail?: Point
}

const currentViewerState = atom<Props>({
  key: 'currentViewer',
  default: {
    selectedFloor: undefined,
    selectedPoint: undefined,
    selectedArea: undefined,
    isOpenLeftMenu: true,
    isOpenMiniMap: false,
    pointDetail: undefined
  }
})

export default currentViewerState
