import { NavigateFunction } from 'react-router-dom'
import { FormInstance, notification } from 'antd'
import ErrorCodes from 'constants/error'
import Encoding from 'encoding-japanese'
import { Organization, Role } from 'models/User'
import { ErrorCode, I18nT, ValidationErrorCodes } from 'types'
import { Point } from 'types/building'

const Utils = class Utils {
  static handleError = (
    error: any,
    t: I18nT,
    form?: FormInstance,
    errorMessage?: string
  ) => {
    const errorCode = error?.response?.data?.error?.key
    if (!errorCode) {
      errorMessage && notification.error({ message: t(errorMessage) })
      return
    }

    if (!form) {
      const message = ErrorCodes.API_ERROR_CODES[errorCode as ErrorCode]
      message && notification.error({ message: t(message) })
      return
    }

    const errorFields =
      ErrorCodes.VALIDATION_ERROR_CODES[errorCode as ValidationErrorCodes]

    if (errorFields) {
      form.setFields(
        errorFields.map((item) => ({
          name: item,
          errors: [t(`common.validationError.${errorCode}`)]
        }))
      )
      form.scrollToField(errorFields[0])
      return
    }

    const message = ErrorCodes.API_ERROR_CODES[errorCode as ErrorCode]
    message && notification.error({ message: t(message) })
  }

  static setCookie(cname: string, cvalue: string) {
    document.cookie = `${cname}=${cvalue};`
  }

  static getCookie(cname: string) {
    const name = `${cname}=`
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }

  static deleteCookie(name: string) {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
  }

  static validateFile = (file: File, t: I18nT) => {
    return new Promise<void>((resolve, reject) => {
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png']
      if (!allowedTypes.includes(file.type)) {
        const error = t('common.validationError.fileNotAllowed')
        setTimeout(() => reject(new Error(error)))
        return
      }

      const fileSizeInMB = file.size / (1024 * 1024)
      if (fileSizeInMB > 50) {
        const error = t('common.validationError.fileTooLarge')
        setTimeout(() => reject(new Error(error)))
        return
      }

      resolve()
    })
  }

  static buildingFormDataConverted = (values: any) => {
    return {
      ...values,
      organizationId: values?.organizationId,
      constructionPeriodStart:
        values.constructionPeriodStart &&
        values.constructionPeriodStart.format('YYYY-MM-DDTHH:mm:ssZ'),
      constructionPeriodEnd:
        values.constructionPeriodEnd &&
        values.constructionPeriodEnd.format('YYYY-MM-DDTHH:mm:ssZ'),
      siteArea: Number(values.siteArea || 0),
      buildingArea: Number(values.buildingArea || 0),
      totalFloorArea: Number(values.totalFloorArea || 0)
    }
  }

  static trimObjectValues = (object: any) =>
    Object.keys(object).reduce((acc, curr) => {
      acc[curr] =
        typeof object[curr] === 'string'
          ? object[curr].trim() || undefined
          : object[curr] ?? undefined
      return acc
    }, {} as any)

  static validRoleChecking = (
    role: string,
    organizationId: number | null,
    manageOrganization?: Organization,
    listManageOrganization?: Organization[]
  ) => {
    if (role === Role.SA) {
      return true
    }
    if (role === Role.UA && manageOrganization?.id === organizationId) {
      return true
    }
    if (
      role === Role.BA &&
      (listManageOrganization?.find((item) => item.id === organizationId) ||
        !organizationId)
    ) {
      return true
    }
    return false
  }

  static inviteMessageError = (
    notExistedError: boolean,
    invitedError: boolean
  ) => {
    if (notExistedError && invitedError) {
      return '既に招待済みのユーザーです, 未登録ユーザーです'
    }
    if (notExistedError && !invitedError) {
      return '既に招待済みのユーザーです'
    }
    if (!notExistedError && invitedError) {
      return '未登録ユーザーです'
    }
    return ''
  }

  static filterInvalidEmailInvite = (
    inviteList: any[] | undefined,
    notExistList: any[],
    invitedList: any[]
  ) => {
    return inviteList?.filter(
      (item) =>
        notExistList?.find((subitem: any) => subitem === item) ||
        invitedList?.find((subitem: any) => subitem === item)
    )
  }

  static getPointColor = (
    point: Point,
    isInArea?: boolean,
    isSelected?: boolean
  ) => {
    if (isSelected) {
      return 'bg-[#609DF9]'
    }
    if (isInArea) {
      return 'bg-[#33AA11]'
    }
    return 'bg-[#171e2f]'
  }

  static getMeta = (url: any) =>
    new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => resolve(img)
      img.onerror = (err) => reject(err)
      img.src = url
    })

  static truncateString = (str: string, length: number): string => {
    if (str.length <= length) {
      return str
    }
    return `${str.slice(0, length)}...`
  }

  static getFileMagicNumber = (file: File, validMagicNumber: string[]) => {
    return new Promise((resolve, reject) => {
      const filreader = new FileReader()
      filreader.onloadend = () => {
        const arr = new Uint8Array(filreader.result as ArrayBuffer).subarray(
          0,
          4
        )
        let header = ''
        for (let i = 0; i < arr.length; i += 1) {
          header += arr[i].toString(16)
        }
        if (validMagicNumber.find((item) => header.startsWith(item))) {
          resolve(true)
        } else {
          reject()
        }
      }

      filreader.readAsArrayBuffer(file)
    })
  }

  static handleErrorNavigate = (
    error: any,
    navigate: NavigateFunction
  ): void => {
    const key = error?.response?.data?.error?.key
    switch (key) {
      case 'record_not_found':
        navigate('/buildings')
        break
      case 'permission_denied':
        navigate('/not-found')
        break
      default:
        break
    }
  }

  static handleExportCSV = (
    data: any[],
    fileHeaders: string[],
    columnHeaders: string[],
    fileName: string
  ) => {
    // Check if JSON data is empty
    if (!data.length) {
      return ''
    }

    // Create headers string
    const headers = `${columnHeaders.join(',')}\n`

    // Map JSON data to CSV rows
    const rows = data
      .map((item) => {
        // Map each row to CSV format
        return fileHeaders
          .map(
            (field) =>
              `"${`${item[field] || ''}`.replaceAll(/"/g, '""')}"` || ''
          )
          .join(',')
      })
      .join('\n')
    const utf8Array = new TextEncoder().encode(headers + rows)
    const encodingCsvShiftJis = Encoding.convert(utf8Array, 'SJIS', 'UTF8')

    // Check if CSV data is empty
    if (encodingCsvShiftJis) {
      // Create CSV file and initiate download
      const blob = new Blob([new Uint8Array(encodingCsvShiftJis)], {
        type: 'text/csv;charset=Shift_JIS;'
      })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', fileName)
      // link.setAttribute('charset', 'Shift_JIS')
      link.click()
    }
  }
}

export default Utils
