import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function MoreIconSVG() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.8" cx="10" cy="10" r="9" fill="#787B82" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 8.75C5.69036 8.75 6.25 9.30967 6.25 10C6.25 10.6903 5.69036 11.25 5 11.25C4.30964 11.25 3.75 10.6903 3.75 10C3.75 9.30967 4.30964 8.75 5 8.75ZM10 8.75C10.6903 8.75 11.25 9.30967 11.25 10C11.25 10.6903 10.6903 11.25 10 11.25C9.30967 11.25 8.75 10.6903 8.75 10C8.75 9.30967 9.30967 8.75 10 8.75ZM15 8.75C15.6903 8.75 16.25 9.30967 16.25 10C16.25 10.6903 15.6903 11.25 15 11.25C14.3097 11.25 13.75 10.6903 13.75 10C13.75 9.30967 14.3097 8.75 15 8.75Z"
        fill="white"
      />
    </svg>
  )
}

export default function MoreIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={MoreIconSVG} {...props} />
}
