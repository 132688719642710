import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

type Props = {
  title: string
  height?: number
  className?: string
  bottomMargin?: string
}

export default function Title({
  title,
  height,
  className,
  bottomMargin
}: Props) {
  const { t } = useTranslation()

  return (
    <div className={clsx('flex justify-center flex-col', bottomMargin)}>
      <p
        className={clsx(
          `text-[1.25rem] leading-[${height}px] text-center font-bold text-primary mb-0 h-[30px]`,
          className
        )}
      >
        {t(`${title}`)}
      </p>
      <hr className="w-20 border-primary border-0 border-b border-solid" />
    </div>
  )
}

Title.defaultProps = {
  height: 28.96,
  className: undefined,
  bottomMargin: 'mb-6'
}
