import { Dispatch, SetStateAction, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { notification } from 'antd'
import L, { LatLngTuple } from 'leaflet'
import {
  rotatedMarkerIcon,
  rotatedMarkerIconDense
} from 'pages/building/floor/area/CustomMarker'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  buildingFilesState,
  buildingModeState,
  floorDetailState,
  listChangedPointsState,
  listLinkState,
  listPointState,
  revalidateFileListState,
  revalidatePointDetailState,
  selectedAreaState,
  selectedPoint,
  selectedPointDetail
} from 'store/buildingStore'
import { currentPovState } from 'store/krpanoStore'
import useSWR from 'swr'
import { Point } from 'types/building'
import Utils from 'utils'
import Krpano from 'utils/krpano'

import useMutation from './useMutation'

export default function useEventHandler() {
  const { id: buildingId, floorId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const buildingMode = useRecoilValue(buildingModeState)
  const [selectedArea, setSelectedArea] = useRecoilState(selectedAreaState)
  const [buildingFiles, setBuildingFiles] = useRecoilState(buildingFilesState)
  const [currentSelectedPoint, setCurrentSelectedPoint] =
    useRecoilState(selectedPoint)
  const [, setRevalidatePointDetail] = useRecoilState(
    revalidatePointDetailState
  )
  const [pointDetail, setPointDetail] = useRecoilState(selectedPointDetail)
  const [points, setPoints] = useRecoilState(listPointState)
  const [links, setLinks] = useRecoilState(listLinkState)
  const [listChangedPoints, setListChangedPoints] = useRecoilState(
    listChangedPointsState
  )
  const [currentPov, setCurrentPov] = useRecoilState(currentPovState)
  const [, setFloorDetail] = useRecoilState(floorDetailState)
  const [, setRevalidateFileList] = useRecoilState(revalidateFileListState)

  const { mutate: refetchFloor } = useSWR(
    {
      url: `v1/floors/${floorId}`
    },
    {
      revalidateOnMount: false
    }
  )

  const { mutate: refetchPoints, isValidating: isFetchingPoint } = useSWR(
    {
      url: 'v1/points',
      params: {
        buildingId,
        floorId
      }
    },
    {
      revalidateOnMount: false
    }
  )

  const { trigger: syncChangedPoint, isMutating: isSyncChangedPoint } =
    useMutation('v1/points')

  // react leaflet components ref
  const mapContainerRef = useRef<any>(null)
  const polylineRef = useRef<any>([])
  const createPolylineRef = useRef<any>()
  const initPositionRef = useRef<any>(null)
  const povMarkerRef = useRef<any>(null)
  // radar dragging mode flag
  const radarDragFlag = useRef<any>({
    isRadarMode: false,
    isDragging: false,
    rotate: null,
    hgap: null,
    mouseHoldTime: null
  })
  const debouncedRef = useRef<any>(null)
  const dragMarkerRef = useRef<boolean>(false)

  const { trigger: handlePoint, isMutating } = useMutation('v1/points')

  const initMap = (
    setImgBounds: Dispatch<SetStateAction<LatLngTuple>>,
    img: any
  ) => {
    const height = img.naturalHeight
    const width = img.naturalWidth
    setImgBounds([height, width])
    // calculate and set MinZoom + MaxZoom level
    const initBound = [
      [0, 0],
      [height, width]
    ]
    mapContainerRef.current?.setMinZoom(-999)
    mapContainerRef.current?.fitBounds(initBound, { animate: false })
    mapContainerRef.current?.setMaxBounds([
      [-500, -1500],
      [height + 500, width + 1500]
    ])
    const minZoomLevel = mapContainerRef.current?.getBoundsZoom(initBound)
    mapContainerRef.current?.setView([height / 2, width / 2], minZoomLevel, {
      animate: false
    })
    mapContainerRef.current?.setMinZoom(minZoomLevel)
    mapContainerRef.current?.setMaxZoom(minZoomLevel + 5)
  }

  const mapOnReady = (
    imagePath: string,
    setImgBounds: Dispatch<SetStateAction<LatLngTuple>>,
    setImageLoading: Dispatch<SetStateAction<boolean | undefined>>
  ) => {
    Utils.getMeta(imagePath)
      .then((img) => {
        initMap(setImgBounds, img)
        setImageLoading(false)
      })
      .catch(() => {
        refetchFloor().then((floor: any) => {
          setFloorDetail(floor)
          Utils.getMeta(floor?.mapFile.s3Path)
            .then((img) => {
              initMap(setImgBounds, img)
              setImageLoading(false)
            })
            .catch(() => {
              navigate('/buildings')
            })
        })
      })
  }

  const sync360ViewToArc = (icon: any) => {
    ;(window as any).sync_360viewToArc = (hlookat: any) => {
      const h = Krpano.NormalizeHgap(hlookat)
      if (
        !radarDragFlag.current.isRadarMode &&
        radarDragFlag.current.hgap !== null
      ) {
        radarDragFlag.current.rotate = Krpano.NormalizeHgap(
          h + radarDragFlag.current.hgap
        )
        povMarkerRef.current?.setIcon(icon(radarDragFlag.current.rotate))
      }
      if (radarDragFlag.current.isRadarMode) {
        radarDragFlag.current.hgap = Krpano.NormalizeHgap(
          radarDragFlag.current.rotate - h
        )
      }
    }
  }

  const linkEventHandler = (id: string, l_index: number) => {
    return {
      click() {
        !isMutating &&
          buildingMode?.selectedMode === 'link' &&
          polylineRef.current[l_index] &&
          handlePoint(
            {
              query: ['delete-link'],
              data: {
                building_id: Number(buildingId),
                floor_id: Number(floorId),
                link_id: id
              }
            },
            {
              onSuccess: () => {
                setLinks((prev) => prev.filter((item) => item.id !== id))
                if (currentSelectedPoint && id.includes(currentSelectedPoint)) {
                  setRevalidatePointDetail(currentSelectedPoint)
                }
              },
              onError: (error) => {
                Utils.handleErrorNavigate(error, navigate)
              }
            }
          )
      }
    }
  }

  const handleRedrawLink = (
    changeLink: number[],
    changePos: number[],
    changeLatLng: any
  ) => {
    const newLinks = [...links]
    for (let i = 0; i < changeLink.length; i += 1) {
      if (changePos[i] === 0) {
        const link = {
          ...newLinks[changeLink[i]],
          point1: {
            ...newLinks[changeLink[i]].point1,
            x: changeLatLng[i][0].lng,
            y: changeLatLng[i][0].lat
          }
        }
        newLinks[changeLink[i]] = link
      } else {
        const link = {
          ...newLinks[changeLink[i]],
          point2: {
            ...newLinks[changeLink[i]].point2,
            x: changeLatLng[i][1].lng,
            y: changeLatLng[i][1].lat
          }
        }
        newLinks[changeLink[i]] = link
      }
    }
    return newLinks
  }

  const handleResetMap = (
    changeLink: number[],
    changePos: number[],
    lat: any,
    lng: any
  ) => {
    for (let i = 0; i < changeLink.length; i += 1) {
      const latlng = polylineRef.current[changeLink[i]].ref.getLatLngs()
      if (changePos[i] === 0) {
        polylineRef.current[changeLink[i]].ref.setLatLngs([
          { lat, lng },
          latlng[1]
        ])
      } else {
        polylineRef.current[changeLink[i]].ref.setLatLngs([
          latlng[0],
          { lat, lng }
        ])
      }
    }
    setPoints((prev) => [...prev])
  }

  const markerEventHandler = (point: Point, imgBounds: LatLngTuple) => {
    const { id, x: lng, y: lat } = point
    const hgap = point.initView.radarHGap
    const changeLink: number[] = []
    const changePos: number[] = []
    const changeLatLng: any = []
    return {
      dragstart() {
        if (!dragMarkerRef.current) {
          dragMarkerRef.current = true
        }
        for (let i = 0; i < polylineRef.current.length; i += 1) {
          if (
            polylineRef.current[i].ref &&
            polylineRef.current[i].id.includes(point.id)
          ) {
            changeLink.push(i)
            const latlng: any = polylineRef.current[i].ref.getLatLngs()
            for (let j = 0; j < 2; j += 1) {
              if (latlng[j].lat === lat && latlng[j].lng === lng) {
                changePos.push(j)
                changeLatLng.push(latlng)
              }
            }
          }
        }
        if (debouncedRef.current) {
          clearTimeout(debouncedRef.current)
          debouncedRef.current = null
        }
      },
      drag(event: any) {
        const newlatlng = {
          lat: event.latlng.lat,
          lng: event.latlng.lng
        }
        for (let i = 0; i < changeLink.length; i += 1) {
          changeLatLng[i].splice(changePos[i], 1, newlatlng)
          polylineRef.current[changeLink[i]].ref.setLatLngs(changeLatLng[i])
        }
        if (currentSelectedPoint === id && povMarkerRef.current) {
          povMarkerRef.current?.setLatLng(newlatlng)
        }
      },
      dragend(event: any) {
        if (dragMarkerRef.current) {
          setTimeout(() => {
            dragMarkerRef.current = false
          }, 500)
        }
        /* eslint no-underscore-dangle: 0 */
        const { x, y } = {
          x: event.target._latlng.lng,
          y: event.target._latlng.lat
        }
        if (x <= imgBounds[1] && y <= imgBounds[0] && x >= 0 && y >= 0) {
          // get previous point change index
          const newListPoint = [...listChangedPoints]
          const previousChange = newListPoint.findIndex(
            (item) => item.id === id
          )
          if (previousChange !== -1) {
            newListPoint.splice(previousChange, 1, { id, x, y })
          } else {
            newListPoint.push({ id, x, y })
          }
          setListChangedPoints(newListPoint)
          setPoints((prev) =>
            prev.map((item) => (item.id === id ? { ...item, x, y } : item))
          )
          const changedPointDetail = newListPoint.find(
            (item) => item.id === currentSelectedPoint
          )
          changedPointDetail &&
            setPointDetail(
              (prev) =>
                prev && {
                  ...prev,
                  x: changedPointDetail.x,
                  y: changedPointDetail.y
                }
            )
          setLinks(handleRedrawLink(changeLink, changePos, changeLatLng))
        } else {
          handleResetMap(changeLink, changePos, lat, lng)
          if (povMarkerRef.current && currentSelectedPoint === id) {
            povMarkerRef.current.setLatLng({ lat, lng })
          }
        }
      },
      click() {
        if (dragMarkerRef.current) {
          return
        }
        if (selectedArea && currentSelectedPoint === id) {
          Krpano.LoadChecking(() => {
            setCurrentSelectedPoint(undefined)
            if (povMarkerRef.current) {
              povMarkerRef.current = null
            }
          })
        }
        if (currentSelectedPoint !== id) {
          Krpano.LoadChecking(() => {
            !selectedArea && setSelectedArea(point.areaId)
            setCurrentSelectedPoint(id)
            id !== pointDetail?.id && setPointDetail(undefined)
          })
        }
        if (currentPov) {
          setCurrentPov(null)
        }
      },
      mousedown() {
        if (buildingMode?.selectedMode !== 'link') return
        if (!createPolylineRef.current) {
          mapContainerRef.current.dragging.disable()
          createPolylineRef.current = L.polyline(
            [
              [lat, lng],
              [lat, lng]
            ],
            { color: '#000', weight: 5 }
          )
          initPositionRef.current = { id, hgap, latlng: { lat, lng } }
          createPolylineRef.current.addTo(mapContainerRef.current)
          createPolylineRef.current.on('mouseup', () => {
            mapContainerRef.current.dragging.enable()
            mapContainerRef.current.removeLayer(createPolylineRef.current)
            initPositionRef.current = null
            createPolylineRef.current = null
          })
        }
      },
      mouseup() {
        if (buildingMode?.selectedMode !== 'link') return
        if (createPolylineRef.current) {
          mapContainerRef.current.dragging.enable()
        }
        // check if click at the same point
        if (
          initPositionRef.current?.latlng.lat === lat &&
          initPositionRef.current?.latlng.lng === lng
        ) {
          mapContainerRef.current.removeLayer(createPolylineRef.current)
          initPositionRef.current = null
          createPolylineRef.current = null
          return
        }
        // set the new temp link & remove initpos to prevent drag from map event
        createPolylineRef.current?.setLatLngs([
          initPositionRef.current.latlng,
          { lat, lng }
        ])
        const currentId = initPositionRef.current.id
        const h1 =
          Krpano.GetAngle(
            lat - initPositionRef.current.latlng.lat,
            lng - initPositionRef.current.latlng.lng
          ) -
          2 *
            (initPositionRef.current.hgap > 180
              ? 0
              : initPositionRef.current.hgap)
        const h2 =
          Krpano.GetAngle(
            initPositionRef.current.latlng.lat - lat,
            initPositionRef.current.latlng.lng - lng
          ) -
          2 * (hgap > 180 ? 0 : hgap)
        initPositionRef.current = null
        const existedLink = links.find(
          (item) => item.id.includes(currentId) && item.id.includes(id)
        )
        if (!existedLink) {
          handlePoint(
            {
              query: ['create-link'],
              data: {
                building_id: Number(buildingId),
                floor_id: Number(floorId),
                h1,
                h2,
                point_id1: currentId,
                point_id2: id
              }
            },
            {
              onSuccess: () => {
                const point1 = points.find((item) => item.id === currentId)
                setLinks((prev) => [
                  ...prev,
                  {
                    id:
                      currentId > id
                        ? `${currentId}_${id}`
                        : `${id}_${currentId}`,
                    point1: {
                      id: currentId,
                      x: point1?.x || 0,
                      y: point1?.y || 0
                    },
                    point2: {
                      id,
                      x: lng,
                      y: lat
                    }
                  }
                ])
                mapContainerRef.current.removeLayer(createPolylineRef.current)
                createPolylineRef.current = null
                if (
                  currentId === currentSelectedPoint ||
                  id === currentSelectedPoint
                ) {
                  setRevalidatePointDetail(currentSelectedPoint)
                }
              },
              onError: (error) => {
                mapContainerRef.current.removeLayer(createPolylineRef.current)
                createPolylineRef.current = null
                Utils.handleErrorNavigate(error, navigate)
              }
            }
          )
        } else {
          mapContainerRef.current.removeLayer(createPolylineRef.current)
          createPolylineRef.current = null
        }
      }
    }
  }

  const radarEventHandler = (mode: string) => {
    const isEditable = pointDetail && mode === 'point'

    return {
      click() {
        if (isEditable) {
          const handleClick = () => {
            if (!radarDragFlag.current.isRadarMode) {
              radarDragFlag.current.isRadarMode = true
              mapContainerRef.current.dragging.disable()
              povMarkerRef.current.setIcon(
                rotatedMarkerIconDense(radarDragFlag.current.rotate)
              )
              return
            }
            radarDragFlag.current.isRadarMode = false
            mapContainerRef.current.dragging.enable()
            povMarkerRef.current.setIcon(
              rotatedMarkerIcon(radarDragFlag.current.rotate)
            )
            // clear timeout to stop mousedown event
            radarDragFlag.current.mouseHoldTime &&
              clearTimeout(radarDragFlag.current.mouseHoldTime)
            // update new h gap between image360 and radar
            const { h, v, z } = pointDetail.initView
            !isMutating &&
              handlePoint(
                {
                  method: 'patch',
                  query: [currentSelectedPoint],
                  data: {
                    floor_id: +(floorId || 0),
                    building_id: +(buildingId || 0),
                    init_view: {
                      h,
                      v,
                      z,
                      radar_h_gap: Krpano.NormalizeHgap(
                        radarDragFlag.current.hgap
                      )
                    }
                  }
                },
                {
                  onSuccess: () => {
                    const oldHgap = Krpano.NormalizeHgap(
                      pointDetail.initView.radarHGap
                    )
                    const newHgap = Krpano.NormalizeHgap(
                      radarDragFlag.current.hgap
                    )
                    const newDirections = pointDetail.directions.map(
                      (item) => ({
                        ...item,
                        h:
                          item.h +
                          2 * (oldHgap > 180 ? 0 : oldHgap) -
                          2 * (newHgap > 180 ? 0 : newHgap)
                      })
                    )
                    // adjust point directions saved hlookat value
                    setPointDetail(
                      (prev) =>
                        prev && {
                          ...prev,
                          directions: newDirections,
                          initView: {
                            ...prev.initView,
                            radarHGap: newHgap
                          }
                        }
                    )
                    setPoints((prev) =>
                      prev.map((item) =>
                        item.id === currentSelectedPoint
                          ? {
                              ...item,
                              initView: {
                                ...item.initView,
                                radarHGap: newHgap
                              }
                            }
                          : item
                      )
                    )
                    // adjust point directions display in krpano
                    Krpano.RevalidateDirection(newDirections, newHgap)
                  },
                  onError: (error) => {
                    Utils.handleErrorNavigate(error, navigate)
                  }
                }
              )
          }
          Krpano.LoadChecking(handleClick)
        }
      },
      mousedown() {
        if (radarDragFlag.current.isRadarMode) {
          radarDragFlag.current.mouseHoldTime = setTimeout(() => {
            radarDragFlag.current.isDragging = true
          }, 200)
        }
      },
      mouseup() {
        if (buildingMode.selectedMode === 'link' && createPolylineRef.current) {
          mapContainerRef.current?.removeLayer(createPolylineRef.current)
          createPolylineRef.current = null
          mapContainerRef.current?.dragging.enable()
        }
      }
    }
  }

  const mapEventHandler = () => {
    return {
      mousemove(e: any) {
        if (
          buildingMode.selectedMode === 'link' &&
          initPositionRef.current &&
          createPolylineRef.current
        ) {
          createPolylineRef.current.setLatLngs([
            initPositionRef.current.latlng,
            e.latlng
          ])
        }
        if (
          radarDragFlag.current.isRadarMode &&
          radarDragFlag.current.isDragging &&
          pointDetail
        ) {
          const newHlookat = Krpano.GetAngle(
            e.latlng.lat - pointDetail.y,
            e.latlng.lng - pointDetail.x
          )
          radarDragFlag.current.rotate = newHlookat
          povMarkerRef.current.setIcon(rotatedMarkerIconDense(newHlookat))
        }
      },
      mouseup() {
        if (radarDragFlag.current.isDragging && pointDetail) {
          radarDragFlag.current.isDragging = false
          const hlookat = Krpano.NormalizeHgap(
            (document.getElementById('embedpano-preview') as any)?.get(
              'view.hlookat'
            )
          )
          radarDragFlag.current.hgap = Krpano.NormalizeHgap(
            radarDragFlag.current.rotate - hlookat
          )
        }
      }
    }
  }

  const handleUpdatePointImageByDrag = (pointId: string, image360: number) => {
    !isMutating &&
      handlePoint(
        {
          method: 'patch',
          query: [pointId],
          data: {
            floor_id: +(floorId || 0),
            building_id: +(buildingId || 0),
            image360
          }
        },
        {
          onSuccess: () => {
            const newPointList = points.map((point) =>
              point.id === pointId
                ? {
                    ...point,
                    image360:
                      buildingFiles.find((file) => file.id === image360) || null
                  }
                : point
            )
            const newFileList = buildingFiles.map((file) =>
              file.id === image360 ? { ...file, pointId } : file
            )
            setPoints(newPointList)
            setBuildingFiles(newFileList)
            setRevalidatePointDetail(pointId)
          },
          onError: (err) => {
            if (err.response.data.error.key === 'not_change_image_360') {
              refetchPoints()
              setRevalidateFileList(1)
              notification.error({
                message: t('translation.imageIsUsed')
              })
            } else {
              Utils.handleErrorNavigate(err, navigate)
            }
          }
        }
      )
  }

  useEffect(() => {
    if (
      pointDetail &&
      radarDragFlag.current.isRadarMode &&
      !buildingMode.selectedMode
    ) {
      const hlookat = Krpano.NormalizeHgap(
        (document.getElementById('embedpano-preview') as any)?.get(
          'view.hlookat'
        )
      )
      radarDragFlag.current.isRadarMode = false
      radarDragFlag.current.hgap = pointDetail.initView.radarHGap
      radarDragFlag.current.rotate = hlookat + pointDetail.initView.radarHGap
      mapContainerRef.current?.dragging.enable()
      if (povMarkerRef.current) {
        povMarkerRef.current.setIcon(
          rotatedMarkerIcon(hlookat + pointDetail.initView.radarHGap)
        )
      }
    }
  }, [buildingMode.selectedMode, pointDetail])

  useEffect(() => {
    //  find position & add pov marker into map
    const handleDisplayPov = () => {
      if (!currentSelectedPoint || currentSelectedPoint !== pointDetail?.id) {
        radarDragFlag.current = {
          isRadarMode: false,
          isDragging: false,
          rotate: null,
          hgap: null,
          mouseHoldTime: null
        }
        mapContainerRef.current?.dragging.enable()
        return
      }
      if (pointDetail) {
        const hlookat = (
          document.getElementById('embedpano-preview') as any
        )?.get('view.hlookat')
        const rotate =
          hlookat !== pointDetail.initView.h
            ? Krpano.NormalizeHgap(hlookat)
            : pointDetail.initView.h
        radarDragFlag.current = {
          isRadarMode: false,
          isDragging: false,
          rotate: Krpano.NormalizeHgap(
            radarDragFlag.current.rotate ||
              rotate + pointDetail.initView.radarHGap
          ),
          hgap: pointDetail.initView.radarHGap,
          mouseHoldTime: null
        }
        if (povMarkerRef.current) {
          povMarkerRef.current.setIcon(
            rotatedMarkerIcon(
              Krpano.NormalizeHgap(radarDragFlag.current.rotate)
            )
          )
        }
      }
    }
    handleDisplayPov()
  }, [currentSelectedPoint, pointDetail])

  useEffect(() => {
    if (listChangedPoints.length && !debouncedRef.current) {
      debouncedRef.current = setTimeout(() => {
        syncChangedPoint(
          {
            method: 'patch',
            params: {
              building_id: buildingId,
              floor_id: floorId
            },
            data: {
              changedPoints: listChangedPoints
            }
          },
          {
            onSuccess: () => {
              setListChangedPoints([])
              debouncedRef.current = null
            },
            onError: (error) => {
              Utils.handleErrorNavigate(error, navigate)
            }
          }
        )
      }, 1000)
    }
  }, [
    buildingId,
    floorId,
    listChangedPoints,
    navigate,
    setListChangedPoints,
    syncChangedPoint
  ])

  return {
    buildingId,
    floorId,
    points,
    setPoints,
    links,
    setLinks,
    selectedArea,
    buildingMode,
    currentSelectedPoint,
    createPolylineRef,
    initPositionRef,
    mapContainerRef,
    polylineRef,
    povMarkerRef,
    radarDragFlag,
    handlePoint,
    isMutating,
    pointDetail,
    mapOnReady,
    linkEventHandler,
    markerEventHandler,
    radarEventHandler,
    mapEventHandler,
    sync360ViewToArc,
    handleUpdatePointImageByDrag,
    refetchPoints,
    setRevalidateFileList,
    setBuildingFiles,
    isSyncChangedPoint,
    listChangedPoints,
    isFetchingPoint
  }
}
