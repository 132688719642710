import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Typography } from 'antd'
import Const from 'constants/constant'
import dayjs from 'dayjs'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import { useRecoilState, useRecoilValue } from 'recoil'
import { revalidatePointDetailState, selectedPoint } from 'store/buildingStore'
import { currentOpenMenu, displayToggleState } from 'store/displayToggleStore'
import useSWR from 'swr'
import { PointHistory, PointHistoryList } from 'types/building'

import Spinner from 'components/atoms/Spinner'
import HistoryClockIcon from 'components/icons/HistoryClockIcon'

import ModalConfirmSelectVersion from './ModalConfirmSelectVersion'
import PointVersionHistory from './PointVersionHistory'

export default function PointVersionMenu() {
  const { t } = useTranslation()
  const { id, floorId } = useParams()
  const current = dayjs()
  const weekAgo = dayjs().subtract(7, 'day')

  const [isOpenPointHistory, setIsOpenPointHistory] = useState(false)
  const [pagination, setPagination] = useState({
    page: 1,
    page_size: 100
  })
  const [pointHistory, setPointHistory] = useState<PointHistory[]>([])
  const [selectedVersion, setSelectedVersion] = useState<PointHistory>()

  const currentSelectedPoint = useRecoilValue(selectedPoint)
  const displayToggle = useRecoilValue(displayToggleState)
  const { isOpenRightMenu } = useRecoilValue(currentOpenMenu)

  const [, setRevalidatePointDetail] = useRecoilState(
    revalidatePointDetailState
  )

  const {
    data: pointHistoryList,
    mutate: refetchPointHistoryList,
    isValidating
  } = useSWR<PointHistoryList>(
    currentSelectedPoint && !displayToggle.isLocked
      ? {
          url: `v1/points/${currentSelectedPoint}/histories`,
          params: {
            ...pagination,
            building_id: id,
            floor_id: floorId
          }
        }
      : null,
    {
      onSuccess: (data) => {
        setPointHistory((prev) => [...prev, ...data.edges])
      }
    }
  )

  const handleToggleHistory = () => {
    if (!isValidating && !displayToggle.isLocked) {
      setIsOpenPointHistory((prev) => !prev)
    }
  }

  // handle modal confirm select version button click
  const handleModalButtonClick = (isRefetchPointList?: boolean) => {
    if (isRefetchPointList) {
      setRevalidatePointDetail(currentSelectedPoint)
      setPointHistory([])
      if (pagination.page !== 1) {
        setPagination({ page: 1, page_size: 100 })
      } else {
        refetchPointHistoryList()
      }
    }
    setSelectedVersion(undefined)
  }

  const handleIntersecting = useCallback(() => {
    if (
      !isValidating &&
      pointHistory.length &&
      pointHistoryList?.pagination.total &&
      pointHistoryList.pagination.total > pointHistory.length
    ) {
      setPagination((prev) => ({ ...prev, page: prev.page + 1 }))
    }
  }, [isValidating, pointHistory.length, pointHistoryList?.pagination.total])

  const { ref: observerRef } = useIntersectionObserver({
    onIntersecting: handleIntersecting
  })

  const historyConverted: PointHistory[] = useMemo(() => {
    let timePeriod: string
    return (
      pointHistory.reduce((acc: PointHistory[], item: PointHistory) => {
        // filter duplicate versionId and empty versionId
        if (
          item.versionId &&
          !acc.find((accItem) => accItem.versionId === item.versionId)
        ) {
          // group by time period, 30minute each group
          const time = dayjs(item.editedAt)
          if (timePeriod) {
            if (
              dayjs(timePeriod).subtract(30, 'minute') > time ||
              dayjs(timePeriod) < time
            ) {
              timePeriod = item.editedAt
            }
          } else {
            timePeriod = item.editedAt
          }
          acc.push({
            ...item,
            timePeriod,
            isWithinWeek: weekAgo <= time && time <= current,
            isCurrentYear: time.year() === dayjs().year()
          })
        }
        return acc
      }, []) || []
    )
  }, [current, pointHistory, weekAgo])

  const historyHashmapConverted = useMemo<Map<string, PointHistory[]>>(() => {
    const map = new Map()
    historyConverted.forEach((item, idx) => {
      let key = ''
      if (idx !== 0) {
        if (item.isWithinWeek) {
          key = `${
            dayjs(item.editedAt).day() === dayjs().day()
              ? '今日'
              : t(Const.WEEKDAYS[dayjs(item.editedAt).day()])
          }`
        } else if (item.isCurrentYear) {
          key = `${dayjs(item.editedAt).month() + 1}月`
        } else {
          key = `${dayjs(item.editedAt).year()}年${
            dayjs(item.editedAt).month() + 1
          }月`
        }
      }
      const mapKey = [key, item.timePeriod].join('period:')
      map.set(mapKey, map.get(mapKey) ? [...map.get(mapKey), item] : [item])
    })
    return map
  }, [historyConverted, t])

  useEffect(() => {
    if (displayToggle.isLocked && isOpenPointHistory) {
      setIsOpenPointHistory(false)
      setPointHistory([])
    }
  }, [displayToggle.isLocked, isOpenPointHistory])

  useEffect(() => {
    if (currentSelectedPoint) {
      setPointHistory([])
      setPagination({ page: 1, page_size: 100 })
    }
  }, [currentSelectedPoint])

  useEffect(() => {
    const historyToggler = document.getElementById('version-history-toggler')
    const pointActionsMenuContainer = document.getElementById(
      'point-action-menu-rightest-element'
    )
    if (!historyToggler || !pointActionsMenuContainer) return

    const togglerBound = historyToggler.getBoundingClientRect()
    const containerBound = pointActionsMenuContainer.getBoundingClientRect()

    if (containerBound.right + 5 >= togglerBound.left) {
      historyToggler.style.top = '98px'
      historyToggler.style.left = '-82px'
    }
    if (containerBound.right + 35 < togglerBound.left) {
      historyToggler.style.top = '20px'
      historyToggler.style.left = '-112px'
    }
  }, [isOpenRightMenu])

  return (
    <>
      <div
        id="version-history-toggler"
        className={`absolute z-[510] top-5 -left-28 select-none flex rounded-full bg-[#021120CC] h-[60px] ${
          displayToggle.isLocked ? 'cursor-not-allowed' : 'cursor-pointer'
        }`}
        onClick={handleToggleHistory}
      >
        <div
          className={`m-auto space-y-[6px] text-center mx-5 ${
            displayToggle.isLocked ? 'opacity-60' : ''
          }`}
        >
          <HistoryClockIcon />
          <Typography className="text-white font-bold text-[10px] leading-[10px] whitespace-nowrap">
            {t('building.editPointHistory')}
          </Typography>
        </div>
      </div>

      {isOpenPointHistory && !displayToggle.isLocked && (
        <div className="absolute z-[500] top-24 bottom-20 -left-[340px] w-[320px] select-none bg-[#f0f4f9] grid grid-rows-[auto_1fr] overflow-auto">
          <div className="h-16 px-6 flex items-center text-[22px] leading-7">
            {t('building.restorePointHistory')}
          </div>
          <div className="overflow-auto">
            <div className="min-h-[300px]">
              {Array.from(historyHashmapConverted).map(([key, value]) => (
                <Fragment key={key}>
                  {key.split('period:')[0] && (
                    <div className="h-[35px] px-6 flex items-center text-[11px] leading-4 font-medium text-[#444746]">
                      {key.split('period:')[0]}
                    </div>
                  )}
                  <PointVersionHistory
                    versions={value}
                    setSelectedVersion={setSelectedVersion}
                  />
                </Fragment>
              ))}
            </div>

            {/* infinite scroll observer */}
            <Spinner spinning={isValidating}>
              <div ref={observerRef} className="h-1" />
            </Spinner>
          </div>
        </div>
      )}

      {selectedVersion && (
        <ModalConfirmSelectVersion
          selectedVersion={selectedVersion}
          handleModalButtonClick={handleModalButtonClick}
        />
      )}
    </>
  )
}
