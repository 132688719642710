import { useTranslation } from 'react-i18next'
import { Typography } from 'antd'
import clsx from 'clsx'
import usePoint from 'hooks/usePoint'
import { CurrentUser, Role } from 'models/User'
import MenuSetAnnotation from 'pages/building/panoramas/components/setAnnotation/MenuSetAnnotation'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  buildingModeState,
  listPointState,
  selectedAreaState,
  selectedPoint,
  selectedPointDetail
} from 'store/buildingStore'
import { displayToggleState } from 'store/displayToggleStore'
import useSWR from 'swr'

import AnnotationIcon from 'components/icons/Annotation'
import DirectionIcon from 'components/icons/DirectionIcon'
import LinkIcon from 'components/icons/LinkIcon'
import LockIcon from 'components/icons/LockIcon'
import PointMarkerIcon from 'components/icons/PointMarkerIcon'
import Preview360Icon from 'components/icons/Preview360'
import PreviewMapIcon from 'components/icons/PreviewMapIcon'
import StairIcon from 'components/icons/StairIcon'

import ModalFinishAllEditing from './ModalFinishAllEditing'

export default function PointActionsMenu() {
  const { t } = useTranslation()

  const selectedArea = useRecoilValue(selectedAreaState)
  const currentSelectedPoint = useRecoilValue(selectedPoint)
  const points = useRecoilValue(listPointState)
  const pointDetail = useRecoilValue(selectedPointDetail)
  const displayToggle = useRecoilValue(displayToggleState)
  const [buildingMode, setBuildingMode] = useRecoilState(buildingModeState)

  const { data: profile } = useSWR<CurrentUser | null>('v1/users/me', {
    revalidateOnMount: false
  })

  const { handleToggleLockOrUnLockPoint } = usePoint()

  const isHavingPoint = Boolean(points.length)
  const isHavingImage = Boolean(
    points.find((item) => item.id === currentSelectedPoint)?.image360
  )

  // Check if the point is included in the area of the router.
  // If not included, check if it is inside the preview mode.
  const isActive360 =
    selectedArea === pointDetail?.areaId || buildingMode.previewMode
  const isCheckLockedPoint =
    pointDetail?.isLocked && profile?.id === pointDetail.lockedBy

  const handleActiveMode = (choosingMode: string) => () => {
    if (!isHavingPoint && choosingMode !== 'point') return
    if (buildingMode.selectedMode) {
      // turn off building selected mode
      if (choosingMode === buildingMode.selectedMode) {
        setBuildingMode((prev) => ({ ...prev, selectedMode: '' }))
        return
      }
      if (
        buildingMode.selectedMode === 'point' ||
        buildingMode.selectedMode === 'link'
      ) {
        setBuildingMode((prev) => ({ ...prev, selectedMode: choosingMode }))
        return
      }
      if (choosingMode !== buildingMode.selectedMode) return
    }
    if (buildingMode.previewMode && !isCheckLockedPoint) return
    // turn on building selected mode
    setBuildingMode((prev) => ({ ...prev, selectedMode: choosingMode }))
  }

  const handleChangePreviewMode = () => {
    const krpanoFull = document.getElementById('embedpano-full') as any
    const krpanoPreview = document.getElementById('embedpano-preview') as any
    if (buildingMode.previewMode) {
      const syncViewHlookat = krpanoFull?.get('view.hlookat')
      const syncViewVlookat = krpanoFull?.get('view.vlookat')
      const syncViewFov = krpanoFull?.get('view.fov')
      krpanoPreview?.call(`set(view.hlookat, ${syncViewHlookat})`)
      krpanoPreview?.call(`set(view.vlookat, ${syncViewVlookat})`)
      krpanoPreview?.call(`set(view.fov, ${syncViewFov})`)
    } else {
      const syncViewHlookat = krpanoPreview?.get('view.hlookat')
      const syncViewVlookat = krpanoPreview?.get('view.vlookat')
      const syncViewFov = krpanoPreview?.get('view.fov')
      krpanoFull?.call(`set(view.hlookat, ${syncViewHlookat})`)
      krpanoFull?.call(`set(view.vlookat, ${syncViewVlookat})`)
      krpanoFull?.call(`set(view.fov, ${syncViewFov})`)
    }
    setBuildingMode((prev) => ({ ...prev, previewMode: !prev.previewMode }))
  }

  const handlePreviewMode = () => {
    const isSwitchAble =
      !buildingMode.selectedMode &&
      currentSelectedPoint &&
      isHavingImage &&
      isActive360
    isSwitchAble && handleChangePreviewMode()
  }

  const handleReflectionIcon = () => {
    const krpano = document.getElementById('embedpano-full') as any
    const isVisible = krpano.get('isVisibleHotspot')
    krpano.call(`show_all_hotspot(${isVisible === 'false'});`)
  }

  const isToggleAble =
    displayToggle.lockedBy === profile?.id ||
    profile?.role === Role.SA ||
    !displayToggle.isLocked

  const isPreviewAble =
    !buildingMode.selectedMode &&
    currentSelectedPoint &&
    isHavingImage &&
    isActive360

  const isUnpreviewAble =
    buildingMode.selectedMode ||
    !currentSelectedPoint ||
    !isHavingPoint ||
    !isHavingImage ||
    !isActive360

  if (buildingMode?.selectedMode === 'direction') return null

  return (
    <div
      id="point-action-menu-container"
      className="absolute z-[500] top-5 left-1/2 -translate-x-1/2 select-none"
    >
      <div className="absolute right-[210px] flex gap-6">
        {buildingMode?.previewMode ? (
          <div
            className={`flex rounded-full w-[94px] h-[60px] ${
              isToggleAble ? 'cursor-pointer' : 'cursor-not-allowed'
            } ${
              displayToggle.isLocked &&
              (profile?.id === displayToggle.lockedBy ||
                profile?.role === Role.SA)
                ? 'bg-[#609df9]'
                : 'bg-[#021120CC]'
            }`}
            onClick={() => {
              isToggleAble && handleToggleLockOrUnLockPoint(profile?.id)
            }}
          >
            <div
              className={`w-full my-auto space-y-[6px] ${
                !isToggleAble ? 'opacity-60' : ''
              }`}
            >
              <div className="ml-10">
                <LockIcon isSelfLocked />
              </div>
              <Typography className="text-white font-bold text-[10px] leading-[10px] text-center">
                {pointDetail?.isLocked
                  ? t('building.finishEditing')
                  : t('building.startEditing')}
              </Typography>
            </div>
          </div>
        ) : null}

        <div
          className={clsx(
            'flex rounded-full bg-[#021120CC] w-[60px] h-[60px]',
            {
              'cursor-not-allowed':
                buildingMode.previewMode && buildingMode.selectedMode
            },
            {
              'cursor-pointer':
                buildingMode.previewMode && !buildingMode.selectedMode
            },
            {
              'cursor-pointer': !buildingMode.previewMode && isPreviewAble
            },
            {
              'cursor-not-allowed': !buildingMode.previewMode && isUnpreviewAble
            }
          )}
          onClick={handlePreviewMode}
        >
          {buildingMode?.previewMode ? (
            <div
              className={`m-auto space-y-[6px] text-center ${
                buildingMode.selectedMode ? 'opacity-60' : ''
              }`}
            >
              <PreviewMapIcon />
              <Typography className="text-white font-bold text-[10px] leading-[10px]">
                {t('building.map')}
              </Typography>
            </div>
          ) : (
            <div
              className={`m-auto space-y-[2px] text-center ${
                isUnpreviewAble ? 'opacity-60' : ''
              }`}
            >
              <Preview360Icon />
              <Typography className="text-white font-bold text-[10px] leading-[10px]">
                {t('building.360')}
              </Typography>
            </div>
          )}
        </div>
      </div>

      <div
        className={`flex py-1 justify-center ${
          buildingMode.previewMode ? 'gap-[11px]' : 'gap-10'
        } rounded-[30px] bg-[#021120CC] w-[186px] h-[60px]`}
      >
        {buildingMode?.previewMode ? (
          <>
            <div
              className={clsx(
                'flex flex-col items-center justify-center gap-1 h-full w-10',
                {
                  'cursor-pointer':
                    isCheckLockedPoint &&
                    (!buildingMode.selectedMode ||
                      buildingMode.selectedMode === 'direction')
                },
                {
                  'cursor-not-allowed opacity-60':
                    !isCheckLockedPoint ||
                    (buildingMode.selectedMode &&
                      buildingMode.selectedMode !== 'direction')
                }
              )}
              onClick={handleActiveMode('direction')}
            >
              <DirectionIcon />
              <Typography className="font-bold text-[10px] leading-[10px] text-white">
                {t('building.initDirection')}
              </Typography>
            </div>
            <div
              className={clsx(
                'flex flex-col items-center justify-center gap-1 h-full w-10',
                {
                  'cursor-pointer':
                    isCheckLockedPoint &&
                    (!buildingMode.selectedMode ||
                      buildingMode.selectedMode === 'annotation')
                },
                {
                  'cursor-not-allowed opacity-60':
                    !isCheckLockedPoint ||
                    (buildingMode.selectedMode &&
                      buildingMode.selectedMode !== 'annotation')
                }
              )}
              onClick={handleActiveMode('annotation')}
            >
              <AnnotationIcon
                selected={buildingMode.selectedMode === 'annotation'}
              />
              <Typography className="font-bold text-[10px] leading-[10px] text-white">
                {t('building.annotation')}
              </Typography>
            </div>
            <div
              className={clsx(
                'flex flex-col items-center justify-center gap-1 h-full w-10',
                {
                  'cursor-pointer':
                    isCheckLockedPoint &&
                    (!buildingMode.selectedMode ||
                      buildingMode.selectedMode === 'stairway')
                },
                {
                  'cursor-not-allowed opacity-60':
                    !isCheckLockedPoint ||
                    (buildingMode.selectedMode &&
                      buildingMode.selectedMode !== 'stairway')
                }
              )}
              onClick={handleActiveMode('stairway')}
            >
              <StairIcon selected={buildingMode.selectedMode === 'stairway'} />
              <Typography className="font-bold text-[10px] leading-[10px] text-white">
                {t('building.stairway')}
              </Typography>
            </div>
          </>
        ) : (
          <>
            <div
              className="flex flex-col items-center justify-center gap-1 h-full w-10 cursor-pointer"
              onClick={handleActiveMode('point')}
            >
              <PointMarkerIcon
                className={clsx({
                  'fill-[#609DF9]': buildingMode?.selectedMode === 'point'
                })}
                selected={buildingMode?.selectedMode === 'point'}
              />
              <Typography
                className={`font-bold text-[10px] leading-[10px] ${
                  buildingMode?.selectedMode === 'point'
                    ? 'text-[#609DF9]'
                    : 'text-white'
                }`}
              >
                {t('building.point')}
              </Typography>
            </div>

            <div
              className="flex flex-col items-center justify-center gap-1 h-full w-10 cursor-pointer"
              onClick={handleActiveMode('link')}
            >
              <LinkIcon
                className={clsx({
                  'fill-[#609DF9]': buildingMode?.selectedMode === 'link'
                })}
                selected={buildingMode?.selectedMode === 'link'}
              />
              <Typography
                className={`font-bold text-[10px] leading-[10px] ${
                  buildingMode?.selectedMode === 'link'
                    ? 'text-[#609DF9]'
                    : 'text-white'
                }`}
              >
                {t('building.link')}
              </Typography>
            </div>
          </>
        )}
      </div>

      <div className="absolute left-[210px] top-0 flex gap-6">
        <MenuSetAnnotation />

        {buildingMode?.previewMode && pointDetail?.isSubImages && (
          <div
            className={clsx(
              'flex items-center text-center px-2 rounded-[30px] bg-[#021120CC] w-[60px] h-[60px] cursor-pointer'
            )}
            onClick={handleReflectionIcon}
          >
            <Typography className="font-bold text-[10px] leading-[10px] text-white">
              アイコン反映
            </Typography>
          </div>
        )}

        {buildingMode?.previewMode && profile?.role === Role.SA ? (
          <ModalFinishAllEditing />
        ) : null}
      </div>
    </div>
  )
}
