import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from 'antd'
import { useRecoilState, useRecoilValue } from 'recoil'
import { buildingModeState, setAnnotationModeState } from 'store/buildingStore'

import AddSetAnnotationIcon from 'components/icons/AddSetAnnotationIcon'
import AnnotationIcon from 'components/icons/Annotation'
import ListSetAnnotationIcon from 'components/icons/ListSetAnnotationIcon'

import ModalAddSetAnnotation from './ModalAddSetAnnotation'
import ModalDeleteSetAnnotation from './ModalDeleteSetAnnotation'
import ModalListSetAnnotation from './ModalListSetAnnotation'

export default function MenuSetAnnotation() {
  const { t } = useTranslation()

  const [isExpanding, setIsExpanding] = useState(false)

  const [{ mode }, setSelectedMode] = useRecoilState(setAnnotationModeState)
  const buildingMode = useRecoilValue(buildingModeState)

  const handleSelectMode = (mode: string | undefined) => {
    setSelectedMode((prev) => ({ ...prev, mode }))
  }

  useEffect(() => {
    if (!buildingMode.previewMode && isExpanding) {
      setIsExpanding(false)
    }
  }, [buildingMode.previewMode, isExpanding])

  useEffect(() => {
    const historyToggler = document.getElementById('version-history-toggler')
    const pointActionsMenuContainer = document.getElementById(
      'point-action-menu-rightest-element'
    )
    if (!historyToggler || !pointActionsMenuContainer) return

    const togglerBound = historyToggler.getBoundingClientRect()
    const containerBound = pointActionsMenuContainer.getBoundingClientRect()

    if (containerBound.right + 5 >= togglerBound.left) {
      historyToggler.style.top = '98px'
      historyToggler.style.left = '-82px'
    }
    if (containerBound.right + 35 < togglerBound.left) {
      historyToggler.style.top = '20px'
      historyToggler.style.left = '-112px'
    }
  }, [isExpanding])

  if (!buildingMode.previewMode) return null

  return (
    <>
      <div className="flex justify-center items-center gap-[18px] h-[60px] rounded-[30px] bg-[#021120CC]">
        {!isExpanding && (
          <div
            className="flex flex-col items-center justify-center gap-1 ml-[25px] mr-[21px] h-full cursor-pointer"
            onClick={() => setIsExpanding(true)}
          >
            <AnnotationIcon />
            <Typography className="font-bold text-[10px] leading-[10px] text-white whitespace-nowrap">
              {t('building.setAnnotation')}
            </Typography>
          </div>
        )}
        {isExpanding && (
          <>
            <div
              className="flex flex-col items-center justify-center gap-1 ml-5 h-full cursor-pointer"
              onClick={() => handleSelectMode('addSetAnnotation')}
            >
              <AddSetAnnotationIcon />
              <Typography className="font-bold text-[10px] leading-[10px] text-white whitespace-nowrap">
                {t('building.addSetAnnotation')}
              </Typography>
            </div>
            <div
              className="flex flex-col items-center justify-center gap-1 mr-5 h-full cursor-pointer"
              onClick={() => handleSelectMode('listSetAnnotation')}
            >
              <ListSetAnnotationIcon />
              <Typography className="font-bold text-[10px] leading-[10px] text-white whitespace-nowrap">
                {t('building.listSetAnnotation')}
              </Typography>
            </div>
          </>
        )}
      </div>

      {(mode === 'addSetAnnotation' || mode === 'editSetAnnotation') && (
        <ModalAddSetAnnotation />
      )}

      {mode === 'listSetAnnotation' && <ModalListSetAnnotation />}

      {mode === 'deleteSetAnnotation' && <ModalDeleteSetAnnotation />}
    </>
  )
}
