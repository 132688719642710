import React, { ReactNode, useCallback, useEffect, useRef } from 'react'
import { useRecoilState } from 'recoil'
import { toggleResizeNotificationState } from 'store/displayToggleStore'

type Props = {
  children: ReactNode
}

export default function ResizeContainer({ children }: Props) {
  const layoutRef = useRef<any>(null)
  const resizeRef = useRef<any>(null)

  const [isOpenResizeNotification, setIsOpenResizeNotification] =
    useRecoilState(toggleResizeNotificationState)

  const handleToggle = useCallback(() => {
    const width = window.innerWidth
    const height = window.innerHeight

    const historyToggler = document.getElementById('version-history-toggler')
    const pointActionsMenuContainer = document.getElementById(
      'point-action-menu-rightest-element'
    )

    if (historyToggler && pointActionsMenuContainer) {
      const togglerBound = historyToggler.getBoundingClientRect()
      const containerBound = pointActionsMenuContainer.getBoundingClientRect()

      if (containerBound.right + 5 >= togglerBound.left) {
        historyToggler.style.top = '98px'
        historyToggler.style.left = '-82px'
      }
      if (containerBound.right + 35 < togglerBound.left) {
        historyToggler.style.top = '20px'
        historyToggler.style.left = '-112px'
      }
    }

    if (resizeRef.current) {
      clearTimeout(resizeRef.current)
    }

    // resize popup as 1080px width and 600px height
    if (!isOpenResizeNotification && (width <= 1024 || height <= 600)) {
      resizeRef.current = setTimeout(() => {
        setIsOpenResizeNotification(true)
      }, 100)
      return
    }
    if (isOpenResizeNotification && width > 1024 && height > 600) {
      resizeRef.current = setTimeout(() => {
        setIsOpenResizeNotification(false)
      }, 100)
    }
  }, [isOpenResizeNotification, setIsOpenResizeNotification])

  useEffect(() => {
    if (!layoutRef.current) return

    const observer = new ResizeObserver(() => {
      handleToggle()
    })
    observer.observe(layoutRef.current)

    return () => {
      observer.disconnect()
    }
  }, [handleToggle])

  return <div ref={layoutRef}>{children}</div>
}
