import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from 'antd'
import Const from 'constants/constant'
import dayjs from 'dayjs'
import { Building } from 'models/Building'

import Modal from 'components/atoms/Modal'
import Spinner from 'components/atoms/Spinner'
import CloseIcon from 'components/icons/CloseIcon'

type Props = {
  building: Building | undefined
  handleToggle: () => void
}

export default function BuildingInformationModal({
  building,
  handleToggle
}: Props) {
  const { t } = useTranslation()
  const [isImageLoading, setIsImageLoading] = useState(false)

  useEffect(() => {
    if (building?.s3Path || building?.thumbnail) {
      setIsImageLoading(true)
    }
  }, [building?.s3Path, building?.thumbnail])

  return (
    <Modal width={1030} rootClassName="">
      <div className="flex gap-5 flex-col items-center h-[615px] overflow-auto px-9 mt-[60px] pb-6">
        <div
          className="absolute top-5 right-5 cursor-pointer"
          onClick={handleToggle}
        >
          <CloseIcon />
        </div>
        <Spinner spinning={isImageLoading}>
          <div className="w-[476px] aspect-video bg-[#021120]">
            <img
              src={building?.s3Path || '/assets/no-image.svg'}
              alt="building"
              className="w-full h-full object-contain"
              loading="lazy"
              onLoad={() => {
                setIsImageLoading(false)
              }}
              onError={({ currentTarget }) => {
                setIsImageLoading(false)
                currentTarget.onerror = null // prevents looping
                currentTarget.src = '/assets/no-image.svg'
              }}
            />
          </div>
        </Spinner>

        <div className="w-full grid grid-cols-3 gap-5">
          <div className="flex flex-col gap-[30px]">
            <Typography className="border-0 border-b border-solid border-[#3163B7] text-lg font-bold text-center text-[#3163B7] h-[33px]">
              {t('translation.buildingInformation')}
            </Typography>

            <div className="flex flex-col gap-4">
              <div className="grid grid-cols-[100px_1fr] gap-[10px]">
                <Typography className="text-[12px] font-bold text-[#021120] justify-self-end">
                  {t('building.buildingNameForm')}&nbsp;&#58;&nbsp;
                </Typography>
                <Typography className="text-[12px] text-[#021120]">
                  {building?.name}
                </Typography>
              </div>
              <div className="grid grid-cols-[100px_1fr] gap-[10px]">
                <Typography className="text-[12px] font-bold text-[#021120] justify-self-end">
                  {t('building.code')}&nbsp;&#58;&nbsp;
                </Typography>
                <Typography className="text-[12px] text-[#021120]">
                  {building?.code}
                </Typography>
              </div>
              <div className="grid grid-cols-[100px_1fr] gap-[10px]">
                <Typography className="text-[12px] font-bold text-[#021120] justify-self-end">
                  {t('building.constructionName')}&nbsp;&#58;&nbsp;
                </Typography>
                <Typography className="text-[12px] text-[#021120]">
                  {building?.aliasName}
                </Typography>
              </div>
              <div className="grid grid-cols-[100px_1fr] gap-[10px]">
                <Typography className="text-[12px] font-bold text-[#021120] justify-self-end">
                  担当支店営業所&nbsp;&#58;&nbsp;
                </Typography>
                <Typography className="text-[12px] text-[#021120]">
                  {building?.branch?.name}&nbsp;
                  {building?.branch?.serviceStoreName}
                </Typography>
              </div>
              <div className="grid grid-cols-[100px_1fr] gap-[10px]">
                <Typography className="text-[12px] font-bold text-[#021120] justify-self-end">
                  {t('building.address')}&nbsp;&#58;&nbsp;
                </Typography>
                <Typography className="text-[12px] text-[#021120]">
                  {building?.zipcode}&nbsp;
                  {building?.prefCode &&
                    Const.PREFECTURES.find(
                      (item) => item.prefCode === building.prefCode
                    )?.prefName}
                  &nbsp;{building?.cityName}&nbsp;
                  {building?.streetName}&nbsp;{building?.buildingName}
                </Typography>
              </div>
              <div className="grid grid-cols-[100px_1fr] gap-[10px]">
                <Typography className="text-[12px] font-bold text-[#021120] justify-self-end">
                  {t('building.createdDate')}&nbsp;&#58;&nbsp;
                </Typography>
                <Typography className="text-[12px] text-[#021120]">
                  {building?.createdAt &&
                    dayjs(building?.createdAt).format('YYYY/MM/DD')}
                </Typography>
              </div>
              <div className="grid grid-cols-[100px_1fr] gap-[10px]">
                <Typography className="text-[12px] font-bold text-[#021120] justify-self-end">
                  更新日&nbsp;&#58;&nbsp;
                </Typography>
                <Typography className="text-[12px] text-[#021120]">
                  {building?.updatedAt &&
                    dayjs(building?.updatedAt).format('YYYY/MM/DD')}
                </Typography>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-[30px]">
            <Typography className="border-0 border-b border-solid border-[#3163B7] text-lg font-bold text-center text-[#3163B7] h-[33px]">
              物件データ
            </Typography>

            <div className="flex flex-col gap-4">
              <div className="grid grid-cols-[100px_1fr] gap-[10px]">
                <Typography className="text-[12px] font-bold text-[#021120] justify-self-end">
                  {t('building.sideArea')}&nbsp;&#58;&nbsp;
                </Typography>
                <Typography className="text-[12px] text-[#021120]whitespace-pre-line">
                  {Number(building?.siteArea || 0).toLocaleString()} ㎡
                </Typography>
              </div>
              <div className="grid grid-cols-[100px_1fr] gap-[10px]">
                <Typography className="text-[12px] font-bold text-[#021120] justify-self-end">
                  {t('building.floorArea')}&nbsp;&#58;&nbsp;
                </Typography>
                <Typography className="text-[12px] text-[#021120]whitespace-pre-line">
                  {Number(building?.totalFloorArea || 0)?.toLocaleString()} ㎡
                </Typography>
              </div>
              <div className="grid grid-cols-[100px_1fr] gap-[10px]">
                <Typography className="text-[12px] font-bold text-[#021120] justify-self-end">
                  {t('building.buildingArea')}&nbsp;&#58;&nbsp;
                </Typography>
                <Typography className="text-[12px] text-[#021120]whitespace-pre-line">
                  {Number(building?.buildingArea || 0).toLocaleString()} ㎡
                </Typography>
              </div>
              <div className="grid grid-cols-[100px_1fr] gap-[10px]">
                <Typography className="text-[12px] font-bold text-[#021120] justify-self-end">
                  {t('building.structure')}&nbsp;&#58;&nbsp;
                </Typography>
                <Typography className="text-[12px] text-[#021120]">
                  {building?.structure}
                </Typography>
              </div>
              <div className="grid grid-cols-[100px_1fr] gap-[10px]">
                <Typography className="text-[12px] font-bold text-[#021120] justify-self-end">
                  {t('building.numberOfFloor')}&nbsp;&#58;&nbsp;
                </Typography>
                <Typography className="text-[12px] text-[#021120]whitespace-pre-line">
                  {building?.floorNumber}
                </Typography>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-[30px]">
            <Typography className="border-0 border-b border-solid border-[#3163B7] text-lg font-bold text-center text-[#3163B7] h-[33px]">
              設計・施工
            </Typography>

            <div className="flex flex-col gap-4">
              <div className="grid grid-cols-[100px_1fr] gap-[10px]">
                <Typography className="text-[12px] font-bold text-[#021120] justify-self-end">
                  {t('building.design')}&nbsp;&#58;&nbsp;
                </Typography>
                <Typography className="text-[12px] text-[#021120]">
                  {building?.designerName}
                </Typography>
              </div>
              <div className="grid grid-cols-[100px_1fr] gap-[10px]">
                <Typography className="text-[12px] font-bold text-[#021120] justify-self-end">
                  {t('building.construction')}&nbsp;&#58;&nbsp;
                </Typography>
                <Typography className="text-[12px] text-[#021120]truncate">
                  {building?.architectName}
                </Typography>
              </div>
              <div className="grid grid-cols-[100px_1fr] gap-[10px]">
                <Typography className="text-[12px] font-bold text-[#021120] justify-self-end">
                  {t('building.constructionPeriod')}&nbsp;&#58;&nbsp;
                </Typography>
                <Typography className="text-[12px] text-[#021120]">
                  {building?.constructionPeriodStart
                    ? dayjs(building?.constructionPeriodStart).format(
                        'YYYY/MM/DD 〜 '
                      )
                    : ''}
                  {building?.constructionPeriodStart
                    ? dayjs(building?.constructionPeriodEnd).format(
                        'YYYY/MM/DD'
                      )
                    : ''}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
