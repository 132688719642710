import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Typography } from 'antd'

import Button from 'components/atoms/Button'

export default function NotFound() {
  const { t } = useTranslation()

  return (
    <div className="flex h-full bg-[#F9FBFF]">
      <div className="absolute top-3 left-1/2 -translate-x-[20%]">
        <img
          src="/assets/icon_background.png"
          alt="404 layer bg"
          className="w-[32.353rem] h-[32.292rem]"
        />
      </div>
      <div className="z-10 flex flex-col items-center justify-between m-auto bg-white w-[31.25rem] h-[37.5rem] rounded-[1.25rem] p-[3.75rem] shadow-[0px_0px_10px_0px_#00000026]">
        <img
          src="/assets/404_bg.png"
          alt="404"
          className="w-[19.75rem] h-[13.75rem]"
        />
        <Typography className="text-[#3163B7] text-[1.25rem] leading-[28.96px] font-bold">
          {t('common.404Title')}
        </Typography>
        <Typography className="text-[#021120] leading-[26px] font-normal">
          {t('common.404Description')}
        </Typography>
        <Link to="/buildings" className="no-underline">
          <Button className="bg-[#3163B7]" sizing="w-[21.875rem] h-[3.75rem]">
            {t('common.TOP')}
          </Button>
        </Link>
      </div>
    </div>
  )
}
