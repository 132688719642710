import { useRef, useState } from 'react'
import { ImageOverlay, MapContainer, Marker, useMapEvents } from 'react-leaflet'
import { CRS, LatLngTuple } from 'leaflet'
import { smallNumberIcon } from 'pages/building/floor/area/CustomMarker'
import { useRecoilState, useRecoilValue } from 'recoil'
import { toggleResizeNotificationState } from 'store/displayToggleStore'
import currentViewerState from 'store/viewerStore'
import { FloorMapElement } from 'types/building'

import useViewBuildingDetail from '../hooks/useViewBuildingDetail'

type Props = {
  imagePath: string | undefined
  mapItems: FloorMapElement | undefined
  refetchFloor: any
}

export default function ViewerFloorMap({
  imagePath,
  mapItems,
  refetchFloor
}: Props) {
  const mapContainerRef = useRef<any>(null)

  const [imgBounds, setImgBounds] = useState<LatLngTuple>([0, 0])

  const [currentViewer, setCurrentViewer] = useRecoilState(currentViewerState)
  const isOpenResizeNotification = useRecoilValue(toggleResizeNotificationState)

  const { mapOnReady } = useViewBuildingDetail({
    imagePath,
    refetchFloor
  })

  const { points } = { ...mapItems }

  function MapEventHandler() {
    useMapEvents({
      click() {
        setCurrentViewer((prev) => ({ ...prev, isOpenMiniMap: true }))
      }
    })
    return null
  }

  if (!imagePath) return null

  return (
    <div className="p-[10px] h-44">
      {!isOpenResizeNotification ? (
        <MapContainer
          ref={mapContainerRef}
          center={[-99999, -99999]}
          zoom={0}
          zoomSnap={0.1}
          zoomDelta={0.5}
          className="area-map-container bg-[#162841]"
          crs={CRS.Simple}
          zoomControl={false}
          attributionControl={false}
          preferCanvas
          whenReady={() => mapOnReady(mapContainerRef, setImgBounds)}
        >
          {/* handle map event */}
          <MapEventHandler />

          {points?.map((point) => (
            <Marker
              key={`marker ${point.id}`}
              draggable={false}
              zIndexOffset={
                point.id === currentViewer.selectedPoint
                  ? 450
                  : 400 + point.order
              }
              // leaflet position is latlng with y=lat x=lng
              position={[point.y, point.x]}
              icon={smallNumberIcon(point, currentViewer.selectedPoint)}
            />
          ))}

          <ImageOverlay url={imagePath} bounds={[[0, 0], imgBounds]} />
        </MapContainer>
      ) : null}
    </div>
  )
}
