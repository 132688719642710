import { useTranslation } from 'react-i18next'
import { Form } from 'antd'
import useMutation from 'hooks/useMutation'
import Utils from 'utils'

import AuthFormContainer from 'components/atoms/AuthFormContainer'

import CompleteSendMail from './CompleteSendMail'
import ForgotPasswordCard from './ForgotPasswordCard'

export default function ForgotPassword() {
  const { trigger, data, isMutating } = useMutation('v1/auth/password/forgot')
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const handleFinish = (values: any) => {
    if (isMutating) return

    trigger(
      { data: values },
      {
        onError: (error) => {
          Utils.handleError(error, t, form)
        }
      }
    )
  }

  if (data)
    return (
      <AuthFormContainer>
        <CompleteSendMail />
      </AuthFormContainer>
    )

  return (
    <AuthFormContainer>
      <ForgotPasswordCard form={form} onFinish={handleFinish} />
    </AuthFormContainer>
  )
}
