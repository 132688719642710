import Validators from 'utils/validators'

import Const from './constant'

class FormComponent {
  static FORM_REGISTER(t: any): any {
    return [
      {
        type: 'string',
        label: 'ID',
        filedType: 'id'
      },
      {
        type: 'input',
        label: 'name',
        required: true,
        validates: [
          { validator: Validators.invalidKeywordValidator(t) },
          { validator: Validators.keywordCustomValidator(t, 80) },
          {
            required: true,
            message: t('organization.keywordOver80Length')
          }
        ],
        placeholder: t('organization.placeholderName')
      },
      {
        type: 'input',
        label: 'nameRuby',
        required: true,
        validates: [
          { validator: Validators.keywordKatakanaValidator(t) },
          { validator: Validators.keywordCustomValidator(t, 200) },
          {
            required: true,
            message: t('organization.keywordOver200Length')
          }
        ],
        placeholder: t('organization.placeholderName')
      },
      {
        type: 'input',
        label: 'emailDomain',
        required: true,
        validates: [
          { validator: Validators.domainValidator(t) },
          { validator: Validators.keywordCustomValidator(t, 100) },
          {
            required: true,
            message: t('organization.keywordOver100Length')
          }
        ],
        placeholder: 'aaa.com'
      },
      {
        type: 'input',
        label: 'url',
        required: false,
        validates: [{ validator: Validators.urlValidator(t) }],
        placeholder: t('organization.placeholderName')
      },
      {
        type: 'input',
        required: false,
        label: 'phone',
        validates: [{ validator: Validators.phoneValidator(t) }],
        placeholder: '03000000000'
      },
      {
        type: 'input',
        label: 'zipcode',
        required: true,
        validates: [
          { validator: Validators.zipcodeValidator(t) },
          {
            required: true,
            message: t('organization.validatedZipcode')
          }
        ],
        placeholder: '0000000'
      },
      {
        type: 'select',
        label: 'prefCode',
        required: true,
        validates: [
          { required: true, message: t('organization.validatedProvince') }
        ],
        placeholder: t('translation.selectProvincePlaceholder'),
        children: Const.PREFECTURES
      },
      {
        type: 'input',
        label: 'cityName',
        required: true,
        validates: [
          { validator: Validators.keywordCustomValidator(t, 20) },
          { validator: Validators.keywordExistsValidator(t) },
          {
            required: true,
            message: t('organization.keywordOver20Length')
          }
        ],
        placeholder: t('organization.placeholderName')
      },
      {
        type: 'input',
        label: 'streetName',
        required: true,
        validates: [
          { validator: Validators.keywordCustomValidator(t, 30) },
          { validator: Validators.keywordExistsValidator(t) },
          {
            required: true,
            message: t('organization.keywordOver30Length')
          }
        ],
        placeholder: t('organization.placeholderName')
      },
      {
        type: 'input',
        label: 'buildingName',
        validates: [
          { validator: Validators.keywordCustomValidator(t, 50) },
          { validator: Validators.keywordExistsValidator(t) }
        ],
        placeholder: t('organization.placeholderName')
      }
    ]
  }
}

export default FormComponent
