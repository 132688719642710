import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from 'antd'
import useMutation from 'hooks/useMutation'
import { CurrentUser } from 'models/User'

type Props = {
  user: CurrentUser
  mutate: () => void
}

export default function UserInviteStatus({ user, mutate }: Props) {
  const { t } = useTranslation()
  const { trigger, isMutating } = useMutation(`/v1/users/${user.id}/reinvite`)

  const handleReinvite = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    !isMutating &&
      trigger(
        {
          method: 'post',
          successMessage: t('user.resendInvitationSuccess'),
          data: null
        },
        {
          onSuccess: () => {
            mutate()
          }
        }
      )
  }

  if (user.deletedAt) return null

  if (!user.isInvitationExpired) {
    return (
      <Typography className="text-xs text-[#5D6266]">
        {t('user.inviting')}
      </Typography>
    )
  }

  return (
    <div className="flex">
      <Typography
        className="text-xs text-[#3163B7] underline underline-offset-2"
        onClick={handleReinvite}
      >
        {t('user.resendInvitation')}
      </Typography>
    </div>
  )
}
