import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ImageOverlay,
  MapContainer,
  Marker,
  Polyline,
  useMapEvents
} from 'react-leaflet'
import { useNavigate } from 'react-router-dom'
import { notification } from 'antd'
import useEventHandler from 'hooks/useEventHandler'
import { CRS, LatLngTuple } from 'leaflet'
import { useRecoilValue } from 'recoil'
import { toggleResizeNotificationState } from 'store/displayToggleStore'
import { SelectedMode } from 'types/building'
import Utils from 'utils'
import Krpano from 'utils/krpano'

import Spinner from 'components/atoms/Spinner'

import { numberIcon, rotatedMarkerIcon } from './CustomMarker'

type Props = {
  imagePath: string
  isLoadingMap: boolean
}

export default function LeafletMap({ imagePath, isLoadingMap }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [imgBounds, setImgBounds] = useState<LatLngTuple>([0, 0])
  const [imageLoading, setImageLoading] = useState<boolean>()
  const isAddingPoint = useRef<boolean>(false)

  const isOpenResizeNotification = useRecoilValue(toggleResizeNotificationState)

  const {
    buildingId,
    floorId,
    points,
    setPoints,
    links,
    selectedArea,
    buildingMode,
    currentSelectedPoint,
    polylineRef,
    mapContainerRef,
    handlePoint,
    pointDetail,
    radarDragFlag,
    createPolylineRef,
    initPositionRef,
    povMarkerRef,
    linkEventHandler,
    markerEventHandler,
    radarEventHandler,
    sync360ViewToArc,
    mapEventHandler,
    mapOnReady,
    handleUpdatePointImageByDrag,
    refetchPoints,
    setBuildingFiles,
    isSyncChangedPoint,
    listChangedPoints,
    setRevalidateFileList,
    isFetchingPoint
  } = useEventHandler()

  sync360ViewToArc(rotatedMarkerIcon)

  const displayRadar = useMemo(() => {
    // condition for display radar at selected point
    const radarPoint =
      selectedArea &&
      currentSelectedPoint &&
      currentSelectedPoint === pointDetail?.id
        ? points.find((point) => point.id === pointDetail?.id)
        : undefined

    if (radarPoint && radarPoint.image360) {
      const hlookat = (
        document.getElementById('embedpano-preview') as any
      )?.get('view.hlookat')
      const rotate =
        hlookat !== radarPoint.initView.h
          ? Krpano.NormalizeHgap(hlookat)
          : radarPoint.initView.h

      return (
        <Marker
          position={[radarPoint.y, radarPoint.x]}
          ref={povMarkerRef}
          zIndexOffset={440 + points.length}
          icon={rotatedMarkerIcon(
            radarDragFlag.current.rotate
              ? radarDragFlag.current.rotate
              : rotate + radarPoint.initView.radarHGap
          )}
          draggable={false}
          eventHandlers={radarEventHandler(buildingMode.selectedMode)}
        />
      )
    }
    return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentSelectedPoint,
    pointDetail,
    buildingMode.selectedMode,
    selectedArea,
    radarDragFlag
  ])

  const handleAddPoint = (
    x: number,
    y: number,
    imgBounds: any,
    image360?: number
  ) => {
    // only addable when user finish sync drag point
    if (!isAddingPoint.current && !listChangedPoints.length) {
      // prevent people add point outside of map imgbound latlng
      if (x >= imgBounds[1] || y >= imgBounds[0] || x < 0 || y < 0) {
        return
      }
      isAddingPoint.current = true

      handlePoint(
        {
          data: {
            x: Number(x.toFixed(3)),
            y: Number(y.toFixed(3)),
            building_id: Number(buildingId),
            area_id: selectedArea,
            floor_id: Number(floorId),
            order: points.length + 1,
            image360
          }
        },
        {
          onSuccess: (data: any) => {
            setPoints((prev) => [...prev, data.data.point])
            image360 &&
              setBuildingFiles((prev) =>
                prev.map((file) =>
                  file.id === image360
                    ? { ...file, pointId: data.data.point.id }
                    : file
                )
              )
            isAddingPoint.current = false
          },
          onError: (err) => {
            if (err.response.data.error.key === 'not_change_image_360') {
              refetchPoints()
              setRevalidateFileList(1)
              isAddingPoint.current = false
              notification.error({
                message: t('translation.imageIsUsed')
              })
            }
            Utils.handleErrorNavigate(err, navigate)
          }
        }
      )
    }
  }

  function MapEventHandler() {
    useMapEvents({
      ...mapEventHandler(),
      click(e: any) {
        buildingMode.selectedMode === 'point' &&
          handleAddPoint(e.latlng.lng, e.latlng.lat, imgBounds)
        if (createPolylineRef.current) {
          initPositionRef.current = null
        }
      }
    })
    return null
  }

  useEffect(() => {
    imagePath && setImageLoading(true)
  }, [imagePath])

  const mapTarget = document.getElementById('map-container')
  if (mapTarget && selectedArea) {
    mapTarget.ondragover = (e) => e.preventDefault()
    mapTarget.ondrop = (e) => {
      e.preventDefault()
      const receivedData = e.dataTransfer?.getData('text') || ''
      if (!receivedData.startsWith('imagedrag-')) return

      const imageId = +receivedData.split('-')[1]
      const { lng, lat } = mapContainerRef.current.mouseEventToLatLng(e)
      const cord = mapContainerRef.current.mouseEventToLayerPoint(e)

      if (buildingMode.selectedMode !== 'point') return

      // check if drop position have point, if latlng is between point latlng +- 17 for min zoom
      const existedPoint = points.find((item) => {
        const pointCordinate = mapContainerRef.current.latLngToLayerPoint([
          item.y,
          item.x
        ])
        return (
          pointCordinate.x - 17 <= cord.x &&
          pointCordinate.x + 17 >= cord.x &&
          pointCordinate.y - 17 <= cord.y &&
          pointCordinate.y + 17 >= cord.y
        )
      })
      if (existedPoint) {
        if (!existedPoint.image360) {
          handleUpdatePointImageByDrag(existedPoint.id, imageId)
        } else {
          notification.error({
            message: t('translation.pointAlreadyHaveImage')
          })
        }
      } else {
        handleAddPoint(lng, lat, imgBounds, imageId)
      }
    }
    mapTarget.ondragenter = (e) => e.preventDefault()
    mapTarget.ondragleave = (e) => e.preventDefault()
  }

  if (isLoadingMap) return null

  return (
    <>
      {(isAddingPoint.current || imageLoading || isFetchingPoint) && (
        <Spinner className="z-[999]" />
      )}
      {!isOpenResizeNotification ? (
        <MapContainer
          ref={mapContainerRef}
          center={[-99999, -99999]}
          zoom={0}
          zoomSnap={0.1}
          zoomDelta={0.5}
          id="map-container"
          className="area-map-container select-none"
          crs={CRS.Simple}
          zoomControl={false}
          attributionControl={false}
          whenReady={() => mapOnReady(imagePath, setImgBounds, setImageLoading)}
        >
          {/* handle map event */}
          <MapEventHandler />

          {points.map((point) => (
            <Marker
              draggable={
                buildingMode.selectedMode === SelectedMode.Point &&
                !isSyncChangedPoint
              }
              bubblingMouseEvents={false}
              eventHandlers={markerEventHandler(point, imgBounds)}
              zIndexOffset={
                point.id === currentSelectedPoint
                  ? 450 + points.length
                  : 400 + point.order
              }
              // leaflet position is latlng with y=lat x=lng
              position={[point.y, point.x]}
              icon={numberIcon(
                point,
                selectedArea ? point.areaId === selectedArea : false,
                selectedArea ? point.id === currentSelectedPoint : false
              )}
              key={point.id}
            />
          ))}

          {links.map((link, index) => (
            <Polyline
              key={link.id}
              ref={(el) => {
                polylineRef.current[index] = { ref: el, id: link.id }
              }}
              // leaflet position is latlng with y=lat x=lng
              positions={[
                [link.point1.y, link.point1.x],
                [link.point2.y, link.point2.x]
              ]}
              color="#000"
              className="delete-able-link"
              weight={5}
              bubblingMouseEvents={false}
              eventHandlers={linkEventHandler(link.id, index)}
            />
          ))}

          {displayRadar}

          <ImageOverlay url={imagePath} bounds={[[0, 0], imgBounds]} />
        </MapContainer>
      ) : null}
    </>
  )
}
