import { useState } from 'react'
import { Menu, Typography } from 'antd'
import { ItemType, MenuItemType } from 'antd/es/menu/hooks/useItems'
import Const from 'constants/constant'
import { Folders } from 'types/building'

import RightArrowIcon from 'components/icons/RightArrowIcon'
import RotatedArrowIcon from 'components/icons/RotatedArrowIcon'

import InstructionMenu from './MenuInstuction'

const expandIcon = ({ isOpen }: any) => {
  return <RotatedArrowIcon className={isOpen ? 'rotate-180' : '-rotate-90'} />
}

export default function DocumentMenu() {
  const [selectedFolder, setSelectedFolder] = useState<{
    doc: Folders
    subDoc?: Folders
  }>()
  const [option, setOption] = useState('main-menu')

  const handleMainMenu = () => setOption('main-menu')
  const handleDocumentMenu = (doc: Folders, subDoc?: Folders) => {
    setSelectedFolder({
      doc,
      subDoc
    })
    setOption('document-menu')
  }

  const items: ItemType<MenuItemType>[] = Const.BUILDING_FOLDER?.map(
    (item) => ({
      key: `${item.id}`,
      label: (
        <div
          className="flex justify-between items-center"
          onClick={() => item.isFileHolder && handleDocumentMenu(item)}
        >
          <Typography className="text-white text-[13px] leading-[19.5px]">
            {item.label}
          </Typography>
          {item.isFileHolder && <RotatedArrowIcon className="-rotate-90" />}
        </div>
      ),
      children: item.children?.map((subItem) => ({
        key: `${subItem.id}`,
        label: (
          <div
            className="flex justify-between cursor-pointer my-auto"
            onClick={() => handleDocumentMenu(item, subItem)}
          >
            <Typography className="text-white text-[13px] leading-[19.5px] my-auto">
              {subItem.label}
            </Typography>
            <RightArrowIcon size={12} />
          </div>
        )
      }))
    })
  )

  return (
    <>
      {option === 'main-menu' && (
        <div>
          <Menu
            theme="dark"
            inlineIndent={0}
            mode="inline"
            items={items}
            rootClassName="custom-document-menu"
            expandIcon={expandIcon}
            className="folder-list-menu"
          />
        </div>
      )}
      {option === 'document-menu' && (
        <InstructionMenu
          handleBack={handleMainMenu}
          selectedFolder={selectedFolder}
        />
      )}
    </>
  )
}
