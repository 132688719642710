import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { notification, Typography } from 'antd'
import Const from 'constants/constant'
import useMutation from 'hooks/useMutation'
import { DeleteUserError } from 'types/userDetail'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'

type Props = {
  lastName?: string
  firstName?: string
  organization?: string
  handleCloseModal: () => void
}

export default function DeleteUserModal({
  lastName,
  firstName,
  organization,
  handleCloseModal
}: Props) {
  const { t } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()

  const { trigger, isMutating } = useMutation(`/v1/users/${params.id}`)

  const handleSubmit = () => {
    !isMutating &&
      trigger(
        {
          method: 'delete',
          data: null,
          successMessage: t('organization.successDeletedNotification')
        },
        {
          onSuccess: () => navigate('/users'),
          onError: (error) => {
            const message =
              Const.DELETE_USER_ERROR[
                error.response.data.error.key as DeleteUserError
              ]
            message
              ? notification.error({
                  message: t(message, {
                    organization
                  })
                })
              : navigate('/users')
            handleCloseModal()
          }
        }
      )
  }

  return (
    <Modal
      width={480}
      className="rounded-modal-lg"
      rootClassName=""
      isEnterable
      onEnter={handleSubmit}
    >
      <div className="py-7 grid gap-10 justify-center">
        <Title title={t('user.deleteUser')} height={30} />

        <Typography className="text-center text-sm">
          {t('user.deleteUserConfirmation')}
        </Typography>

        <Typography className="flex gap-5 justify-center items-center rounded-xl w-[22.5rem] h-[3.75rem] bg-[#F9FBFF]">
          <span>{lastName}</span>
          <span>{firstName}</span>
        </Typography>

        <div className="w-full flex justify-center gap-10">
          <Button sizing="w-36 h-12" onClick={handleCloseModal}>
            {t('user.cancel')}
          </Button>

          <Button
            className="bg-[#B80808]"
            sizing="w-36 h-12"
            onClick={handleSubmit}
          >
            {t('user.delete')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

DeleteUserModal.defaultProps = {
  firstName: '',
  lastName: '',
  organization: ''
}
