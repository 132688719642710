import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { notification, Typography } from 'antd'
import useMutation from 'hooks/useMutation'
import { Building } from 'models/Building'
import CustomCheckBox from 'pages/building/floor/components/CustomCheckBox'
import useSWR from 'swr'
import { BuildingStatus } from 'types/building'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'

type Props = {
  handleCancel: () => void
}

export default function ModalChangeStatus({ handleCancel }: Props) {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const [buildingStatus, setBuildingStatus] = useState<BuildingStatus>()

  const {
    data: building,
    mutate,
    isValidating
  } = useSWR<Building>(
    {
      url: `/v1/buildings/${id}`
    },
    {
      revalidateOnMount: false
    }
  )

  useEffect(() => {
    if (building) {
      setBuildingStatus(building.status)
    }
  }, [building, building?.status])

  const { trigger: editFloorName, isMutating } =
    useMutation(`/v1/buildings-status`)

  const handleClose = () => {
    handleCancel()
  }

  const handleSubmit = () => {
    if (buildingStatus === building?.status) {
      notification.success({
        message: t('building.successChangeStatus'),
        key: t('building.successChangeStatus'),
        duration: 3
      })
      handleClose()
      return
    }
    !isMutating &&
      editFloorName(
        {
          method: 'post',
          data: {
            buildingId: Number(id),
            toStatus: buildingStatus
          },
          successMessage: t('building.successChangeStatus')
        },
        {
          onSuccess: () => {
            mutate()
            handleClose()
          },
          onError: () => {
            handleClose()
            navigate(`/buildings/${id}`)
          }
        }
      )
  }

  const statusItems: {
    id: BuildingStatus
    label: string
    isDefault: boolean
  }[] = [
    {
      id: BuildingStatus.PUBLIC,
      label: t('building.statusPrivate'),
      isDefault: BuildingStatus.PUBLIC === building?.status
    },
    {
      id: BuildingStatus.PRIVATE,
      label: t('building.30'),
      isDefault: BuildingStatus.PRIVATE === building?.status
    }
  ]

  const handleSelectStatus = (id: number) => {
    setBuildingStatus(id)
  }

  return (
    <Modal
      open={!isValidating}
      onCancel={handleClose}
      width={490}
      isEnterable
      onEnter={handleSubmit}
    >
      <Title
        title={t('building.editStatusBuilding')}
        bottomMargin="mb-[31px]"
      />
      <div className="flex w-full justify-center gap-[62px] cursor-pointer mx-[10px]">
        {statusItems.map((item) => (
          <div
            className="flex gap-[10px]"
            key={item.id}
            onClick={() => handleSelectStatus(item.id)}
          >
            <CustomCheckBox
              id={item.id}
              isDefault={item.isDefault}
              checked={buildingStatus}
            />
            <Typography className="font-medium text-[#021120]">
              {item.label}
            </Typography>
          </div>
        ))}
      </div>
      <div className="flex gap-10 justify-center mt-[38px]">
        <Button sizing="w-[140px] h-[50px]" onClick={handleClose}>
          {t('building.buttonCancel')}
        </Button>
        <Button
          onClick={handleSubmit}
          type="primary"
          sizing="w-[140px] h-[50px]"
        >
          {t('building.buttonSave')}
        </Button>
      </div>
    </Modal>
  )
}
