import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Form } from 'antd'
import Const from 'constants/constant'
import { useRecoilState, useRecoilValue } from 'recoil'
import { selectedPoint } from 'store/buildingStore'
import { currentAnnotation } from 'store/krpanoStore'
import { AnnotationType } from 'types/building'
import Utils from 'utils'
import Krpano from 'utils/krpano'

import useMutation from './useMutation'

export default function useAnnotation() {
  const { t } = useTranslation()
  const { id, floorId } = useParams()
  const [form] = Form.useForm()
  const memo = Form.useWatch('memo', form)
  const navigate = useNavigate()
  const currentSelectedPoint = useRecoilValue(selectedPoint)
  const [currentSelected, setCurrentSelected] =
    useRecoilState(currentAnnotation)
  const [selectedFolder, setSelectedFolder] = useState<AnnotationType>(
    currentSelected?.type || AnnotationType.ARCHITECTURE
  )
  const [selectedChildrenFolder, setSelectedChildrenFolder] = useState<{
    id: AnnotationType
    label: string
  }>()
  const isDataExistsAnnotation =
    !currentSelected?.files?.length &&
    !currentSelected?.link &&
    !currentSelected?.name &&
    !form.getFieldValue('memo')

  const { trigger, isMutating } = useMutation(`v1/annotations`)
  const dataSource = useMemo(
    () => Const.BUILDING_FOLDER.find((folder) => folder.id === selectedFolder),
    [selectedFolder]
  )
  const childrenFolder = useMemo(() => {
    const children = (dataSource?.children || []).filter(
      (item) => item.label !== '竣工図'
    )
    return [
      ...children,
      { label: 'リンク', id: 40, isFileHolder: true, children: null }
    ]
  }, [dataSource?.children])

  const hotspotLoadStyle = (krpano: any, data: any) => {
    krpano.call(`remove_annotation(${data.id})`)
    Krpano.AddAnnotation(krpano, [data])

    if (data.type === AnnotationType.MEMO) {
      krpano.call(
        `set(hotspot[id-${data.id}].text,${Krpano.ConvertMemo(
          data.memo || ''
        )});`
      )
    }
  }

  const createNewAnnotation = (krpano: any, data: any) => {
    krpano.call('hide_temp_hotspot()')
    Krpano.AddAnnotation(krpano, [data])
  }

  const handleBack = () => {
    selectedChildrenFolder && setSelectedChildrenFolder(undefined)
    if (
      selectedChildrenFolder?.id === 40 &&
      !currentSelected?.link &&
      currentSelected?.name
    ) {
      form.setFieldValue('name', '')
      setCurrentSelected((prev) => ({ ...prev, name: '' }))
    }
  }

  const handleCloseModal = () => {
    if (selectedChildrenFolder) {
      setSelectedChildrenFolder(undefined)
    }
    if (!currentSelected?.id) {
      Krpano.RemoveTempAnnotation()
    }
    setCurrentSelected(undefined)
  }

  const request = () => {
    const krpano = document.getElementById('embedpano-full') as any
    const convertedValue = {
      ...currentSelected,
      link: (currentSelected?.link || '').trim(),
      name: (currentSelected?.name || '').trim(),
      memo: (memo || '').trim(),
      type: selectedFolder,
      files:
        currentSelected?.files && currentSelected?.files.map((item) => item.id)
    }
    !isMutating &&
      trigger(
        {
          method: 'put',
          data: {
            building_id: Number(id),
            floor_id: Number(floorId),
            point_id: currentSelectedPoint,
            annotation: convertedValue
          },
          successMessage: t('building.saved')
        },
        {
          onSuccess: ({ data }) => {
            if (!convertedValue.id) {
              createNewAnnotation(krpano, data)
            } else {
              hotspotLoadStyle(krpano, data)
            }
            handleCloseModal()
          },
          onError: (error) => {
            krpano.call('hide_temp_hotspot()')
            Utils.handleErrorNavigate(error, navigate)
          }
        }
      )
  }

  const validateAnnotationLink = () => {
    if (selectedChildrenFolder?.id === 40) {
      const link = currentSelected?.link
      const name = currentSelected?.name

      // TODO: regex for non special characters
      const pattern: RegExp =
        // eslint-disable-next-line no-useless-escape
        /^(http(s)?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
      const regexNonSpecialCha: RegExp =
        // eslint-disable-next-line no-irregular-whitespace
        /^[\p{L}\d０-９ 　「」｛｝（）＋！ー＆＃＠〜＊＝：；？＜＞、。＄"｜・\-_!+()&*$@":;<>/?,.[\]#|=]+$/gu

      const isLinkInvalid =
        link && (!pattern.test(link.trim()) || link.trim().length > 500)

      const isNameInvalid =
        name &&
        (!regexNonSpecialCha.test(name.trim()) || name.trim().length > 500)

      if (isLinkInvalid || isNameInvalid) {
        return
      }
      if (!link?.trim()) {
        form.setFields([
          {
            name: 'link',
            errors: [t('translation.annotationLinkRequired')]
          }
        ])
        return
      }
    }

    request()
  }

  const handleSubmit = () => {
    if (selectedFolder === AnnotationType.MEMO) {
      form
        .validateFields(['memo'])
        .then(() => {
          request()
        })
        .catch(() => {})
    } else {
      validateAnnotationLink()
    }
  }

  return {
    form,
    memo,
    isDataExistsAnnotation,
    dataSource,
    childrenFolder,
    currentSelected,
    setCurrentSelected,
    selectedFolder,
    setSelectedFolder,
    selectedChildrenFolder,
    setSelectedChildrenFolder,
    handleBack,
    handleCloseModal,
    handleSubmit
  }
}
