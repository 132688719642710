import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import { iijApiDomain } from 'services/authAxios'

import Title from 'components/atoms/Title'

export default function SmzSignin() {
  const { t } = useTranslation()

  useEffect(() => {
    document.title = 'shimz_デジトリ360'
  }, [])

  return (
    <div className="flex justify-center items-center h-full">
      <div
        className="rounded-[20px] !w-[470px] !h-[438px] p-[60px]"
        style={{
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.15)'
        }}
      >
        <div className="w-[350px] h-[318px] gap-[38px] flex flex-col">
          <Title
            className="!mb-0"
            bottomMargin="mb-0"
            title="common.smzSignin.title"
          />

          <p className="mb-0 leading-[26px]  ">
            {t('common.smzSignin.subTitle')}
          </p>

          <Link
            to={iijApiDomain || ''}
            className="text-primary font-semibold flex justify-center underline"
          >
            <Button type="primary" className="w-full" size="large">
              {t('login.buttonLogin')}
            </Button>
          </Link>

          <p className="mb-0 leading-[26px] text-[13px] #021120">
            {t('common.smzSignin.description')}
          </p>
        </div>
      </div>
    </div>
  )
}
