type Props = {
  size?: 'large' | 'small'
}

export default function HouseIcon({ size }: Props) {
  if (size === 'small') {
    return (
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="28" height="28" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.11133 24.8876V8.83667L13.6113 2.33203L24.1113 8.83667V24.8876H3.11133ZM18.6872 10.6094H8.53463V20.7229H18.6872V10.6094ZM16.8972 12.3923H14.5064V14.7739H16.8972V12.3923ZM10.3254 16.5574H12.7162V18.939H10.3254V16.5574ZM14.5064 16.5574H16.8972V18.939H14.5064V16.5574ZM10.3254 12.3923H12.7162V14.7739H10.3254V12.3923Z"
          fill="#454DFF"
        />
      </svg>
    )
  }

  return (
    <svg
      width="27"
      height="29"
      viewBox="0 0 27 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 29V8.36311L13.5 0L27 8.36311V29H0ZM20.0262 10.6424H6.97281V23.6453H20.0262V10.6424ZM17.7247 12.9347H14.6508V15.9967H17.7247V12.9347ZM9.27524 18.2898H12.3491V21.3518H9.27524V18.2898ZM14.6508 18.2898H17.7247V21.3518H14.6508V18.2898ZM9.27524 12.9347H12.3491V15.9967H9.27524V12.9347Z"
        fill="#454DFF"
      />
    </svg>
  )
}

HouseIcon.defaultProps = {
  size: 'large'
}
