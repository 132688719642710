import Const from 'constants/constant'
import { I18nT } from 'types'
import isEmail from 'validator/es/lib/isEmail'

const regexNonSpecialCha: RegExp =
  // eslint-disable-next-line no-irregular-whitespace
  /^[\p{L}\d０-９/\n 　「」｛｝（）＋！ー＆＃＠〜＊＝：；？＜＞、。＄"｜・\-_!+()&*$@":;<>/?,.[\]#|=]+$/gu
const Validators = class Validators {
  static passwordValidator = (t: I18nT) => (_: any, value?: string) => {
    const pattern: RegExp = /^[a-zA-Z0-9]+$/

    if (
      !value?.trim() ||
      value.trim().length < 8 ||
      value.trim().length > 14 ||
      !pattern.test(value.trim())
    ) {
      const error = t('resetPassword.passwordRequired')
      return Promise.reject(new Error(error))
    }

    return Promise.resolve()
  }

  static confirmPasswordValidator =
    (t: I18nT) =>
    ({ getFieldValue }: any) => ({
      validator(_: any, value?: string) {
        const password = getFieldValue('password')

        if (!value?.trim() || value.trim() !== password) {
          const error = t('resetPassword.confirmPasswordNotMatch')
          return Promise.reject(new Error(error))
        }

        return Promise.resolve()
      }
    })

  static emailValidator = (t: I18nT) => (_: any, value?: string) => {
    const pattern: RegExp =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    if (value && !pattern.test(value)) {
      const error = t('user.invalidEmail')
      return Promise.reject(new Error(error))
    }

    if (
      !value?.trim() ||
      value.trim().length < 2 ||
      value.trim().length > 128
    ) {
      const error = t('forgotPassword.emailRequired')
      return Promise.reject(new Error(error))
    }
    if (!isEmail(value.trim())) {
      const error = t('forgotPassword.invalidEmail')
      return Promise.reject(new Error(error))
    }

    // TODO: 組織のドメインのメールアドレスを入力してください

    return Promise.resolve()
  }

  static emailValidatorDomain =
    (t: I18nT, domain?: string) => (_: any, value?: string) => {
      const pattern: RegExp =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

      if (value && !pattern.test(value)) {
        const error = t('user.invalidEmail')
        return Promise.reject(new Error(error))
      }

      if (value && (value.length < 2 || value.length > 128)) {
        const error = t('user.emailRequired')
        return Promise.reject(new Error(error))
      }

      if (value && !isEmail(value.trim())) {
        const error = t('user.invalidEmail')
        return Promise.reject(new Error(error))
      }

      if (value && domain && !value.endsWith(`@${domain}`)) {
        const error = t('user.domainEmail')
        return Promise.reject(new Error(error))
      }

      return Promise.resolve()
    }

  static keywordValidator = (t: I18nT) => (_: any, value?: string) => {
    if (value && value.length > 100) {
      const error = t('user.keywordOverLength')
      return Promise.reject(new Error(error))
    }

    return Promise.resolve()
  }

  static localPartDomainValidator = (t: I18nT) => (_: any, value?: string) => {
    const pattern: RegExp = /^@?([\w-]+\.)+[\w-]{2,4}$/

    if (value?.trim() && !pattern.test(value.trim())) {
      const error = t('user.invalidDomain')
      return Promise.reject(new Error(error))
    }

    return Promise.resolve()
  }

  static domainValidator = (t: I18nT) => (_: any, value?: string) => {
    const formatPattern: RegExp =
      /^[a-zA-Z0-9]+(?:[\-.][a-zA-Z0-9]+)*\.[a-zA-Z]{2,6}$/ //  eslint-disable-line

    const localPattern: RegExp = /^[-a-zA-Z0-9]+$/

    if (
      value &&
      (!localPattern.test(value.trim().split('.')[0]) || !value.trim())
    ) {
      const error = t('user.invalidDomain')
      return Promise.reject(new Error(error))
    }

    if (value?.trim() && !formatPattern.test(value.trim())) {
      const error = t('user.invalidDomainFormat')
      return Promise.reject(new Error(error))
    }

    return Promise.resolve()
  }

  static keywordCustomValidator =
    (t: I18nT, errKey: number) => (_: any, value?: string) => {
      let error: string
      switch (errKey) {
        case 20:
          error = t('organization.keywordOver20Length')
          break
        case 30:
          error = t('organization.keywordOver30Length')
          break
        case 50:
          error = t('organization.keywordOver50Length')
          break
        case 80:
          error = t('organization.keywordOver80Length')
          break
        case 100:
          error = t('organization.keywordOver100Length')
          break
        case 200:
          error = t('organization.keywordOver200Length')
          break
        default:
          error = t('organization.keywordOverLength')
      }
      if (value && value.trim().length > errKey) {
        return Promise.reject(new Error(error))
      }

      return Promise.resolve()
    }

  static invalidKeywordValidator = (t: I18nT) => (_: any, value?: string) => {
    const pattern: RegExp = new RegExp(regexNonSpecialCha)
    if (value && (!pattern.test(value) || !value.trim())) {
      const error = t('organization.validatedKeywords')
      return Promise.reject(new Error(error))
    }
    return Promise.resolve()
  }

  static keywordKatakanaValidator = (t: I18nT) => (_: any, value?: string) => {
    const pattern: RegExp = /^([ァ-ン]|ー)+$/
    if (value && !value.trim()) {
      const error = t('organization.validatedKeywords')
      return Promise.reject(new Error(error))
    }
    if (value && !pattern.test(value.trim())) {
      const error = t('organization.validateKatakanaKeywords')
      return Promise.reject(new Error(error))
    }
    return Promise.resolve()
  }

  static urlValidator = (t: I18nT) => (_: any, value?: string) => {
    const pattern: RegExp =
      // eslint-disable-next-line no-useless-escape
      /^(http(s)?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-.\/?%&=]*)?/

    if (value && value.length > 100) {
      const error = t('translation.inputOverlength')
      return Promise.reject(new Error(error))
    }

    if (value && !pattern.test(value)) {
      const error = t('organization.validatedUrl')
      return Promise.reject(new Error(error))
    }
    return Promise.resolve()
  }

  static linkAnnotationValidator = (t: I18nT) => (_: any, value?: string) => {
    const pattern: RegExp =
      // eslint-disable-next-line no-useless-escape
      /^(http(s)?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/

    if (value && (!pattern.test(value.trim()) || !value.trim())) {
      const error = t('organization.validatedUrl')
      return Promise.reject(new Error(error))
    }

    if (value?.trim() && value.trim().length > 500) {
      return Promise.reject(new Error(t('building.keywordOver500Length')))
    }

    return Promise.resolve()
  }

  static phoneValidator = (t: I18nT) => (_: any, value?: string) => {
    const pattern: RegExp = /^\d{10,15}$/

    if (value && !pattern.test(value)) {
      const error = t('organization.validatedPhone')
      return Promise.reject(new Error(error))
    }
    return Promise.resolve()
  }

  static zipcodeValidator = (t: I18nT) => (_: any, value?: string) => {
    const pattern: RegExp = /^\d{7}$/

    if (value && (!pattern.test(value.trim()) || !value.trim())) {
      const error = t('organization.validatedZipcode')
      return Promise.reject(new Error(error))
    }
    return Promise.resolve()
  }

  static provinceValidator = (t: I18nT) => (_: any, value?: string) => {
    if (value) {
      const error = t('organization.validatedProvince')
      return Promise.reject(new Error(error))
    }
    return Promise.resolve()
  }

  static keywordExistsValidator = (t: I18nT) => (_: any, value?: string) => {
    // eslint-disable-next-line no-useless-escape
    const pattern: RegExp = new RegExp(regexNonSpecialCha)
    if (value && (!pattern.test(value) || !value.trim())) {
      const error = t('organization.validatedKeywordExists')
      return Promise.reject(new Error(error))
    }
    return Promise.resolve()
  }

  static registerNameValidator = (t: I18nT) => (_: any, value?: string) => {
    if (value && !Const.KANJI_ONLY.test(value)) {
      const error = t('user.validatedKeywords')
      return Promise.reject(new Error(error))
    }

    if (!value?.trim() || (value.trim() && value.trim().length > 30)) {
      const error = t('user.lengthNameValidator')
      return Promise.reject(new Error(error))
    }
    return Promise.resolve()
  }

  static registerNameKatakanaValidator =
    (t: I18nT) => (_: any, value?: string) => {
      const pattern: RegExp = /^([ァ-ン]|ー)+$/
      if (value && !value?.trim()) {
        const error = t('user.validatedKeywords')
        return Promise.reject(new Error(error))
      }
      if (value && !pattern.test(value.trim())) {
        const error = t('user.validateKatakanaKeywords')
        return Promise.reject(new Error(error))
      }
      if (!value || value.trim().length > 80) {
        const error = t('user.lengthNameKatakanaValidator')
        return Promise.reject(new Error(error))
      }
      return Promise.resolve()
    }

  static companyNameValidator = (t: I18nT) => (_: any, value?: string) => {
    const pattern: RegExp = new RegExp(regexNonSpecialCha)

    if (value && (!pattern.test(value) || !value?.trim())) {
      const error = t('organization.validatedKeywords')
      return Promise.reject(new Error(error))
    }

    if (!value || value.trim().length > 80) {
      const error = t('organization.keywordOver80Length')
      return Promise.reject(new Error(error))
    }

    return Promise.resolve()
  }

  static nameValidator = (t: I18nT) => (_: any, value?: string) => {
    const pattern: RegExp = new RegExp(regexNonSpecialCha)

    if (value && (!pattern.test(value) || !value?.trim())) {
      const error = t('common.validationError.nameSpecialCharacters')
      return Promise.reject(new Error(error))
    }

    if (!value || value.trim().length > 30) {
      const error = t('common.validationError.nameOverLength')
      return Promise.reject(new Error(error))
    }

    return Promise.resolve()
  }

  static kanaNameValidator = (t: I18nT) => (_: any, value?: string) => {
    const regexKana = /^([ァ-ン]|ー)+$/
    if (value && !value.trim()) {
      const error = t('common.validationError.nameSpecialCharacters')
      return Promise.reject(new Error(error))
    }
    if (value && !regexKana.test(value.trim())) {
      const error = t('common.validationError.kanaNameSpecialCharacters')
      return Promise.reject(new Error(error))
    }
    if (!value || value.trim().length > 80) {
      const error = t('common.validationError.kanaNameOverLength')
      return Promise.reject(new Error(error))
    }
    return Promise.resolve()
  }

  static phoneNumberValidator = (t: I18nT) => (_: any, value?: string) => {
    const regexPhoneHalfWidth = /^[0-9]+$/

    if (value && !regexPhoneHalfWidth.test(value)) {
      const error = t('common.validationError.phoneNumberHalfWidth')
      return Promise.reject(new Error(error))
    }

    if (
      !value?.trim() ||
      value.trim().length < 10 ||
      value.trim().length > 15
    ) {
      const error = t('common.validationError.phoneNumberHalfWidth')
      return Promise.reject(new Error(error))
    }

    return Promise.resolve()
  }

  static phoneNumberValidatorNoRequired =
    (t: I18nT) => (_: any, value?: string) => {
      const regexPhoneHalfWidth = /^[0-9]+$/

      if (value && !regexPhoneHalfWidth.test(value)) {
        const error = t('common.validationError.phoneNumberHalfWidth')
        return Promise.reject(new Error(error))
      }

      if (value && (value.length < 10 || value.length > 15)) {
        const error = t('common.validationError.phoneNumberHalfWidth')
        return Promise.reject(new Error(error))
      }

      return Promise.resolve()
    }

  static organizationNameValidator = (t: I18nT) => (_: any, value?: string) => {
    if (!value?.trim() || (value.trim() && value.trim().length > 80)) {
      const error = t('common.validationError.organizationNameOverLength')
      return Promise.reject(new Error(error))
    }

    return Promise.resolve()
  }

  static nameBuildingValidator = (t: I18nT) => (_: any, value?: string) => {
    const pattern: RegExp = new RegExp(regexNonSpecialCha)
    if (value && (!pattern.test(value) || !value.trim())) {
      const error = t('building.nameBuildingValidate')
      return Promise.reject(new Error(error))
    }

    if (!value || value.trim().length > 200) {
      const error = t('building.nameBuildingOver')
      return Promise.reject(new Error(error))
    }

    return Promise.resolve()
  }

  static codeValidator = (t: I18nT) => (_: any, value?: string) => {
    const regexPhoneHalfWidth = /^[A-Za-z0-9-]{1,250}$/

    if (value && !value.trim()) {
      const error = t('building.nameBuildingValidate')
      return Promise.reject(new Error(error))
    }

    if (value && !regexPhoneHalfWidth.test(value.trim())) {
      const error = t('building.codeHalfWidth')
      return Promise.reject(new Error(error))
    }

    return Promise.resolve()
  }

  static aliasNameValidator = (t: I18nT) => (_: any, value?: string) => {
    const pattern: RegExp = new RegExp(regexNonSpecialCha)
    if (value && (!pattern.test(value) || !value.trim())) {
      const error = t('building.nameBuildingValidate')
      return Promise.reject(new Error(error))
    }

    if (value && value.trim().length > 200) {
      const error = t('building.aliasNameOver')
      return Promise.reject(new Error(error))
    }

    return Promise.resolve()
  }

  static requiredInputValidator =
    (t: I18nT, fieldName: string) => (_: any, value?: string) => {
      if (!value?.trim()) {
        const error = t('common.emptyInputField', {
          fieldName
        })
        return Promise.reject(new Error(error))
      }

      return Promise.resolve()
    }

  static addFloorValidator = (t: I18nT) => (_: any, value?: string) => {
    const pattern: RegExp = new RegExp(regexNonSpecialCha)
    if (value && (!pattern.test(value.trim()) || !value.trim())) {
      const error = t('common.validationError.nameSpecialCharacters')
      return Promise.reject(new Error(error))
    }

    if (!value || value.trim().length > 200) {
      const error = t('common.validationError.nameFloorOverLength')
      return Promise.reject(new Error(error))
    }

    return Promise.resolve()
  }

  static inputAreaValidator = (t: I18nT) => (_: any, value?: string) => {
    const pattern: RegExp = /^\d+(\.\d+)?$/

    if (value) {
      if (!pattern.test(value)) {
        const error = t('common.validationError.numericError')
        return Promise.reject(new Error(error))
      }

      if (Number(value) === 0) {
        const error = t('common.validationError.inputMustBePositive')
        return Promise.reject(new Error(error))
      }

      if (
        (value.includes('.') && value.split('.')[1].length > 2) ||
        (!value.includes('.') && value.length > 10) ||
        (value.includes('.') && value.length > 11)
      ) {
        const error = t('common.validationError.numericError')
        return Promise.reject(new Error(error))
      }
    }

    return Promise.resolve()
  }

  static keywordsAndLengthCustomValidator =
    (t: I18nT, errKey: number, isRequired?: boolean, isTrim?: boolean) =>
    (_: any, value?: string) => {
      const pattern: RegExp = new RegExp(regexNonSpecialCha)

      const trimedValue = isTrim ? value?.trim() : value

      let error: string
      switch (errKey) {
        case 20:
          error = t('organization.keywordOver20Length')
          break
        case 30:
          error = t('organization.keywordOver30Length')
          break
        case 50:
          error = t('organization.keywordOver50Length')
          break
        case 80:
          error = t('organization.keywordOver80Length')
          break
        case 100:
          error = t('organization.keywordOver100Length')
          break
        case 200:
          error = t('organization.keywordOver200Length')
          break
        case 500:
          error = t('building.keywordOver500Length')
          break
        case 2000:
          error = t('building.keywordOver2000Length')
          break
        default:
          error = t('organization.keywordOverLength')
      }

      if (isRequired && !value) {
        return Promise.reject(new Error(error))
      }

      if (trimedValue && trimedValue.length > errKey) {
        return Promise.reject(new Error(error))
      }

      if (value && (!trimedValue || !pattern.test(trimedValue))) {
        const error = t('organization.validatedKeywordExists')
        return Promise.reject(new Error(error))
      }

      return Promise.resolve()
    }

  static memoValidator = (t: I18nT) => (_: any, value?: string) => {
    const pattern: RegExp = new RegExp(regexNonSpecialCha)
    const input = value?.split('\n').join('').trim() || ''
    let error: string
    if (value && value.length > 500) {
      error = t('building.keywordOver500Length')
      return Promise.reject(new Error(error))
    }

    if (value && (!pattern.test(input) || !input)) {
      const error = t('organization.validatedKeywordExists')
      return Promise.reject(new Error(error))
    }

    return Promise.resolve()
  }

  static searchFileValidator =
    (t: I18nT, isRequired?: boolean, isTrim?: boolean) =>
    (_: any, value?: string) => {
      const pattern: RegExp = new RegExp(regexNonSpecialCha)
      const trimedValue = isTrim ? value?.trim() : value
      const maxLengthValue = 100
      const errorLength = t('translation.maxLengthSearchFile')
      const errorValue = t('organization.validatedKeywordExists')

      if (isRequired && !value) {
        return Promise.reject(new Error(errorLength))
      }

      if (trimedValue && trimedValue.length > maxLengthValue) {
        return Promise.reject(new Error(errorLength))
      }

      if (value && (!trimedValue || !pattern.test(trimedValue))) {
        return Promise.reject(new Error(errorValue))
      }

      return Promise.resolve()
    }
}
export default Validators
