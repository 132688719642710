import building from './building.json'
import common from './common.json'
import forgotPassword from './forgotPassword.json'
import login from './login.json'
import organization from './organization.json'
import resetPassword from './resetPassword.json'
import translation from './translation.json'
import user from './user.json'

export default {
  translation: {
    translation,
    common,
    forgotPassword,
    resetPassword,
    user,
    login,
    organization,
    building
  }
}
