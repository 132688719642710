import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Typography } from 'antd'
import clsx from 'clsx'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  buildingFilesState,
  listPointState,
  revalidateFileListState
} from 'store/buildingStore'
import useSWR from 'swr'
import { BuildingFile, Folders, ListBuildingFiles } from 'types/building'

import Button from 'components/atoms/Button'
import Spinner from 'components/atoms/Spinner'
import LeftArrowIcon from 'components/icons/LeftArrowIcon'

import Image360Detail from './360ImageDetail'
import BuildingFileDetail from './BuildingFileDetail'
import UploadFileMenu from './MenuUploadFile'
import ModalDelete360Image from './ModalDelete360Image'
import ModalDeleteFile from './ModalDeleteFile'

type Props = {
  handleBack: (() => void) | undefined
  selectedFolder: { doc: Folders; subDoc?: Folders } | undefined
}

export default function InstructionMenu({ handleBack, selectedFolder }: Props) {
  const { t } = useTranslation()
  const { id, floorId } = useParams()
  const [selectImage, setSelectImage] = useState<number>()
  const [uploadFileStep, setUploadFileStep] = useState(false)
  const [deleteFile, setDeleteFile] = useState<BuildingFile | undefined>(
    undefined
  )
  const [confirmDelete, setConfirmDelete] = useState<
    | {
        id: number
        name: string
      }
    | undefined
  >()
  const points = useRecoilValue(listPointState)
  const [buildingFiles, setBuildingFiles] = useRecoilState(buildingFilesState)
  const [revalidateFileList, setRevalidateFileList] = useRecoilState(
    revalidateFileListState
  )

  const sorted360Images = useMemo(
    () =>
      buildingFiles
        .map((file) => {
          const pointInFloor = points.find(
            (point) =>
              point.id === file.pointId || point.image360?.id === file.id
          )
          // file is used in other floor if file tied to a point and that point is not current floor
          const isInFloor = Boolean(!floorId || pointInFloor || !file.pointId)
          return {
            ...file,
            isInFloor,
            pointOrder: pointInFloor?.order || 0
          }
        })
        .sort((a, b) => {
          if (a.isInFloor && b.isInFloor) {
            return !a.pointOrder && !b.pointOrder
              ? a.name.localeCompare(b.name)
              : a.pointOrder - b.pointOrder
          }
          return +b.isInFloor - +a.isInFloor
        }),
    [buildingFiles, floorId, points]
  )

  const sortedBuildingFiles = useMemo(
    () => [...buildingFiles].sort((a, b) => a.name.localeCompare(b.name)),
    [buildingFiles]
  )

  const sortedCondition = useMemo(
    () =>
      selectedFolder && selectedFolder.doc.isFileHolder
        ? selectedFolder.doc?.id
        : selectedFolder?.subDoc?.id,
    [selectedFolder]
  )

  const { data, isValidating, mutate } = useSWR<ListBuildingFiles>({
    url: 'v1/buildings/files',
    params: {
      buildingId: id,
      floorId,
      category: sortedCondition
    }
  })

  const handleSwitchMenu = () => {
    setUploadFileStep((prev) => !prev)
  }

  const handleSelectImage = (id: number) => {
    if (selectImage === id) {
      setSelectImage(undefined)
      return
    }
    setSelectImage(id)
  }

  const handleToggleDeleteModal = (
    key: { id: number; name: string } | undefined
  ) => {
    setConfirmDelete(key)
  }

  const handleToggleDeleteFile = (file: BuildingFile | undefined) => {
    setDeleteFile(file)
  }

  const handleSetRevalidateByCategory = () => {
    setRevalidateFileList(sortedCondition)
  }

  useEffect(() => {
    if (data?.files && !isValidating) {
      setBuildingFiles(data.files)
    }
  }, [data?.files, isValidating, setBuildingFiles])

  useEffect(() => {
    if (revalidateFileList) {
      if (revalidateFileList === sortedCondition) {
        mutate()
      }
      setRevalidateFileList(undefined)
    }
  }, [mutate, revalidateFileList, setRevalidateFileList, sortedCondition])

  return (
    <>
      <div className="w-full grid grid-rows-[1fr_auto] overflow-y-auto">
        {uploadFileStep ? (
          <UploadFileMenu
            documentBreadcrumb={selectedFolder}
            handleSwitchMenu={handleSwitchMenu}
            selectedFolder={selectedFolder}
          />
        ) : (
          <>
            <div className="grid grid-rows-[auto_1fr] overflow-auto">
              {handleBack && (
                <div
                  className="flex gap-3 align-middle min-h-[50px] p-[10px] cursor-pointer w-full border-0 border-b border-solid border-[#1C314D]"
                  onClick={handleBack}
                >
                  <LeftArrowIcon />
                  <Typography className="text-white font-[700] text-[14px] my-auto overflow-hidden">
                    {selectedFolder?.doc?.label}
                    {selectedFolder?.subDoc ? (
                      <>&nbsp;&gt;&nbsp;{selectedFolder.subDoc.label}</>
                    ) : (
                      ''
                    )}
                  </Typography>
                </div>
              )}

              <div
                className={clsx('overflow-auto relative', {
                  'opacity-50': isValidating
                })}
              >
                {selectedFolder?.doc.id !== 1 && !isValidating
                  ? sortedBuildingFiles.map((item: BuildingFile) => (
                      <BuildingFileDetail
                        item={item}
                        handleToggleDeleteFile={handleToggleDeleteFile}
                        key={item.id}
                      />
                    ))
                  : null}

                {selectedFolder?.doc.id === 1 && !isValidating
                  ? sorted360Images.map((item) => (
                      <Image360Detail
                        item={item}
                        key={item.id}
                        handleSelectImage={handleSelectImage}
                        selectImage={selectImage}
                        isInFloor={item.isInFloor}
                        pointOrder={item.pointOrder}
                        confirmDelete={handleToggleDeleteModal}
                      />
                    ))
                  : null}

                {!isValidating && !buildingFiles.length ? (
                  <Typography className="mt-[14px] text-center text-[13px] leading-[19.5px] text-white">
                    {t('building.fileNotFound')}
                  </Typography>
                ) : null}
              </div>
            </div>

            {isValidating && <Spinner />}

            <div className="flex h-[3.75rem] border-0 border-t border-solid border-[#1C314D]">
              <Button
                type="primary"
                className="mt-[10px] m-auto"
                sizing="h-[40px] w-[160px]"
                onClick={handleSwitchMenu}
              >
                {t('common.upload')}
              </Button>
            </div>
          </>
        )}
      </div>

      {deleteFile && (
        <ModalDeleteFile
          handleToggleDeleteFile={handleToggleDeleteFile}
          handleSetRevalidateByCategory={handleSetRevalidateByCategory}
          item={deleteFile}
        />
      )}

      {confirmDelete && (
        <ModalDelete360Image
          selectedFile={confirmDelete}
          handleToggle={handleToggleDeleteModal}
          handleSetRevalidateByCategory={handleSetRevalidateByCategory}
        />
      )}
    </>
  )
}
