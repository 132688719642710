import { useTranslation } from 'react-i18next'
import { Form, Input } from 'antd'
import { SearchUser } from 'types/listUser'
import Utils from 'utils'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'

type Props = {
  handleSearch: (value: SearchUser) => void
}
export default function FormSearchOrganization({ handleSearch }: Props) {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const onSubmit = (value: SearchUser) => {
    handleSearch(Utils.trimObjectValues(value))
    form.setFieldsValue(Utils.trimObjectValues(value))
  }

  return (
    <Form
      layout="horizontal"
      className="flex items-center w-full"
      onFinish={onSubmit}
      colon={false}
      form={form}
      initialValues={{ role: '' }}
    >
      <Form.Item
        className="flex-1 label-custom-select text-[14px] font-medium mb-0"
        label={<span className="text-sm font-medium">{t('user.keyword')}</span>}
        name="keyword"
        rules={[{ validator: Validators.keywordValidator(t) }]}
      >
        <Input className="h-10" />
      </Form.Item>

      <Button
        type="primary"
        className="ml-24"
        sizing="w-40 h-10"
        htmlType="submit"
      >
        {t('user.buttonSearch')}
      </Button>
    </Form>
  )
}
