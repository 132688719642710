import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import Const from 'constants/constant'
import EventEmitter from 'events'
import { TokenBundle } from 'types'
import Utils from 'utils'

export const emit = new EventEmitter()

export const apiDomain = process.env.REACT_APP_API_URL
export const iijApiDomain = process.env.REACT_APP_IIJ_API_URL

export const storeIdTokens = (tokenBundle: TokenBundle) => {
  localStorage.setItem('idToken', tokenBundle.idToken)
  if (tokenBundle.refreshToken) {
    localStorage.setItem('refreshToken', tokenBundle.refreshToken)
  }
}

export const getToken = () => {
  const refreshToken =
    localStorage.getItem('refreshToken') || Utils.getCookie('refreshToken')
  const idToken = localStorage.getItem('idToken') || Utils.getCookie('idToken')
  return { refreshToken, idToken }
}

export const clearIdTokens = () => {
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('idToken')
  Utils.deleteCookie('refreshToken')
  Utils.deleteCookie('idToken')
}

let refreshTokenRequest: null | Promise<any> = null

const instance = axios.create({
  baseURL: apiDomain,
  headers: {
    'Content-Type': 'application/json'
  }
})

const refresh = async () => {
  try {
    const { refreshToken } = getToken()

    const response = await axios
      .create({
        baseURL: apiDomain
      })
      .post(`/v1/auth/token/refresh`, {
        refreshToken
      })
    storeIdTokens(response.data.data)
    refreshTokenRequest = null

    return response.data.idToken
  } catch (error: any) {
    refreshTokenRequest = null
    clearIdTokens()

    // TODO: BE need to change to 401
    if (error?.response?.status === 400) {
      emit.emit(Const.UNAUTHORIZED_ERROR)
    }

    return Promise.reject(error)
  }
}

const onRequest: any = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const { idToken } = getToken()
  if (config.headers) config.headers.Authorization = `Bearer ${idToken}`
  return config
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error)
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response
}

const onResponseError = async (error: AxiosError): Promise<any> => {
  if (
    error.response &&
    error.response.status === 401 &&
    error.config &&
    !error.config.url?.includes('login')
  ) {
    refreshTokenRequest = refreshTokenRequest || refresh()

    const newIdToken = await refreshTokenRequest

    error.config.headers = {
      ...(error.config.headers as any),
      authorization: `Bearer ${newIdToken}`
    }

    refreshTokenRequest = null

    return instance(error.config)
  }

  return Promise.reject(error)
}

instance.interceptors.request.use(onRequest, onRequestError)
instance.interceptors.response.use(onResponse, onResponseError)

export default instance
