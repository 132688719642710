import { Dispatch, SetStateAction } from 'react'
import { Typography } from 'antd'
import { Folders } from 'types/building'

import RightArrowIcon from 'components/icons/RightArrowIcon'

import FolderIcon from './FolderIcon'

type Props = {
  selectedFolder: Folders | undefined
  setSelectedFolder: Dispatch<SetStateAction<Folders | undefined>>
  selectedChildrenFolder: Folders | undefined
  setSelectedChildrenFolder: Dispatch<SetStateAction<Folders | undefined>>
}

export default function ListChildrenFolder({
  selectedFolder,
  setSelectedFolder,
  selectedChildrenFolder,
  setSelectedChildrenFolder
}: Props) {
  const isDisplay =
    selectedFolder && !selectedFolder.isFileHolder && !selectedChildrenFolder

  const handleBack = () => {
    setSelectedFolder(undefined)
  }

  if (!isDisplay) return null

  return (
    <>
      <div />
      <div>
        <div className="px-5 bg-[#3163B7]">
          <div
            className="flex gap-4 py-1 items-center mr-auto cursor-pointer"
            onClick={handleBack}
          >
            <FolderIcon type={selectedFolder.id} size="small" />
            <Typography className="text-white font-medium">
              {selectedFolder?.label}
            </Typography>
          </div>
        </div>
        {selectedFolder?.children?.map((item) => (
          <div
            key={item.id}
            className="bg-white h-[60px] px-5 flex items-center border-0 border-b border-solid border-[#EEEFF2] cursor-pointer"
            onClick={() => setSelectedChildrenFolder(item)}
          >
            <Typography className="font-medium text-[#021120]">
              {item.label}
            </Typography>
            <div className="ml-auto">
              <RightArrowIcon color="#3B3C46" size={24} />
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
