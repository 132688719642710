import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function PreviewIconSVG() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.394 12.796L7.8495 18.334C7.0395 18.8035 6 18.235 6 17.2885V6.21252C6 5.26752 7.038 4.69752 7.8495 5.16852L17.394 10.7065C17.5783 10.8117 17.7314 10.9638 17.8379 11.1472C17.9445 11.3307 18.0006 11.5391 18.0006 11.7513C18.0006 11.9634 17.9445 12.1718 17.8379 12.3553C17.7314 12.5388 17.5783 12.6908 17.394 12.796Z"
        fill="white"
      />
    </svg>
  )
}
export default function PreviewIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={PreviewIconSVG} {...props} />
}
