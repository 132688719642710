import { AxiosResponse } from 'axios'
import { snakeCase } from 'lodash'

import authAxios from './authAxios'

const defaultHeaders = {
  'Content-Type': 'application/json'
}

export type APIParams = Record<string, string | number | string[] | number[]>

class Api {
  static get = async <T>(
    url: string,
    params?: APIParams,
    headers = defaultHeaders
  ) => {
    const snakeCaseParams: APIParams = {}

    if (params) {
      Object.keys(params).forEach((key) => {
        if (params[key] !== undefined && params[key] !== '') {
          snakeCaseParams[snakeCase(key)] = params[key]
        }
      })
    }

    return authAxios<any, AxiosResponse<{ data: T }>>(url, {
      method: 'GET',
      params: snakeCaseParams,
      headers
    }).then((response) => response.data.data)
  }

  static post = async <T>(url: string, data: T, headers = defaultHeaders) =>
    authAxios(url, {
      method: 'POST',
      headers,
      data
    }).then((response) => response.data)

  static patch = async <T>(url: string, data: T, headers = defaultHeaders) =>
    authAxios(url, {
      method: 'PATCH',
      headers,
      data
    }).then((response) => response.data)

  static put = async <T>(url: string, data: T, headers = defaultHeaders) =>
    authAxios(url, {
      method: 'PUT',
      headers,
      data
    }).then((response) => response.data)

  static delete = async <T>(url: string, data: T, headers = defaultHeaders) =>
    authAxios(url, {
      method: 'DELETE',
      headers,
      data
    }).then((response) => response.data)
}

export default Api
