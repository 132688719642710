import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown, Typography } from 'antd'
import Const from 'constants/constant'
import useAuth from 'hooks/useAuth'
import { useRecoilState } from 'recoil'
import { isUploadingMapState } from 'store/buildingStore'

import MenuIcon from 'components/icons/MenuIcon'

import ModalDefaultImage from './ModalDefaultImage'
import DeleteFloorModal from './ModalDelete'
import EditNameModal from './ModalEditName'

type Props = {
  floorName: string
}

export default function FloorDropDownMenu({ floorName }: Props) {
  const { profile } = useAuth()
  const { t } = useTranslation()

  const [openedModal, setOpenedModal] = useState('')
  const [, setIsUploadingMap] = useRecoilState(isUploadingMapState)

  const handleOpenModal = (key: string) => () => {
    if (key === 'handleUploadMap') {
      setIsUploadingMap((prev) => ({ ...prev, isOpen: true }))
      return
    }
    setOpenedModal(key)
  }

  const items = Const.SIDEBAR_DETAIL_FLOOR_MENU.filter(
    (item) => profile?.role && item.role.includes(profile?.role)
  ).map(({ label, key }) => {
    return {
      key,
      label: (
        <Typography className="text-white text-[12px] font-[700] whitespace-nowrap">
          {t(label)}
        </Typography>
      ),
      onClick: handleOpenModal(key)
    }
  })

  return (
    <div className="h-full cursor-pointer">
      <Dropdown
        menu={{ items }}
        className="w-auto"
        placement="bottomRight"
        trigger={['click']}
        rootClassName="dropdown-custom"
      >
        <div className="h-full flex my-auto pr-[10px]">
          <MenuIcon />
        </div>
      </Dropdown>

      {openedModal === 'handleDelete' && (
        <DeleteFloorModal floorName={floorName} setIsOpen={setOpenedModal} />
      )}

      {openedModal === 'handleEditName' && (
        <EditNameModal setIsOpen={setOpenedModal} />
      )}

      {openedModal === 'handleDefaultImage' && (
        <ModalDefaultImage setIsOpen={setOpenedModal} />
      )}
    </div>
  )
}
