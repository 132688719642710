import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Typography } from 'antd'
import dayjs from 'dayjs'
import useMutation from 'hooks/useMutation'
import { useRecoilValue } from 'recoil'
import { selectedPoint, selectedPointDetail } from 'store/buildingStore'
import { PointHistory } from 'types/building'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'

type Props = {
  selectedVersion: PointHistory
  handleModalButtonClick: (isRefetchPointList?: boolean) => void
}

export default function ModalConfirmSelectVersion({
  selectedVersion,
  handleModalButtonClick
}: Props) {
  const { id, floorId } = useParams()
  const { t } = useTranslation()

  const currentSelectedPoint = useRecoilValue(selectedPoint)
  const pointDetail = useRecoilValue(selectedPointDetail)

  const { trigger, isMutating } = useMutation(
    `v1/points/${currentSelectedPoint}/restore`
  )

  const handleSubmitVersion = () => {
    if (!isMutating) {
      trigger(
        {
          data: {
            buildingId: Number(id),
            floorId: Number(floorId),
            versionId: selectedVersion?.versionId
          }
        },
        {
          onSuccess: () => {
            handleModalButtonClick(true)
          },
          onError: () => {
            handleModalButtonClick()
          }
        }
      )
    }
  }

  return (
    <Modal width="480px" isEnterable onEnter={handleSubmitVersion}>
      <Title title={t('building.editPointHistory')} />
      <Typography className="text-sm text-black text-center my-10">
        {t('building.titleRestoreModal')}
      </Typography>

      <div className="w-[360px] h-[60px] p-2 bg-[#F9FBFF] text-center">
        <div className="text-base text-black">
          {dayjs(selectedVersion.editedAt).format('MM/DD')}
        </div>
        {pointDetail?.versionId === selectedVersion.versionId && (
          <div className="italic text-xs text-[#444746]">
            {t('building.currentVersion')}
          </div>
        )}
        <Typography.Text
          className="text-base text-black"
          ellipsis={{
            tooltip: selectedVersion.user.isDeleted
              ? t('building.deletedUser')
              : `${selectedVersion.user.lastName} ${selectedVersion.user.firstName}`
          }}
        >
          {selectedVersion.user.isDeleted
            ? t('building.deletedUser')
            : `${selectedVersion.user.lastName} ${selectedVersion.user.firstName}`}
        </Typography.Text>
      </div>

      <div className="flex gap-10 justify-center mt-10">
        <Button
          sizing="w-[140px] h-[50px]"
          onClick={() => handleModalButtonClick()}
        >
          {t('building.buttonCancel')}
        </Button>

        <Button
          sizing="w-[140px] h-[50px]"
          type="primary"
          loading={isMutating}
          onClick={handleSubmitVersion}
        >
          {t('building.buttonRestore')}
        </Button>
      </div>
    </Modal>
  )
}
