import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Typography } from 'antd'
import Const from 'constants/constant'
import TableColumn from 'constants/tableColumn'
import usePermissions from 'hooks/usePermissions'
import { ListOrganizations } from 'models/Organization'
import useSWR from 'swr'
import { OrderByType } from 'types'
import { ParamQueryOrganization } from 'types/listOrganization'
import Utils from 'utils'

import Button from 'components/atoms/Button'
import ExtraTable from 'components/organisms/ExtraTable'

import FormSearch from './components/FormSearch'

export default function OrganizationList() {
  const { t } = useTranslation()
  const { isCreate } = usePermissions('organization')

  const [queries, setQueries] = useState<ParamQueryOrganization | null>({
    sort: 'id',
    sortOrder: OrderByType.descend,
    pageSize: Const.DEFAULT_PAGINATION.defaultPageSize,
    page: Const.DEFAULT_PAGINATION.current,
    fields: 'buildingCount'
  })

  const {
    data: organizations,
    isValidating,
    mutate
  } = useSWR<ListOrganizations>({
    url: `/v1/organizations`,
    params: queries
  })

  const dataSource = useMemo(
    () =>
      organizations?.edges.map((item) => ({
        ...item,
        name: (
          <div className="grid grid-cols-1">
            <Typography.Paragraph
              className="!mb-0"
              ellipsis={{
                rows: 2,
                tooltip: item.name
              }}
            >
              {item.name}
            </Typography.Paragraph>
          </div>
        )
      })) || [],
    [organizations?.edges]
  )

  const onTableChange = useCallback((sorter: string, field: string) => {
    setQueries((prev) => ({
      ...prev,
      sort: sorter ? field : 'id',
      sortOrder: sorter
        ? OrderByType[sorter as keyof typeof OrderByType]
        : OrderByType.descend,
      page: 1
    }))
  }, [])

  const handleSearch = (value: any) => {
    setQueries((prev) => ({
      ...prev,
      ...Utils.trimObjectValues(value),
      page: 1
    }))
  }

  return (
    <>
      <div className="flex justify-between pb-5 items-center">
        <div className="font-bold text-xl">{t('organization.title')}</div>
        {isCreate && (
          <Link to="register" className="no-underline">
            <Button type="primary" sizing="w-[160px] h-[40px]">
              {t('organization.buttonAdd')}
            </Button>
          </Link>
        )}
      </div>

      <div className="px-10 py-5 bg-white rounded-lg shadow-sm mb-5 lg:px-[30px]">
        <FormSearch handleSearch={handleSearch} />
      </div>
      <ExtraTable
        isLoading={isValidating && !organizations}
        columns={TableColumn.COLUMN_ORGANIZATION}
        data={dataSource}
        onChange={onTableChange}
        total={organizations?.pagination.total}
        pagination={{
          page: queries?.page || 1,
          pageSize: queries?.pageSize || 10,
          setQueries,
          queries
        }}
        refetch={mutate}
      />
    </>
  )
}
