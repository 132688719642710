import clsx from 'clsx'
import AreaPanorama from 'pages/building/panoramas/AreaPanorama'
import { useRecoilValue } from 'recoil'
import { buildingModeState, selectedAreaState } from 'store/buildingStore'

import LeafletMap from './LeafletMap'
import PointActionsMenu from './PointActionsMenu'

type Props = {
  imagePath: string
  isLoadingMap: boolean
}

export default function FloorArea({ imagePath, isLoadingMap }: Props) {
  const buildingMode = useRecoilValue(buildingModeState)
  const selectedArea = useRecoilValue(selectedAreaState)

  return (
    <div
      className={clsx('w-full h-full', {
        'is-link-mode': buildingMode.selectedMode === 'link'
      })}
    >
      {selectedArea ? (
        <>
          <PointActionsMenu />
          <AreaPanorama />
        </>
      ) : null}

      {imagePath && !buildingMode.previewMode && (
        <LeafletMap imagePath={imagePath} isLoadingMap={isLoadingMap} />
      )}
    </div>
  )
}
