import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function LinkSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.23047 13.5998L8.13519 14.7109L11.9991 12.457L15.8637 14.7109L17.7685 13.5998L11.9991 10.2344L6.23047 13.5998Z"
        fill="white"
      />
      <path
        d="M11.9996 6C5.37216 6.00041 0.000703125 9.13376 0 13.0002C0.000703125 16.8662 5.37216 19.9996 11.9996 20C18.6278 19.9996 23.9993 16.8662 24 13.0002C23.9993 9.13376 18.6278 6.00041 11.9996 6ZM19.1524 17.1722C17.3188 18.2408 14.796 18.9001 11.9996 18.9005C9.20367 18.9001 6.68086 18.2408 4.84758 17.1722C3.01537 16.1028 1.88522 14.6312 1.88484 13.0002C1.88522 11.369 3.01537 9.89717 4.84758 8.82775C6.68086 7.75897 9.20367 7.09971 11.9996 7.09949C14.796 7.09971 17.3188 7.75897 19.1524 8.82775C20.9842 9.89717 22.1148 11.369 22.1152 13.0002C22.1148 14.6312 20.9846 16.1028 19.1524 17.1722Z"
        fill="white"
      />
    </svg>
  )
}

function SelectedLinkSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.23047 13.5998L8.13519 14.7109L11.9991 12.457L15.8637 14.7109L17.7685 13.5998L11.9991 10.2344L6.23047 13.5998Z"
        fill="#609DF9"
      />
      <path
        d="M11.9996 6C5.37216 6.00041 0.000703125 9.13376 0 13.0002C0.000703125 16.8662 5.37216 19.9996 11.9996 20C18.6278 19.9996 23.9993 16.8662 24 13.0002C23.9993 9.13376 18.6278 6.00041 11.9996 6ZM19.1524 17.1722C17.3188 18.2408 14.796 18.9001 11.9996 18.9005C9.20367 18.9001 6.68086 18.2408 4.84758 17.1722C3.01537 16.1028 1.88522 14.6312 1.88484 13.0002C1.88522 11.369 3.01537 9.89717 4.84758 8.82775C6.68086 7.75897 9.20367 7.09971 11.9996 7.09949C14.796 7.09971 17.3188 7.75897 19.1524 8.82775C20.9842 9.89717 22.1148 11.369 22.1152 13.0002C22.1148 14.6312 20.9846 16.1028 19.1524 17.1722Z"
        fill="#609DF9"
      />
    </svg>
  )
}

export default function LinkIcon(
  props: Partial<CustomIconComponentProps> & { selected?: boolean }
) {
  const { selected, ...rest } = props

  if (selected) return <Icon component={SelectedLinkSvg} {...rest} />

  return <Icon component={LinkSvg} {...rest} />
}

LinkIcon.defaultProps = {
  selected: undefined
}
