import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Form, notification, Typography } from 'antd'
import Const from 'constants/constant'
import TableColumn from 'constants/tableColumn'
import dayjs from 'dayjs'
import useAuth from 'hooks/useAuth'
import useMutation from 'hooks/useMutation'
import { ListUsers } from 'models/User'
import UserInviteStatus from 'pages/user/listUser/components/UserInviteStatus'
import useSWR from 'swr'
import { OrderByType } from 'types'
import { ParamQueryUser } from 'types/listUser'
import Utils from 'utils'

import Button from 'components/atoms/Button'
import ExtraTable from 'components/organisms/ExtraTable'

import FormSearch from './components/FormSearch'
import InviteUser from './components/InviteUser'

export default function UserList() {
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const [queries, setQueries] = useState<ParamQueryUser | null>({
    sort: 'id',
    sortOrder: OrderByType.descend,
    pageSize: Const.DEFAULT_PAGINATION.defaultPageSize,
    page: Const.DEFAULT_PAGINATION.current
  })
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { profile } = useAuth()

  const {
    data: users,
    isValidating,
    mutate
  } = useSWR<ListUsers>({
    url: `/v1/users`,
    params: queries
  })

  const { trigger, isMutating } = useMutation('v1/users/invite', {
    onSuccess: () => {
      notification.success({ message: '送信しました' })
      setIsModalOpen(false)
      mutate()
    },
    onError: (error) => {
      Utils.handleError(error, t, form)
    }
  })

  const listUser = useMemo(
    () =>
      users?.edges?.map((item) => {
        return {
          ...item,
          role: t(`user.${item.role}`),
          organizationName: (
            <div className="grid grid-cols-1">
              <Typography.Paragraph
                className="!mb-0"
                ellipsis={{
                  rows: 2,
                  tooltip: item.organization?.name || item.companyName
                }}
              >
                {item.organization?.name || item.companyName}
              </Typography.Paragraph>
            </div>
          ),
          email: (
            <div className="grid grid-cols-1">
              <Typography.Paragraph
                className="!mb-0"
                ellipsis={{
                  rows: 2,
                  tooltip: item.email
                }}
              >
                {item.email}
              </Typography.Paragraph>
            </div>
          ),
          avatar: (
            <Avatar
              className="min-w-[2.5rem] text-black font-light"
              size="large"
            >
              {item.lastName.split('')[0]}
              {item.firstName.split('')[0]}
            </Avatar>
          ),
          name: (
            <div className="grid grid-cols-1">
              <Typography.Paragraph
                className="!mb-0"
                ellipsis={{
                  rows: 2,
                  tooltip: `${item.lastName} ${item.firstName}`
                }}
              >
                <div>{`${item.lastName} ${item.firstName}`} </div>
              </Typography.Paragraph>

              {item.status === 'INVITED' && (
                <UserInviteStatus user={item} mutate={mutate} />
              )}
            </div>
          ),
          latestLoginAt:
            item.latestLoginAt !== '0001-01-01T00:00:00Z'
              ? dayjs(item.latestLoginAt).format('YYYY-MM-DD HH:mm')
              : ''
        }
      }),
    [mutate, t, users?.edges]
  )

  const listRole = profile ? Const.ROLES[profile.role] : []

  const onTableChange = useCallback((sorter: string, field: string) => {
    setQueries((prev) => ({
      ...prev,
      sort: sorter ? field : 'id',
      sortOrder: sorter
        ? OrderByType[sorter as keyof typeof OrderByType]
        : OrderByType.descend,
      page: 1
    }))
  }, [])

  const handleSearch = (value: any) => {
    setQueries((prev) => ({
      ...prev,
      ...Utils.trimObjectValues(value),
      page: 1
    }))
  }

  const handleOk = async (value: any) => {
    if (isMutating) return
    await trigger({ data: value })
  }

  const handleCancel = () => {
    form.resetFields()
    setIsModalOpen(false)
  }

  return (
    <>
      {isModalOpen && (
        <InviteUser
          form={form}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      )}

      <div className="flex justify-between pb-5 items-center">
        <div className="font-bold text-xl">{t('user.title')}</div>
        <Button
          type="primary"
          className="ml-14"
          sizing="w-40 h-10"
          onClick={() => setIsModalOpen(true)}
        >
          {t('user.buttonCreate')}
        </Button>
      </div>
      <div className="px-10 py-5 flex items-center bg-white rounded-lg shadow-sm mb-5 lg:px-[30px]">
        <FormSearch handleSearch={handleSearch} listRole={listRole} />
      </div>
      <ExtraTable
        isLoading={isValidating && !users}
        columns={TableColumn.COLUMN_USER}
        data={listUser}
        onChange={onTableChange}
        total={users?.pagination.total}
        pagination={{
          page: queries?.page || 1,
          pageSize: queries?.pageSize || 10,
          setQueries,
          queries
        }}
        refetch={mutate}
      />
    </>
  )
}
