import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Form,
  notification,
  Tooltip,
  Typography,
  Upload,
  UploadProps
} from 'antd'
import useMutation from 'hooks/useMutation'
import FloorArea from 'pages/building/floor/area/FloorArea'
import UploadMapModal from 'pages/building/floor/components/ModalUploadMap'
import { useRecoilState } from 'recoil'
import {
  buildingModeState,
  floorDetailState,
  isUploadingMapState,
  listLinkState,
  listPointState,
  selectedAreaState,
  selectedPoint
} from 'store/buildingStore'
import useSWR from 'swr'
import { FloorDetail, FloorMapElement } from 'types/building'
import Utils from 'utils'

import Button from 'components/atoms/Button'
import AreaNoticeIcon from 'components/icons/AreaNotice'

const { Dragger } = Upload

export default function DetailFloor() {
  const { t } = useTranslation()
  const { id, floorId } = useParams()
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const [typeError, setTypeError] = useState(false)
  const [sizeError, setSizeError] = useState(false)

  const [, setSelectedPoint] = useRecoilState(selectedPoint)
  const [points, setPoints] = useRecoilState(listPointState)
  const [, setLinks] = useRecoilState(listLinkState)
  const [selectedArea, setSelectedArea] = useRecoilState(selectedAreaState)
  const [floorDetail, setFloorDetail] = useRecoilState(floorDetailState)
  const [, setBuildingMode] = useRecoilState(buildingModeState)
  const [isUploadingMap, setIsUploadingMap] =
    useRecoilState(isUploadingMapState)

  const imagePath = useMemo(
    () => (floorDetail?.mapFile ? floorDetail.mapFile.s3Path : ''),
    [floorDetail?.mapFile]
  )

  const { isValidating, isLoading, mutate } = useSWR<FloorDetail>(
    {
      url: `v1/floors/${floorId}`
    },
    {
      onSuccess: (data) => {
        setFloorDetail(data)
      },
      onError: (err) => {
        Utils.handleErrorNavigate(err, navigate)
      }
    }
  )

  const { trigger: uploadMap, isMutating } = useMutation(`v1/floors/${floorId}`)
  const { trigger: handleSort } = useMutation('v1/points/reorder')

  const handleSortPoint = (idList: { id: string; order: number }[]) => {
    if (idList.length) {
      handleSort({
        data: {
          building_id: Number(id),
          floor_id: Number(floorId),
          orders: idList
        }
      })
    }
  }

  const { isValidating: isValidatingPoint } = useSWR<FloorMapElement>(
    {
      url: 'v1/points',
      params: {
        buildingId: id,
        floorId
      }
    },
    {
      onSuccess: (data) => {
        // redirect to point
        const targetPoint = localStorage.getItem('targetPoint')
        const previewMode = localStorage.getItem('previewMode')
        if (targetPoint) {
          const target = data.points.find((item) => item.id === targetPoint)
          if (target) {
            setSelectedArea(target.areaId)
            setSelectedPoint(target.id)
            localStorage.removeItem('targetPoint')
            if (previewMode === 'true') {
              setBuildingMode((prev) => ({
                ...prev,
                previewMode: true
              }))
              localStorage.removeItem('previewMode')
            }
          } else {
            localStorage.removeItem('targetPoint')
            localStorage.removeItem('previewMode')
          }
        }

        const sortedPoint = [...data.points]
          .sort((a, b) => a.order - b.order)
          .map((item, index) => ({ ...item, order: index + 1 }))
        handleSortPoint(
          sortedPoint.map((item) => ({ id: item.id, order: item.order }))
        )
        setPoints(sortedPoint)
        setLinks(data.links)
      }
    }
  )

  const handleOpenUploadMap = () => {
    setIsUploadingMap((prev) => ({
      ...prev,
      isOpen: true
    }))
  }

  const types = ['.jpg', '.jpeg', '.png']

  const readFile = (file: File) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = (e) => {
        resolve(e)
      }
      img.onerror = () => {
        reject()
      }
      img.src = window.URL.createObjectURL(file)
    })
  }

  const isTypeInclude = (name: string, types: string[]) => {
    return Boolean(types.find((type) => name.toLowerCase().includes(type)))
  }

  const uploadProps: UploadProps = {
    name: 'map-upload',
    multiple: false,
    showUploadList: false,
    beforeUpload: async (file) => {
      if (!isTypeInclude(file.name, types)) {
        setTypeError(true)
        return false
      }
      if (file.size && file.size / (1024 * 1024) > 50) {
        setSizeError(true)
        return false
      }
      await readFile(file)
        .then(() => {
          const formData = new FormData()
          formData.append('mapFile', file)
          !isMutating &&
            uploadMap(
              {
                form,
                method: 'patch',
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
                successMessage: t('building.saved')
              },
              {
                onSuccess: () => {
                  mutate()
                }
              }
            )
        })
        .catch(() => {
          notification.error({
            message: t('translation.brokenFileMessage')
          })
        })
      return false
    },
    fileList: []
  }

  useEffect(() => {
    if (isUploadingMap) {
      if (typeError) {
        form.setFields([
          {
            name: 'mapUploadWrapper',
            errors: [t('common.validationError.fileNotAllowed')]
          }
        ])
        setTypeError(false)
      }

      if (sizeError) {
        form.setFields([
          {
            name: 'mapUploadWrapper',
            errors: [t('common.validationError.fileTooLarge')]
          }
        ])
        setSizeError(false)
      }
    }
  }, [isUploadingMap, typeError, sizeError, form, t])

  useEffect(() => {
    return () => {
      setSelectedArea(undefined)
      setSelectedPoint(undefined)
      setPoints([])
      setLinks([])
    }
  }, [setLinks, setPoints, setSelectedArea, setSelectedPoint])

  return (
    <div className="flex flex-col items-center w-full relative">
      {imagePath && !isValidating && (
        <FloorArea imagePath={imagePath} isLoadingMap={isValidating} />
      )}

      {!floorDetail?.mapFile && !isValidating && (
        <Dragger
          id="map-upload"
          openFileDialogOnClick={false}
          {...uploadProps}
          className="no-style-upload m-auto"
        >
          <div className="shadow-[0_0_10px_0_#00000026] rounded-[20px] bg-white grid gap-[30px] justify-items-center px-20 py-40">
            <Typography className="text-black text-[20px] leading-[30px] font-bold">
              {t('building.registerMapTitle')}
            </Typography>
            <Typography className="text-black text-[14px] leading-[21px]">
              {t('building.uploadGuiden')}
            </Typography>
            <Button
              sizing="h-[60px] w-[300px]"
              type="primary"
              onClick={handleOpenUploadMap}
            >
              {t('building.selectFile')}
            </Button>
          </div>
        </Dragger>
      )}

      {!points.find((item) => item.areaId === selectedArea) &&
        !isValidatingPoint &&
        !isValidating &&
        selectedArea && (
          <div className="absolute bottom-[0px] left-0 z-[650] flex justify-center items-center gap-[15px] h-[60px] bg-[#EEEFF2] opacity-75 w-full">
            <Typography className="text-[20px] leading-[30px] font-bold text-black">
              {t('building.noPointRegistered')}
            </Typography>
          </div>
        )}

      {floorDetail?.mapFile && !floorDetail?.areas.length && !isLoading && (
        <div className="absolute bottom-[0px] left-0 z-[650] flex justify-center items-center gap-[15px] h-[60px] bg-[#EEEFF2] opacity-75 w-full">
          <Typography className="text-[20px] leading-[30px] font-bold text-black">
            {t('building.pleaseRegisterArea')}
          </Typography>

          <Tooltip
            title={t('building.roomPointNotice')}
            placement="topLeft"
            color="#0E0D0A80"
            trigger="click"
          >
            <AreaNoticeIcon className="cursor-pointer" />
          </Tooltip>
        </div>
      )}

      {isUploadingMap.isOpen && <UploadMapModal form={form} />}
    </div>
  )
}
