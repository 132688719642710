import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import useMutation from 'hooks/useMutation'

import Button from './Button'
import Modal from './Modal'
import Title from './Title'

type SelectedRecord = {
  id: number | string
  name: string
}

type Props = {
  selectedRecord: {
    id: number | string
    name: string
  }
  setSelectedRecord: Dispatch<SetStateAction<SelectedRecord | undefined>>
  refetchList: () => void
}

const typeTitleTranslations = (type: string): string => {
  switch (type) {
    case 'buildings':
      return 'restoreOrForceDeleteBuilding'
    case 'organizations':
      return 'restoreOrForceDeleteOrganization'
    case 'users':
      return 'restoreOrForceDeleteUser'
    default:
      return 'restoreOrDeleteConfirmation'
  }
}

const typeRestoreTitle = (type: string): string => {
  switch (type) {
    case 'buildings':
      return 'restoreOrForceDeleteBuilding'
    case 'organizations':
      return 'restoreConfirmation'
    case 'users':
      return 'restoreOrForceDeleteUser'
    default:
      return 'restoreConfirmation'
  }
}

export default function ModalRestoreOrDelete({
  selectedRecord,
  setSelectedRecord,
  refetchList
}: Props) {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const urlTarget = useMemo(() => pathname.split('/')[1], [pathname])

  const [selectedMode, setSelectedMode] = useState('')

  const { trigger: handleRestoreOrDelete, isMutating } = useMutation(
    `v1/${urlTarget}/${selectedRecord.id}`
  )

  const handleClose = () => {
    setSelectedRecord(undefined)
  }

  const handlePermanentDelete = () => {
    setSelectedMode('delete')
  }

  const handleRestore = () => {
    setSelectedMode('restore')
  }

  const handleSubmit = () => {
    if (!isMutating) {
      handleRestoreOrDelete(
        {
          method: selectedMode === 'delete' ? 'delete' : 'patch',
          data: null,
          query: [selectedMode === 'delete' ? 'force' : 'restore']
        },
        {
          onSuccess: () => {
            setSelectedRecord(undefined)
            refetchList()
          },
          onError: () => {
            refetchList()
          }
        }
      )
    }
  }

  return (
    <Modal open width={selectedMode ? 480 : 620}>
      <div className="text-center flex flex-col gap-10 items-center">
        <Title title={t(`translation.${urlTarget}Title`)} />

        <div className="text-sm text-[#000]">
          {!selectedMode &&
            t(`translation.${typeTitleTranslations(urlTarget)}`)}
          {selectedMode === 'delete' && t('translation.deleteConfirmation')}
          {selectedMode === 'restore' &&
            t(`translation.${typeRestoreTitle(urlTarget)}`)}
        </div>

        <div className="w-[360px] bg-[#F9FBFF] rounded-[10px] py-5 text-base text-[#000]">
          {selectedRecord.name}
        </div>

        <div className="flex gap-10">
          {!selectedMode && (
            <Button
              sizing="w-[140px] h-[50px]"
              className="bg-[#B80808]"
              onClick={handlePermanentDelete}
            >
              {t('translation.permanentDelete')}
            </Button>
          )}
          <Button sizing="w-[140px] h-[50px]" onClick={handleClose}>
            {t('translation.cancel')}
          </Button>
          {!selectedMode && (
            <Button
              sizing="w-[140px] h-[50px]"
              type="primary"
              onClick={handleRestore}
            >
              {t('translation.restore')}
            </Button>
          )}

          {selectedMode ? (
            <Button
              sizing="w-[140px] h-[50px]"
              type={selectedMode === 'delete' ? 'default' : 'primary'}
              className={selectedMode === 'delete' ? 'bg-[#B80808]' : ''}
              onClick={handleSubmit}
            >
              {t(
                `translation.${
                  selectedMode === 'restore' ? 'restore' : 'permanentDelete'
                }`
              )}
            </Button>
          ) : null}
        </div>
      </div>
    </Modal>
  )
}
