import { useTranslation } from 'react-i18next'
import { Button, Form, Input } from 'antd'
import { ResetPasswordProps } from 'types/forgotPassword'
import Validators from 'utils/validators'

import Title from 'components/atoms/Title'

export default function ResetPassword({ onFinish }: ResetPasswordProps) {
  const { t } = useTranslation()

  return (
    <>
      <Title title="resetPassword.title" />

      <Form
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        requiredMark={false}
      >
        <Form.Item
          name="password"
          label={t('resetPassword.newPassword')}
          rules={[{ validator: Validators.passwordValidator(t) }]}
        >
          <Input.Password
            autoComplete="new-password"
            size="middle"
            placeholder={t('resetPassword.enterPassword')}
          />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          label={t('resetPassword.confirmPassword')}
          dependencies={['password']}
          rules={[Validators.confirmPasswordValidator(t)]}
        >
          <Input.Password
            autoComplete="new-confirmPassword"
            size="middle"
            placeholder={t('resetPassword.enterPassword')}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block size="large">
            {t('resetPassword.buttonResetPassword')}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
