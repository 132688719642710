import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Input, Typography } from 'antd'
import useMutation from 'hooks/useMutation'
import useSWR from 'swr'
import Utils from 'utils'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'

type Props = {
  handleCancel: () => void
}

export default function AddFloorModal({ handleCancel }: Props) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { id } = useParams()
  const navigate = useNavigate()
  const stopPropagation = useRef<boolean>(false)

  const { mutate } = useSWR(
    {
      url: `/v1/buildings/${id}`
    },
    {
      revalidateOnMount: false
    }
  )

  const { trigger, isMutating } = useMutation('v1/floors', {
    onSuccess: () => {
      handleCancel()
      form.resetFields()
      mutate()
    }
  })

  const handleAddFloor = (values: any) => {
    const floor = {
      buildingId: Number(id),
      name: values?.floorName
    }
    !isMutating &&
      trigger(
        {
          data: floor,
          form,
          successMessage: 'building.saved'
        },
        {
          onError: (error) => {
            Utils.handleErrorNavigate(error, navigate)
          }
        }
      )
  }

  const handleEnterSubmit = () => {
    if (stopPropagation.current) return
    form
      .validateFields()
      .then(() => handleAddFloor(form.getFieldsValue()))
      .catch(() => {})
  }

  return (
    <Modal
      width="526px"
      onCancel={() => {
        handleCancel()
        form.resetFields()
      }}
      isEnterable
      onEnter={handleEnterSubmit}
    >
      <div className="text-center flex flex-col gap-[30px] items-center">
        <Typography className="text-[20px] font-[700] text-[#3163B7]">
          {t('common.floor.addFloor')}
          <hr className="h-[1px] bg-[#3163B7] border-none w-[40px]" />
        </Typography>
        <Form
          form={form}
          colon={false}
          onFocus={() => {
            stopPropagation.current = true
          }}
          onBlur={() => {
            stopPropagation.current = false
          }}
          onFinish={handleAddFloor}
        >
          <Form.Item
            name="floorName"
            className="input-floor thin-error-message"
            label={t('common.floor.name')}
            rules={[
              {
                validator: Validators.addFloorValidator(t)
              }
            ]}
          >
            <Input
              className="w-[300px] h-[38px] text-[14px] font-[400]"
              placeholder={t('common.floor.placeHolder')}
            />
          </Form.Item>
          <div className="flex gap-10 justify-center w-full items-end h-full mt-10">
            <Button
              sizing="w-[140px] h-[50px]"
              onClick={() => {
                handleCancel()
                form.resetFields()
              }}
            >
              {t('common.cancel')}
            </Button>
            <Button
              type="primary"
              sizing="w-[140px] h-[50px]"
              htmlType="submit"
            >
              {t('common.confirm')}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}
