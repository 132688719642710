import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown, Typography } from 'antd'
import Const from 'constants/constant'
import useAuth from 'hooks/useAuth'
import { AreaDetail } from 'types/building'

import MenuIcon from 'components/icons/MenuIcon'

import DeleteAreaModal from './ModalDelete'
import EditAreaNameModal from './ModalEditName'

type Props = {
  handleToggle: (key: string, cb: any) => void
  areaDetail: AreaDetail | undefined
}

export default function AreaDropDownMenu({ handleToggle, areaDetail }: Props) {
  const { profile } = useAuth()
  const { t } = useTranslation()
  const [openMenu, setOpenMenu] = useState('')

  const items = Const.SIDEBAR_DETAIL_AREA_MENU.filter(
    (item) => profile?.role && item.role.includes(profile?.role)
  ).map(({ label, key }) => {
    return {
      key,
      label: (
        <Typography className="text-white text-[12px] font-[700] whitespace-nowrap w-[136px]">
          {t(label)}
        </Typography>
      ),
      onClick: () => {
        handleToggle(key, () => setOpenMenu(key))
      }
    }
  })

  return (
    <>
      <div className="h-full cursor-pointer">
        <Dropdown
          menu={{ items }}
          trigger={['click']}
          className="w-auto"
          placement="bottomRight"
          rootClassName="dropdown-custom w-[136px]"
        >
          <div className="h-full flex my-auto pr-[10px]">
            <MenuIcon />
          </div>
        </Dropdown>
      </div>

      {openMenu === 'name' && (
        <EditAreaNameModal areaDetail={areaDetail} setIsOpen={setOpenMenu} />
      )}

      {openMenu === 'delete' && (
        <DeleteAreaModal areaDetail={areaDetail} setIsOpen={setOpenMenu} />
      )}
    </>
  )
}
