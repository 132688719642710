import Const from 'constants/constant'

import useAuth from './useAuth'

export default function usePermissions(page: string) {
  const { profile } = useAuth()
  const isCreate =
    profile?.role && Const.PERMISSIONS[page]
      ? Const.PERMISSIONS[page].create.includes(profile?.role)
      : false
  const isUpdate =
    profile?.role && Const.PERMISSIONS[page]
      ? Const.PERMISSIONS[page].update.includes(profile?.role)
      : false
  const isDelete =
    profile?.role && Const.PERMISSIONS[page]
      ? Const.PERMISSIONS[page].delete.includes(profile?.role)
      : false
  return { isCreate, isUpdate, isDelete }
}
