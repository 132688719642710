import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'antd'
import { UploadProps } from 'types'

import Button from './Button'

export default function Upload({
  onChange,
  accept,
  name,
  wrapperClassName = '',
  value
}: UploadProps) {
  const { t } = useTranslation()

  const inputRef = useRef<HTMLInputElement>(null)

  const handleChooseFile = () => inputRef?.current?.click()

  return (
    <div className={wrapperClassName}>
      <Button type="primary" onClick={handleChooseFile}>
        {t('building.selectFile')}
      </Button>
      <div className="hidden">{value?.name}</div>

      <Form.Item name={name} className="mb-0 hidden">
        <Form.Item className="mb-0 hidden">
          <input
            accept={accept}
            type="file"
            ref={inputRef}
            onChange={onChange}
            hidden
          />
        </Form.Item>
      </Form.Item>
    </div>
  )
}
