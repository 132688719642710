import CustomCheckIcon from 'components/icons/CustomCheck'
import CustomCheckedIcon from 'components/icons/CustomCheckBox'

type Props = {
  id: any
  checked: any | undefined
  isDefault: boolean
}

export default function CustomCheckBox({ id, isDefault, checked }: Props) {
  if ((!checked && isDefault) || id === checked) {
    return <CustomCheckedIcon />
  }
  return <CustomCheckIcon />
}
