import { useState } from 'react'
import clsx from 'clsx'
import DocumentMenu from 'pages/building/buildingDetail/components/MenuDocument'

import InstructionMenu from './MenuInstuction'

const optionsList = [
  { label: '360', value: '360' },
  { label: '資料', value: 'document' }
]

export default function RightBarDetailBuilding() {
  const [option, setOption] = useState('360')

  return (
    <div className="h-full grid grid-rows-[auto_1fr]">
      <div className="grid grid-cols-2">
        {optionsList.map((item) => (
          <div
            key={item.value}
            onClick={() => setOption(item.value)}
            className={clsx(
              'cursor-pointer text-white font-bold text-[12px] leading-3 h-[30px] flex items-center justify-center transition-all',
              { 'bg-[#021120]': option !== item.value }
            )}
          >
            {item.label}
          </div>
        ))}
      </div>

      {option === '360' && (
        <InstructionMenu
          handleBack={undefined}
          selectedFolder={{
            doc: { id: 1, isFileHolder: true, label: '', children: null }
          }}
        />
      )}
      {option === 'document' && <DocumentMenu />}
    </div>
  )
}
