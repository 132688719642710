import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Sidebar from '../Sidebar'
import UserDropdown from '../UserDropdown'

export default function Header() {
  const { t } = useTranslation()

  return (
    <div className="h-full flex justify-between items-center pl-5 bg-gradient-to-r from-[#3163B7] to-[#123674]">
      <div className="flex items-center gap-2 select-none">
        <Sidebar />

        <Link to="/buildings">
          <img
            src="/logo/transparent_header.png"
            alt="logo"
            className="max-w-[207px] cursor-pointer ml-[53px]"
          />
        </Link>
      </div>

      <div className="flex justify-end items-center">
        <div className="relative z-0">
          <img src="/assets/background_header.png" alt="header" />
          <div className="absolute inset-0 flex justify-end items-center z-10">
            <UserDropdown t={t} />
          </div>
        </div>
      </div>
    </div>
  )
}
