import { Dispatch, SetStateAction, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Form, Upload, UploadFile, UploadProps } from 'antd'
import axios from 'axios'
import dayjs from 'dayjs'
import useMutation from 'hooks/useMutation'
import { useRecoilState } from 'recoil'
import {
  listPointState,
  revalidatePointDetailState,
  selectedPoint
} from 'store/buildingStore'
import { OpenModalDelete } from 'types/building'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'

const { Dragger } = Upload

type Props = {
  open: OpenModalDelete
  setOpen: Dispatch<SetStateAction<OpenModalDelete>>
  setIsExpanded: Dispatch<SetStateAction<boolean>>
}

export default function ModalUploadSubImages({
  open,
  setOpen,
  setIsExpanded
}: Props) {
  const { id, floorId } = useParams()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const stopPropagation = useRef<boolean>(false)
  const [isUploading, setIsUploading] = useState(false)
  const [fileList, setFileList] = useState<UploadFile>()

  const [, setRevalidatePointDetail] = useRecoilState(
    revalidatePointDetailState
  )
  const [, setPointList] = useRecoilState(listPointState)
  const [, setCurrentSelectedPoint] = useRecoilState(selectedPoint)

  const { trigger: getPresignedUrl } = useMutation(
    'v1/buildings/presigned-urls'
  )
  const { trigger: saveFile } = useMutation(
    `v1/points/${open.pointId}/sub-images`
  )

  const handleOpenDialog = () => {
    const mapUpload = document.getElementById('modal-upload-subimages')
    stopPropagation.current = true
    mapUpload && mapUpload.click()
  }

  const convertedFile = (uploadedFile: UploadFile, path: any) => {
    return {
      extension: uploadedFile.name.split('.').pop(),
      name: uploadedFile.name,
      mimeType: uploadedFile.type,
      size: uploadedFile.size,
      s3Path: path,
      takenAt: dayjs(uploadedFile.lastModified).format('YYYY-MM-DDTHH:mm:ssZ')
    }
  }

  const types = ['.jpg', '.jpeg', '.png']

  const readFile = (file: File) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = (e) => {
        resolve(e)
      }
      img.onerror = () => {
        reject()
      }
      img.src = window.URL.createObjectURL(file)
    })
  }

  const isTypeInclude = (name: string, types: string[]) => {
    return Boolean(types.find((type) => name.toLowerCase().includes(type)))
  }

  const uploadProps: UploadProps = {
    name: 'modal-upload-subimages',
    multiple: false,
    beforeUpload: (file) => {
      if (!isTypeInclude(file.name, types)) {
        form.setFields([
          {
            name: 'subimageUploadWrapper',
            errors: [t('common.validationError.fileNotAllowed')]
          }
        ])
        return false
      }
      if (file.size && file.size / (1024 * 1024) > 50) {
        form.setFields([
          {
            name: 'subimageUploadWrapper',
            errors: [t('common.validationError.fileTooLarge')]
          }
        ])
        return false
      }
      readFile(file)
        .then(() => {
          setFileList(file)
          form.resetFields(['subimageUploadWrapper'])
        })
        .catch(() => {
          form.setFields([
            {
              name: 'subimageUploadWrapper',
              errors: [t('translation.brokenFileMessage')]
            }
          ])
        })
      return false
    },
    fileList: fileList ? [fileList] : [],
    onRemove: () => setFileList(undefined)
  }

  const hanldeClose = () => {
    setIsUploading(false)
    setOpen({ isOpen: '', pointId: '', pointOrder: 0 })
  }

  const handleEnterSubmit = async () => {
    if (stopPropagation.current || isUploading || !fileList) return

    setIsUploading(true)
    await getPresignedUrl(
      {
        data: {
          buildingId: +(id || 0),
          count: 1,
          category: 1
        }
      },
      {
        onSuccess: async (data) => {
          const { presignedUrl, key } = data.data.s3PreSignLinks[0]
          await axios.put(presignedUrl, fileList).then(() => {
            saveFile(
              {
                data: {
                  buildingId: +(id || 0),
                  floorId: +(floorId || 0),
                  category: 1,
                  ...convertedFile(fileList, key)
                }
              },
              {
                onSuccess: () => {
                  setRevalidatePointDetail(open.pointId)
                  setPointList((prev) =>
                    prev.map((item) =>
                      item.id === open.pointId
                        ? { ...item, isSubImages: true }
                        : item
                    )
                  )
                  setCurrentSelectedPoint(open.pointId)
                  setIsExpanded(true)
                  hanldeClose()
                },
                onError: () => hanldeClose()
              }
            )
          })
        },
        onError: () => hanldeClose()
      }
    )
  }

  return (
    <Modal open width={580} isEnterable onEnter={handleEnterSubmit}>
      <Title title={t('building.subimageUploadTitle')} bottomMargin="mb-8" />

      <Form form={form}>
        <Form.Item
          name="subimageUploadWrapper"
          className="mb-0 thin-error-message"
        >
          <Dragger
            id="modal-upload-subimages"
            openFileDialogOnClick={false}
            {...uploadProps}
            disabled={isUploading}
            onChange={() => {
              stopPropagation.current = false
            }}
          >
            <div className="grid gap-3 mx-[91px] my-2">
              <div className=" text-[14px] leading-[21px]">
                {t('building.mapUploadGuiden')}
              </div>
              <Button
                type="primary"
                sizing="w-40 h-10"
                className="mx-auto"
                disabled={isUploading}
                onClick={handleOpenDialog}
              >
                {t('building.selectFile')}
              </Button>
            </div>
          </Dragger>
        </Form.Item>
      </Form>

      <div className="flex gap-10 justify-center mt-10">
        <Button
          sizing="w-[140px] h-[50px]"
          disabled={isUploading}
          onClick={hanldeClose}
        >
          {t('building.buttonCancel')}
        </Button>

        <Button
          type="primary"
          sizing="w-[140px] h-[50px]"
          disabled={!fileList}
          loading={isUploading}
          onClick={handleEnterSubmit}
        >
          {t('building.buttonSave')}
        </Button>
      </div>
    </Modal>
  )
}
