import { useMemo, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Typography } from 'antd'
import useMutation from 'hooks/useMutation'
import { useRecoilState } from 'recoil'
import { floorDetailState, selectedAreaState } from 'store/buildingStore'
import useSWR from 'swr'
import { AreaDetail } from 'types/building'

import Spinner from 'components/atoms/Spinner'
import AddIcon from 'components/icons/AddIcon'
import LeftArrowIcon from 'components/icons/LeftArrowIcon'
import RightArrowIcon from 'components/icons/RightArrowIcon'

import FloorDropDownMenu from './MenuDropDown'
import AddAreaModal from './ModalAddArea'

type Props = {
  handleBack: () => void
}

export default function FloorMenu({ handleBack }: Props) {
  const { t } = useTranslation()
  const { id, floorId } = useParams()
  const navigate = useNavigate()
  const [openAddAreaModal, setOpenAddAreaModal] = useState(false)

  const [floorDetail, setFloorDetail] = useRecoilState(floorDetailState)
  const [, setSelectedArea] = useRecoilState(selectedAreaState)

  const sortedArea = useMemo(
    () =>
      floorDetail?.areas
        ? [...floorDetail.areas].sort((a, b) => a.order - b.order)
        : [],
    [floorDetail?.areas]
  )

  const { mutate, isValidating } = useSWR(
    {
      url: `v1/floors/${floorId}`
    },
    {
      revalidateOnMount: false
    }
  )

  const getFloorDetail = () => mutate().then((data) => setFloorDetail(data))

  const { trigger: sortingArea } = useMutation(`v1/floors/${floorId}/areas`)

  const onBack = () => {
    navigate(`/buildings/${id}`)
    handleBack()
  }

  const sorting = (items: AreaDetail[]) => {
    const areasId = items.map((item) => item.id)
    areasId.toString() !== sortedArea.map((item) => item.id).toString() &&
      sortingArea({
        method: 'patch',
        data: {
          areaIds: items.map((item) => item.id)
        }
      })
  }

  const handleOnDragEnd = (result: any) => {
    if (sortedArea) {
      if (!result.destination) return
      const items = [...sortedArea]
      const [reorderedItem] = items.splice(result.source.index, 1)
      items.splice(result.destination.index, 0, reorderedItem)
      const newFloorDetailAreas = floorDetail
        ? {
            ...floorDetail,
            areas: floorDetail.areas.map((area) => ({
              ...area,
              order: items.findIndex((item) => item.id === area.id) + 1
            }))
          }
        : null
      setFloorDetail(newFloorDetailAreas)
      sorting(items)
    }
  }

  const handleSelectArea = (areaId: number) => () => {
    setSelectedArea(areaId)
  }

  return (
    <>
      {isValidating && <Spinner />}
      <div className="flex justify-between align-middle h-10 ml-[10px] cursor-pointer">
        <div
          className="flex items-center gap-3 cursor-pointer"
          onClick={onBack}
        >
          <LeftArrowIcon />

          <Typography.Text className="text-inherit text-white font-[700] text-[16px] my-auto">
            {t('common.sidebar.floorMenu')}
          </Typography.Text>
        </div>

        <FloorDropDownMenu floorName={floorDetail?.name || ''} />
      </div>
      {floorDetail?.id === Number(floorId) && (
        <>
          <div
            className="flex justify-between align-middle w-60 min-h-[40px] p-[10px] gap-3 cursor-pointer"
            onClick={() => setOpenAddAreaModal(true)}
          >
            <Typography.Text className="text-inherit text-white font-[700] text-[14px] leading-[14px] my-auto">
              {floorDetail?.name}
            </Typography.Text>

            <AddIcon />
          </div>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="areas">
              {(provided: any) => (
                <div
                  className="cursor-pointer"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {sortedArea.map((item, index) => (
                    <Draggable
                      key={`key ${item.id}`}
                      draggableId={`drag ${String(item.id)}`}
                      index={index}
                    >
                      {(provided: any) => (
                        <div
                          className="grid grid-cols-[1fr_auto] w-60 items-center bg-[#172841] h-[36px] px-[10px] !cursor-pointer gap-3 border-0 border-b border-solid border-[#1C314D]"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          onClick={handleSelectArea(item.id)}
                        >
                          <Typography.Text
                            className="text-white text-[13px] leading-[19.5px] truncate"
                            ellipsis={{
                              tooltip: item.name
                            }}
                          >
                            {item.name}
                          </Typography.Text>
                          <RightArrowIcon color="#609DF9" />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}

      {openAddAreaModal && (
        <AddAreaModal
          setIsOpen={setOpenAddAreaModal}
          getFloorDetail={getFloorDetail}
        />
      )}
    </>
  )
}
