import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Radio } from 'antd'
import Const from 'constants/constant'
import TableColumn from 'constants/tableColumn'
import useAuth from 'hooks/useAuth'
import { ListOrganizations } from 'models/Organization'
import { Role } from 'models/User'
import useSWR from 'swr'
import { OrderByType } from 'types'
import { SelectOrganization } from 'types/listUser'
import { InviteUserModalOrganization } from 'types/userDetail'
import Utils from 'utils'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'
import ExtraTable from 'components/organisms/ExtraTable'

import FormSearchOrganization from './FormSearchOrganization'

export default function ModalOrganization({
  previousSelect,
  setPreviousSelect,
  queries,
  setQueries,
  handleOk,
  handleCancel,
  roleUser,
  setOrganization,
  organizationId
}: InviteUserModalOrganization) {
  const { t } = useTranslation()
  const { profile } = useAuth()
  const [organizationsId, setOrganizationId] = useState<SelectOrganization>(
    organizationId?.current || Const.OTHER_ORG_DEFAULT
  )

  const { data: organizations, isValidating } = useSWR<ListOrganizations>({
    url: `/v1/organizations`,
    params: { ...queries, include_my_org: profile?.role === Role.BA }
  })

  const onTableChange = useCallback(
    (sorter: string, field: string) => {
      if (field === 'checkbox') {
        field = 'id'
      }
      setQueries((prev) => ({
        ...prev,
        sort: sorter ? field : 'id',
        sortOrder: sorter
          ? OrderByType[sorter as keyof typeof OrderByType]
          : OrderByType.descend,
        page: 1
      }))
    },
    [setQueries]
  )

  const mapOrganizations = useMemo(() => {
    if (organizations && organizations.edges) {
      const newList = organizations.edges.map((organization) => ({
        id: organization.id,
        name: organization.name,
        domain: organization.emailDomain,
        checkbox: (
          <div className="flex">
            <Radio value={organization.id} />
          </div>
        )
      }))
      if (roleUser === Role.UA) {
        return newList.filter((item) => item.id !== 1)
      }
      return newList
    }
    return []
  }, [organizations, roleUser])

  const listOrganization =
    !queries?.keyword && roleUser === 'USER'
      ? [
          {
            ...Const.OTHER_ORG_DEFAULT,
            id: '',
            checkbox: <Radio value={0} />
          },
          ...mapOrganizations
        ]
      : mapOrganizations

  const handleSearch = (value: any) => {
    setQueries((prev) => ({
      ...prev,
      ...Utils.trimObjectValues(value),
      page: 1
    }))
  }

  const handleChangeCheckbox = (event: any) => {
    const organizationSelect = listOrganization.find(
      (item) => item.id === event.id
    )
    if (organizationSelect) {
      setOrganizationId({
        id: event.id || 0,
        name: organizationSelect.name,
        domain: organizationSelect.domain
      })
    }
  }

  const onClose = () => {
    setQueries({
      sort: 'id',
      sortOrder: OrderByType.descend
    })
    if (previousSelect) {
      setOrganizationId(previousSelect)
    } else {
      setOrganizationId(Const.OTHER_ORG_DEFAULT)
    }
    setOrganization && setOrganization(previousSelect)
    organizationId?.current && setOrganizationId(organizationId.current)
    handleCancel()
  }

  const onSubmit = () => {
    setQueries({
      sort: 'id',
      sortOrder: OrderByType.descend
    })
    setPreviousSelect && setPreviousSelect(organizationsId)
    handleOk(organizationsId)
  }

  useEffect(() => {
    if (
      roleUser === Role.UA &&
      organizations &&
      organizations.edges.length > 1 &&
      profile?.organization
    ) {
      const orgSelect: SelectOrganization =
        profile.role === Role.UA
          ? {
              id: profile.organization.id,
              domain: profile.organization.emailDomain,
              name: profile.organization.name
            }
          : {
              id: organizations.edges?.[0].id,
              domain: organizations.edges?.[0].emailDomain,
              name: organizations.edges?.[0].name
            }
      setOrganization(orgSelect)
      setOrganizationId(orgSelect)
      setPreviousSelect && setPreviousSelect(orgSelect)
    }
    if (roleUser === Role.SA || roleUser === Role.BA) {
      const orgDefault = organizations?.edges.find((org) => org.id === 1)
      const orgSelect: SelectOrganization = {
        id: orgDefault?.id || null,
        domain: orgDefault?.emailDomain || '',
        name: orgDefault?.name || ''
      }
      setOrganization(orgSelect)
      setOrganizationId(orgSelect)
      setPreviousSelect && setPreviousSelect(orgSelect)
    }
    if (roleUser === Role.USER && profile?.organization) {
      if (profile?.role === Role.UA) {
        setOrganization({
          id: profile.organization.id,
          domain: profile.organization.emailDomain,
          name: profile.organization.name
        })
      } else {
        setOrganization(Const.OTHER_ORG_DEFAULT)
        setOrganizationId(Const.OTHER_ORG_DEFAULT)
        setPreviousSelect && setPreviousSelect(Const.OTHER_ORG_DEFAULT)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleUser])

  return (
    <Modal
      onCancel={handleCancel}
      width={1074}
      className="modal-organization"
      rootClassName=""
      isEnterable
      onEnter={onSubmit}
      zIndex={1000}
    >
      <Title title="user.selectOrganization" />
      <div className="bg-white rounded-lg w-full mb-7 flex items-center">
        <FormSearchOrganization handleSearch={handleSearch} />
      </div>
      <Radio.Group
        className="w-full table-list-org-invite"
        value={organizationsId.id}
      >
        <ExtraTable
          isDetail={false}
          scroll={{ y: 350 }}
          isLoading={isValidating && !organizations}
          columns={TableColumn.COLUMN_USER_SELECT_ORGANIZATION}
          data={listOrganization}
          onChange={onTableChange}
          total={organizations?.pagination.total}
          actionSelect={handleChangeCheckbox}
        />
      </Radio.Group>
      <div className="flex justify-center gap-10 mt-[30px]">
        <Button
          sizing="w-[140px] h-[50px]"
          className="px-3 py-[10px]"
          onClick={onClose}
        >
          {t('organization.buttonCancel')}
        </Button>
        <Button
          className="px-3 py-[10px]"
          sizing="w-[140px] h-[50px]"
          type="primary"
          onClick={onSubmit}
          disabled={organizationId && Boolean(!organizationsId?.id)}
        >
          {t('organization.buttonSelect')}
        </Button>
      </div>
    </Modal>
  )
}
