import { useParams } from 'react-router-dom'
import useMutation from 'hooks/useMutation'
import useSWR from 'swr'

import AuthFormContainer from 'components/atoms/AuthFormContainer'

import ResetPassword from './ResetPassword'
import ResetPasswordSuccess from './ResetPasswordSuccess'
import TimeExpired from './TimeExpired'

export default function ResetPasswordPage() {
  const { token } = useParams()
  const { data: tokenValid, error } = useSWR({
    url: `v1/auth/password/token-check/${token}`
  })

  const {
    trigger,
    data,
    isMutating,
    error: err
  } = useMutation('v1/auth/password/confirm')
  const handleFinish = (values: any) => {
    if (isMutating) return

    const data = {
      ...values,
      token
    }

    trigger({ data })
  }

  if (data)
    return (
      <AuthFormContainer>
        <ResetPasswordSuccess />
      </AuthFormContainer>
    )

  if (
    err?.response?.data?.error?.key === 'expired_code' ||
    error?.response?.data?.error?.key === 'expired_link_reset'
  )
    return (
      <AuthFormContainer>
        <TimeExpired />
      </AuthFormContainer>
    )
  if (tokenValid)
    return (
      <AuthFormContainer>
        <ResetPassword onFinish={handleFinish} />
      </AuthFormContainer>
    )

  return <> </>
}
