import { useTranslation } from 'react-i18next'
import { Form } from 'antd'

import FormRegister from '../../listOrganization/components/FormRegister'

export default function RegisterOrganization() {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  return (
    <div>
      <div className="font-bold text-[20px] leading-[30px] text-xl mb-5">
        {t('organization.titleAdd')}
      </div>
      <FormRegister form={form} />
    </div>
  )
}
