import { Typography } from 'antd'
import { AnnotationType } from 'types/building'

import AirConditionIcon from 'components/icons/AirConditionIcon'
import HouseIcon from 'components/icons/HouseIcon'
import LightBulbIcon from 'components/icons/LightBulbIcon'

type Props = {
  type: AnnotationType | undefined
}

export default function AnnotationHeader({ type }: Props) {
  const annotationIcon = () => {
    switch (type) {
      case AnnotationType.ELECTRICITY:
        return <LightBulbIcon />
      case AnnotationType.AIR_CONDITION:
        return <AirConditionIcon />
      case AnnotationType.ARCHITECTURE:
        return <HouseIcon />
      default:
        return null
    }
  }

  const annotationText = () => {
    switch (type) {
      case AnnotationType.ELECTRICITY:
        return '電気'
      case AnnotationType.AIR_CONDITION:
        return '空調衛生'
      case AnnotationType.ARCHITECTURE:
        return '建築'
      case AnnotationType.MEMO:
        return 'メモ'
      default:
        return ''
    }
  }

  return (
    <div className="flex gap-[10px] ml-5 items-center">
      {type !== AnnotationType.MEMO ? (
        <div className="grid place-items-center w-9 h-9">
          {annotationIcon()}
        </div>
      ) : (
        annotationIcon()
      )}
      <Typography className="text-xl font-bold">{annotationText()}</Typography>
    </div>
  )
}
