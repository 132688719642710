import { PropsWithChildren } from 'react'
import { useRecoilValue } from 'recoil'
import { toggleResizeNotificationState } from 'store/displayToggleStore'

import ResizeNotification from 'components/atoms/ResizeNotification'
import Header from 'components/organisms/Header/Header'

export default function HomeLayout({ children }: PropsWithChildren) {
  const isOpenResizeNotification = useRecoilValue(toggleResizeNotificationState)

  return (
    <div className="h-screen min-h-screen grid grid-rows-[auto_1fr]">
      <div className="h-[60px] relative">
        <Header />
      </div>

      {isOpenResizeNotification ? (
        <div className="z-[999]">
          <ResizeNotification />
        </div>
      ) : null}

      <div
        className={`flex py-5 bg-[#F9FBFF] overflow-auto ${
          isOpenResizeNotification ? 'hidden' : ''
        }`}
      >
        <div className="grow px-4 transition-all ease-out duration-200 bg-[#F9FBFF] ml-0">
          {children}
        </div>
      </div>
    </div>
  )
}
