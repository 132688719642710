import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button, Form, Input } from 'antd'
// import { useWatch } from 'antd/es/form/Form'
import { ForgotPasswordCardProps } from 'types/forgotPassword'
import Validators from 'utils/validators'

import Title from 'components/atoms/Title'

export default function ForgotPasswordCard({
  onFinish,
  form
}: ForgotPasswordCardProps) {
  const { t } = useTranslation()
  return (
    <>
      <Title title="forgotPassword.title" />

      <div className="my-3">{t('forgotPassword.subTitle1')}</div>
      <div className="mb-5">{t('forgotPassword.subTitle2')}</div>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        requiredMark={false}
        className="custom-label"
      >
        <Form.Item
          label={t('forgotPassword.email')}
          name="email"
          rules={[{ validator: Validators.emailValidator(t) }]}
        >
          <Input
            autoComplete="new-email"
            size="large"
            placeholder={t('forgotPassword.email')}
          />
        </Form.Item>

        <div className="grid grid-cols-2 gap-6">
          <Form.Item>
            <Link to="/signin">
              <Button type="default" block size="large">
                {t('forgotPassword.buttonBack')}
              </Button>
            </Link>
          </Form.Item>

          <Form.Item>
            <Button type="primary" block htmlType="submit" size="large">
              {t('forgotPassword.buttonSubmit')}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  )
}
