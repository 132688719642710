import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function ViewFile360Svg() {
  return (
    <svg
      width="19"
      height="23"
      viewBox="0 0 19 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19 11.5L0.25 22.3253L0.25 0.674683L19 11.5Z" fill="#3163B7" />
    </svg>
  )
}

export default function ViewFile360Icon(
  props: Partial<CustomIconComponentProps>
) {
  return <Icon component={ViewFile360Svg} {...props} />
}
