import AppDownload from 'pages/app-download'
import NotFound from 'pages/not-found'
import Policies from 'pages/policies/Policies'
import { RouteObject } from 'types'

import DefaultLayout from 'components/templates/DefaultLayout'

const publicRoutes: RouteObject = {
  path: '',
  layout: DefaultLayout,
  children: [
    {
      name: 'app-download',
      path: '/app',
      element: <AppDownload />
    },
    {
      name: 'policies',
      path: '/policies',
      element: <Policies />
    },
    {
      name: 'not-found',
      path: '/*',
      element: <NotFound />
    }
  ]
}

export default publicRoutes
