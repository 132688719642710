import React from 'react'
import { Button as AntButton, ButtonProps } from 'antd'
import clsx from 'clsx'

type Props = ButtonProps & {
  variant?: 'main-button' | 'default-button'
  sizing?: string
}

export default function Button(props: Props) {
  const { variant, sizing, className, ...remainProps } = props

  if (variant === 'main-button') {
    return (
      <AntButton
        className={clsx(
          'leading-[25.6px] font-bold rounded-[4px] flex justify-center items-center',
          className,
          sizing
        )}
        {...remainProps}
      />
    )
  }

  return <AntButton {...remainProps} />
}

Button.defaultProps = {
  variant: 'main-button',
  sizing: 'w-[7.5rem] h-10'
}
