import { useEffect, useRef, useState } from 'react'
import { Document, pdfjs } from 'react-pdf'
import { Form, Input, InputNumber, Typography } from 'antd'
import { BuildingFile } from 'types/building'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Spinner from 'components/atoms/Spinner'
import DownloadIcon from 'components/icons/DownloadIcon'
import PrintIcon from 'components/icons/PrintIcon'
import ZoomInIcon from 'components/icons/ZoomInIcon'
import ZoomOutIcon from 'components/icons/ZoomOutIcon'

import PdfPage from './PdfPage'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

type Props = {
  file: BuildingFile
  handleCloseFile: () => void
}

const documentOptions = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`
}

export default function ViewerPdf({ file, handleCloseFile }: Props) {
  const [form] = Form.useForm()
  const [passwordForm] = Form.useForm()
  const pageRefs = useRef<any>([])
  const stopPropagation = useRef<boolean>(false)
  const [pageCount, setPageCount] = useState<number>()
  const [scale, setScale] = useState(1)
  const [blob, setBlob] = useState<any>()
  const [initPage, setInitPage] = useState<number>()
  const [isProtected, setIsProtected] = useState<{
    callback: any
  }>()

  const zoomIn = () => {
    if (scale < 10) {
      setScale((prev) => prev + 1)
    }
  }

  const zoomOut = () => {
    if (scale > 1 && scale <= 5) {
      setScale((prev) => prev - 1)
    }
    if (scale > 5) {
      setScale((prev) => prev - 2)
    }
  }

  const onViewPage = (viewPage: number) => {
    form.setFieldValue('currentViewPage', viewPage)
  }

  const onFocusInput = () => {
    setInitPage(form.getFieldValue('currentViewPage'))
  }

  const onJumpToPage = (event: any) => {
    const getPageIndex = () => {
      if (
        pageCount &&
        (event.target.value > pageCount || event.target.value <= 0)
      ) {
        return initPage
      }
      return event.target.value
    }
    const pageIndex = getPageIndex()
    pageRefs.current[pageIndex - 1].scrollIntoView()
    form.setFieldValue('currentViewPage', pageIndex)
    setInitPage(pageIndex)
  }

  const handleModalCancel = () => {
    setIsProtected(undefined)
    handleCloseFile()
  }

  const handleModalSubmit = (values: any) => {
    if (!values.password) {
      passwordForm.setFields([
        { name: 'password', errors: ['パスワードを入力してください'] }
      ])
    }
    isProtected?.callback(values.password)
  }

  const onPassword = (callback: any, reason: any) => {
    // reason = 2 equal incorrect password
    reason === 2 &&
      passwordForm.setFields([
        { name: 'password', errors: ['パスワードに誤りがあります'] }
      ])
    if (!isProtected) {
      setIsProtected({
        callback
      })
    }
  }

  const downLoadFile = () => {
    fetch(file.s3Path)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file.name
        link.click()
      })
  }

  const handleEnterSubmit = () => {
    if (stopPropagation.current) return
    passwordForm
      .validateFields()
      .then(() => handleModalSubmit(passwordForm.getFieldsValue()))
      .catch(() => {})
  }

  useEffect(() => {
    if (scale) {
      // focus on current page when zoom
      const previousPage = form.getFieldValue('currentViewPage')
      if (pageRefs.current[previousPage - 1]) {
        pageRefs.current[previousPage - 1].scrollIntoView()
      }
    }
  }, [form, scale])

  return (
    <>
      <div className="absolute top-14 bottom-0 left-0 right-0 flex overflow-y-auto">
        <div className="m-auto h-full">
          <Document
            options={documentOptions}
            file={file.s3Path}
            onLoadSuccess={async (successEvent) => {
              isProtected && setIsProtected(undefined)
              setPageCount(successEvent.numPages)
              const data = await successEvent.getData()
              const tempblob = new Blob([data], { type: 'application/pdf' })
              setBlob(tempblob)
            }}
            onPassword={onPassword}
            loading={<Spinner />}
            className="space-y-2 w-auto"
          >
            {Array.from(Array(pageCount).keys()).map((item) => (
              <div
                key={`page-${item}`}
                ref={(el) => {
                  pageRefs.current[item] = el
                }}
                style={{
                  width: 664 * scale
                }}
              >
                <PdfPage
                  pageNumber={item + 1}
                  onViewPage={() => {
                    onViewPage(item + 1)
                  }}
                  scale={scale}
                />
              </div>
            ))}
          </Document>
        </div>
      </div>
      <div className="absolute bottom-[30px] left-1/2 -translate-x-1/2 w-[510px] h-[60px] rounded-[30px] bg-[#00000066] flex items-center justify-between pl-[30px] pr-5">
        <div className="grid grid-cols-[auto_auto_auto] items-center text-[#F2F2F3] text-base font-bold select-none">
          <div>ページ&nbsp;</div>
          <Form form={form}>
            <Form.Item name="currentViewPage" className="mb-0">
              <InputNumber
                bordered={false}
                controls={false}
                className="custom-page-jump w-[50px] h-[30px] bg-[#00000066] hover:bg-[#00000066] focus:bg-[#00000066] rounded-none"
                onFocus={onFocusInput}
                onPressEnter={onJumpToPage}
                onWheel={(e) => e.currentTarget.blur()}
                onKeyDown={(event) => {
                  const re = /^[0-9\b]+$/
                  if (!re.test(event.key) && event.key !== 'Backspace') {
                    event.preventDefault()
                  }
                }}
              />
            </Form.Item>
          </Form>
          <div>&nbsp;&#47;&nbsp;{pageCount}</div>
        </div>

        <div className="flex gap-5 items-center">
          <div
            className={`w-11 h-11 grid place-items-center rounded-full ${
              scale >= 10
                ? 'cursor-not-allowed opacity-60'
                : 'cursor-pointer active:bg-[#00000033]'
            }`}
            onClick={zoomIn}
          >
            <ZoomInIcon />
          </div>
          <div
            className={`w-11 h-11 grid place-items-center rounded-full ${
              scale <= 1
                ? 'cursor-not-allowed opacity-60'
                : 'cursor-pointer active:bg-[#00000033]'
            }`}
            onClick={zoomOut}
          >
            <ZoomOutIcon />
          </div>
          <div
            className="w-11 h-11 grid place-items-center rounded-full active:bg-[#00000033] cursor-pointer"
            onClick={() => {
              window.open(URL.createObjectURL(blob), file.name)?.print()
            }}
          >
            <PrintIcon />
          </div>
          <div
            className="w-11 h-11 grid place-items-center rounded-full active:bg-[#00000033] cursor-pointer"
            onClick={downLoadFile}
          >
            <DownloadIcon />
          </div>
        </div>
      </div>
      {isProtected && (
        <Modal isEnterable onEnter={handleEnterSubmit} rootClassName="">
          <Typography>PDFファイルのパスワードを入力してください</Typography>
          <Form
            form={passwordForm}
            preserve={false}
            onFinish={handleModalSubmit}
            className="mt-5"
            onFocus={() => {
              stopPropagation.current = true
            }}
            onBlur={() => {
              stopPropagation.current = false
            }}
          >
            <Form.Item name="password">
              <Input
                type="password"
                onChange={() =>
                  passwordForm.setFields([
                    {
                      name: 'password',
                      errors: undefined
                    }
                  ])
                }
              />
            </Form.Item>
            <div className="flex justify-end gap-[10px]">
              <Button onClick={handleModalCancel}>キャンセル</Button>
              <Button htmlType="submit" type="primary">
                保存する
              </Button>
            </div>
          </Form>
        </Modal>
      )}
    </>
  )
}
