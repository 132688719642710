import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Radio } from 'antd'
import TableColumn from 'constants/tableColumn'
import { ListBranch } from 'models/Branch'
import useSWR from 'swr'
import { OrderByType } from 'types'
import { ParamQueryBranch } from 'types/listBranch'
import { SelectBranch } from 'types/listUser'
import Utils from 'utils'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'
import ExtraTable from 'components/organisms/ExtraTable'

import FormSearchBranch from './FormSearchBranch'

type Props = {
  handleOk: (value: SelectBranch) => void
  handleCancel: () => void
  buildingBranchName?: string
}

export default function ModalBranch({
  handleOk,
  handleCancel,
  buildingBranchName
}: Props) {
  const [queries, setQueries] = useState<ParamQueryBranch | null>({
    sort: 'name',
    sortOrder: OrderByType.descend,
    type: 'name'
  })
  const { t } = useTranslation()
  const stopPropagation = useRef<boolean>(false)

  const { data: branches, isValidating } = useSWR<ListBranch>({
    url: `/v1/branches`,
    params: queries
  })

  const listBranch = branches?.edges
    ? branches?.edges.map((branch) => {
        return {
          ...branch,
          checkbox: <Radio value={branch.id} />
        }
      })
    : []

  const [branchId, setBranchId] = useState<SelectBranch | undefined>(undefined)

  const onTableChange = useCallback((sorter: string, field: string) => {
    if (field === 'checkbox') {
      field = 'name'
    }
    setQueries((prev) => ({
      ...prev,
      sort: field,
      sortOrder: sorter
        ? OrderByType[sorter as keyof typeof OrderByType]
        : OrderByType.descend
    }))
  }, [])

  const handleSearch = (value: any) => {
    setQueries((prev) => ({
      ...prev,
      ...Utils.trimObjectValues(value)
    }))
  }

  const handleChangeCheckbox = (event: any) => {
    const nameOrganization = listBranch.find((item) => item.id === event.id)
    if (nameOrganization) {
      setBranchId({
        id: event.id,
        name: event.name
      })
    }
  }

  const handleSubmit = () => {
    if (branchId) {
      handleOk(branchId)
    }
    if (!branchId && branches) {
      handleCancel()
    }
  }

  const handleEnterSubmit = () => {
    if (stopPropagation.current) return
    handleSubmit()
  }

  return (
    <Modal
      onCancel={handleCancel}
      width={1074}
      className="modal-organization"
      zIndex={1000}
      rootClassName=""
      isEnterable
      onEnter={handleEnterSubmit}
    >
      <Title title="organization.selectBranch" />
      <div className="bg-white rounded-lg w-full mb-5 flex items-center">
        <FormSearchBranch
          handleSearch={handleSearch}
          onFormFocus={() => {
            stopPropagation.current = true
          }}
          onFormBlur={() => {
            stopPropagation.current = false
          }}
        />
      </div>
      {branches ? (
        <Radio.Group
          className="w-full table-list-org-invite"
          defaultValue={
            branches.edges.find((item) => item.name === buildingBranchName)?.id
          }
          value={branchId && branchId.id}
        >
          <ExtraTable
            isDetail={false}
            scroll={{ y: 350 }}
            isLoading={isValidating || !listBranch}
            columns={TableColumn.COLUMN_USER_SELECT_BRANCH}
            data={listBranch}
            onChange={onTableChange}
            total={branches?.pagination.total}
            actionSelect={handleChangeCheckbox}
          />
        </Radio.Group>
      ) : (
        <ExtraTable
          isDetail={false}
          scroll={{ y: 350 }}
          isLoading={isValidating || !listBranch}
          columns={TableColumn.COLUMN_USER_SELECT_BRANCH}
          data={[]}
        />
      )}

      <div className="flex justify-center gap-10 mt-[30px]">
        <Button
          sizing="w-[140px] h-[50px]"
          className="px-3 py-[10px] bg-[#A6AAAF]"
          onClick={handleCancel}
        >
          {t('organization.buttonCancel')}
        </Button>
        <Button
          sizing="w-[140px] h-[50px]"
          className="px-3 py-[10px]"
          type="primary"
          onClick={handleSubmit}
        >
          {t('organization.buttonSelect')}
        </Button>
      </div>
    </Modal>
  )
}

ModalBranch.defaultProps = {
  buildingBranchName: undefined
}
