import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function StairSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 64 64"
    >
      <g id="Layer_31" data-name="Layer 31" fill="white">
        <path d="M28.4,13.44a3.69,3.69,0,0,0,0-7.38A3.69,3.69,0,0,0,28.4,13.44Zm0-6.38a2.69,2.69,0,0,1,0,5.38A2.69,2.69,0,0,1,28.4,7.06Z" />
        <path d="M52.91,15.38H44.2a4,4,0,0,0-3.15,1.53L24.26,38.18h0l-4.11,5h0a6.06,6.06,0,0,1-4.78,2.32H11.09c-6.34.2-6.35,9.4,0,9.6H19.8A4,4,0,0,0,23,53.63l8.1-10.11h0L43.84,27.3A6.09,6.09,0,0,1,48.63,25h4.28C59.25,24.79,59.26,15.58,52.91,15.38Z" />
        <path d="M52.91,12.59H44.2a6.76,6.76,0,0,0-5.34,2.59l-6.22,7.61V18.2a3.61,3.61,0,0,0-7.21,0V31.88S18,41.49,18,41.51a3.29,3.29,0,0,1-2.59,1.25H11.09a7.59,7.59,0,1,0,0,15.18H19.8a6.75,6.75,0,0,0,5.33-2.58h0l8.1-10.11h0L46,29h0a3.29,3.29,0,0,1,2.6-1.26h4.28C63,27.42,63,13,52.91,12.59Zm0,14.18H48.63a4.28,4.28,0,0,0-3.38,1.64h0L32.45,44.63h0l-8.1,10.1h0a5.75,5.75,0,0,1-4.55,2.2H11.09c-8.72-.31-8.72-12.87,0-13.18h4.28a4.24,4.24,0,0,0,3.41-1.68l7.54-9.72a.58.58,0,0,0,.09-.25s0-13.85,0-13.91a2.61,2.61,0,0,1,5.22,0v6a.5.5,0,0,0,.89.31l7.11-8.7a5.79,5.79,0,0,1,4.56-2.21h8.71C61.63,13.9,61.63,26.46,52.91,26.77Z" />
      </g>
    </svg>
  )
}

function SelectedStairSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 64 64"
    >
      <g id="Layer_31" data-name="Layer 31" fill="#609DF9">
        <path d="M28.4,13.44a3.69,3.69,0,0,0,0-7.38A3.69,3.69,0,0,0,28.4,13.44Zm0-6.38a2.69,2.69,0,0,1,0,5.38A2.69,2.69,0,0,1,28.4,7.06Z" />
        <path d="M52.91,15.38H44.2a4,4,0,0,0-3.15,1.53L24.26,38.18h0l-4.11,5h0a6.06,6.06,0,0,1-4.78,2.32H11.09c-6.34.2-6.35,9.4,0,9.6H19.8A4,4,0,0,0,23,53.63l8.1-10.11h0L43.84,27.3A6.09,6.09,0,0,1,48.63,25h4.28C59.25,24.79,59.26,15.58,52.91,15.38Z" />
        <path d="M52.91,12.59H44.2a6.76,6.76,0,0,0-5.34,2.59l-6.22,7.61V18.2a3.61,3.61,0,0,0-7.21,0V31.88S18,41.49,18,41.51a3.29,3.29,0,0,1-2.59,1.25H11.09a7.59,7.59,0,1,0,0,15.18H19.8a6.75,6.75,0,0,0,5.33-2.58h0l8.1-10.11h0L46,29h0a3.29,3.29,0,0,1,2.6-1.26h4.28C63,27.42,63,13,52.91,12.59Zm0,14.18H48.63a4.28,4.28,0,0,0-3.38,1.64h0L32.45,44.63h0l-8.1,10.1h0a5.75,5.75,0,0,1-4.55,2.2H11.09c-8.72-.31-8.72-12.87,0-13.18h4.28a4.24,4.24,0,0,0,3.41-1.68l7.54-9.72a.58.58,0,0,0,.09-.25s0-13.85,0-13.91a2.61,2.61,0,0,1,5.22,0v6a.5.5,0,0,0,.89.31l7.11-8.7a5.79,5.79,0,0,1,4.56-2.21h8.71C61.63,13.9,61.63,26.46,52.91,26.77Z" />
      </g>
    </svg>
  )
}

export default function StairIcon(
  props: Partial<CustomIconComponentProps> & { selected?: boolean }
) {
  const { selected, ...rest } = props

  if (selected) return <Icon component={SelectedStairSvg} {...rest} />

  return <Icon component={StairSvg} {...rest} />
}

StairIcon.defaultProps = {
  selected: undefined
}
