import { atom } from 'recoil'
import { Annotation } from 'types/building'

type Props = {
  h: number
  v: number
  z: number
} | null

export const currentPovState = atom<Props>({
  key: 'currentPov',
  default: null
})

export const currentAnnotation = atom<
  (Annotation & { isMutating?: boolean }) | undefined
>({
  key: 'currentAnnotation',
  default: undefined
})
