import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { notification, Typography } from 'antd'
import useMutation from 'hooks/useMutation'
import { Building } from 'models/Building'
import { useRecoilState } from 'recoil'
import {
  buildingModeState,
  listPointState,
  selectedPointDetail
} from 'store/buildingStore'
import { displayToggleState } from 'store/displayToggleStore'
import useSWR from 'swr'
import Krpano from 'utils/krpano'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'
import LockIcon from 'components/icons/LockIcon'

export default function ModalFinishAllEditing() {
  const { id } = useParams()
  const { t } = useTranslation()
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false)

  const [, setPoints] = useRecoilState(listPointState)
  const [, setDisplayToggle] = useRecoilState(displayToggleState)
  const [, setPointDetail] = useRecoilState(selectedPointDetail)
  const [, setBuildingMode] = useRecoilState(buildingModeState)

  const { data: building } = useSWR<Building>(
    {
      url: `/v1/buildings/${id}`
    },
    {
      revalidateOnMount: false
    }
  )

  const { trigger: finishAllEditing, isMutating: isFinishAllEditing } =
    useMutation(`v1/buildings/${id}/unlock-all-points`, {
      onSuccess: () => {
        setPoints((prev) =>
          prev.map((item) => ({
            ...item,
            lockedBy: null,
            isLocked: false
          }))
        )
        Krpano.TogglePointLock(false)
        setDisplayToggle({ lockedBy: '', isLocked: false })
        setPointDetail(
          (prev) => prev && { ...prev, isLocked: false, lockedBy: null }
        )
        setBuildingMode((prev) => ({
          ...prev,
          selectedMode: ''
        }))
        setIsOpenModalConfirm(false)
        notification.success({
          key: 'finishAllEditingSuccess',
          message: t('building.finishAllEditingSuccess')
        })
      },
      onError: () => {
        setIsOpenModalConfirm(false)
        notification.error({
          key: 'finishAllEditingError',
          message: t('building.finishAllEditingError')
        })
      }
    })

  const handleToggleDisplayModal = () => {
    setIsOpenModalConfirm((prev) => !prev)
  }

  const handleSubmitFinishAllEditing = () => {
    !isFinishAllEditing &&
      finishAllEditing({ data: undefined, method: 'patch' })
  }

  return (
    <>
      <div
        id="point-action-menu-rightest-element"
        className="flex flex-col justify-center gap-[6px] rounded-full bg-[#021120CC] w-[114px] h-[60px] cursor-pointer"
        onClick={handleToggleDisplayModal}
      >
        <div className="h-5 ml-[50px]">
          <LockIcon isSelfLocked />
        </div>
        <Typography className="text-white font-bold text-[10px] leading-[10px] text-center">
          {t('building.finishAllEditing')}
        </Typography>
      </div>
      {isOpenModalConfirm && (
        <Modal open width={480} isEnterable onEnter={undefined}>
          <Title
            title={t('building.titleConfirmFinishAllEditing')}
            bottomMargin="mb-8"
          />

          <div className="flex flex-col gap-10 text-center">
            <Typography className="text-[14px] leading-[21px]">
              {t('building.descriptionConfirmFinishAllEditing')}
            </Typography>

            <div className="bg-[#F9FBFF] rounded-[10px] py-[18px] px-5">
              {building?.name}
            </div>

            <div className="flex gap-10 justify-center">
              <Button
                sizing="w-[140px] h-[50px]"
                onClick={handleToggleDisplayModal}
              >
                {t('building.buttonCancel')}
              </Button>

              <Button
                loading={isFinishAllEditing}
                type="primary"
                sizing="w-[140px] h-[50px]"
                onClick={handleSubmitFinishAllEditing}
              >
                {t('building.submitFinishAllEditing')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}
