import { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Avatar, Divider, Form, Input, Select, Typography } from 'antd'
import clsx from 'clsx'
import TableColumn from 'constants/tableColumn'
import useAuth from 'hooks/useAuth'
import useDebounce from 'hooks/useDebounce'
import useMutation from 'hooks/useMutation'
import { ListSharedUser } from 'models/Building'
import useSWR from 'swr'
import { OrderByType } from 'types'
import Utils from 'utils'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import DropdownIconCustom from 'components/icons/DropdownIconCustom'
import UserGroupIcon from 'components/icons/UserGroupIcon'
import ExtraTable from 'components/organisms/ExtraTable'

import UserPermissionSelector from './SelectorUserPermission'

const { Option } = Select

type Props = {
  handleCancel: () => void
}

export default function ShareBuildingModal({ handleCancel }: Props) {
  const { t } = useTranslation()
  const { id: buildingId } = useParams()
  const [form] = Form.useForm()
  const { profile } = useAuth()
  const navigate = useNavigate()

  const stopPropagation = useRef<boolean>(false)
  const [userToBeInvited, setUserToBeInvited] = useState<number[]>()
  const [permissionInSelect, setPermissionInSelect] = useState<'VIEW' | 'EDIT'>(
    'VIEW'
  )
  const [invitedError, setInvitedError] = useState(false)
  const [searchString, setSearchString] = useState<string>()
  const [sharedUserList, setSharedUserList] = useState<any[]>([])

  const [queries, setQueries] = useState({
    sort: 'name',
    sortOrder: 'asc'
  })

  const debouncedSearchString = useDebounce(searchString, 300)

  const { data: userCanBeInvited, mutate } = useSWR({
    url: '/v1/users/list-for-building',
    params: {
      building_id: buildingId,
      keyword: debouncedSearchString
    }
  })

  const [filterString, setFilterString] = useState<string>('')

  const [userToBeUpdated, setUserToBeUpdated] = useState<
    { action: string; id: number }[]
  >([])

  const handleSelect = (option: any) => {
    setUserToBeUpdated((prev) => [
      ...prev.filter((item) => item.id !== option.userId),
      { id: option.userId, action: option.value }
    ])
  }

  const {
    data: sharedUser,
    isValidating,
    mutate: reloadSharedUserList
  } = useSWR<ListSharedUser>(
    {
      url: `v1/buildings/${buildingId}/shared-user`,
      params: { ...queries, keyword: filterString }
    },
    {
      onSuccess: (data) => {
        setSharedUserList(
          data?.edges.map((u) => ({
            ...u,
            key: u.id,
            name: (
              <Typography className="text-sm text-[#021120] font-medium">
                {u.lastName}&nbsp;{u.firstName}
              </Typography>
            ),
            organizationName: (
              <Typography className="text-[14px] leading-7 text-[#021120]">
                {u.organizationName || u.companyName || ''}
              </Typography>
            ),
            email: (
              <Typography className="text-[14px] leading-7 text-[#021120]">
                {u.email}
              </Typography>
            ),
            action: (
              <UserPermissionSelector
                user={u}
                profile={profile}
                handleSelect={handleSelect}
              />
            )
          }))
        )
      }
    }
  )

  const {
    trigger: callApiUpdateUserPermission,
    isMutating: isUpdatingPermission
  } = useMutation('/v1/building-users')

  const listUsers: any = useMemo(
    () =>
      userCanBeInvited?.users.filter(
        (item: any) =>
          !sharedUser?.edges.find((subitem) => subitem.id === item.id)
      ) || [],
    [userCanBeInvited, sharedUser]
  )

  const { trigger: callApiInviteUserToBuilding, isMutating } = useMutation(
    '/v1/users/invite-building'
  )

  const handleInvite = () => {
    stopPropagation.current = false
    !isMutating &&
      userToBeInvited?.length &&
      callApiInviteUserToBuilding(
        {
          data: {
            action: permissionInSelect,
            buildingId: Number(buildingId),
            users: userToBeInvited
          }
        },
        {
          onSuccess: (data) => {
            !isMutating && reloadSharedUserList()
            setUserToBeInvited((prev) =>
              Utils.filterInvalidEmailInvite(
                prev,
                data.data.notExistUsers,
                data.data.invitedUsers
              )
            )
            if (
              data.data.notExistUsers.length ||
              data.data.invitedUsers.length
            ) {
              setInvitedError(true)
              form.setFields([
                {
                  name: 'usersInvite',
                  errors: [
                    Utils.inviteMessageError(
                      !!data.data.notExistUsers.length,
                      !!data.data.invitedUsers.length
                    )
                  ]
                }
              ])
              mutate()
            }
          },
          onError: (error) => {
            Utils.handleErrorNavigate(error, navigate)
          }
        }
      )
  }

  const onTableChange = useCallback((sorter: string, field: string) => {
    setQueries((prev) => ({
      ...prev,
      sort: sorter ? field : 'name',
      sortOrder: sorter
        ? OrderByType[sorter as keyof typeof OrderByType]
        : OrderByType.ascend
    }))
  }, [])

  const handleUpdate = () => {
    if (stopPropagation.current) return
    !isUpdatingPermission &&
      callApiUpdateUserPermission(
        {
          data: {
            buildingId: Number(buildingId),
            users: userToBeUpdated
          },
          successMessage: 'ユーザー権限を正常に更新しました。'
        },
        {
          onSuccess: () => {
            reloadSharedUserList()
            setUserToBeUpdated([])
          },
          onError: (error) => {
            Utils.handleErrorNavigate(error, navigate)
          }
        }
      )
  }

  const handleResetError = () => {
    form.setFields([
      {
        name: 'usersInvite',
        errors: undefined
      }
    ])
    setInvitedError(false)
    invitedError && setUserToBeInvited([])
  }

  const handleClose = () => {
    setUserToBeInvited([])
    handleCancel()
  }

  const items = [
    { value: 'VIEW', label: '閲覧者' },
    { value: 'EDIT', label: '編集者' }
  ]

  const handleChangeAction = (value: 'VIEW' | 'EDIT') => {
    setPermissionInSelect(value)
  }

  const onFormFocus = () => {
    stopPropagation.current = true
  }

  const onFormBlur = () => {
    stopPropagation.current = false
  }

  return (
    <Modal
      width="840px"
      rootClassName="shared-permission-modal"
      isEnterable
      onEnter={handleUpdate}
    >
      <div className="flex flex-col w-[45rem]">
        <Typography className="text-center text-[20px] font-[700] text-[#3163B7]">
          {t('common.buildingMenu.editShare')}
        </Typography>
        <hr className="w-[40px] border-0 border-b border-solid border-[#3163B7]" />

        <Form form={form} onFinish={handleInvite} onFocus={onFormFocus}>
          <div className="grid grid-cols-[1fr_auto] w-full mt-[10px] gap-[30px]">
            <Form.Item
              name="usersInvite"
              className="mb-5 thin-error-message w-full relative"
            >
              <div className="flex items-center overflow-auto max-h-[100px] rounded-md">
                <Select
                  mode="multiple"
                  value={userToBeInvited}
                  placeholder="メールアドレス、名前をカンマで区切って入力"
                  defaultActiveFirstOption={false}
                  suffixIcon={null}
                  filterOption={false}
                  onSearch={(newValue: string) => {
                    setSearchString(newValue)
                  }}
                  onChange={(newValue: number[]) => {
                    setUserToBeInvited(newValue)
                    setSearchString('')
                  }}
                  onFocus={handleResetError}
                  className={clsx('grow over-flow-select-box min-h-[40px]', {
                    'error-email-select': invitedError
                  })}
                  notFoundContent={null}
                  optionLabelProp="label"
                >
                  {listUsers.map((u: any) => (
                    <Option key={u.id} value={u.id} label={u.email}>
                      <div className="flex gap-3">
                        <Avatar
                          className="min-w-[2.5rem] text-black font-light"
                          size="large"
                        >
                          {u.lastName.split('')[0]}
                          {u.firstName.split('')[0]}
                        </Avatar>
                        <div className="grid">
                          <div className="text-sm h-5 text-[#021120]">
                            {u.lastName}&nbsp;{u.firstName}
                          </div>
                          <div className="text-xs h-4 text-[#555]">
                            {u.email}
                          </div>
                        </div>
                      </div>
                    </Option>
                  ))}
                </Select>

                <div className="absolute top-1/2 -translate-y-1/2 right-0">
                  <Select
                    defaultValue="VIEW"
                    bordered={false}
                    options={items}
                    onChange={handleChangeAction}
                    suffixIcon={
                      <DropdownIconCustom
                        colorIcon="#000"
                        width={13}
                        height={7}
                      />
                    }
                  />
                </div>
              </div>
            </Form.Item>

            <Button
              type="primary"
              htmlType="submit"
              sizing="w-[100px] h-[40px]"
              disabled={!userToBeInvited?.length || !!searchString}
            >
              {t('common.invitation')}
            </Button>
          </div>
        </Form>

        <Divider className="border-[#CED0D4] my-0 mb-[14px]" />

        <div className="flex gap-[6px]">
          <UserGroupIcon />
          <Typography className="leading-7 text-[#021120]">
            {t('translation.searchWithEmail')}
          </Typography>
        </div>

        <Form
          onFinish={(values) => setFilterString(values.filterString)}
          onFocus={onFormFocus}
          onBlur={onFormBlur}
        >
          <div className="flex w-full mt-[6px]">
            <Form.Item name="filterString" noStyle>
              <Input
                placeholder="メールアドレスをカンマで区切って入力"
                className="w-[590px] mr-[30px] text-sm"
              />
            </Form.Item>
            <Button
              htmlType="submit"
              sizing="w-[100px] h-[40px]"
              className="bg-[#3163B7]"
            >
              {t('user.buttonSearch')}
            </Button>
          </div>
        </Form>

        <div className="grow">
          <ExtraTable
            isLoading={isValidating}
            columns={TableColumn.COLUMN_USER_SHARED}
            rowKey="id"
            data={sharedUserList}
            scroll={{ y: 300 }}
            className="mt-[14px]"
            size="small"
            bordered={false}
            isDetail={false}
            onChange={onTableChange}
          />
        </div>

        <div className="flex gap-10 justify-center w-full items-end mt-5">
          <Button onClick={handleClose} sizing="w-[140px] h-[50px]">
            {t('common.cancel')}
          </Button>
          <Button
            onClick={handleUpdate}
            sizing="w-[140px] h-[50px]"
            className="bg-[#3163B7]"
          >
            {t('common.confirm')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
