import { Menu, Typography } from 'antd'
import { useRecoilState } from 'recoil'
import currentViewerState from 'store/viewerStore'
import { AreaDetail, Point } from 'types/building'

import RotatedArrowIcon from 'components/icons/RotatedArrowIcon'

import ViewerPointImages from './ViewerPointImages'

type Props = {
  areas: AreaDetail[]
  points: Point[]
}

export default function ViewerAreaDropdown({ areas, points }: Props) {
  const [currentViewer, setCurrentViewer] = useRecoilState(currentViewerState)

  const handleOpenMenu = (id: any) =>
    setCurrentViewer((prev) => ({
      ...prev,
      selectedArea: id !== prev.selectedArea ? id : undefined
    }))

  const sortedPointList = points.sort((a, b) => a.order - b.order)

  const listArea = areas.map((area) => {
    const children = sortedPointList
      .filter((point) => point.areaId === area.id)
      .map((item) => ({
        key: item.id,
        label: <ViewerPointImages point={item} />
      }))
    return {
      key: `${area.id}`,
      label: (
        <div className="flex" onClick={() => handleOpenMenu(`${area.id}`)}>
          <Typography.Text
            className="text-white text-[13px] leading-[19.5px]"
            ellipsis={{
              tooltip: area.name
            }}
          >
            {area.name}
          </Typography.Text>
          <RotatedArrowIcon
            fill="#609DF9"
            className={`transition-all ml-auto ${
              currentViewer.selectedArea === `${area.id}` ? '' : '-rotate-90'
            }`}
          />
        </div>
      ),
      children
    }
  })

  return (
    <Menu
      theme="dark"
      mode="inline"
      style={{
        backgroundColor: '#1C314D'
      }}
      items={listArea}
      openKeys={
        currentViewer.selectedArea ? [currentViewer.selectedArea] : undefined
      }
      className="custom-list-area-dropdown overflow-auto"
      expandIcon={() => null}
    />
  )
}
