import { useEffect } from 'react'
import { Route, Routes as Switch } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
import { RouteObject } from 'types'

import DefaultLayout from 'components/templates/DefaultLayout'

import authRoutes from './authRoutes'
import ProtectedRoute from './ProtectedRoute'
import publicRoutes from './publicRoutes'
import RedirectHomeRoute from './RedirectHomeRoute'
import userRoutes from './userRoutes'

const routes: RouteObject[] = [...authRoutes, ...userRoutes]

export default function Routes() {
  const { profile, mutate } = useAuth()

  useEffect(() => {
    window.addEventListener('storage', () => mutate())
    return () => {
      window.removeEventListener('storage', () => mutate())
    }
  }, [mutate])

  return (
    <Switch>
      {routes.map(
        ({
          path,
          children,
          layout: RootLayout = DefaultLayout,
          guardOnLogged
        }) => {
          return (
            <Route
              key={path}
              path={path}
              element={
                guardOnLogged ? <RedirectHomeRoute /> : <ProtectedRoute />
              }
            >
              {children.map(
                ({ role, index, path, element, layout: NestedLayout }) => {
                  const Layout = NestedLayout || RootLayout

                  return (
                    (!role || !profile || role.includes(profile.role)) && (
                      <Route
                        key={path}
                        path={path}
                        index={index}
                        element={<Layout>{element}</Layout>}
                      />
                    )
                  )
                }
              )}
            </Route>
          )
        }
      )}

      {publicRoutes.children.map((item) => {
        const Layout = item.layout || DefaultLayout
        return (
          <Route
            key={item.name}
            path={item.path}
            element={<Layout>{item.element}</Layout>}
          />
        )
      })}
    </Switch>
  )
}
