type Props = {
  colorIcon: string
  width?: number
  height?: number
}

export default function DropdownIconCustom({
  colorIcon,
  width,
  height
}: Props) {
  return (
    <svg
      width={width?.toString()}
      height={height?.toString()}
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.646009 0.646894C0.692455 0.60033 0.74763 0.563387 0.808375 0.538181C0.86912 0.512974 0.934242 0.5 1.00001 0.5C1.06578 0.5 1.1309 0.512974 1.19164 0.538181C1.25239 0.563387 1.30756 0.60033 1.35401 0.646894L7.00001 6.29389L12.646 0.646894C12.6925 0.600406 12.7477 0.563529 12.8084 0.53837C12.8692 0.513211 12.9343 0.500262 13 0.500262C13.0658 0.500262 13.1309 0.513211 13.1916 0.53837C13.2523 0.563529 13.3075 0.600406 13.354 0.646894C13.4005 0.693381 13.4374 0.748571 13.4625 0.80931C13.4877 0.870049 13.5006 0.93515 13.5006 1.00089C13.5006 1.06664 13.4877 1.13174 13.4625 1.19248C13.4374 1.25322 13.4005 1.30841 13.354 1.35489L7.35401 7.35489C7.30756 7.40146 7.25239 7.4384 7.19164 7.46361C7.1309 7.48881 7.06578 7.50179 7.00001 7.50179C6.93424 7.50179 6.86912 7.48881 6.80838 7.46361C6.74763 7.4384 6.69245 7.40146 6.64601 7.35489L0.646009 1.35489C0.599446 1.30845 0.562503 1.25327 0.537296 1.19253C0.51209 1.13178 0.499115 1.06666 0.499115 1.00089C0.499115 0.935126 0.51209 0.870005 0.537296 0.80926C0.562503 0.748515 0.599446 0.693339 0.646009 0.646894Z"
        fill={colorIcon}
      />
    </svg>
  )
}

DropdownIconCustom.defaultProps = {
  width: 14,
  height: 8
}
