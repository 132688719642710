import { Dispatch, SetStateAction, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Input, Typography } from 'antd'
import useMutation from 'hooks/useMutation'
import Utils from 'utils'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'

type Props = {
  setIsOpen: Dispatch<SetStateAction<boolean>>
  getFloorDetail: () => void
}

export default function AddAreaModal({ getFloorDetail, setIsOpen }: Props) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { floorId } = useParams()
  const stopPropagation = useRef<boolean>(false)
  const navigate = useNavigate()

  const { trigger: addArea, isMutating } = useMutation(
    `v1/floors/${floorId}/areas`
  )

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleSubmit = (values: any) => {
    !isMutating &&
      addArea(
        {
          data: values,
          successMessage: t('building.saved')
        },
        {
          onSuccess: () => {
            getFloorDetail()
            handleClose()
          },
          onError: (error) => {
            Utils.handleErrorNavigate(error, navigate)
            handleClose()
          }
        }
      )
  }

  const handleEnterSubmit = () => {
    if (stopPropagation.current) return
    form
      .validateFields()
      .then(() => handleSubmit(form.getFieldsValue()))
      .catch(() => {})
  }

  return (
    <Modal
      className="shadow-[0_4px_14px_0_#00000040 rounded-[20px]"
      onCancel={handleClose}
      width={526}
      rootClassName=""
      isEnterable
      onEnter={handleEnterSubmit}
    >
      <div className="my-[30px] mx-9 text-center">
        <Title title={t('common.floor.addArea')} />

        <Form
          form={form}
          onFinish={handleSubmit}
          onFocus={() => {
            stopPropagation.current = true
          }}
          onBlur={() => {
            stopPropagation.current = false
          }}
        >
          <div className="flex items-center gap-[50px]">
            <Typography className="text-[#021120] text-[14px] leading-[21px] font-medium">
              {t('common.floor.areaName')}
            </Typography>
            <Form.Item
              name="name"
              className="mb-0 flex-grow thin-placeholder thin-error-message"
              rules={[
                {
                  validator: Validators.addFloorValidator(t)
                }
              ]}
            >
              <Input
                className="h-[38px] text-sm text-[#021120]"
                placeholder={t('common.floor.placeHolder')}
              />
            </Form.Item>
          </div>

          <div className="flex gap-10 justify-center mt-10">
            <Button sizing="w-[140px] h-[50px]" onClick={handleClose}>
              {t('building.buttonCancel')}
            </Button>

            <Button
              type="primary"
              sizing="w-[140px] h-[50px]"
              htmlType="submit"
            >
              {t('building.buttonSave')}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}
